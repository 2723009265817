import React, { useState, useEffect, useRef } from "react";
import "./voice_record.css";
import MicRecorder from "mic-recorder-to-mp3";
import PropTypes from "prop-types";

//MUI
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

//MUI icon
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";

const Input = styled("input")({
  display: "none",
});

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const VoiceRecord = (props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [uploadBool, setUploadBool] = useState(false);
  const audioRef = React.useRef();
  const playerButton = React.useRef();
  const timeline = React.useRef();
  const soundButton = React.useRef();

  // mui customized button
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }));

  //svg icons
  const playIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
<path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
</svg>
`;
  const pauseIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
<path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
</svg>
`;
  const soundIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
<path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
</svg>`;
  const muteIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
<path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
</svg>`;

  //play/pause audio
  const toggleAudio = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
      playerButton.current.innerHTML = pauseIcon;
    } else {
      audioRef.current.pause();
      playerButton.current.innerHTML = playIcon;
    }
  };

  //change audio timeline
  const changeTimelinePosition = () => {
    let state = audioRef.current;
    const percentagePosition = (100 * state.currentTime) / state.duration;
    timeline.current.style.backgroundSize = `${percentagePosition}% 100%`;
    timeline.current.value = percentagePosition;
  };

  const changeSeek = () => {
    const time = (timeline.current.value * audioRef.current.duration) / 100;
    audioRef.current.currentTime = time;
  };

  //mute/unmute sound
  const toggleSound = () => {
    audioRef.current.muted = !audioRef.current.muted;
    soundButton.current.innerHTML = audioRef.current.muted
      ? muteIcon
      : soundIcon;
  };

  //function called after audio ended
  const audioEnded = () => {
    playerButton.current.innerHTML = playIcon;
  };

  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobrUrl = URL.createObjectURL(blob);
        setBlobURL(blobrUrl);
        setIsRecording(false);
        var d = new Date();
        var file = new File([blob], (d.valueOf() + '.mp3'), { type: "audio/wav" });
        props.onVoiceUpload(file);
      })
      .catch((e) => console.log(e));
  };

  const handleClick = () => {
    !isRecording ? start() : stop();
  };

  const handleUploadClick = () => {
    setUploadBool((prev) => !prev);
    props.onUpload(uploadBool);
  };

  const handleVoiceAdd = (e) => {
    const newVoice = e.target.files[0];
    const blobrUrl = URL.createObjectURL(newVoice);
    setBlobURL(blobrUrl);
    setIsRecording(false);
    var d = new Date();
    var file = new File([newVoice], d.valueOf(), { type: "audio/wav" });
    props.onVoiceUpload(file);
  };

  useEffect(() => {
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false);
      },
      () => {
        setIsBlocked(true);
      }
    );
  }, []);

  return (
    <DialogContent sx={{ backgroundColor: "#bdd6ff" }}>
      <div className="voice_record">
        <header className="voice_record-header">
          {!isRecording ? (
            <IconButton
              onClick={handleClick}
              id="start"
              size="large"
              style={{
                position: "relative",
                width: "20%",
                margin: "10%",
                height: "85px",
                color: " #fff",
                lineHeight: "37px",
                textAlign: "center",
                borderRadius: "50%",
                backgroundColor: "#1553b5",
              }}
              className="button"
            >
              <KeyboardVoiceIcon fontSize="inherit" />
            </IconButton>
          ) : (
            <IconButton
              onClick={handleClick}
              id="stop"
              size="large"
              style={{
                position: "relative",
                width: "20%",
                margin: "10%",
                height: "85px",
                color: " #fff",
                lineHeight: "37px",
                textAlign: "center",
                borderRadius: "50%",
                backgroundColor: "#1553b5",
              }}
              className="button rec-effect"
            >
              <GraphicEqIcon />
            </IconButton>
          )}
          <br />
          {/* {blobURL !== "" && <audio src={blobURL} controls="controls" />} */}
        </header>
        <label htmlFor="contained-button-file">
          <Input
            accept="audio/*	"
            id="contained-button-file"
            multiple
            type="file"
            onChange={handleVoiceAdd}
          />

          <ColorButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
              display: "flex",
              marginBottom: "20px",
            }}
            variant="contained"
            component="span"
          >
            Add from your device
          </ColorButton>
        </label>
        <audio
          controls
          ref={audioRef}
          onTimeUpdate={changeTimelinePosition}
          onEnded={audioEnded}
          src={blobURL}
          hidden
        ></audio>
        {blobURL !== "" && (
          <div>
            <div className="controls">
              <button
                className="player-button"
                ref={playerButton}
                onClick={toggleAudio}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#1553b5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <input
                type="range"
                ref={timeline}
                className="timeline"
                max="100"
                value="0"
                onChange={changeSeek}
              />
              <button
                className="sound-button"
                ref={soundButton}
                onClick={toggleSound}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#1553b5"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            {/* voice upload button */}
            <div className="upload_Button">
              <ColorButton
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  display: "flex",
                }}
                variant="contained"
                onClick={handleUploadClick}
              >
                Upload
              </ColorButton>
            </div>
          </div>
        )}
      </div>
    </DialogContent>
  );
};

VoiceRecord.propTypes = {
  onVoiceUpload: PropTypes.func,
  onUpload: PropTypes.func,
};
export default VoiceRecord;
