import { useEffect, useState } from "react";

import {
  CircularProgress, Box, Paper, Select, Stack, Table,
  MenuItem, Checkbox, ListItemText, OutlinedInput,
  TableBody, TableCell, tableCellClasses, TableContainer,
  TableHead, TableRow, styled, Typography, FormControl, InputLabel
} from "@mui/material"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import { StyledTextField } from "../Report";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

function VeriferTable() {
  const [date, setDate] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [districts, setDistricts] = useState([])
  const [selectedDistricts, setSelectedDistricts] = useState([])
  const [verifierPerformance, setVerifierPerformance] = useState([])
  const { token } = useSelector((state) => state.token.value)

  const createData = (name, district, inTime, outTime, caseCount, km) => {
    return { name, district, inTime, outTime, caseCount, km };
  }

  const rows = verifierPerformance.map(data =>
    createData(
      data.verifier.split("/")[0],
      data.district,
      data.punchInTime,
      data.punchOutTime,
      data.caseCount, data.km
    )
  )

  useEffect(async () => {
    const params = new URLSearchParams()
    params.append("role", "verifier")
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      })

      if (response.data.status !== 100)
        return

      const districts = response.data.result.map(item => item.district)
        .filter((ele, i, arr) => arr.indexOf(ele) === i)

      setDistricts(districts)
      setSelectedDistricts(districts)
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(async () => {
    if (selectedDistricts.length) {
      setLoading(true)
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/VerifierPerfomanceDashboard`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            date: moment(date).format("YYYY-MM-DD"),
            districts: selectedDistricts
          }
        })
        if (response.data.status === 1)
          setVerifierPerformance(response.data.data)
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }
  }, [date, selectedDistricts])

  const handleChange = (event) => {
    const { target: { value } } = event
    if (value.includes("select-all")) {
      if (value.length === districts.length + 1)
        setSelectedDistricts([])
      else
        setSelectedDistricts(districts)
    } else {
      setSelectedDistricts(value)
    }
  }

  return (
    <Box mt={5} mb={8}>
      <Typography variant="h6" fontWeight={600} fontSize={16} mb={1}>
        Verifier Performance
      </Typography>

      <Stack direction="row" spacing={3} mb={2} mt={2}>
        <FormControl sx={{ display: "block", width: 500 }}>
          <InputLabel size="small" id="verifier-table-date-label">District</InputLabel>
          <Select
            labelId="verifier-table-date-label"
            fullWidth
            size="small"
            multiple
            value={selectedDistricts}
            onChange={handleChange}
            input={<OutlinedInput size="small" label="District" />}
            renderValue={selected => selected.includes("select-all") ? "All" : selected.join(", ")}
          >
            <MenuItem dense key="select-all" value="select-all">
              <Checkbox
                checked={selectedDistricts.length === districts.length}
              />
              <ListItemText primary="Select All" />
            </MenuItem>
            {districts.map(district => (
              <MenuItem dense key={district} value={district}>
                <Checkbox checked={selectedDistricts.indexOf(district) > -1} />
                <ListItemText primary={district} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl size="small">
          <Stack direction="row" spacing={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                label="Date"
                inputFormat="DD/MM/YY"
                value={date}
                onChange={(date) => setDate(date.$d)}
                renderInput={(params) => (
                  <StyledTextField
                    sx={{
                      width: "80%",
                      '& label': {
                        zIndex: '-1'
                      }
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
            <Box width={20} height={40}>
              {loading && <CircularProgress sx={{ height: 10, width: 10 }} />}
            </Box>
          </Stack>
        </FormControl>
      </Stack>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>District</StyledTableCell>
              <StyledTableCell>In Time</StyledTableCell>
              <StyledTableCell>Out Time</StyledTableCell>
              <StyledTableCell align="right">Case Count</StyledTableCell>
              <StyledTableCell align="right">KM</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell>{row.district}</StyledTableCell>
                <StyledTableCell>{row.inTime}</StyledTableCell>
                <StyledTableCell>{row.outTime}</StyledTableCell>
                <StyledTableCell align="right">{row.caseCount}</StyledTableCell>
                <StyledTableCell align="right">{row.km}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default VeriferTable
