import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import moment, { max } from "moment";
import JsFileDownloader from "js-file-downloader";

// material ui
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

//custom MUI select
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "15px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 150,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#000",
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "#000",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "#000",
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: "#000",
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: "#000",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
  {
    color: "#000",
  },
  [`& .${inputLabelClasses.outlined}`]: {
    color: "#000",
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: "#000",
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: "#000",
  },
});

const FileList = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.token.value);
  const { empId } = useSelector((state) => state.user.value);

  const [value, setValue] = useState(0);
  const [apiCallBool, setApiCallBool] = useState(false);
  const [loading, setLoading] = useState(false);
  const [samplerListData, setSamplerListData] = useState([]); // sampler list api response is stored in this state
  const [verifierListData, setVerifierListData] = useState([]); // verifier list api response is stored in this state
  const [bankListData, setBankListData] = useState([]); // bank list api response is stored in this state
  const [branchList, setBranchList] = useState([]); // branch list api response is stored in this state
  const [productList, setProductList] = useState([]); //product list api reponse is stored in this state
  const [selectedBankMulti, setSelectedBankMulti] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedProdMulti, setSelectedProdMulti] = useState([]);
  const [selectedVerifiers, setSelectedVerifiers] = useState([]);
  const [selectedSamplers, setSelectedSamplers] = useState([]);
  const [selectProdSingle, setSelectProdSingle] = useState({
    productRefId: "",
    productName: "",
    fields: "",
    productType: "",
  });
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const [input, setInput] = useState({
    bankId: "",
    bankName: "",
    bankRefId: "",
    reportStatus: [],
    branchName: [],
    productName: [],
    verifier: [],
    sampler: [],
    supervisor: [],
    fromdate: "",
    todate: "",
    sortby: "",
  });
  //api call for generate report . Here useLayoutEffect and useRef is used to prevent api call on initial render
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    switch (value) {
      case 0: //MIS-1
        const mis1 = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getMIS1Report`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankId,
                bankName: input.bankName,
                branchName: input.branchName,
                productName: input.productName,
                from: input.fromdate,
                to: input.todate,
                reportStatus: input.reportStatus,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        mis1();
        break;
      case 1: //MIS-2
        if (selectProdSingle.productType === 'general') {
          var productNames = [];

          productList.forEach(product => {
            if (product.type === 'general') {
              productNames.push({
                product_name: product.productName,
                productRefId: product.id
              })
            }
          })
        }

        const mis2 = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getMIS2Report`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankId,
                bankName: input.bankName,
                branchName: input.branchName,
                // productRefId: selectProdSingle.productRefId,
                productName: selectProdSingle.productType === 'general' ? productNames : selectProdSingle.productName,
                ...(selectProdSingle.productType !== 'general' && { productRefId: selectProdSingle.productObjId }),
                fields: selectProdSingle.fields,
                Type: selectProdSingle.productType,
                from: input.fromdate,
                to: input.todate,
                reportStatus: input.reportStatus,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        mis2();
        break;
      case 2: //Other
        const other = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getMIS1Report`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankId,
                bankName: input.bankName,
                productRefId: selectProdSingle.productRefId,
                productName: selectProdSingle.productName,
                fields: selectProdSingle.fields,
                from: input.fromdate,
                to: input.todate,
                reportStatus: input.reportStatus,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        other();
        break;
      case 3: //Pending
        const pending = async () => {
          setLoading(true);
          try {
            let bankId = [];
            selectedBankMulti.map((id) => {
              let result = [...bankId, id.value];
              bankId = result.map(String);
            });
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getVerifierPendingReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: bankId,
                verifier: input.verifier,
                sortBy: input.sortby,
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        pending();
        break;
      case 4: //Login
        const login_details = async () => {
          setLoading(true);
          try {
            let bankId = [];
            selectedBankMulti.map((id) => {
              bankId = [...bankId, id.value];
            });
            let reqBranchNames = [];
            let reqProductNames = [];
            input.branchName.map((data) => {
              reqBranchNames = [...reqBranchNames, data.branch_name];
            });
            input.productName.map((data) => {
              reqProductNames = [...reqProductNames, data.product_name];
            });
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getLoginMIS`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankRefId,
                bankName: input.bankName,
                branch: reqBranchNames,
                product: reqProductNames,
                sampler: input.sampler,
                from: input.fromdate,
                to: input.todate,
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        login_details();
        break;
      default:
        break;
    }
  }, [apiCallBool]);

  // mui customized button
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    border: "1px solid #1553b5",
    backgroundColor: "#1553b5",
    color: "#fff !important",
    padding: "4px 8px",
    margin: "auto 2px",
    "&:hover": {
      border: "1px solid #f4f4f4",
      backgroundColor: "#1553b5",
      color: "#fff !important",
    },
  }));

  // fucntion to get VerifierList SamplerList
  // api call to get verifier list
  const getVerifierList = async () => {
    const params = new URLSearchParams();
    params.append("role", "verifier");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      });
      if (response.data.status !== 100)
        return

      setVerifierListData(
        response.data.result
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map(user => ({ ...user, id: user._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  // api call to get verifier list
  const getSamplerList = async () => {
    const params = new URLSearchParams();
    params.append("role", "sampler");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      });
      if (response.data.status !== 100)
        return

      setSamplerListData(
        response.data.result
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map(user => ({ ...user, id: user._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // api call to get bank list
    const bankList = async () => {
      try {
        const response = await axios({
          // method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBankListById`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status !== 100 && response.data.status !== 702)
          return

        setBankListData(
          response.data.bankDetails
            .sort((a, b) => a.bankName.localeCompare(b.bankName))
            .map(bank => ({ ...bank, id: bank._id }))
            .filter(item => item.enabled)
        );
      } catch (error) {
        swal({
          title: "Failed to get bank list",
          icon: "error",
        });
      }
    };
    bankList();
  }, []);

  useEffect(() => {
    if (value === 3) {
      getVerifierList();
    } else if (value === 4) {
      getSamplerList();
    }
  }, [value]);

  const banklistDropdown = bankListData.map((data) => ({
    label: data.bankName,
    value: data.bankCode,
  }));

  const branchlistDropdown = branchList.map((data) => ({
    label: data.branchName,
    value: data.branchCode,
  }));

  const productListDropdown = productList.map((data) => ({
    label: data.productName,
    value: data.prodRefId,
  }));

  const generalProductList = productList.map(
    (data) =>
      data.type === "general" && {
        label: data.productName,
        value: data.prodRefId,
      }
  );

  const verifierListDropdown = verifierListData.map((data) => ({
    label: `${data.firstName} ${data.lastName}/${data.district}`,
    value: data.empCode,
  }));

  const samplerListDropdown = samplerListData.map((data) => ({
    label: `${data.firstName} ${data.lastName}/${data.district}`,
    value: data.empCode,
  }));

  let productListDropdownGeneral = [];
  useEffect(() => {
    generalProductList.length > 0 &&
      generalProductList.forEach(function (el) {
        typeof el === "object" && productListDropdownGeneral.push(el);
      });
  }, [generalProductList]);

  //for switching report tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setBranchList([]);
    setSelectedBranch([]);
    setSelectProdSingle({
      productRefId: "",
      productName: "",
      fields: "",
      productType: "",
    });
    setSelectedProdMulti([]);
    setInput({
      bankId: "",
      bankName: "",
      reportStatus: [],
      branchName: [],
      productName: [],
      verifier: [],
      sampler: [],
      supervisor: [],
      fromdate: "",
      todate: "",
      sortby: "",
    });
  };

  const handleChangeBank = (event) => {
    bankListData.map((bank) => {
      if (bank.bankCode === event.target.value) {
        let bankId = bank.id;
        setInput((values) => ({
          ...values,
          bankRefId: bank.id,
          bankId: bank.bankCode,
          bankName: bank.bankName,
          reportStatus: bank.reportStatus,
        }));
        const params = new URLSearchParams();
        params.append("bankId", bankId);
        //api call to get branch list

        const getBranchList = async () => {
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBranchListByBank`,
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: params,
            });
            if (response.data.status !== 100)
              return

            setBranchList(response.data.result.map(branch => ({ ...branch, id: branch._id })));
          } catch (err) {
            console.log(err);
          }
        };

        const getProductList = async () => {
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getProductListByBank`,
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: params,
            });
            if (response.data.status !== 100)
              return

            setProductList(response.data.result.map(product => ({ ...product, id: product._id })));
          } catch (error) {
            console.log(error);
          }
        };
        getBranchList();
        getProductList();
      }
    });
  };

  const handleChangeSingleProd = (event) => {
    productList.map((prod) => {
      if (prod.prodRefId === event.target.value) {
        setSelectProdSingle((values) => ({
          ...values,
          productRefId: prod.prodRefId,
          productObjId: prod.id,
          productName: prod.productName,
          fields: prod.fields,
          productType: prod.type === "other" ? "special" : "general",
        }));
      }
    });
  };

  const handleChangeSortBy = (event) => {
    setInput((values) => ({
      ...values,
      sortby: event.target.value,
    }));
  };

  //Reusable dropdowns
  const SelectBank = () => {
    return (
      <FormControl sx={{ m: 1 }} variant="standard">
        <FormHelperText
          sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
        >
          Bank
        </FormHelperText>
        <Select
          required={true}
          labelId="demo-simple-select-label-bank"
          value={input.bankId}
          id="demo-simple-select-bank"
          MenuProps={MenuProps}
          onChange={handleChangeBank}
          input={<BootstrapInput />}
        >
          {bankListData?.map((bank) => (
            <MenuItem key={bank.id} value={bank.bankCode}>
              {bank.bankName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const SelectProduct = () => {
    return (
      <FormControl sx={{ m: 1 }} variant="standard">
        <FormHelperText
          sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
        >
          Product
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label-prod"
          value={selectProdSingle.productRefId}
          id="demo-simple-select-prod"
          MenuProps={MenuProps}
          onChange={handleChangeSingleProd}
          input={<BootstrapInput />}
          disabled={input.bankId === ""}
        >
          {value === 2
            ? productList?.map(
              (prod) =>
                prod.type === "other" && (
                  <MenuItem key={prod.id} value={prod.prodRefId}>
                    {prod.productName}
                  </MenuItem>
                )
            ) : value === 1 ? productList.length && productList
              .filter((value, index, self) => {  // filtering out repeating general products
                if (value.type === 'other')
                  return true
                return index === self.findIndex((t) => t.type === value.type)
              })
              .map((prod) => {
                if (prod.type === 'other') {
                  return (
                    <MenuItem key={prod.id} value={prod.prodRefId}>
                      {prod.productName}
                    </MenuItem>
                  )
                }
                else if (prod.type === 'general') {
                  return (
                    <MenuItem key={prod.id} value={prod.prodRefId}>
                      General
                    </MenuItem>
                  )
                }
              }) : productList?.map((prod) => (
                <MenuItem key={prod.id} value={prod.prodRefId}>
                  {prod.productName}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    );
  };

  const SelectSortBy = () => {
    return (
      <FormControl sx={{ m: 1, width: 200 }}>
        <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
          Sort By
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={input.sortby}
          MenuProps={MenuProps}
          onChange={handleChangeSortBy}
          input={<BootstrapInput />}
        >
          <MenuItem value="Verifier">Verifier</MenuItem>
          <MenuItem value="Date">Date</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const maxDate = new Date(fromDate)
  maxDate.setDate(maxDate.getDate() + 31)

  const DateSelect = () => {
    return (
      <FormControl sx={{ m: 1, width: 300 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            margin="15px 10px 20px"
          >
            <DatePicker
              label="From Date"
              inputFormat="DD/MM/YY"
              value={fromDate}
              onChange={handleFromDateChange}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                    '& label': {
                      zIndex: '-1'
                    }
                  }}
                  {...params}
                />
              )}
            />
            <DatePicker
              maxDate={maxDate}
              label="To Date"
              inputFormat="DD/MM/YY"
              value={toDate}
              onChange={handleToDateChange}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                    '& label': {
                      zIndex: '-1'
                    }
                  }}
                  {...params}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        <FormHelperText sx={{ mt: -1.5, mb: 2 }}>Report available for maximum 31 days</FormHelperText>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            margin="15px 10px 20px"
          >
            <DesktopDatePicker
              // shouldDisableDate={disableRandomDates}
              label="From Date"
              inputFormat="dd/MM/yy"
              value={fromDate}
              onChange={handleFromDateChange}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                  }}
                  {...params}
                />
              )}
            />
            <DesktopDatePicker
              label="To Date"
              inputFormat="dd/MM/yy"
              value={toDate}
              onChange={handleToDateChange}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                  }}
                  {...params}
                />
              )}
            />
          </Stack>
        </LocalizationProvider> */}
      </FormControl>
    );
  };

  //generate report button
  const GenerateReportButton = () => {
    return (
      <div style={{ paddingLeft: "10px" }}>
        <ColorButton
          sx={{
            color: "#fff",
            borderRadius: "10px",
          }}
          onClick={handleGenerateReport}
          loading={loading}
          loadingPosition="start"
          variant="contained"
        >
          Generate Report
        </ColorButton>
      </div>
    );
  };

  // function to update from date
  const handleFromDateChange = (newValue) => {
    setFromDate(newValue.$d);
  };
  // function to update to date
  const handleToDateChange = (newValue) => {
    setToDate(newValue.$d);
  };

  //for report generation api call
  const handleGenerateReport = async () => {
    //setting branch input
    let inputBranch = [];
    selectedBranch.map((e) => {
      let branchData = branchList.find((list) => {
        return list.branchCode === e.value;
      });
      inputBranch = [
        ...inputBranch,
        {
          branch_name: branchData.branchName,
          branchId: branchData.branchCode,
          branchObjId: branchData.id
        },
      ];
    });

    //setting product input
    let inputProd = [];
    (value === 0 || value === 4) && //for MIS-1 and login details only
      selectedProdMulti.map((e) => {
        let prodData = productList.find((list) => {
          return list.prodRefId === e.value;
        });
        inputProd = [
          ...inputProd,
          {
            prodRefId: prodData.prodRefId,
            product_name: prodData.productName,
            fields: prodData.fields,
          },
        ];
      });

    //setting verifier input
    let inputVerifier = [];
    selectedVerifiers.map((e) => {
      let verifierData = verifierListData.find((list) => {
        return list.empCode === e.value;
      });
      inputVerifier = [
        ...inputVerifier,
        `${verifierData.firstName} ${verifierData.lastName}/${verifierData.district}`,
      ];
    });

    //setting verifier input
    let inputSampler = [];
    selectedSamplers.map((e) => {
      let samplerData = samplerListData.find((list) => {
        return list.empCode === e.value;
      });
      inputSampler = [
        ...inputSampler,
        {
          id: samplerData.id,
          emp_id: samplerData.empCode,
          sampler_name: `${samplerData.firstName} ${samplerData.lastName}/${samplerData.district}`,
        },
      ];
    });

    setInput((values) => ({
      ...values,
      branchName: inputBranch,
      productName: inputProd,
      fromdate: moment(fromDate).format("YYYY-MM-DD"),
      todate: moment(toDate).format("YYYY-MM-DD"),
      verifier: inputVerifier,
      sampler: inputSampler,
    }));

    setApiCallBool((prev) => !prev);
  };

  const Labels = [
    "MIS-1 Report",
    "MIS-2 Report",
    "Other Report",
    "Pending Report",
    "Login Details",
  ];

  return (
    <div className="Supervisor-dashboard">
      <Box
        sx={{
          mt: 5,
          flexGrow: 1,
          borderRadius: "20px",
          border: "2px solid #1553b5",
          display: "flex",
          color: "#000",
          bgcolor: "inherit",
          maxHeight: 500,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          textColor="#000"
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#1553b5",
              height: 3,
              width: "100%",
              borderTopLeftRadius: "17px",
              borderBottomLeftRadius: "17px",
              zIndex: -1,
              opacity: "50%",
            },
          }}
          sx={{
            borderRight: 1,
            borderColor: "#1553b5",
          }}
        >
          {Labels.map((label) => (
            <Tab
              label={label}
              sx={{
                height: "90px",
                borderBottom: "2px solid #1553b5",
                borderBottomLeftRadius: "17px",
              }}
            />
          ))}
        </Tabs>

        {/* MIS-1 */}
        <TabPanel value={value} index={0} style={{ width: "100%" }}>
          {/* bank */}
          <SelectBank />
          {/* branch */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Branch
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={branchlistDropdown}
                value={selectedBranch}
                onChange={setSelectedBranch}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* product */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Product
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={productListDropdownGeneral}
                value={selectedProdMulti}
                onChange={setSelectedProdMulti}
                labelledBy="product"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* MIS-2 */}
        <TabPanel value={value} index={1} style={{ width: "100%" }}>
          {/* bank */}
          <SelectBank />
          {/* branch */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Branch
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={branchlistDropdown}
                value={selectedBranch}
                onChange={setSelectedBranch}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* product */}
          <SelectProduct />
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Other  */}
        <TabPanel value={value} index={2} style={{ width: "100%" }}>
          {/* bank */}
          <SelectBank />
          {/* product */}
          <SelectProduct />
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Pending */}
        <TabPanel value={value} index={3} style={{ width: "100%" }}>
          {/* bank */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Bank
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={banklistDropdown}
                value={selectedBankMulti}
                onChange={setSelectedBankMulti}
                labelledBy="Bank"
              />
            </div>
          </FormControl>
          {/* verifier */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Verifier
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={selectedBankMulti.length <= 0}
                options={verifierListDropdown}
                value={selectedVerifiers}
                onChange={setSelectedVerifiers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* Sort by */}
          <SelectSortBy />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Login details */}
        <TabPanel value={value} index={4} style={{ width: "100%" }}>
          {/* bank */}
          <SelectBank />
          {/* branch */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Branch
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={branchlistDropdown}
                value={selectedBranch}
                onChange={setSelectedBranch}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* product */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Product
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={productListDropdown}
                value={selectedProdMulti}
                onChange={setSelectedProdMulti}
                labelledBy="product"
              />
            </div>
          </FormControl>
          {/* sampler */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Sampler
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={samplerListDropdown}
                value={selectedSamplers}
                onChange={setSelectedSamplers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>
      </Box>
    </div>
  );
};

export default FileList;
