import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axios";

import {
    Box, Typography, Stack,
    Select, InputLabel, FormControl, OutlinedInput, MenuItem, Checkbox, ListItemText, CircularProgress
} from '@mui/material';

import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import {
    ResponsiveContainer,
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label
} from 'recharts';

// DateFilter
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

// Form Controller
//bank and district filter
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function BarchartPage() {
    {/* BarChart Section */ }
    // BankFilter api call
    const { token } = useSelector((state) => state.token.value);
    const [banklistDropdown, setbanklistDropdown] = useState([]); // bank list api response is stored in this state
    const [districtlistDropdown, setDistrictlistDropdown] = useState([]); // district list api response is stored in this state
    const [selectedBankCodes, setSelectedBankCodes] = useState([]);//bank id selection 
    const [selectedBankNames, setSelectedBankNames] = useState([]);//bank name storing
    const [districtName, setDistrictName] = useState([]);//district name storing
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());//selected from date
    const [selectedToDate, setSelectedToDate] = useState(new Date()); //selected to date
    const [selectedBarChart, setSelectedBarChart] = useState([]);//bank name storing
    const [loading, setLoading] = useState(false)
    // console.log(dataSets);

    //API calling 20 seconds interval value setting start
    const [intervalValue, setIntervalValue] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            // Toggle the value between true and false
            setIntervalValue((prevValue) => !prevValue);
        }, 20000); // 20 seconds in milliseconds
        return () => {
            clearInterval(interval); // Clear the interval if the component unmounts
        };
    }, []);
    //API calling 20 seconds interval value setting end

    useEffect(() => {
        // Preselect the date range with 24 days
        const toDate = new Date();
        const fromDate = new Date();
        fromDate.setDate(toDate.getDate() - 24); // Subtract 24      days from toDate
        const dayjs = require('dayjs');
        const formatDate = (dateString) => {
            const formattedDate = dayjs(dateString).format('YYYY/MM/DD');
            return formattedDate;
        };
        setSelectedFromDate(formatDate(fromDate));
        setSelectedToDate(formatDate(toDate));
        // Call the API with preselected dates
    }, []);


    // api call to get bank list start
    useEffect(() => {
        // api call to get bank list
        const bankList = async () => {
            try {
                const response = await axios({
                    // method: "post",
                    url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBankListById`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.data.status !== 100 && response.data.status !== 702)
                    return

                if (response.data.status == 100) {
                    const bankListData = response.data.bankDetails
                        .sort((a, b) => a.bankName.localeCompare(b.bankName))
                        .map(bank => ({ ...bank, id: bank._id }))
                        .filter(item => item.enabled)

                    const banklistDropdown = bankListData.map((data) => ({
                        label: data.bankName,
                        code: data._id,
                    }));
                    setbanklistDropdown(banklistDropdown);

                }
                // const allBankCodes = banklistDropdown.map((bank) => bank.code);
                // setSelectedBankCodes(allBankCodes);
            } catch (error) {
                swal({
                    title: "Failed to get bank list",
                    icon: "error",
                });
            }
        };
        bankList();

    }, []);


    // api call to get bank list end


    // api call to get  district list start
    useEffect(() => {
        // api call to get district list
        const districtList = async () => {
            const params = new URLSearchParams();
            params.append("role", "verifier");
            try {
                const response = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: params,
                });
                if (response.data.status !== 100)
                    return
                if (response.data.status == 100) {

                    const districtListData = response.data.result
                        .sort((a, b) => a.district.localeCompare(b.district))
                        .map(user => ({ ...user, id: user._id }))

                    const districtSet = new Set();
                    const districtlistDropdown = districtListData.filter((data) => {
                        if (!districtSet.has(data.district)) {
                            districtSet.add(data.district);
                            return true;
                        }
                        return false;
                    }).map((data) => ({
                        value: data.district
                    }));

                    setDistrictlistDropdown(districtlistDropdown);
                }
            } catch (error) {
                swal({
                    title: "Failed to get district list",
                    icon: "error",
                });
            }
        };
        districtList();
        // callApi(selectedBankCodes, districtName, selectedFromDate, selectedToDate);
    }, []);

    // api call to get  district list end

    // Form Controller

    //bank and district filter start
    const handleBankNameChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.includes('all')) {
            if (selectedBankCodes.length === banklistDropdown.length) {
                setSelectedBankCodes([]);
                setSelectedBankNames([]);
            } else {
                const allBankCodes = banklistDropdown.map((bank) => bank.code);
                setSelectedBankCodes(allBankCodes);
                setSelectedBankNames(banklistDropdown.map((bank) => bank.label));
            }
        } else {
            setSelectedBankCodes(value);
            setSelectedBankNames(
                value.map((selectedCode) => {
                    const bank = banklistDropdown.find((bank) => bank.code === selectedCode);
                    return bank ? bank.label : '';
                })
            );
        }
    };

    useEffect(() => {
        // When the component mounts, select all banks
        const allBankCodes = banklistDropdown.map((bank) => bank.code);
        setSelectedBankCodes(allBankCodes);
        setSelectedBankNames(banklistDropdown.map((bank) => bank.label));
        const selectedBankLabels = banklistDropdown.map((bank) => bank.label);
        setSelectedBankNames(selectedBankLabels);
    }, [banklistDropdown]);


    const handleDistrictNameChange = (event) => {
        const {
            target: { value },
        } = event;
        if (value.includes('select-all')) {
            if (value.length === districtlistDropdown.length + 1) {
                setDistrictName([]);
            } else {
                setDistrictName(districtlistDropdown.map(name => name.value));
            }
        } else {
            setDistrictName(value);
        }
    };

    useEffect(() => {
        // When the component mounts, select all district
        setDistrictName(districtlistDropdown.map(name => name.value));
    }, [districtlistDropdown]);

    //bank and district filter end

    // DatePicker start
    const handleFromDateChange = (date) => {
        const dayjs = require('dayjs');
        const formatDate = (dateString) => {
            const formattedDate = dayjs(dateString).format('YYYY/MM/DD');
            return formattedDate;
        };
        setSelectedFromDate(formatDate(date));
    };
    const handleToDateChange = (date) => {
        const dayjs = require('dayjs');
        const formatDate = (dateString) => {
            const formattedDate = dayjs(dateString).format('YYYY/MM/DD');
            return formattedDate;
        };
        setSelectedToDate(formatDate(date));
    };

    const disabledDays = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
        if (selectedFromDate) {
            return date > today || date > selectedFromDate;
        }
        return date > today;
    };
    // DatePicker start end

    // API Calling Start
    useEffect(() => {
        setLoading(true)
        const callApi = async (selectedBankCodes, districtName, fromDate, toDate) => {
            if (!selectedBankCodes.length || !districtName.length)
                return

            const dayjs = require('dayjs');
            const formatDate = (dateString) => {
                const formattedDate = dayjs(dateString).format('YYYY-MM-DD');
                return formattedDate;
            };
            const requestBody = {
                bankRefId: selectedBankCodes,
                verifierCity: districtName,
                from: formatDate(fromDate),
                to: formatDate(toDate)
            };

            try {
                const response = await axios({
                    method: "post",
                    url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/verifierPendingCases`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    data: requestBody,
                });


                if (response.data.status == 100) {
                    function formatDate(dateString) {
                        const parts = dateString.split('-'); // Replace "-" with your actual separator
                        if (parts.length === 3) {
                            const year = parts[0];
                            const month = parts[1];
                            const day = parts[2];
                            return `${day}/${month}`;
                        } else {
                            console.log("Invalid date format:", dateString);
                            return dateString; // Return original string if unable to format
                        }
                    }
                    const barChartData = response.data.result;
                    const barChartDataValues = [];
                    barChartData?.forEach((dates) => {
                        if (dates.date) {
                            const formattedDate = formatDate(dates.date);
                            barChartDataValues.push({
                                name: formattedDate,
                                File: dates.fileCount,
                                Document: dates.docCount,
                                amt: 1000
                            });
                        }
                    });
                    setSelectedBarChart(barChartDataValues.reverse());
                }

            } catch (error) {
                swal({
                    title: "Failed to get verifier Pending Cases list",
                    icon: "error",
                });
            } finally {
                setLoading(false)
            }
        };
        callApi(selectedBankCodes, districtName, selectedFromDate, selectedToDate);
    }, [selectedBankCodes, districtName, selectedFromDate, selectedToDate, intervalValue]);
    // API Calling End

    //barchart index start
    const [startIndex, setStartIndex] = useState(0);
    const chartLimit = 16; // Number of data points to display
    const handlePrevClick = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - chartLimit);
        }
    };
    const handleNextClick = () => {
        if (startIndex + chartLimit < selectedBarChart.length) {
            setStartIndex(startIndex + chartLimit);
        }
    };
    const visibleDataSets = selectedBarChart.slice(startIndex, startIndex + chartLimit);
    const canShowPrevious = startIndex > 0;
    const canShowNext = startIndex + chartLimit < selectedBarChart.length;
    //barchart index end

    return (
        <>

            {/* Current Pending Status BarChart  Section Start  */}
            <Box>
                <Stack direction="row" height={10} mb={3}>
                    <Typography fontWeight={600} fontSize={16} variant="h6">
                        Current Pending Status
                    </Typography >
                    {loading && <CircularProgress />}
                </Stack>

                {/* Form Controller */}

                <div >
                    {/* Bank Start*/}
                    <Box display={"flex"}>
                        <FormControl sx={{ m: 1, width: '300px' }}>
                            <InputLabel size="small" id="demo-multiple-checkbox-label">Bank</InputLabel>
                            <Select
                                style={
                                    {
                                        fontSize: '10x',
                                        color: 'rgba(0, 22, 79, 1)',
                                    }
                                }
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={selectedBankCodes}
                                onChange={handleBankNameChange}
                                input={<OutlinedInput size="small" label="Bank" />}
                                renderValue={(selected) =>
                                    selected
                                        .map((selectedCode) => {
                                            const bank = banklistDropdown.find((bank) => bank.code === selectedCode);
                                            return bank ? bank.label : '';
                                        })
                                        .join(', ')
                                }
                                MenuProps={MenuProps}
                                size='small'
                            >
                                {/* "Select All" Option */}
                                <MenuItem dense key="select-all" value="all" >
                                    <Checkbox
                                        checked={selectedBankCodes.length === banklistDropdown.length}
                                        indeterminate={
                                            selectedBankCodes.length > 0 &&
                                            selectedBankCodes.length < banklistDropdown.length
                                        }
                                        onChange={() =>
                                            handleBankNameChange({
                                                target: { value: selectedBankCodes.length === banklistDropdown.length ? [] : banklistDropdown.map((bank) => bank.code) },
                                            })
                                        }
                                    />
                                    <ListItemText primary="Select All" />
                                </MenuItem>
                                {/* Individual Bank Options */}
                                {banklistDropdown.map((bank) => (
                                    <MenuItem dense key={bank.code} value={bank.code}>
                                        <Checkbox checked={selectedBankCodes.indexOf(bank.code) > -1} />
                                        <ListItemText primary={bank.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* </Box> */}
                        {/* Bank End*/}

                        {/* District Start*/}
                        {/* <Box marginBottom='10px'> */}
                        <FormControl sx={{ m: 1, width: '300px' }}>
                            <InputLabel id="demo-multiple-checkbox-label" size="small">
                                District
                            </InputLabel>
                            <Select
                                style={
                                    {
                                        fontSize: '10x',
                                        color: 'rgba(0, 22, 79, 1)',
                                    }
                                }
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                value={districtName}
                                onChange={handleDistrictNameChange}
                                input={<OutlinedInput size="small" label="District" />}
                                renderValue={(selected) =>
                                    selected.includes('select-all')
                                        ? 'All'
                                        : selected.join(', ')
                                }
                                size='small'
                            >
                                <MenuItem dense value="select-all">
                                    <Checkbox
                                        checked={
                                            districtName.length === districtlistDropdown.length
                                        }
                                    />
                                    <ListItemText primary="Select All" />
                                </MenuItem>
                                {districtlistDropdown.map((name) => (
                                    <MenuItem dense key={name.value} value={name.value}>
                                        <Checkbox checked={districtName.includes(name.value)} />
                                        <ListItemText primary={name.value} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {/* </Box> */}
                        {/* District End*/}

                        {/* </div > */}

                        {/* From To Date Start*/}
                        {/* < Box > */}
                        <>
                            {/* <Typography fontSize="15px" fontWeight={800} textAlign='left' marginLeft='5px'> Select A Time Period</Typography> */}
                            <Box marginBottom='15px' marginTop='7.5px' marginLeft='10px' display='flex' marginRight='60px'>
                                <Box marginRight='15px' width={150} >
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            style={{ borderRadius: '12px' }}
                                            label="From"
                                            value={selectedFromDate}
                                            inputFormat='DD/MM/YYYY'
                                            onChange={handleFromDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Box>

                                <Box width={150}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="To"
                                            value={selectedToDate}
                                            inputFormat='DD/MM/YYYY'
                                            onChange={handleToDateChange}
                                            renderInput={(params) => <TextField {...params} />}
                                            shouldDisableDate={disabledDays}
                                        />
                                    </LocalizationProvider>
                                </Box>
                            </Box>
                        </>
                    </Box >
                </div >
                {/* From To Date End*/}
                < p style={{ color: '#B7B7B7', fontSize: '14px' }
                }> </p >

                {/* BarChart Start */}
                < Box p={3}
                    marginBottom="50px"
                    border="1px solid #bdc3c9"
                    height={475}
                    width="95%"
                    borderRadius="24px"

                >
                    <ResponsiveContainer width="100%" height={450}>
                        <BarChart
                            height={500}
                            data={visibleDataSets}
                            margin={{
                                top: 20,
                                right: 10,
                                left: 5,
                                bottom: 5,
                            }}
                            barGap={2}
                            barCategoryGap="20%"
                        >
                            {/* selectedToDate.toDateString() */}
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tick={{ fontSize: 12, fontWeight: 600 }} interval={0} >
                                <Label value={dayjs(selectedFromDate).format('MMMM YYYY') + " to " + dayjs(selectedToDate).format('MMMM YYYY')} position="top" offset={339} fontSize='12px' />
                                <Label value="Date (dd/mm)" position="bottom" offset={-5} />
                            </XAxis>
                            <YAxis tick={{ fontSize: 12, fontWeight: 600 }} interval={0} domain={[0, 'dataMax + 1']} >
                                <Label value="Count" position="left" angle={-90} offset={-5} />
                            </YAxis>
                            <Tooltip contentStyle={{ fontSize: 14, fontWeight: 600 }} />
                            <Legend
                                iconType="square"
                                iconSize="24px"
                                align="left"
                                verticalAlign="bottom"
                                wrapperStyle={{ fontSize: '15px', paddingLeft: '90px', paddingTop: '20px', borderRadius: '10px', paddingRight: '20px', paddingBottom: '20px', }}
                                contentStyle={{ fontWeight: 'bold' }}
                            />
                            <defs>
                                <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#C0BC01" />
                                    <stop offset="100%" stopColor="#51C001" />
                                </linearGradient>
                                <linearGradient id="colorGradient2" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#944EC4" />
                                    <stop offset="100%" stopColor="#4E4FC4" />
                                </linearGradient>
                            </defs>

                            <Bar
                                dataKey="File"
                                barSize={10}
                                fill="url(#colorGradient2)"
                                label={{
                                    position: 'top',
                                    fill: '#914EC4',
                                    fontSize: '12px',
                                    fontWeight: 600
                                }}
                                radius={[15, 15, 0, 0]}
                            />
                            <Bar
                                dataKey="Document"
                                barSize={10}
                                fill="url(#colorGradient)"
                                label={{
                                    position: 'top',
                                    fill: '#BEBC01',
                                    fontSize: '12px',
                                    fontWeight: 600,
                                }}
                                radius={[15, 15, 0, 0]}
                            />

                        </BarChart>
                    </ResponsiveContainer>

                    <Box marginRight='20px' marginTop='-60px'>

                        <Stack direction="row" spacing={2} justifyContent='flex-end' marginRight='10px' >
                            <Button
                                disabled={!canShowPrevious}
                                sx={{
                                    width: '156px',
                                    height: '40px',
                                    border: 'none',
                                    borderRadius: '12px',
                                    marginRight: '20px',
                                    color: 'rgba(8, 0, 108, 1)',
                                    fontWeight: '700',
                                    fontSize: '12px',
                                    cursor: 'pointer'
                                }}
                                onClick={handlePrevClick}
                                startIcon={<ArrowBackIosNewIcon style={{ fontSize: '15px' }} />}>
                                Previous
                            </Button>
                            <Button
                                disabled={!canShowNext}
                                sx={{
                                    width: '156px',
                                    height: '40px',
                                    border: 'none',
                                    borderRadius: '12px',
                                    marginRight: '20px',
                                    color: 'rgba(8, 0, 108, 1)',
                                    fontWeight: '700',
                                    fontSize: '12px',
                                    cursor: 'pointer'
                                }}
                                onClick={handleNextClick}
                                endIcon={<ArrowForwardIosIcon style={{ fontSize: '15px' }} />}>
                                Next
                            </Button>
                        </Stack>
                    </Box>

                </Box >
                {/* BarChart End */}

            </Box >
            {/* Current Pending Status BarChart  Section End  */}
        </>

    )
}

export default BarchartPage