import styled from "@emotion/styled";

import { Dialog, InputBase } from "@mui/material";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: '16px',
  },
  '& .MuiDialogActions-root': {
    padding: '8px',
  },
}));

export const Search = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: '4px 8px',
    margin: '4px 24px',
    outline: `1px solid #cecece`,
    borderRadius: '5px',
  },
  '& .MuiInputBase-input:focus': {
    boxShadow: 'none',
    outline: `1px solid #cecece`
  }
}));