import React from "react";
import { useSelector, useDispatch } from "react-redux";

//components
import FileFilter from "../../filter/FileFilter";
import AddNewFile from "../file/AddNewFile";
// material ui
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DraftTable from "../../table/draft/DraftTable";

import { setSelectedBankTab } from "../../../../../../features/spselection";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Draft() {
  const dispatch = useDispatch();
  // const [value, setValue] = React.useState(0);
  const [fileCountApiResponse, setFileCountApiResponse] = React.useState([]);
  const [selectedFileId, setSelectedFileId] = React.useState();
  const value = useSelector(
    (state) => state.spselection.selectedBankTab
  );
  const handleChange = (event, newValue) => {
    // setValue(newValue);
    dispatch(setSelectedBankTab(newValue));
  };
  const idCheck = (details) => {
    setSelectedFileId(details);
  };

  return (
    <>
      {!selectedFileId ? (
        <div className="Supervisor-dashboard">
          <FileFilter
            // count api response from filter component is passed to setFileCountApiResponse
            fileCountResponse={(response) => setFileCountApiResponse(response)}
          />

          <Box
            sx={{
              flexGrow: 1,
              borderRadius: "20px",
              border: "2px solid #1553b5",
              display: "flex",
              color: "#fff",
              bgcolor: "inherit",
              height: "100%",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              textColor="#fff"
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#1553b5",
                  height: 3,
                  width: "100%",
                  borderTopLeftRadius: "17px",
                  borderBottomLeftRadius: "17px",
                  zIndex: -1,
                  opacity: "50%",
                },
              }}
              sx={{
                borderRight: 1,
                borderColor: "#1553b5",
                borderBottom: "2px solid #1553b5",
                borderBottomRightRadius: "17px",
                borderBottomLeftRadius: "17px",
                maxWidth: 140,
                minWidth: 125,
                maxHeight: 800,
              }}
            >
              {/* Loop fileCountApiResponse to get bank name and file count  */}
              {fileCountApiResponse.map((result) => (
                <Tab
                  key={result._id.bankname}
                  label={
                    <div>
                      {/* <span>{result._id.bankname}</span> */}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: "10px",
                        }}
                      >
                        <img
                          alt=""
                          src={
                            result._id.logo
                              ? result._id.logo
                              : `https://uat.rijasprime.com/assets/images/bank-icon.png`
                          }
                          style={{
                            height: "50px",
                            width: "50px",
                          }}
                        />
                        <span style={{ color: "#000", paddingTop: "8px" }}>
                          {result._id.bankname}
                        </span>
                      </div>
                    </div>
                  }
                  sx={{
                    height: "auto",
                    borderBottom: "2px solid #1553b5",
                    borderBottomLeftRadius: "17px",
                  }}
                />
              ))}
            </Tabs>
            {fileCountApiResponse.map((result, index) => (
              <TabPanel
                value={value}
                key={index}
                index={index}
                style={{ width: "100%" }}
              >
                <DraftTable bankId={result._id.bankRefId} fileId={idCheck} />
              </TabPanel>
            ))}
          </Box>
        </div>
      ) : (
        <AddNewFile fileDetails={selectedFileId} />
      )}
    </>
  );
}
