import React from "react";
import "./preloader.css";

const PreLoader = () => {
  return (
    <div className="preloader">
      <div className="body">
        <span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
        <div className="base">
          <span></span>
          <div className="face"></div>
        </div>
      </div>
      <div className="longfazers">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h1 className="preloader-h1">Loading ...</h1>
    </div>
  );
};

export default PreLoader;
