import React, { useState, useEffect } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../features/user";
import { selection } from "../../../features/selection";
import "./role.css";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import FormHelperText from "@mui/material/FormHelperText";
import { token as setToken } from "../../../features/token";

import loginLogoSupervisor from '../../../utils/assets/images/loginLogoSupervisor.png';
import DeviceDetector from "device-detector-js"

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#e8e8e8",
    border: "1px solid #ced4da",
    fontSize: 16,
    display: "flex",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      backgroundColor: theme.palette.background.paper,

      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const rolesMap = {
  bank_manager: 'Bank Manager',
  prime_manager: 'Prime Manager',
  client_manager: "Client Manager",
  verifier: 'Verifier',
  admin: 'Admin',
  supervisor: 'Supervisor',
  sampler: 'Sampler'
}

const Role = () => {
  const dispatch = useDispatch();

  const [userState, setUserState] = useState({
    roleCollection: [],
    locationCollection: [],
    empId: "",
    firstName: "",
    lastName: "",
    _id: "",
    userProfileImage: "",
  });
  const [role, setRole] = useState("");
  const [location, setLocation] = useState("");
  const [locationId, setLocationId] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(true);
  const { token } = useSelector((state) => state.token.value);
  const [agencyDetails, setAgencyDetails] = useState(true);

  useEffect(() => {
    const selection = async () => {
      const deviceDetector = new DeviceDetector()
      const device = deviceDetector.parse(navigator.userAgent)

      // api call to get user roles and locations
      const ipifyResponse = await axios({
        method: "get",
        url: `https://api.ipify.org/?format=json`
      })

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v3/admin/getCurrentUser`,
        data: {
          type: "Supervisor Web",
          model: device.client.name,
          os: device.os.name,
          ip: ipifyResponse.data.ip
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.data.status === 100) {
            const agencyLogo = response.data.result.agencyId.logo
            const agencyName = response.data.result.agencyId.agencyName
            const agencyLogoColor = response.data.result.agencyId.logoBackgroundColor
            const agencyId = response.data.result.agencyId._id

            localStorage.setItem("agencyLogo", agencyLogo)
            localStorage.setItem("agencyName", agencyName)
            localStorage.setItem("agencyLogoColor", agencyLogoColor)
            localStorage.setItem("agencyId", agencyId)

            setAgencyDetails(response.data.result.agencyId);
            setUserState({
              roleCollection: response.data.result.roles,
              locationCollection: response.data.result.locationId,
              empId: response.data.result.empCode,
              firstName: response.data.result.firstName,
              lastName: response.data.result.lastName,
              _id: response.data.result._id,
              userProfileImage: response.data.result.userProfileImage,
            });
          }
          else if (response.data.status === 5) {
            swal({
              title: "User is already logged in!",
              text: "Logout user from the other device?",
              icon: "error",
              buttons: true
            })
              .then((confirm) => {
                if (confirm) {
                  axios({
                    url: `${process.env.REACT_APP_ADMINNODE}/api/v2/admin/logOut`,
                    headers: {
                      Authorization: `Bearer ${token}`,
                    }
                  })
                    .then(response => {
                      if (response.data.status === 100) {
                        localStorage.clear()
                        dispatch(setToken({ token: "" }))
                        // window.location.reload()
                      }
                    })
                    .catch(err => {
                      console.log(err)
                    })
                    .finally(() => {
                      localStorage.clear()
                      dispatch(token({ token: "" }))
                    })
                } else {
                  dispatch(setToken({ token: "" }))
                  localStorage.clear()
                }
              })
            // axios.get(
            //   `${process.env.REACT_APP_ADMINNODE}/api/v3/admin/sessionClear`,
            //   { headers: { Authorization: `Bearer ${token}` } }
            // ).then(() => {
            //   const loginDetails = response.data.result[0]
            //   const { type, model, os, ip } = loginDetails
            //   const deviceDetails = `${type ?? ""} - ${model ?? ""} - ${os ?? ""} - ${ip ?? ""}`
            //   swal({
            //     title: "User already logged in on another device!",
            //     text: `Device details:\n ${deviceDetails} \n Logout from the other device or contact admin.`,
            //     icon: "info"
            //   })
            //     .then(() => {
            //       dispatch(setToken({ token: "" }))
            //       localStorage.clear()
            //     })
            // })
            //   .catch(() => {
            //     swal({
            //       title: "Failed to clear session!",
            //       text: "Contact admin to reset session",
            //       icon: "error"
            //     })
            //   })
          }
        })
        .catch((err) => {
          swal({
            title: "Failed to get role data",
            text: "Check your username/password and try again.",
            icon: "error",
          });
        });
    };
    selection();
  }, []);

  useEffect(() => {
    userState.locationCollection.map((e) => {
      if (e.locationName === location) {
        setLocationId(e._id);
        setLocationCode(e.locationCode)
      }
    });
  }, [location]);

  useEffect(() => {
    if (location && role) {
      setDisableSubmit(false);
    }
  }, [location, role])

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };
  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.setItem("role", role);
      localStorage.setItem("location", locationId);
      localStorage.setItem("locationCode", locationCode);
      localStorage.setItem("empId", userState.empId);
      localStorage.setItem("firstName", userState.firstName);
      localStorage.setItem("lastName", userState.lastName);
      localStorage.setItem("_id", userState._id);
      localStorage.setItem("userProfileImage", userState.userProfileImage);

      localStorage.setItem("bank", "[]");
      localStorage.setItem("doc", "[]");
      localStorage.setItem("verifier", "[]");
      localStorage.setItem("fileStat", "[]");

      localStorage.setItem("bankFilter", "[]");
      localStorage.setItem("docFilter", "[]");
      localStorage.setItem("verifierFilter", "[]");
      localStorage.setItem("fileStatFilter", "[]");

      dispatch(
        selection({
          bank: [],
          bankFilter: [],
          doc: [],
          verifier: [],
          filestat: [],
        })
      );
      dispatch(
        login({
          role: role,
          location: locationId,
          locationCode: locationCode,
          empId: userState.empId,
          firstName: userState.firstName,
          lastName: userState.lastName,
          _id: userState._id,
          userProfileImage: userState.userProfileImage,

        })
      );
    } catch (err) {
      console.log(err.response);
    }
  };

  return (
    <div className="container-role">
      <div className="container-role-sub">
        <form action="" onSubmit={handleSubmit} className="role-selection-form">
          <div className="role-selection-title-container">
            <p className="role-selection-title-sub">
              Select your
            </p>
            <p className="role-selection-title">Role and Location</p>
          </div>

          <FormControl sx={{ my: 1, width: '350px', height: '40px' }} size='small'>
            <InputLabel id="role-selection-label">Select Your Role</InputLabel>
            <Select
              labelId="role-selection-label"
              value={role}
              label="Select Your Role"
              onChange={handleRoleChange}
              sx={{ textAlign: "left" }}
            >
              {userState.roleCollection.map(role => {
                if (['supervisor', 'prime_manager', "client_manager"].includes(role))
                  return (
                    <MenuItem value={role} key={role}>
                      {rolesMap[role]}
                    </MenuItem>
                  )
              })}
            </Select>
          </FormControl>

          <FormControl sx={{ my: 1, width: '350px', height: '40px' }} size='small'>
            <InputLabel id="role-selection-label">Select Your Location</InputLabel>
            <Select
              labelId="role-selection-label"
              value={location}
              label="Select Your Location"
              onChange={handleLocationChange}
              sx={{ textAlign: "left" }}
            >
              {userState.locationCollection.map(location => {
                return (
                  <MenuItem value={location.locationName} key={location.locationName}>
                    {location.locationName}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>


          <div className="supervisorLoginForm-button">
            <input className="login-input" type="submit" value="Continue" disabled={disableSubmit} />
          </div>
          <br />
        </form>
        <div className="overlay">
          <div className="overlay-sub" style={{ textAlign: "center" }}>
            <img alt="" src={agencyDetails.logo} height={100} width={100} />
            <h4 style={{ padding: "10px", paddingTop: "0px" }}>{agencyDetails.agencyName}</h4>
            <h6>
              Powered by <span>RiMS</span>
            </h6>
          </div>
        </div>
      </div>
      {/* <div className="form" action="">
        <p className="container-role-title">Welcome Back</p>

        <div>
          <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
            <FormHelperText
              sx={{ fontSize: "1em", color: "#fff", paddingLeft: "10px" }}
            >
              Role
            </FormHelperText>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={role}
              onChange={handleRoleChange}
              input={<BootstrapInput />}
            >
              {userState.roleCollection.map((role, index) => (
                <MenuItem key={index} value={role}>
                  {role === "supervisor"
                    ? "Supervisor"
                    : role === "prime_manager"
                    ? "Prime Manager"
                    : ""}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <br />
        <div>
          <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
            <FormHelperText
              sx={{ fontSize: "1em", color: "#fff", paddingLeft: "10px" }}
            >
              Location
            </FormHelperText>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={location}
              onChange={handleLocationChange}
              input={<BootstrapInput />}
            >
              {userState.locationCollection.map((loc, index) => (
                <MenuItem key={index} value={loc.locationName}>
                  {loc.locationName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <input
          className="roleSubmitButton login-input"
          type="button"
          value="Submit"
          onClick={handleSubmit}
        />
        <br />
      </div> */}
    </div>
  );
};

export default Role;
