import * as React from "react";
import _ from 'lodash';

//MUI icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

//MUI
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import { Button } from "@mui/material";

//Nested modal style
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
export const ChildModal = ({ url, imageList, imageIndex }) => {
  const [openChild, setOpenChild] = React.useState(false);
  const [currentImg, setCurrentImg] = React.useState(imageIndex);

  const handleOpen = () => {
    setOpenChild(true);
  };

  const handleClose = () => {
    setCurrentImg(imageIndex);
    setOpenChild(false);
  };

  const handleNextClick = () => {
    setCurrentImg(prevValue => prevValue + 1)
  }

  const handlePrevClick = () => {
    setCurrentImg(prevValue => prevValue - 1)
  }

  const handleDownload = (imgUrl) => {
    fetch(imgUrl, { cache: "no-store" })  // "no-store", to make a fresh request (ignore cache) - to avoid CORS error
      .then(response => response.blob())
      .then(blob => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';
        a.download = imgUrl.slice(imgUrl.indexOf("/", 50) + 1);
        document.body.appendChild(a);
        a.click();
      })
      .catch(err => {
        console.log('Failed to fetch; trying without fetch', err);
        const link = document.createElement("a");
        link.download = imgUrl.slice(imgUrl.indexOf("/", 50) + 1);
        link.target = '_blank'
        link.href = imgUrl;
        link.click();
      })
  }

  return (
    <React.Fragment>
      <p
        onClick={handleOpen}
        style={{
          textDecoration: "none",
          width: '100px'
        }}
      >
        {url.split(".")[5] === "pdf" ? (
          <a href={url} target="_blank" onClick={(event) => { event.stopPropagation() }}>
            <PictureAsPdfIcon
              sx={{
                height: "25px",
                width: "25px",
              }}
              fontSize="medium"
            />
          </a>
        ) : (
          <img
            src={url}
            alt=""
            style={{
              height: "25px",
              width: "25px",
            }}
          />
        )}
        &nbsp; View here
      </p>
      {imageList ?
        <Modal
          hideBackdrop
          open={openChild}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: "80%", height: "90%" }}>
            <CloseIcon
              style={{
                color: "#fff",
                position: "fixed",
                top: -20,
                right: "50%",
                fontSize: "25px",
                backgroundColor: "#171717",
                borderRadius: "50%",
                padding: "3px",
                zIndex: 1,
              }}
              onClick={handleClose}
            />
            <div style={{
              height: "100%",
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              position: 'relative'
            }}>
              <IconButton
                sx={{
                  marginLeft: '70px', visibility: currentImg === 0 ? 'hidden' : 'visible'
                }}
                onClick={handlePrevClick}
                disabled={currentImg === 0}
              >
                <ArrowCircleLeftOutlinedIcon sx={{ fontSize: '50px' }} />
              </IconButton>
              <img
                src={imageList && imageList[currentImg]?.url}
                width="60%"
                height="100%"
                style={{ objectFit: "contain" }}
              />
              <IconButton
                sx={{
                  marginRight: '70px', visibility: currentImg === imageList?.length - 1 ? 'hidden' : 'visible'
                }}
                onClick={handleNextClick}
                disabled={currentImg === imageList?.length - 1}
              >
                <ArrowCircleRightOutlinedIcon sx={{ fontSize: '50px' }} />
              </IconButton>
            </div>
            <Button
              href=""
              style={{ position: 'absolute', right: '50px', bottom: '25px' }}
              startIcon={<DownloadIcon />}
              onClick={() => handleDownload(imageList[currentImg].url)}
            >
              Download
            </Button>
          </Box>
        </Modal>
        : <Modal
          hideBackdrop
          open={openChild}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: "90%", height: "90%" }}>
            <CloseIcon
              style={{
                color: "#fff",
                position: "fixed",
                top: -20,
                right: "50%",
                fontSize: "25px",
                backgroundColor: "#171717",
                borderRadius: "50%",
                padding: "3px",
                zIndex: 1,
              }}
              onClick={handleClose}
            />
            <div style={{ height: "100%" }}>
              <embed
                src={url}
                width="100%"
                height="100%"
                style={{ objectFit: "contain" }}
              />
            </div>
          </Box>
        </Modal>}
    </React.Fragment >
  );
};
