import React from "react";

import swal from "sweetalert";
import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux"; // useSelect for accessing state and useDispatch for updating state
import { token } from "../../../../features/token";
import loginLogoSupervisor from "../../../../utils/assets/images/loginLogoSupervisor.png";

import './login.css';

import { TextField } from "@mui/material";

const SupervisorLogin = ({ history }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  localStorage.setItem("userNameEmail", user.username);
  const loginSubmit = async (e) => {
    e.preventDefault();
    // try {
    //   const params = new URLSearchParams();
    //   params.append("grant_type", "password");
    //   params.append("client_id", process.env.REACT_APP_CLIENT_ID);
    //   params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
    //   params.append("username", user.username);
    //   params.append("password", user.password);
    //   await axios({
    //     method: "post",
    //     url: process.env.REACT_APP_AUTHSERVER,
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     },
    //     data: params,
    //   }).then(async (response) => {
    //     // handle success
    //     localStorage.setItem("token", response.data.access_token);
    //     dispatch(token({ token: response.data.access_token }));
    //   });
    // } catch (err) {
    //   swal({
    //     title: "Invalid user credentials",
    //     text: "Check your username/password and try again.",
    //     icon: "error",
    //   });
    // }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_ADMINNODE}/api/v2/admin/login`,
      data: {
        userName: user.username,
        password: user.password
      }
    }).then(response => {
      if (response.data.status === 100) {
        localStorage.setItem("token", response.data.result);
        dispatch(token({ token: response.data.result }));
      } else if (response.data.status === 5) {
        swal({
          title: "User is already logged in!",
          text: "Logout user from the other device?",
          icon: "error",
          buttons: true
        })
          .then((confirm) => {
            if (confirm) {
              axios({
                url: `${process.env.REACT_APP_ADMINNODE}/api/v2/admin/logOut`,
                headers: {
                  Authorization: `Bearer ${response.data.token}`,
                }
              })
                .then(response => {
                  if (response.data.status === 100) {
                    localStorage.clear()
                    dispatch(token({ token: "" }))
                    // window.location.reload()
                  }
                })
                .catch(err => {
                  console.log(err)
                })
                .finally(() => {
                  localStorage.clear()
                  dispatch(token({ token: "" }))
                })
            } else {
              dispatch(token({ token: "" }))
              localStorage.clear()
            }
          })
      } else {
        swal({
          title: "Invalid user credentials",
          text: "Check your username/password and try again.",
          icon: "error",
        });
      }
    }).catch(err => {
      swal({
        title: "Invalid user credentials",
        text: "Check your username/password and try again.",
        icon: "error",
      });
    })
  };

  return (
    <div className="container-supervisor">
      <div className="container-supevisor-sub">
        <form action="" onSubmit={loginSubmit} className="supervisorLoginForm">
          <div className="supervisorLoginForm-login-title-container">
            <p className="supervisorLoginForm-login-title">Welcome back..!</p>
            <p className="supervisorLoginForm-login-title-sub">
              Login as Supervisor
            </p>
          </div>
          {/* <input
            className="supervisorLoginForm-login-input"
            type="text"
            required
            value={user.username}
            placeholder="Username"
            onChange={(e) => {
              setUser({ ...user, username: e.target.value });
            }}
          />
          <br />

          <input
            className="supervisorLoginForm-login-input"
            type="password"
            required
            value={user.password}
            placeholder="Password"
            onChange={(e) => {
              setUser({ ...user, password: e.target.value });
            }}
          />
          <br /> */}
          <TextField
            label="Username"
            size="small"
            sx={{
              width: '350px',
              my: 1,
              '& input:focus': { boxShadow: 'none !important' },
            }}
            onChange={(e) => {
              setUser({ ...user, username: e.target.value });
            }}
          />
          <TextField
            label='Password'
            type='password'
            size="small"
            sx={{
              width: '350px',
              my: 1,
              '& input:focus': { boxShadow: 'none !important' },
            }}
            onChange={(e) => {
              setUser({ ...user, password: e.target.value });
            }}
          />
          <div className="supervisorLoginForm-button">
            <input className="login-input" type="submit" value="Login" />
          </div>
          <br />
        </form>
        <div className="overlay">
          <div className="overlay-sub">
            <img alt="" src={loginLogoSupervisor} />
            <h4>
              Powered by <span>RiMS</span>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupervisorLogin;
