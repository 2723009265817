import {
  Typography, Avatar, Tooltip, Checkbox
} from "@mui/material";
import _ from 'lodash';
import { useSelector, useDispatch } from "react-redux";

import { StyledChip, StyledBox } from "./BankTile.styles";

import { selection } from "../../../../../../../features/selection";


export default function BankTile({ counts, checked, closeGrid }) {
  const selectionDetails = useSelector(state => state.selection.value);
  const dispatch = useDispatch();

  const handleTileClick = (e) => {
    // const bankFilterCopy = selectionDetails.bankFilter.slice();
    // const bankCopy = selectionDetails.bank.slice();

    if (e.detail === 1) {
      const index = selectionDetails.bankFilter.findIndex(bank => bank.value === counts.bankId);
      const bankFilterCopy = selectionDetails.bankFilter.slice();
      const bankCopy = selectionDetails.bank.slice();

      // to handle single click
      if (index !== -1) {
        bankFilterCopy.splice(index, 1);
        bankCopy.splice(index, 1);

        dispatch(selection({
          ...selectionDetails,
          bankFilter: bankFilterCopy,
          bank: bankCopy
        }))
      } else {
        bankFilterCopy.push({ label: counts.bankName, value: counts.bankId });
        bankCopy.push({
          bankname: counts.bankName,
          bankRefId: counts.bankId,
          logo: counts.bankLogo,
          bankCode: counts.bankCode,
        })
        dispatch(selection({
          ...selectionDetails,
          bankFilter: bankFilterCopy.sort((a, b) => a.label.localeCompare(b.label)),
          bank: bankCopy.sort((a, b) => a.bankname.localeCompare(b.bankname))
        }))
      }
    }

    // to handle double click
    else if (e.detail === 2) {
      const index = selectionDetails.bankFilter.findIndex(bank => bank.value === counts.bankId);
      const bankFilterCopy = selectionDetails.bankFilter.slice();
      const bankCopy = selectionDetails.bank.slice();

      if (index === -1) {
        // console.log('hi');
        bankFilterCopy.push({ label: counts.bankName, value: counts.bankId });
        bankCopy.push({
          bankname: counts.bankName,
          bankRefId: counts.bankId,
          logo: counts.bankLogo,
          bankCode: counts.bankCode,
        })
        dispatch(selection({
          ...selectionDetails,
          bankFilter: bankFilterCopy.sort((a, b) => a.label.localeCompare(b.label)),
          bank: bankCopy.sort((a, b) => a.bankname.localeCompare(b.bankname))
        }))
      }

      closeGrid({ newBankFilter: bankFilterCopy, newBank: bankCopy, dbClickedBankId: counts.bankId });
    }
  };

  return (
    <StyledBox onClick={handleTileClick}>
      <div>
        <Tooltip title='All'>
          <StyledChip label={counts.ALL} size="small" sx={{ backgroundColor: "#ed944d" }} />
        </Tooltip>
        <Tooltip title='Allocation Pending'>
          <StyledChip label={counts.NEW} size="small" sx={{ backgroundColor: "#48a14d" }} />
        </Tooltip>
        <Tooltip title='Verification Pending'>
          <StyledChip label={counts.INPROGRESS} size="small" sx={{ backgroundColor: "#b33f40" }} />
        </Tooltip>
        <Tooltip title='Verified'>
          <StyledChip label={counts.VERIFIED} size="small" sx={{ backgroundColor: "#d2ca00" }} />
        </Tooltip>
        <Tooltip title='Ready For Report'>
          <StyledChip label={counts.COMPLETED} size="small" sx={{ backgroundColor: "#6577b3" }} />
        </Tooltip>
      </div>

      <Avatar
        variant="square"
        src={counts.bankLogo ?? "https://uat.rijasprime.com/assets/images/bank-icon.png"}
        sx={{
          width: '85px',
          height: '85px',
          margin: '15px auto',
          borderRadius: '8px'
        }}
      />
      <Tooltip
        title={counts.bankName}
        placement="top"
      >
        <Typography
          align="center"
          width='100%'
          padding='0 10px'
          margin='0 auto'
          fontSize='14px'
          fontWeight='bold'
          noWrap
        >
          {counts.bankName}
        </Typography>
      </Tooltip>
      <Checkbox
        sx={{
          alignSelf: 'flex-end',
          padding: 0,
          color: '#1553b5',
          '&.Mui-checked': {
            color: '#1553b5',
          },
        }}
        checked={checked}
      />
    </StyledBox>
  )
}