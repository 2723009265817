import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import swal from "sweetalert";
import moment from "moment";

//MUI
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridOverlay,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Slide from "@mui/material/Slide";

//mui icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

//components
import FileFilter from "../../filter/FileFilter";
import { ViewFile } from "../file/ViewFile";

//for customizing action tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#032437",
    fontSize: 14,
  },
}));

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: "column",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#262626" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#595959" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#434343" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));
function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 2, color: "#000" }}>No Disabled File(s) Found</Box>
    </StyledGridOverlay>
  );
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 3,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        backgroundColor: "#1553b5",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
      }}
    >
      <div>
        <GridToolbarColumnsButton
          sx={{
            color: "#fff",
            fontSize: ".8em",
          }}
        />
        &nbsp; &nbsp; &nbsp;
        <GridToolbarFilterButton
          sx={{
            color: "#fff",
            fontSize: ".8em",
          }}
        />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(-1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-input": {
            color: "#fff",
            fontSize: "1.1em",
          },
          "& .MuiInput-input:focus": {
            color: "#fff",
            fontSize: "1.1em",
            boxShadow: "none",
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "#fff",
          },
          "& .MuiInput-underline:after": {
            borderBottom: 1,
            borderColor: "#fff",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
//for mui dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DisabledFiles = () => {
  const { token } = useSelector((state) => state.token.value);
  const { bank } = useSelector((state) => state.selection.value);

  const [page, setPage] = React.useState(0); // current page number is stored in "page". Initial value = 0
  const [searchText, setSearchText] = React.useState("");
  const [tableDataResult, setTableDataResult] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(""); // total number of files, for particular selection, is stored in "rowCount" for pagination
  const [pageSize, setPageSize] = React.useState(10); // Number of rows on data grid. Initial value = 10
  const [enableRender, setEnableRender] = React.useState(false);
  const [fileCountApiResponse, setFileCountApiResponse] = React.useState([]);
  const [selectedFileId, setSelectedFileId] = React.useState("");
  const [selectedFileRefId, setSelectedFileRefId] = React.useState("");
  const [selectedBankId, setSelectedBankId] = React.useState("");
  const [openView, setOpenView] = React.useState(false);
  const [disabledFileTableLoading, setDisabledFileTableLoading] =
    React.useState(false); // for setting loading animation while waiting for document list api response

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
  };

  React.useEffect(() => {
    const tableData = async () => {
      setDisabledFileTableLoading(true);
      if (bank.length > 0) {
        let bankRefIdList = [];
        bank.map((data) => {
          bankRefIdList = [...bankRefIdList, data.bankRefId];
        });
        // api call to get disable file list
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/getDisableFile`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            bankRefId: bankRefIdList,
            searchKey: searchText,
          },
        })
          .then((response) => {
            setRowCount(response.data.fileCount);
            setTableDataResult(
              response.data.fileDetail.map((result) => ({
                id: result._id,
                fileRefId: result.internalRefId,
                fileId: result.fileId,
                CustomerName: result.userName,
                bankName: result.bankName,
                branch: result.branchName,
                product: result.productName,
                status: result.status,
                sampler:
                   result.samplerName && result.samplerName.charAt(0)?.toUpperCase() +
                  result.samplerName.slice(1),
                bankId: result.bankId,
                createdTime: moment(result.createdAt).format(
                  "DD/MM/YY, h:mm a"
                ),
              }))
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }

      setDisabledFileTableLoading(false);
    };
    tableData();
  }, [fileCountApiResponse, bank, searchText, enableRender, openView]);

  //view disabled file
  const viewDisableFile = React.useCallback(
    (data) => () => {
      setSelectedFileId(data.fileId);
      setSelectedFileRefId(data.id);
      setSelectedBankId(data.bankId);
      setOpenView(true);
    },
    []
  );

  //enable file
  const enableFile = React.useCallback(
    (id) => () => {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to enable this File?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/enableDisable`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              _id: id,
              docId: "",
              isActive: "active",
            },
          })
            .then(() => {
              swal({
                title: "File Enabled Successfully",
                text: "You can Disable it from files list",
                icon: "success",
              });
              setEnableRender((enableRender) => !enableRender); // toggling the disableRender state to rerender only the file list data
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          swal("Selected File is not Enabled");
        }
      });
    },
    []
  );

  const handleClose = () => {
    setOpenView(false);
  };

  // datagrid column initializing
  const columns = React.useMemo(
    () => [
      {
        field: "fileRefId",
        headerName: "Reference No",
        minWidth: 50,
        hide: true, // by default "reference no" column will be hidden and can be made visible using column filter
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "fileId",
        headerName: "File No",
        minWidth: 120,
        type: "number",
        align: "left",
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "CustomerName",
        headerName: "Customer Name",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "sampler",
        headerName: "Sampler",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "bankName",
        headerName: "Bank",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "branch",
        headerName: "Branch",
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "product",
        headerName: "Product Name",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "createdTime",
        headerName: "Created Time",
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },

      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        sortable: false,
        minWidth: 130,
        getActions: (params) => [
          //View
          <CustomTooltip
            title="View File"
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <span
              style={{
                padding: "0",
                margin: "0",
                height: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <GridActionsCellItem
                icon={<RemoveRedEyeIcon />}
                label="View"
                onClick={viewDisableFile(params.row)}
                sx={{
                  backgroundColor: "#1553b5",
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "#1553b5" },
                }}
              />
            </span>
          </CustomTooltip>,
          //enable
          <CustomTooltip
            title="Enable File"
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <span
              style={{
                padding: "0",
                margin: "0",
                height: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <GridActionsCellItem
                icon={
                  <Button
                    sx={{
                      backgroundColor: "#1553b5",
                      padding: "5px 1px",
                      textTransform: "capitalize",
                    }}
                    variant="contained"
                  >
                    Enable
                  </Button>
                }
                label="Disable"
                onClick={enableFile(params.row.id)}
                // sx={{ backgroundColor: "#1553b5", fontSize: "16px" }}
              />
            </span>
          </CustomTooltip>,
        ],
      },
    ],
    [viewDisableFile, enableFile]
  );

  // datagrid row initializing.
  const rows = tableDataResult;

  const descriptionElementRefView = React.useRef(null);
  React.useEffect(() => {
    if (openView) {
      const { current: descriptionElement } = descriptionElementRefView;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openView]);
  return (
    <div className="Disabled_fileList">
      <FileFilter
        // count api response from filter component is passed to setFileCountApiResponse
        fileCountResponse={(response) => setFileCountApiResponse(response)}
      />
      <Box
        sx={{
          flexGrow: 1,
          borderRadius: "20px",
          border: "1px solid #1553b5",
          display: "flex",
          color: "#fff",
          bgcolor: "inherit",
          height: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <DataGrid
            loading={disabledFileTableLoading}
            autoHeight
            components={{
              Toolbar: QuickSearchToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            page={page}
            onPageChange={(newPage) => setPage(newPage)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rows={rows}
            columns={columns}
            rowCount={rowCount}
            rowHeight={75}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            sx={{
              borderRadius: "20px",
              height: "100%",
              color: "#fff",
              fontSize: "16px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#1553b5",
              },
              "& .MuiDataGrid-cell": {
                border: "1px groove rgba(0, 0, 0, 0.2)",
                color: "#000",
              },
              "& .MuiTablePagination-selectLabel": {
                fontSize: "1.2em",
                margin: "auto",
                color: "#000",
              },
              "& .MuiTablePagination-displayedRows": {
                fontSize: "1.2em",
                margin: "auto",
                color: "#000",
              },
              "& .MuiTablePagination-select": {
                fontSize: "1.2em",
                color: "#000",
              },

              "& .MuiSvgIcon-root": {
                fontSize: "1.2em",
                color: "#fff",
              },
              "& .MuiTablePagination-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "1.2em",
                  color: "#000",
                },
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              },
            }}
          />
        </div>
      </Box>
      <Dialog
        open={openView}
        TransitionComponent={Transition}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md" //You can change md to sm, xs, lg and xl as per your requirement.
      >
        <ViewFile
          bankId={selectedBankId}
          fileId={selectedFileId}
          fileRefId={selectedFileRefId}
          openCloseBool={setOpenView}
        />
      </Dialog>
    </div>
  );
};

export default DisabledFiles;
