import { useEffect, useState } from "react";

import {
  CircularProgress, Box, Paper, Stack, Table,
  TableBody, TableCell, tableCellClasses, TableContainer,
  TableHead, TableRow, styled, Typography, FormControl
} from "@mui/material"

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import { StyledTextField } from "../Report";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))


function SupervisorTable() {
  const [date, setDate] = useState(new Date())
  const [supervisorPerformance, setSupervisorPerformance] = useState([])
  const [loading, setLoading] = useState(false)
  const { token } = useSelector((state) => state.token.value)

  const createData = (name, initiated, processed, sent, sentAndMail) => {
    return { name, initiated, processed, sent, sentAndMail };
  }

  const rows = supervisorPerformance.map(data =>
    createData(
      data.supervisor,
      data.initiated,
      data.processed,
      data.sent,
      data.sentAndMail
    )
  )

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/SupervisorPerfomanceDashboard`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          date: moment(date).format("YYYY-MM-DD")
        }
      })
      if (response.data.status === 1)
        setSupervisorPerformance(response.data.data)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [date])

  return (
    <Box mt={5}>
      <Typography variant="h6" fontWeight={600} fontSize={16} mb={1}>
        Supervisor Performance
      </Typography>

      <FormControl sx={{ mt: 1, mb: 2, width: 300 }} size="small">
        <Stack direction="row" spacing={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disableFuture
              label="Date"
              inputFormat="DD/MM/YY"
              value={date}
              onChange={(date) => setDate(date.$d)}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                    '& label': {
                      zIndex: '-1'
                    }
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          {loading && <CircularProgress />}
        </Stack>
      </FormControl>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">Initiated</StyledTableCell>
              <StyledTableCell align="right">Processed</StyledTableCell>
              <StyledTableCell align="right">Sent</StyledTableCell>
              <StyledTableCell width="20%" align="right">Sent & Email</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.initiated}</StyledTableCell>
                <StyledTableCell align="right">{row.processed}</StyledTableCell>
                <StyledTableCell align="right">{row.sent}</StyledTableCell>
                <StyledTableCell align="right">{row.sentAndMail}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default SupervisorTable
