import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//components
import DocumentList from "./pages/drawerPages/DocumentList";
import FileList from "./pages/drawerPages/FileList";
import Report from "./pages/drawerPages/Report";
import ReportDownload from "./pages/drawerPages/ReportDownload";
import ReportSent from "./pages/drawerPages/ReportSent";
import DisabledFiles from "./pages/drawerPages/DisabledFiles";
import AddNewFile from "./pages/file/AddNewFile";
import Draft from "./pages/drawerPages/Draft";
import Dashboard from "./pages/drawerPages/Dashboard";

const SPDashboard = () => {
  const { value, currentBankId } = useSelector(
    (state) => state.spselection.value
  );

  const renderSwitch = () => {
    switch (value) {
      case "Dashboard":
        return <Dashboard />
      case "File":
        return <FileList />;
      case "Document":
        return <DocumentList />;
      case "Report Sent":
        return <ReportSent />;
      case "Disabled Files":
        return <DisabledFiles />;
      case "Report":
        return <Report />;
      case "Report Download":
        return <ReportDownload />;
      case "add file1":
        return <AddNewFile />;
      case "add file3":
        return <AddNewFile toAddNewDocument />
      case "Draft":
        return <Draft />;
      default:
        return null;
    }
  };
  return <div className="Supervisor-dashboard">{renderSwitch(value)}</div>;
};

export default SPDashboard;
