import * as React from "react";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ViewDoc } from "../../pages/document/ViewDoc";
import Edit from "../../pages/document/Edit";

//mui
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridOverlay,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import Zoom from "@mui/material/Zoom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Slide from "@mui/material/Slide";

//mui icons
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";

//for customizing action tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#032437",
    fontSize: 14,
  },
}));

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: "column",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#262626" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#595959" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#434343" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));
function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 2, color: "#000" }}>No Document(s) Found</Box>
    </StyledGridOverlay>
  );
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 3,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        backgroundColor: "#1553b5",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
      }}
    >
      <div>
        <GridToolbarColumnsButton
          sx={{
            color: "#fff",
            fontSize: ".8em",
          }}
        />
        &nbsp; &nbsp; &nbsp;
        <GridToolbarFilterButton
          sx={{
            color: "#fff",
            fontSize: ".8em",
          }}
        />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(-1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-input": {
            color: "#fff",
            fontSize: "1.1em",
          },
          "& .MuiInput-input:focus": {
            color: "#fff",
            fontSize: "1.1em",
            boxShadow: "none",
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "#fff",
          },
          "& .MuiInput-underline:after": {
            borderBottom: 1,
            borderColor: "#fff",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

//for mui dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DocTable() {
  const { token } = useSelector((state) => state.token.value);

  const { bank, doc, verifier } = useSelector((state) => state.selection.value);

  const [tableDataResult, setTableDataResult] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [rowCount, setRowCount] = React.useState(""); // total number of files, for particular selection, is stored in "rowCount" for pagination
  const [pageSize, setPageSize] = React.useState(10); // Number of rows on data grid. Initial value = 10
  const [page, setPage] = React.useState(0); // current page number is stored in "page". Initial value = 0
  const [openView, setOpenView] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedDocId, setSelectedDocId] = React.useState("");
  const [selectedBankRefId, setSelectedBankRefId] = React.useState("");
  const [docTableLoading, setDocTableLoading] = React.useState(false); // for setting loading animation while waiting for document list api response
  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
  };

  React.useEffect(() => {
    const tableData = async () => {
      setDocTableLoading(true);
      if (bank.length > 0) {
        let pageNumber = page + 1;
        let bankRefIdList = [];
        bank.map((data) => {
          bankRefIdList = [...bankRefIdList, data.bankRefId];
        });
        // api call to get document list
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/getDocument`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            bankRefId: bankRefIdList,
            docIdlist: doc,
            verifierlist: verifier,
            pageNo: pageNumber,
            size: pageSize,
            searchKey: searchText,
          },
        })
          .then((response) => {
            if (typeof response.data.fileDetail !== "string") {
              setRowCount(response.data.documentCount);
              setTableDataResult(
                response.data.documentDetail.map((result) => ({
                  id: result._id,
                  docRefId: result.docRefId,
                  CustomerName: result.userName,
                  bankName: result.bankName,
                  prodName: result.productName,
                  docName: result.docType,
                  fileId: result.fileId,
                  verifierName: result.verifier,
                  verificationStatus: result.verificationStatus,
                  bankRefId: result.bankRefId,
                  createdTime: new Date(result.createdAt),
                }))
              );
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      setDocTableLoading(false);
    };
    tableData();
  }, [pageSize, page, searchText, bank, doc, verifier, openView]);

  const viewDoc = React.useCallback(
    (Id) => () => {
      setSelectedDocId(Id);
      setOpenView(true);
    },
    []
  );

  const editDoc = React.useCallback(
    (data) => () => {
      setSelectedDocId(data.id);
      setSelectedBankRefId(data.bankRefId);
      setOpenEdit(true);
    },
    []
  );

  const handleClose = () => {
    setOpenView(false);
    setOpenEdit(false);
  };

  // datagrid column initializing
  const columns = React.useMemo(
    () => [
      {
        field: "fileId",
        headerName: "File No",
        minWidth: 120,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "CustomerName",
        headerName: "Customer Name",
        flex: 2,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "bankName",
        headerName: "Bank Name",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "prodName",
        headerName: "Product Name",
        flex: 2,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "docName",
        headerName: "Document Name",
        flex: 2,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },

      {
        field: "verifierName",
        headerName: "Verifier Name",
        flex: 2,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "verificationStatus",
        headerName: "Verification Status",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "createdTime",
        headerName: "Created Time",
        type: "dateTime",
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {(() => moment(cellValues.value).format(
                "DD/MM/YY, h:mm a"
              ))()}
            </Box>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        sortable: false,
        minWidth: 100,
        getActions: (params) => [
          //view Doc
          <CustomTooltip
            title="View Doc"
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <span
              style={{
                padding: "0",
                margin: "0",
                height: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <GridActionsCellItem
                icon={<RemoveRedEyeIcon />}
                label="View"
                onClick={viewDoc(params.row.id)}
                sx={{
                  backgroundColor: "#1553b5",
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "#1553b5" },
                }}
              />
            </span>
          </CustomTooltip>,
          //edit doc
          <CustomTooltip
            title="Edit Doc"
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <span
              style={{
                padding: "0",
                margin: "0",
                height: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={editDoc(params.row)}
                sx={{
                  backgroundColor: "#1553b5",
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "#1553b5" },
                }}
              />
            </span>
          </CustomTooltip>,
        ],
      },
    ],
    [viewDoc, editDoc]
  );

  // datagrid row initializing.
  const rows = tableDataResult;

  const descriptionElementRefView = React.useRef(null);
  React.useEffect(() => {
    if (openView) {
      const { current: descriptionElement } = descriptionElementRefView;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openView]);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        rows={rows}
        rowCount={rowCount}
        loading={docTableLoading}
        autoHeight
        components={{
          Toolbar: QuickSearchToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        page={page}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={(newPage) => setPage(newPage)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        columns={columns}
        rowHeight={75}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
          },
        }}
        sx={{
          borderRadius: "20px",
          height: "100%",
          color: "#fff",
          fontSize: "16px",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#1553b5",
          },
          "& .MuiDataGrid-cell": {
            border: "1px groove rgba(0, 0, 0, 0.2)",
            color: "#000",
          },
          "& .MuiTablePagination-selectLabel": {
            fontSize: "1.2em",
            margin: "auto",
            color: "#000",
          },
          "& .MuiTablePagination-displayedRows": {
            fontSize: "1.2em",
            margin: "auto",
            color: "#000",
          },
          "& .MuiTablePagination-select": {
            fontSize: "1.2em",
            color: "#000",
          },
          "& .MuiSvgIcon-root": {
            fontSize: "1.2em",
            color: "#fff",
          },
          "& .MuiTablePagination-root": {
            "& .MuiSvgIcon-root": {
              fontSize: "1.2em",
              color: "#000",
            },
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            maxHeight: "inherit",
            width: "100%",
            whiteSpace: "initial",
            lineHeight: "16px",
            wordBreak: "break-all",
          },
        }}
      />
      {/* Doc view */}
      <Dialog
        open={openView}
        TransitionComponent={Transition}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md" //You can change md to sm, xs, lg and xl as per your requirement.
      >
        <ViewDoc docId={selectedDocId} openCloseBool={setOpenView} />
      </Dialog>
      {/* Doc edit */}
      <Dialog
        open={openEdit}
        TransitionComponent={Transition}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md" //You can change md to sm, xs, lg and xl as per your requirement.
      >
        <Edit
          docId={selectedDocId}
          bankRefId={selectedBankRefId}
          openCloseBool={setOpenEdit}
        />
      </Dialog>
    </div>
  );
}
