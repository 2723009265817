import React from "react";
import "./notfound.css";
import image from "../assets/images/404.svg";

function NotFound() {
  return (
    <div className="errorPage">
      <img className="errorImg" src={image} alt="" />
      <div className="wrapper">
        <h1 className="errorPage-title">Page Not Found</h1>
        <a href="#" className="btn">
          Head back to home
        </a>
      </div>
    </div>
  );
}

export default NotFound;
