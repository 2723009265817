import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import { selection } from "../../../../../../../features/selection";

import {
  DialogTitle, IconButton, DialogContent, Button,
  Grid, CircularProgress
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";

import { PropTypes } from "prop-types";

import BankTile from "./BankTile";

import { BootstrapDialog, Search } from "./BanksGrid.styles";


function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, px: 2, py: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export default function BanksGrid({ open, onClose }) {
  const dispatch = useDispatch();

  const { location } = useSelector((state) => state.user.value);
  const { token } = useSelector((state) => state.token.value);
  const selectionDetails = useSelector(state => state.selection.value);

  const [fileCountMap, setFileCountMap] = useState([]);
  const [isfileCountMapLoading, setIsFileCountMapLoading] = useState(false);
  const [filteredFileCountMap, setFileteredFileCountMap] = useState(fileCountMap);
  const [allBanksFileCount, setAllBanksFileCount] = useState([]);
  const [allSelected, setAllSelected] = useState();

  const handleSearch = (event) => {
    setFileteredFileCountMap(fileCountMap);
    const searchText = event.target.value;

    setFileteredFileCountMap(prevValue => (
      prevValue.filter(item => item.bankName.toLowerCase().includes(searchText.toLowerCase()))
    ))
  }

  const handleSelectAll = () => {
    const allBankFilter = allBanksFileCount.map(bank => ({ label: bank.bankname, value: bank.bankRefId }));

    if (allSelected) {
      dispatch(selection({
        ...selectionDetails,
        bankFilter: [],
        bank: []
      }));
    } else {
      dispatch(selection({
        ...selectionDetails,
        bankFilter: allBankFilter,
        bank: allBanksFileCount
      }));
    }
  }

  useEffect(() => {
    if (selectionDetails?.bankFilter?.length === allBanksFileCount.length)
      setAllSelected(true);
    else
      setAllSelected(false);
  })

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("locationId", location);

    const getFileCounts = async () => {
      setIsFileCountMapLoading(true);

      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUserBankListByLocation`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      }).then(response => {
        if (response.data.status !== 100)
          return

        const bankRefIdlist = response.data.result.map(bank => ({
          bankCode: bank.bankCode.toString(),
          bankRefId: bank._id,
          bankname: bank.bankName,
          logo: bank.logo
        }))

        setAllBanksFileCount(bankRefIdlist);

        axios({
          method: 'post',
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/getFileCount`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { bankRefIdlist },
        }).then(response => {
          const fileCount = response.data;
          fileCount.sort((a, b) => a._id.bankname.localeCompare(b._id.bankname));

          const fileCountMap = fileCount.map(item => {
            const countMap = {};
            item.info.forEach(count => {
              countMap[count.Status] = count.Count;
              countMap.ALL === undefined ? countMap.ALL = count.Count : countMap.ALL += count.Count;
            })

            if (!countMap.ALL)
              countMap.ALL = 0;
            if (!countMap.NEW)
              countMap.NEW = 0;
            if (!countMap.INPROGRESS)
              countMap.INPROGRESS = 0;
            if (!countMap.VERIFIED)
              countMap.VERIFIED = 0;
            if (!countMap.COMPLETED)
              countMap.COMPLETED = 0;

            return {
              ...countMap,
              bankName: item._id.bankname,
              bankId: item._id.bankRefId,
              bankCode: item._id.bankCode,
              bankLogo: item._id.logo
            }
          })

          setFileCountMap(fileCountMap);
          setFileteredFileCountMap(fileCountMap);
        })
      })

      setIsFileCountMapLoading(false);
    }

    open && getFileCounts();
  }, [open]);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
      fullWidth
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        All Banks
        <Search placeholder="Search" onChange={handleSearch} />
        <Button size="small" onClick={handleSelectAll}>{allSelected ? 'Unselect All' : 'Select All'}</Button>
      </BootstrapDialogTitle>

      {isfileCountMapLoading ?
        <DialogContent
          dividers
          sx={{
            backgroundColor: '#f4f4f4',
            height: '85vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <CircularProgress />
        </DialogContent>
        :
        <DialogContent
          dividers
          sx={{
            backgroundColor: '#f4f4f4',
            height: '85vh'
          }}
        >
          <Grid container spacing={2}>
            {filteredFileCountMap.map(item => (
              <Grid key={item.bankId} item lg={2} md={3}>
                <BankTile
                  counts={item}
                  checked={Boolean(selectionDetails.bankFilter.find(bank => bank.value === item.bankId))}
                  closeGrid={onClose}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>}
    </BootstrapDialog >
  )
}