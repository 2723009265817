import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import {
    Box, Typography, Stack, Divider, CircularProgress
} from '@mui/material';

import {
    PieChart, Pie, Cell, ResponsiveContainer
} from 'recharts';

// apicalling
import axios from "axios";


const COLORS = ['#7E0872', '#028448', '#C0BC01'];

function PiechartPage() {
    //PieChart API Calling Start
    const { token } = useSelector((state) => state.token.value)
    const [loading, setLoading] = useState(false);
    const [pieChartData, setPieChartData] = useState([]);

    //API calling 20 seconds interval value setting start
    const [intervalValue, setIntervalValue] = useState(false);
    useEffect(() => {
        const interval = setInterval(() => {
            // Toggle the value between true and false
            setIntervalValue((prevValue) => !prevValue);
        }, 20020); // 20 seconds in milliseconds
        return () => {
            clearInterval(interval); // Clear the interval if the component unmounts
        };
    }, []);
    //API calling 20 seconds interval value setting end

    useEffect(() => {
        const today = new Date();
        const lastSevenDays = [];
        for (let i = 0; i < 7; i++) {
            const date = new Date(today);
            date.setDate(today.getDate() - i);
            const dates = (date.toISOString().split('T')[0]);
            // const a = [date: ...dates]
            lastSevenDays.push(dates);
        }

        const getTatCountApiCall = async () => {
            setLoading(true)
            try {
                const response = await axios({
                    method: "get",
                    url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/getTatCount`,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                // console.log(response);
                if (response.status == 200) {
                    const tatData = response.data.result;

                    const tatData1 = lastSevenDays?.map(item => {
                        const found = tatData.find(data => {
                            const date = new Date(data.updatedAt)
                            return date.toISOString().split('T')[0] === item
                        })

                        if (found) {
                            return {
                                updatedAt: item,
                                zeroDayTat: found.zeroDayTat,
                                oneDayTat: found.oneDayTat,
                                moreDayTat: found.moreDayTat
                            }
                        }
                        return {
                            updatedAt: item,
                            zeroDayTat: 0,
                            oneDayTat: 0,
                            moreDayTat: 0
                        }

                    })

                    setPieChartData(tatData1);
                }
                // if (response.status !== 200) {
                // }
            } catch (err) {
                console.log(err.message);
            } finally {
                setLoading(false)
            }


        };
        getTatCountApiCall();
        // setTimeout(() => setLoader(false), 1000);
    }, [intervalValue]);

    //PieChart API Calling End



    return (
        <>
            {/* Chart 1 PieChartWithPaddingAngle Start */}
            < Box >
                <Stack direction="row" height={20} mb={3} mt={3}>
                    <Typography fontWeight={600} fontSize={16} variant="h6">
                        TAT Graph
                    </Typography >
                    {loading && <CircularProgress />}
                </Stack>


                <Box p={3}
                    marginBottom="50px"
                    border="1px solid #bdc3c9"
                    height={350}
                    width="90vw"
                    borderRadius="24px"

                >
                    <Stack height={200} direction="row" width="100%" divider={< Divider orientation="vertical" />}>


                        {pieChartData?.map((dates, index) => {

                            const pieChartDataValues = [
                                { value: dates.zeroDayTat },
                                { value: dates.oneDayTat },
                                { value: dates.moreDayTat }
                            ];

                            const totalfiles = pieChartDataValues?.reduce((acc, curr) => acc + curr.value, 0);

                            const inputDate = new Date(dates.updatedAt);
                            const currentDate = new Date();
                            const isToday = inputDate.toDateString() === currentDate.toDateString();
                            const day = inputDate.getDate().toString().padStart(2, '0');
                            const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
                            const year = inputDate.getFullYear();
                            let formattedDate;
                            if (isToday) {
                                formattedDate = "Today";
                            } else {
                                formattedDate = `${day}-${month}-${year}`;
                            }

                            return (
                                <Box width="100%">
                                    <Typography textAlign="center">{formattedDate}</Typography>
                                    < ResponsiveContainer >
                                        <PieChart width={220} height={230} >
                                            <text
                                                x="50%"
                                                y="50%"
                                                dy={-4} // Adjust this value to vertically align the text
                                                textAnchor="middle"
                                                fontSize={30}
                                                fontWeight="700"
                                                dominantBaseline="middle"
                                            >
                                                {totalfiles}
                                                {/* {data3.reduce((acc, ))} */}
                                            </text>
                                            <text
                                                x="50%"
                                                y="50%"
                                                dy={20} // Adjust this value to vertically align the text
                                                fontSize={13}
                                                fontWeight="400"
                                                textAnchor="middle"
                                                dominantBaseline="middles"
                                            >
                                                Files
                                            </text>

                                            <Pie
                                                data={pieChartDataValues}
                                                innerRadius={42}
                                                outerRadius={75}
                                                paddingAngle={0}
                                            >
                                                {pieChartDataValues.map((entry, index) => (
                                                    <Cell key={`cell - ${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                            </Pie>
                                        </PieChart>
                                    </ResponsiveContainer>
                                    {/* zeroDayTat */}
                                    <div className='piechart-values'
                                        style={{
                                            marginLeft: '20px',
                                            marginBottom: '5px'
                                        }}>

                                        < div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: '5px',
                                                    borderRadius: '3px',
                                                    backgroundColor: '#7E0872',
                                                }}

                                            ></div>

                                            < span style={{
                                                marginRight: 'auto',
                                                fontSize: '12px',
                                            }}
                                            >0 TAT</span>
                                            <span
                                                style={{
                                                    color: '#7E0872',
                                                    fontSize: '12px',
                                                    marginRight: '5px',
                                                }}
                                            >{dates.zeroDayTat}</span>
                                        </div>
                                    </div>

                                    {/* oneDayTat */}
                                    <div className='piechart-values'
                                        style={{
                                            marginLeft: '20px',
                                            marginBottom: '5px'
                                        }}>

                                        < div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: '5px',
                                                    borderRadius: '3px',
                                                    backgroundColor: '#028448',
                                                }}

                                            ></div>

                                            < span style={{
                                                marginRight: 'auto',
                                                fontSize: '12px',
                                            }}
                                            >1 TAT</span>
                                            <span
                                                style={{
                                                    color: '#028448',
                                                    fontSize: '12px',
                                                    marginRight: '5px',
                                                }}
                                            >{dates.oneDayTat}</span>
                                        </div>
                                    </div>

                                    {/* twoDayTat */}
                                    <div className='piechart-values'
                                        style={{
                                            marginLeft: '20px',
                                            marginBottom: '5px'
                                        }}>

                                        < div
                                            style={{
                                                display: 'flex',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    height: '20px',
                                                    width: '20px',
                                                    marginRight: '5px',
                                                    borderRadius: '3px',
                                                    backgroundColor: '#C0BC01',
                                                }}

                                            ></div>

                                            < span style={{
                                                marginRight: 'auto',
                                                fontSize: '12px',
                                            }}
                                            >2 or above TAT</span>
                                            <span
                                                style={{
                                                    color: '#C0BC01',
                                                    fontSize: '12px',
                                                    marginRight: '5px',
                                                }}
                                            >{dates.moreDayTat}</span>
                                        </div>
                                    </div>
                                </Box>
                            )
                        })}

                    </Stack>
                </Box >
            </Box >
            {/* Chart 1 PieChartWithPaddingAngle End */}
        </>
    )
}

export default PiechartPage