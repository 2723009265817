import React from "react";
import { useSelector } from "react-redux";

//components
import Report from "./pages/drawerPages/Report";
import ReportDownload from "../../supervisor/dashboard/pages/drawerPages/ReportDownload";
import MDashboard from "./pages/drawerPages/MDashboard.jsx";

const PMDashboard = () => {
  const { value, currentBankId } = useSelector(
    (state) => state.pmSelection.value
  );
  const renderSwitch = () => {
    switch (value) {
      case "Dashboard":
        return <MDashboard />
      case "Report":
        return <Report />;
      case "Report Download":
        return <ReportDownload />;
      default:
        return null;
    }
  };
  return <div className="Supervisor-dashboard">{renderSwitch()}</div>;
};

export default PMDashboard;
