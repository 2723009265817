import fileDefault from "../utils/assets/attachFileImages/IconDefault.svg";
import fileDoc from "../utils/assets/attachFileImages/IconDoc.svg";
import filePdf from "../utils/assets/attachFileImages/IconPdf.svg";
import fileXlsx from "../utils/assets/attachFileImages/IconXlsx.svg";
import filePng from "../utils/assets/attachFileImages/IconPng.svg";
import fileJpg from "../utils/assets/attachFileImages/IconJpg.svg";

export const AttachFileImageConfig = {
  default: fileDefault,
  pdf: filePdf,
  msword: fileDoc,
  xlsx: fileXlsx,
  png: filePng,
  jpeg: fileJpg,
};
