import { Box, Grid } from "@mui/material"
import SupervisorTable from "./components/SupervisorTable"
import FraudTable from "./components/FraudTable"
import VeriferTable from "./components/VerifierTable"
import PiechartPage from "../../../../supervisor/dashboard/pages/drawerPages/components/Piechart"
import BarchartPage from "../../../../supervisor/dashboard/pages/drawerPages/components/Barchart"
import BarchartFileupdatesPage from "../../../../supervisor/dashboard/pages/drawerPages/components/BarchartFileupdates"

function MDashboard() {
  return (
    <Box p={1}>
      <Grid container spacing={3} rowSpacing={-8}>
        <Grid item xs={12}>
          <PiechartPage />
        </Grid>
        <Grid item xs={12}>
          <BarchartPage />
        </Grid>
        <Grid item xs={12}>
          <BarchartFileupdatesPage />
        </Grid>
        <Grid item xs={6}>
          <SupervisorTable />
        </Grid>
        <Grid item xs={6}>
          <FraudTable />
        </Grid>
        <Grid item xs={6}>
          <VeriferTable />
        </Grid>
      </Grid>
    </Box>
  )
}

export default MDashboard
