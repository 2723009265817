import styled from "@emotion/styled";

import { Chip, Box } from "@mui/material";

export const StyledBox = styled(Box)({
  padding: '5px',
  paddingTop: '10px',
  height: '215px',
  cursor: 'pointer',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: 'white',
  border: '1px solid #bdc3c9',
  '&:hover': {
    backgroundColor: '#e8eef3'
  }
})

export const StyledChip = styled(Chip)({
  fontWeight: 'bold',
  color: 'white',
  margin: '2px',
  fontSize: '12px',
  height: '20px',
  '& span': {
    padding: '5px'
  }
})
