import { useEffect, useState } from "react";

import {
  Box, Paper, Table, CircularProgress,
  TableBody, TableCell, tableCellClasses, TableContainer,
  TableHead, TableRow, styled, Typography, FormControl, Stack
} from "@mui/material"

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";

import { StyledTextField } from "../Report";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}))

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs };
}


function FraudTable() {
  const startingDate = new Date()
  startingDate.setDate(startingDate.getDate() - 7)
  const [fromDate, setFromDate] = useState(startingDate)
  const [toDate, setToDate] = useState(new Date())
  const { token } = useSelector((state) => state.token.value)
  const [allFraudCases, setAllFraudCases] = useState([])
  const [loading, setLoading] = useState(false)

  const createData = (name, district, profileFraud, documentFraud) => {
    return { name, district, profileFraud, documentFraud };
  }

  const rows = allFraudCases.map(data => (
    createData(
      data.verifier.split("/")[0],
      data.verifierCity,
      data?.statusCounts["Profile Fraud"] ?? 0,
      data?.statusCounts["Document Fraud"] ?? 0
    )
  ))


  useEffect(async () => {
    setLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v4/file/manage/getAllFraudCases`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          from: fromDate,
          to: toDate
        }
      })
      if (response.data.status === 100)
        setAllFraudCases(response.data.result)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }, [fromDate, toDate])

  return (
    <Box mt={5}>
      <Typography variant="h6" fontWeight={600} fontSize={16} mb={1}>
        Fraud Cases
      </Typography>

      <FormControl sx={{ mt: 1, mb: 2, width: 300 }} size="small">
        <Stack direction="row" spacing={1}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              inputFormat="DD/MM/YY"
              value={fromDate}
              onChange={date => setFromDate(date.$d)}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                    '& label': {
                      zIndex: '-1'
                    }
                  }}
                  {...params}
                />
              )}
            />

            <DatePicker
              label="To"
              inputFormat="DD/MM/YY"
              value={toDate}
              onChange={date => setToDate(date.$d)}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                    '& label': {
                      zIndex: '-1'
                    }
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
          {loading && <CircularProgress />}
        </Stack>
      </FormControl>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align="right">District</StyledTableCell>
              <StyledTableCell align="right">Profile Fraud</StyledTableCell>
              <StyledTableCell align="right">Document Fraud</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <StyledTableCell component="th" scope="row">
                  {row.name}
                </StyledTableCell>
                <StyledTableCell align="right">{row.district}</StyledTableCell>
                <StyledTableCell align="right">{row.profileFraud}</StyledTableCell>
                <StyledTableCell align="right">{row.documentFraud}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default FraudTable
