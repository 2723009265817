import { createSlice } from "@reduxjs/toolkit";

export const primeMngrSlice = createSlice({
  name: "primeManagerDrawer",
  initialState: {
    value: {
      value: "Report",
      currentBankId: localStorage.currentBankId,
    },
  },
  reducers: {
    changeDrawerSelectionPimeManager: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { changeDrawerSelectionPimeManager } = primeMngrSlice.actions;

export default primeMngrSlice.reducer;
