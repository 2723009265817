import React from "react";

const BMDashboard = ({ match }) => {
  console.log(match);
  return (
    <div>
      <h1>Bank manager dashboard</h1>
    </div>
  );
};

export default BMDashboard;
