import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeDrawerSelection } from "../../../../../../features/spselection";
import { AttachFileImageConfig } from "../../../../../../config/AttachFileImageConfig";

//MUI
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

//MUI icon
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UpdateIcon from "@mui/icons-material/Update";
import swal from "sweetalert";
import CloseIcon from "@mui/icons-material/Close";
import CancelIcon from "@mui/icons-material/Cancel";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

// for third page document type upload
const Input = styled("input")({
  display: "none",
});

//custom MUI textField
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#171717",
  },
  "& label.MuiInputLabel-formControl": {
    color: "#171717",
  },
  "& svg.MuiSvgIcon-root": {
    color: "#171717",
  },
  "& .MuiSelect-select": {
    color: "#171717",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#171717",
  },
  "& .MuiOutlinedInput-input": {
    color: "#171717",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#171717",
    },
    "&:hover fieldset": {
      borderColor: "#171717",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#171717",
    },
  },
});

//for custom MUI DialogTitle
const DialogTitleStyle = {
  color: "#fff",
  backgroundColor: "#1553b5 ",
  display: "flex",
  justifyContent: "space-between",
};

const Edit = ({ fileId, fileRefId, openCloseBool }) => {
  const { token } = useSelector((state) => state.token.value);
  const { value, currentBankId } = useSelector(
    (state) => state.spselection.value
  );

  const [inputs, setInputs] = useState({}); // user entered input data is stored in "inputs" state
  const [productList, setProductList] = useState([]); //getproductlist api reponse is stored in this state
  const [branchList, setBranchList] = useState([]); //getbranchlist api reponse is stored in this state
  const [loading, setLoading] = useState(false);
  const [isDisplayed, setIsDisplayed] = React.useState(false);
  const [fileList, setFileList] = useState([]); //for displaying uploaded application form

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("bankId", currentBankId);
    const viewFile = async () => {
      try {
        // api call to get file detail
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/viewFile`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: fileRefId,
          },
        });

        setInputs({
          id: response.data.fileDetail[0]._id,
          userName: response.data.fileDetail[0].userName,
          branchName: response.data.fileDetail[0].branchName,
          productName: response.data.fileDetail[0].productName,
          customer_office_full_address: "",
          productFields: response.data.fileDetail[0].productFields,
          applicationImage: response.data.fileDetail[0].applicationImage,
          productType: response.data.fileDetail[0].productType,
          status: response.data.fileDetail[0].status
        });
        // let draftImgArr = [];
        // response.data.fileDetail[0].applicationImage.map((e) => {
        //   draftImgArr = [
        //     ...draftImgArr,
        //     [{ name: e.url.split("/")[4].replace(/[0-9]/g, "") }],
        //   ];
        // });
        // setFileList(draftImgArr);
      } catch (error) {
        console.log(error);
      }
    };
    //api call to get product list
    const productList = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getProductListByBank`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });
        setProductList(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    //api call to get branch list
    const branchList = async () => {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBranchListByBank`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      });
      setBranchList(response.data);
    };
    productList();
    branchList();
    viewFile();
  }, []);

  // mui customized button
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#f4f4f4",
    border: "1px solid #1553b5",
    color: "#1553b5 !important",
    padding: "4px 8px",
    margin: "auto 2px",
    "&:hover": {
      border: "1px solid #f4f4f4",
      backgroundColor: "#1553b5",
      color: "#fff !important",
    },
  }));

  // custom mui button (for edit submit)
  const UpdateButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }));

  const handleProdFieldChange = (event) => {
    const name = event.target.name;
    const value =
      name === "_pan_number"
        ? event.target.value.toUpperCase()
        : name === "text_caps" ? event.target.value.toUpperCase():event.target.value //pancard number must be in uppercase

    inputs.productFields.map((e) => {
      let updatedProdArray = inputs.productFields.map((a) => {
        return { ...a };
      });
      updatedProdArray.find((a) => a.FIELD == name).VALUE = value;
      setInputs((values) => ({ ...values, productFields: updatedProdArray }));
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      name === "userName"
        ? event.target.value.toUpperCase().replace(/\s+/g, ' ')
        : event.target.value; //customer name must be in uppercase
    setInputs((values) => ({ ...values, [name]: value }));
    // const name = event.target.name;
    // const value = event.target.value;
    // setInputs((values) => ({ ...values, [name]: value }));
  };

  const updateFileApiCall = async () => {
    try {
      //api call for update file
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/updateFile`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: inputs,
      });
      response.data.status === "success" &&
        swal({
          title: `File Updated Successfully`,
          icon: "success",
        });
      setLoading(false);
    } catch (error) {
      swal({
        title: `Failed on updating file`,
        icon: "error",
      });
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);

    let invalidDateFound = false;
    const formattedProdFields = inputs.productFields.map(item => {
      if ((item.FILLED_BY === "sampler" || item.FILLED_BY === "verifier") && item.TYPE === "date" && item.VALUE) {
        let date;
        if (typeof item.VALUE === "string") {
          date = item.VALUE;
        } else {
          date = moment(item.VALUE.$d).format("DD-MM-YYYY");
        }
        if (date === "Invalid date") {
          invalidDateFound = true;
        }
        return {
          ...item,
          VALUE: date
        }
      }
      return item;
    })

    inputs.productFields = formattedProdFields;

    if (invalidDateFound) {
      swal({
        title: 'Invalid Date Format',
        icon: 'info'
      })
      return;
    }

    let panFieldBool = inputs.productFields.some(
      (field) => field.FIELD === "_pan_number"
    );
    if (panFieldBool) {
      inputs.productFields.map((field) => {
        if (field.FIELD === "_pan_number") {
          let regexPan = /^[A-Z]{3}([ABCFGHLJPT])[A-Z][0-9]{4}[A-Z]$/;
          if (regexPan.test(field.VALUE) || field.VALUE === "NA") {
            updateFileApiCall();
          } else {
            return swal({
              title: `Invalid Pancard Number`,
              icon: "info",
            });
          }
        }
      });
    } else {
      updateFileApiCall();
    }
  };

  React.useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
    }, 400);
  }, []);

  //application form remove
  const applicationFormRemove = async (file) => {
    const updatedList = [...inputs.applicationImage];
    updatedList.splice(inputs.applicationImage.indexOf(file), 1);
    setInputs({ ...inputs, applicationImage: updatedList });
  };

  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      {/* close icon for file view modal */}
      {isDisplayed && (
        <CloseIcon
          style={{
            color: "#171717",
            position: "fixed",
            top: 20,
            right: "50%",
            fontSize: "25px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "3px",
            zIndex: 1,
          }}
          onClick={() => {
            openCloseBool(false);
          }}
        />
      )}
      {/*Application Form */}
      <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
        Update File
        <label htmlFor="contained-button-formUpload">
          <Input
            accept="application/pdf, image/*"
            id="contained-button-formUpload"
            type="file"
            multiple
            onChange={async (s) => {
              var applicationForm = new FormData();
              applicationForm.append("s3path", "application-form");
              // applicationForm.append("file", s.target.files[0]);
              const files = s.target.files;

              if (files.length > 20) {
                swal({
                  title: `Cannot upload more than 20 files at a time`,
                  icon: "error",
                })

                return;
              }

              for (let i = 0; i < files.length; i++) {
                applicationForm.append('file', files[i]);
              }
              try {
                const response = await axios({
                  method: "post",
                  url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                  data: applicationForm,
                });
                if (response.data.s3Url) {
                  let updatedApplicationImage = [
                    ...inputs.applicationImage,
                    ...response.data.s3Url.map(url => ({ url })),
                  ];
                  setInputs((values) => ({
                    ...values,
                    applicationImage: updatedApplicationImage,
                  }));

                  swal({
                    title: `File Upload Complete`,
                    icon: "success",
                  });
                }
              } catch (error) {
                swal({
                  title: `File Upload Failed`,
                  icon: "error",
                });
              }
            }}
          />
          <ColorButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
            }}
            variant="contained"
            component="span"
            startIcon={<AttachFileIcon />}
          >
            Attach
          </ColorButton>
        </label>
      </DialogTitle>
      <DialogContent dividers="paper">
        <div>
          {inputs.applicationImage?.length > 0 ? (
            <div
              className="drop-file-preview"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {inputs.applicationImage.map((item, index) => (
                  <div key={index} className="drop-file-preview__item">
                    <img
                      src={
                        inputs.applicationImage[index]?.url.split(".")[5] ===
                          "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          ? AttachFileImageConfig["xlsx"]
                          : AttachFileImageConfig[
                          inputs.applicationImage[index]?.url.split(".")[5]
                          ] || AttachFileImageConfig["pdf"]
                      }
                      alt=""
                    />
                    <div
                      onClick={() => {
                        window.open(
                          `${inputs.applicationImage[index]?.url}`,
                          "_blank"
                        );
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                      className="drop-file-preview__item__info"
                    >
                      <p style={{ color: "#fff" }}>
                        {inputs.applicationImage[index]?.url
                          .split("/")[4]
                          .replace(/[0-9]/g, "")}
                      </p>
                      {/* <p>{item[0].size}B</p> */}
                    </div>

                    <div id="delete-appImg">
                      <IconButton
                        sx={{
                          color: "#C9302C",
                          position: "absolute",

                          top: "-35%",
                          left: "85%",
                        }}
                        size="large"
                        aria-label="delete-appImg"
                        onClick={() => applicationFormRemove(item)}
                      >
                        <CancelIcon sx={{ fontSize: "30px" }} />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
        <Box
          sx={{
            borderRadius: "20px",
            border: "2px solid #1553b5",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            "& > :not(style)": { m: 2 },
          }}
          component="form"
          autoComplete="off"
          onSubmit={handleUpdate}
        >
          {/* Customer Name */}
          <CssTextField
            multiline
            focused
            id="outlined-CustomerName"
            label="Customer Name"
            name="userName"
            value={inputs?.userName || ""}
            onChange={handleChange}
          />
          {/* CPA Branch */}
          {/* <CssTextField
            id="outlined-select-currency"
            select
            label="Branch Name"
            name="branchName"
            value={inputs?.branchName || ""}
            onChange={handleChange}
          >
            {branchList.map((option) => (
              <MenuItem key={option.id} value={option.branchName}>
                {option.branchName}
              </MenuItem>
            ))}
          </CssTextField> */}

          {/* Product */}
          {/* <CssTextField
            id="outlined-select-currency"
            select
            label="Product"
            name="productName"
            value={inputs?.productName || ""}
            onChange={handleChange}
          >
            {productList.map((option) => (
              <MenuItem key={option.id} value={option.productName}>
                {option.productName}
              </MenuItem>
            ))}
          </CssTextField> */}

          {inputs.productFields?.map((e) => {
            if (e.FILLED_BY === "sampler") {
              return e.TYPE === "select" ? (
                <>
                  <CssTextField
                    required={e.FILLED_BY === "sampler" ? e.REQUIRED : false}
                    select
                    type={e.TYPE}
                    id={`outlined-${e.DISPLAY_NAME}`}
                    label={e.DISPLAY_NAME}
                    name={e.FIELD}
                    value={e.VALUE}
                    onChange={handleProdFieldChange}
                    sx={{ margin: "auto" }}
                    key={e.DISPLAY_NAME}
                  >
                    {e.VALUES_LIST.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  {e.VALUE.toLowerCase() === "other" && (
                    <CssTextField
                      focused
                      multiline
                      required
                      id="outlined-fileNo"
                      label={`Other Value (${e.DISPLAY_NAME})`}
                      name="otherValue"
                      value={e.OTHER_VALUE || ""}
                      onChange={(s) => {
                        e.OTHER_VALUE = s.target.value;
                        setInputs({ ...inputs });
                      }}
                      sx={{ marginLeft: "30px" }}
                    />
                  )}
                </>
              ) : e.TYPE === "date" ? (
                <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={(() => {
                      if (typeof e.VALUE === "string") {
                        const dateString = e.VALUE;
                        let parts = dateString.split("-");
                        let day = parseInt(parts[0], 10);
                        let month = parseInt(parts[1], 10) - 1; // Subtract 1 from month because months are zero-indexed
                        let year = parseInt(parts[2], 10);
                        return new Date(year, month, day);
                      }
                      return e.VALUE
                    })()}
                    label={e.DISPLAY_NAME}
                    inputFormat="DD-MM-YYYY"
                    InputProps={{ name: e.FIELD }}
                    renderInput={(params) => (
                      <CssTextField
                        focused {...params}
                        required={e.REQUIRED}
                      />
                    )}
                    onChange={value => {
                      let updatedProdArray = inputs.productFields.map((a) => {
                        return { ...a };
                      });
                      updatedProdArray.find((a) => a.FIELD == e.FIELD).VALUE = value;
                      setInputs((values) => ({ ...values, productFields: updatedProdArray }));
                    }}
                  />
                </LocalizationProvider>
              ) : (
                <CssTextField
                  required={e.FILLED_BY === "sampler" ? e.REQUIRED : false}
                  multiline={
                    e.TYPE !== "date" &&
                    e.TYPE !== "time" &&
                    e.TYPE !== "month" &&
                    e.TYPE !== "number"
                  }
                  focused
                  type={e.TYPE}
                  id={`outlined-${e.DISPLAY_NAME}`}
                  label={e.DISPLAY_NAME}
                  name={e.FIELD}
                  value={e.VALUE}
                  inputProps={{ maxLength: e.MAX_CHAR || 100 }} // for setting max char limit
                  onInput={(num) => {
                    // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
                    e.TYPE === "number" &&
                      (num.target.value = Math.floor(
                        Math.max(0, parseInt(num.target.value))
                          .toString()
                          .slice(0, e.MAX_CHAR || 12)
                      ));
                      e.TYPE ==="text_caps" &&
                      (num.target.value=num.target.value.toUpperCase());
                  }}
                  onChange={handleProdFieldChange}
                />
              );
            }
          })}

          {inputs.productFields?.some(field => field.FILLED_BY === 'verifier') &&
            // inputs.status === 'VERIFIED' &&
            <Box
              sx={{
                borderRadius: "25px",
                border: "2px solid #1553b569",
                backgroundColor: "rgba(21, 83, 181, 0.1)",
                padding: '15px 20px',
                display: "flex",
                flexDirection: "column",
                "& > :not(style)": { m: 2 },
              }}
            >
              Filled by verifier
              {inputs.productFields?.map((e) => {
                if (e.FILLED_BY === "verifier") {
                  return e.TYPE === "select" ? (
                    <>
                      <CssTextField
                        select
                        type={e.TYPE}
                        id={`outlined-${e.DISPLAY_NAME}`}
                        label={e.DISPLAY_NAME}
                        name={e.FIELD}
                        value={e.VALUE}
                        onChange={handleProdFieldChange}
                        sx={{ margin: "verifier" }}
                        key={e.DISPLAY_NAME}
                      >
                        {e.VALUES_LIST.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </CssTextField>
                      {e.VALUE.toLowerCase() === "other" && (
                        <CssTextField
                          focused
                          multiline
                          required
                          id="outlined-fileNo"
                          label={`Other Value (${e.DISPLAY_NAME})`}
                          name="otherValue"
                          value={e.OTHER_VALUE || ""}
                          onChange={(s) => {
                            e.OTHER_VALUE = s.target.value;
                            setInputs({ ...inputs });
                          }}
                          sx={{ marginLeft: "30px" }}
                        />
                      )}
                    </>
                  ) : e.TYPE === "date" ? (
                    <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={(() => {
                          if (typeof e.VALUE === "string") {
                            const dateString = e.VALUE;
                            let parts = dateString.split("-");
                            let day = parseInt(parts[0], 10);
                            let month = parseInt(parts[1], 10) - 1; // Subtract 1 from month because months are zero-indexed
                            let year = parseInt(parts[2], 10);
                            return new Date(year, month, day);
                          }
                          return e.VALUE
                        })()}
                        label={e.DISPLAY_NAME}
                        inputFormat="DD-MM-YYYY"
                        InputProps={{ name: e.FIELD }}
                        renderInput={(params) => (
                          <CssTextField
                            focused 
                            {...params}
                            required={e.REQUIRED}
                          />
                        )}
                        onChange={value => {
                          let updatedProdArray = inputs.productFields.map((a) => {
                            return { ...a };
                          });
                          updatedProdArray.find((a) => a.FIELD == e.FIELD).VALUE = value;
                          setInputs((values) => ({ ...values, productFields: updatedProdArray }));
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <CssTextField
                      multiline={
                        e.TYPE !== "date" &&
                        e.TYPE !== "time" &&
                        e.TYPE !== "month" &&
                        e.TYPE !== "number"
                      }
                      focused
                      type={e.TYPE}
                      id={`outlined-${e.DISPLAY_NAME}`}
                      label={e.DISPLAY_NAME}
                      name={e.FIELD}
                      value={e.VALUE}
                      inputProps={{ maxLength: e.MAX_CHAR || 100 }} // for setting max char limit
                      onInput={(num) => {
                        // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
                        e.TYPE === "number" &&
                          (num.target.value = Math.floor(
                            Math.max(0, parseInt(num.target.value))
                              .toString()
                              .slice(0, e.MAX_CHAR || 12)
                          ));
                          e.TYPE ==="text_caps" &&
                          (num.target.value=num.target.value.toUpperCase());
                      }}
                      onChange={handleProdFieldChange}
                    />
                  );
                }
              })}
            </Box>}

          <UpdateButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
              fontSize: "16px",
              letterSpacing: "4px",
              marginTop: "20px",
              minWidth: "200px",
            }}
            loading={loading}
            loadingPosition="start"
            type="submit"
            variant="contained"
            startIcon={<UpdateIcon />}
          >
            Update Files
          </UpdateButton>
        </Box>
      </DialogContent>
    </div>
  );
};

export default Edit;
