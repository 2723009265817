import React from "react";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import swal from "sweetalert";

//MUI
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridOverlay,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

//mui icons
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

//components
import FileFilter from "../../filter/FileFilter";

//for customizing action tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#032437",
    fontSize: 14,
  },
}));

const StyledGridOverlay = styled(GridOverlay)(({ theme }) => ({
  flexDirection: "column",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#262626" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#595959" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#434343" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));
function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 2, color: "#000" }}>No Report(s) to Download</Box>
    </StyledGridOverlay>
  );
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 3,
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        backgroundColor: "#1553b5",
        borderTopLeftRadius: "20px",
        borderTopRightRadius: "20px",
      }}
    >
      <div>
        <GridToolbarColumnsButton
          sx={{
            color: "#fff",
            fontSize: ".8em",
          }}
        />
        &nbsp; &nbsp; &nbsp;
        <GridToolbarFilterButton
          sx={{
            color: "#fff",
            fontSize: ".8em",
          }}
        />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(-1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-input": {
            color: "#fff",
            fontSize: "1.1em",
          },
          "& .MuiInput-input:focus": {
            color: "#fff",
            fontSize: "1.1em",
            boxShadow: "none",
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "#fff",
          },
          "& .MuiInput-underline:after": {
            borderBottom: 1,
            borderColor: "#fff",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const ReportDownload = () => {
  const { token } = useSelector((state) => state.token.value);
  const { empId } = useSelector((state) => state.user.value);

  const [tableDataResult, setTableDataResult] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [rowCount, setRowCount] = React.useState(""); // total number of files, for particular selection, is stored in "rowCount" for pagination
  const [pageSize, setPageSize] = React.useState(10); // Number of rows on data grid. Initial value = 10
  const [page, setPage] = React.useState(0); // current page number is stored in "page". Initial value = 0
  const [fileCountApiResponse, setFileCountApiResponse] = React.useState([]);
  const [reportDownloadTableLoading, setReportDownloadTableLoading] =
    React.useState(false); // for setting loading animation while waiting for report download list api response

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
  };

  React.useEffect(() => {
    const tableData = async () => {
      setReportDownloadTableLoading(true);
      let pageNumber = page + 1;
      try {
        // api call to get report list for download
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/reportExtract`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            empId: empId,
            searchKey: searchText,
            pageNo: pageNumber,
            size: pageSize,
          },
        });
        setRowCount(response.data.totalCount);
        setTableDataResult(
          response.data.report.map((result) => ({
            id: result._id,
            fileRefId: result.internalRefId,
            fileId: result.fileId,
            reportName: result.reportName,
            createdTime: moment(result.createdAt).format("DD/MM/YY, h:mm a"),
            fileName: result.fileName,
            status: result.status && result.status,
            completionDate:
              result.completion_date &&
              moment(result.completion_date).format("DD/MM/YY, h:mm a"),
          }))
        );
      } catch (err) {
        console.log(err);
      }

      setReportDownloadTableLoading(false);
    };
    tableData();
  }, [fileCountApiResponse, pageSize, page, empId, searchText]);

  //download file
  const downloadFile = React.useCallback(
    (url) => () => {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to download this File?",
        icon: "info",
        buttons: true,
      }).then((willDelete) => {
        if (willDelete) {
          const link = document.createElement("a");
          link.download = url.slice(url.indexOf("/", 50) + 1);
          link.href = url;
          link.click();
          swal({
            title: "Download Complete",
            icon: "success",
          });
        }
      });
    },
    []
  );
  // datagrid column initializing
  const columns = React.useMemo(
    () => [
      {
        field: "reportName",
        headerName: "Report Name",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "createdTime",
        headerName: "Created Time",
        type: "date",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "fileName",
        headerName: "File Name",
        flex: 2,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },
      {
        field: "completionDate",
        headerName: "Completion Time",
        type: "date",
        flex: 1,
        renderCell: (cellValues) => {
          return (
            <Box
              sx={{
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              }}
            >
              {cellValues.value}
            </Box>
          );
        },
      },

      {
        field: "actions",
        type: "actions",
        headerName: "Action",
        sortable: false,
        minWidth: 90,
        getActions: (params) => [
          //download
          <CustomTooltip
            title="Download"
            arrow
            placement="top"
            TransitionComponent={Zoom}
          >
            <span
              style={{
                padding: "0",
                margin: "0",
                height: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <GridActionsCellItem
                icon={<DownloadIcon />}
                label="Download"
                disabled={params.row.status !== "COMPLETED"}
                onClick={downloadFile(params.row.fileName)}
                sx={{
                  backgroundColor: "#1553b5",
                  fontSize: "16px",
                  "&:hover": { backgroundColor: "#1553b5" },
                  "&:disabled": {
                    backgroundColor: "#6c83a8",
                  },
                }}
              />
            </span>
          </CustomTooltip>,
        ],
      },
    ],
    [downloadFile]
  );

  // datagrid row initializing.
  const rows = tableDataResult;

  return (
    <div className="Disabled_fileList">
      <FileFilter
        // count api response from filter component is passed to setFileCountApiResponse
        fileCountResponse={(response) => setFileCountApiResponse(response)}
      />
      <Box
        sx={{
          flexGrow: 1,
          borderRadius: "20px",
          border: "1px solid #1553b5",
          display: "flex",
          color: "#fff",
          bgcolor: "inherit",
          height: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <DataGrid
            rows={rows}
            rowCount={rowCount}
            loading={reportDownloadTableLoading}
            autoHeight
            components={{
              Toolbar: QuickSearchToolbar,
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            page={page}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            columns={columns}
            rowHeight={75}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
              },
            }}
            sx={{
              borderRadius: "20px",
              height: "100%",
              color: "#fff",
              fontSize: "16px",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#1553b5",
              },
              "& .MuiDataGrid-cell": {
                border: "1px groove rgba(0, 0, 0, 0.2)",
                color: "#000",
              },
              "& .MuiTablePagination-selectLabel": {
                fontSize: "1.2em",
                margin: "auto",
                color: "#000",
              },
              "& .MuiTablePagination-displayedRows": {
                fontSize: "1.2em",
                margin: "auto",
                color: "#000",
              },
              "& .MuiTablePagination-select": {
                fontSize: "1.2em",
                color: "#000",
              },
              "& .MuiSvgIcon-root": {
                fontSize: "1.2em",
                color: "#fff",
              },
              "& .MuiTablePagination-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: "1.2em",
                  color: "#000",
                },
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                maxHeight: "inherit",
                width: "100%",
                whiteSpace: "initial",
                lineHeight: "16px",
                wordBreak: "break-all",
              },
            }}
          />
        </div>
      </Box>
    </div>
  );
};

export default ReportDownload;
