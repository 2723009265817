import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Pages from "./components/mainpages/Pages";
import PreLoader from "./utils/preloader/PreLoader";
import axios from "axios";
import swal from "sweetalert";

import './App.css';

// redux
import { token } from "./features/token";
import { login } from "./features/user";
import { useDispatch } from "react-redux";
import { setSelectedBankTab } from "./features/spselection";
import { selection } from "./features/selection";
import { changeDrawerSelection } from "./features/spselection";

import DeviceDetector from "device-detector-js"

function App() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const localStoredToken = localStorage.getItem("token");

  axios.interceptors.response.use(
    function (response) {
      if (response?.data?.status === 702) {
        swal({
          title: "Session expired!",
          text: "Please login again to continue",
          icon: "error",
        }).then(() => {
          dispatch(token({ token: "" })); // clear token value in redux
          dispatch(setSelectedBankTab(0));
          dispatch(login({ role: "null", location: "null", locationCode: "null" })); // clear role and location value in redux
          dispatch(selection({ bank: [], bankFilter: [], doc: [], verifier: [], filestat: [] }));
          dispatch(changeDrawerSelection({ value: "Dashboard" }))
          localStorage.clear();
        })
      }
      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  const handleTokenValid = () => {
    dispatch(token({ token: "" })); // clear token value in redux
    dispatch(login({ role: "null", location: "null", locationCode: "null" })); // clear role and location value in redux
    localStorage.clear();
  };
  useEffect(() => {
    const tokenIsValid = async () => {
      const deviceDetector = new DeviceDetector()
      const device = deviceDetector.parse(navigator.userAgent)

      // api call to get user roles and locations
      const ipifyResponse = await axios({
        method: "get",
        url: `https://api.ipify.org/?format=json`
      })

      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v3/admin/getCurrentUser`,
          data: {
            type: "Supervisor Web",
            model: device.client.name,
            os: device.os.name,
            ip: ipifyResponse.data.ip
          },
          headers: {
            Authorization: `Bearer ${localStoredToken}`,
          },
        });
        if (response.status !== 200) {
          handleTokenValid();
        }
      } catch (err) {
        handleTokenValid();
        console.log(err.message);
      }
    };
    tokenIsValid();
    setTimeout(() => setLoader(false), 1000);
  }, []);

  // To prevent number type input field from changing when scroll wheel turned.
  React.useEffect(() => {
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  });

  return <Router>{!loader ? <Pages /> : <PreLoader />}</Router>;
}

export default App;
