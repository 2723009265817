import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeDrawerSelection } from "../../../../../../features/spselection";

//MUI
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

//MUI icon
import AttachFileIcon from "@mui/icons-material/AttachFile";
import UpdateIcon from "@mui/icons-material/Update";
import swal from "sweetalert";
import CancelIcon from "@mui/icons-material/Cancel";
import { AttachFileImageConfig } from "../../../../../../config/AttachFileImageConfig";
import CloseIcon from "@mui/icons-material/Close";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

// for third page document type upload
const Input = styled("input")({
  display: "none",
});

//custom MUI textField
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#171717",
  },
  "& label.MuiInputLabel-formControl": {
    color: "#171717",
  },
  "& svg.MuiSvgIcon-root": {
    color: "#171717",
  },
  "& .MuiSelect-select": {
    color: "#171717",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#171717",
  },
  "& .MuiOutlinedInput-input": {
    color: "#171717",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#171717",
    },
    "&:hover fieldset": {
      borderColor: "#171717",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#171717",
    },
  },
});
//for custom MUI DialogTitle
const DialogTitleStyle = {
  color: "#fff",
  backgroundColor: "#1553b5 ",
  display: "flex",
  justifyContent: "space-between",
};

const Edit = ({ docId, bankRefId, openCloseBool }) => {
  const { token } = useSelector((state) => state.token.value);

  const [inputs, setInputs] = useState({
    reportStatus: [],
    fields: [],
    id: "",
    docImage: [],
  }); // user entered input data is stored in "inputs" state
  const [loading, setLoading] = useState(false);
  const [boolean, setBoolean] = React.useState(false); //for document update
  const [docFields, setDocFields] = React.useState([]);
  const [isDisplayed, setIsDisplayed] = React.useState(false);
  const [docStatus, setDocStatus] = useState("");
  const [isPincodeChanged, setIsPincodeChanged] = useState(false);
  const [docRefId, setDocRefId] = useState();

  // mui customized button
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: "#f4f4f4",
    border: "1px solid #1553b5",
    color: "#1553b5 !important",
    padding: "4px 8px",
    margin: "auto 2px",
    "&:hover": {
      border: "1px solid #f4f4f4",
      backgroundColor: "#1553b5",
      color: "#fff !important",
    },
  }));

  // custom mui button (for edit submit)
  const UpdateButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }));

  // // useRef and useEffect is used to set the status of "status" state to  "REVERIFY" or "CLEAR". By the use of useRef the function inside useEffect will not called on initial render. Also a rendering condition is mentioned
  const isInitialMount = React.useRef(true);
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updateDocCall();
    }
  }, [boolean]);

  React.useEffect(() => {
    const viewDoc = async () => {
      // api call to get file detail
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/viewDocument`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: docId,
        },
      })
        .then((response) => {
          if ((response.data.status = "success")) {
            response.data.docDetail?.map((data) => {
              setDocStatus(data.verificationStatus);
              setDocFields(data.fields);
              setDocRefId(data.docRefId);
              setInputs((values) => ({ ...values, docImage: data.docImage }));
            });
          } else {
            swal({
              title: `Error while Fetching Document Data`,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const bankDetails = async () => {
      const params = new URLSearchParams();
      params.append("role", "verifier");
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBankList`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status !== 100)
          return

        response.data.bankDetails.map((details) => {
          details._id === bankRefId &&
            setInputs((values) => ({
              ...values,
              reportStatus: details.reportStatus,
            }));
        });
      } catch (error) {
        console.log(error);
      }
    };
    bankDetails();
    viewDoc();
  }, []);

  const handleDocFieldChange = (event) => {
    if (event.target.name === 'Pincode') {
      setIsPincodeChanged(true);
    }

    const name = event.target.name;
    const value = event.target.value;
    docFields?.map((e) => {
      let updatedFieldArray = docFields?.map((a) => {
        return { ...a };
      });
      updatedFieldArray.find((a) => a.DISPLAY_NAME == name).VALUE = value;
      setDocFields(updatedFieldArray);
    });
  };

  const handleDocUpdate = async (e) => {
    e.preventDefault();

    let invalidDateFound = false;
    const formattedDocFields = docFields.map(field => {
      if (field.TYPE === "date" && (field.FILLED_BY === "sampler" || field.FILLED_BY === "verifier") && field.VALUE) {
        let date;
        if (typeof field.VALUE === "string") {
          date = field.VALUE;
        } else {
          date = moment(field.VALUE.$d).format("DD-MM-YYYY");
        }
        if (date === "Invalid date") {
          invalidDateFound = true;
        }
        return {
          ...field,
          VALUE: date
        }
      }
      return field;
    })
    setInputs((values) => ({
      ...values,
      fields: formattedDocFields,
      id: docId,
    }));

    if (invalidDateFound) {
      swal({
        title: 'Invalid Date Format',
        icon: 'info'
      })
      return;
    }

    setBoolean(!boolean);
  };

  const updateDocCall = async () => {
    setLoading(true);
    let pincodeUpdate = { pincodeChange: false };
    try {
      if (isPincodeChanged) {
        const resp = await axios({
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getDocumentTypeList`,
          headers: { Authorization: `Bearer ${token}` }
        });
        if (resp.data.status !== 100)
          return

        const document = resp.data?.result[0]?.documentTypeId.find(doc => doc._id === docRefId);

        if (document) {
          pincodeUpdate = {
            pincodeBasedAssignment: document.pincodeBasedAssignment,
            pincodeChange: true
          }
          setIsPincodeChanged(false);
        } else {
          setIsPincodeChanged(false);
          setLoading(false);
          return swal({
            title: 'Pincode cannot be updated',
            icon: 'error'
          })
        }
      }

      if (inputs.reportStatus?.length === 0) {
        return swal({
          title: "Could not get report status. Document Updation Failed",
          icon: "error"
        })
      }

      // api call to get file detail
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/updateDocument`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...inputs,
          ...pincodeUpdate
        },
      });
      response.data.status === "success"
        ? swal({
          title: `Document Updated Successfully`,
          icon: "success",
        })
        : swal({
          title: `Document Updation Failed`,
          icon: "error",
        });
      setLoading(false);
    } catch (error) {
      console.log(error);
      swal({
        title: `Document Updation Failed`,
        icon: "error",
      });
      setLoading(false);
    }
  };

  //doc image remove
  const docImageRemove = async (file) => {
    const updatedList = [...inputs.docImage];
    updatedList.splice(inputs.docImage.indexOf(file), 1);
    setInputs({ ...inputs, docImage: updatedList });
  };

  React.useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
    }, 400);
  }, []);

  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      {/* close icon for doc edit modal */}
      {isDisplayed && (
        <CloseIcon
          style={{
            color: "#171717",
            position: "fixed",
            top: 20,
            right: "50%",
            fontSize: "25px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "3px",
            zIndex: 1,
          }}
          onClick={() => {
            openCloseBool(false);
          }}
        />
      )}
      {/* Document Detail*/}
      <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
        Update Document
        <label htmlFor="contained-button-formUpload">
          <Input
            accept="application/pdf, image/*"
            id="contained-button-formUpload"
            type="file"
            multiple
            onChange={async (s) => {
              var applicationForm = new FormData();
              applicationForm.append("s3path", "application-form");
              // applicationForm.append("file", s.target.files[0]);
              const files = s.target.files;

              if (files.length > 20) {
                swal({
                  title: `Cannot upload more than 20 files at a time`,
                  icon: "error",
                })

                return;
              }

              for (let i = 0; i < files.length; i++) {
                applicationForm.append('file', files[i]);
              }
              try {
                const response = await axios({
                  method: "post",
                  url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                  data: applicationForm,
                });
                if (response.data.s3Url) {
                  let updatedDocImage = [
                    ...inputs.docImage,
                    ...response.data.s3Url.map(url => ({ url }))
                  ];
                  setInputs((values) => ({
                    ...values,
                    docImage: updatedDocImage,
                  }));
                  swal({
                    title: `File Upload Complete`,
                    icon: "success",
                  });
                }
              } catch (error) {
                swal({
                  title: `File Upload Failed`,
                  icon: "error",
                });
              }
            }}
          />
          <ColorButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
            }}
            variant="contained"
            component="span"
            startIcon={<AttachFileIcon />}
          >
            Attach
          </ColorButton>
        </label>
      </DialogTitle>
      <DialogContent dividers="paper">
        <div>
          {inputs.docImage?.length > 0 ? (
            <div
              className="drop-file-preview"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "50%",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {inputs.docImage.map(
                  (item, index) =>
                    inputs.docImage[index]?.url.length > 0 && (
                      <div key={index} className="drop-file-preview__item">
                        <img
                          src={
                            inputs.docImage[index]?.url.split(".")[5] ===
                              "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              ? AttachFileImageConfig["xlsx"]
                              : AttachFileImageConfig[
                              inputs.docImage[index]?.url.split(".")[5]
                              ] || AttachFileImageConfig["pdf"]
                          }
                          alt=""
                        />
                        <div
                          onClick={() => {
                            window.open(
                              `${inputs.docImage[index]?.url}`,
                              "_blank"
                            );
                          }}
                          style={{
                            cursor: "pointer",
                          }}
                          className="drop-file-preview__item__info"
                        >
                          <p style={{ color: "#fff" }}>
                            {inputs.docImage[index]?.url
                              .split("/")[4]
                              .replace(/[0-9]/g, "")}
                          </p>
                          {/* <p>{item[0].size}B</p> */}
                        </div>

                        <div id="delete-appImg">
                          <IconButton
                            sx={{
                              color: "#C9302C",
                              position: "absolute",

                              top: "-35%",
                              left: "85%",
                            }}
                            size="large"
                            aria-label="delete-appImg"
                            onClick={() => docImageRemove(item)}
                          >
                            <CancelIcon sx={{ fontSize: "30px" }} />
                          </IconButton>
                        </div>
                      </div>
                    )
                )}
              </div>
            </div>
          ) : null}
        </div>
        <Box
          sx={{
            borderRadius: "20px",
            border: "2px solid #1553b5",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            "& > :not(style)": { m: 4 },
          }}
          component="form"
          autoComplete="off"
          onSubmit={handleDocUpdate}
        >
          {docFields?.map((e) => {
            if (e.FILLED_BY === "sampler") {
              return e.TYPE === "select" ? (
                <>
                  <CssTextField
                    required={e.FILLED_BY === "sampler" ? e.REQUIRED : false}
                    select
                    type={e.TYPE}
                    id={`outlined-${e.DISPLAY_NAME}`}
                    label={e.DISPLAY_NAME}
                    name={e.DISPLAY_NAME}
                    value={e.VALUE}
                    onChange={handleDocFieldChange}
                    onInput={(num) => {
                          
                      e.TYPE ==="text_caps" &&
                      (num.target.value=num.target.value.toUpperCase());
                  }}
                    sx={{ margin: "auto" }}
                    key={e.DISPLAY_NAME}
                  >
                    {e.FIELD === "status"
                      ? e.VALUES_LIST[0].value.map((option) => (
                        <MenuItem key={option.type} value={option.type}>
                          {option.status}
                        </MenuItem>
                      ))
                      : e.VALUES_LIST.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </CssTextField>
                  {e.VALUE?.toLowerCase() === "other" && (
                    <CssTextField
                      focused
                      multiline
                      required
                      id="outlined-fileNo"
                      label={`Other Value (${e.DISPLAY_NAME})`}
                      name="otherValue"
                      value={e.OTHER_VALUE || ""}
                      onInput={(num) => {
                          
                        e.TYPE ==="text_caps" &&
                        (num.target.value=num.target.value.toUpperCase());
                    }}
                      onChange={(s) => {
                        e.OTHER_VALUE = s.target.value;
                        setDocFields([...docFields]);
                      }}
                      sx={{
                        marginLeft: "30px",
                      }}
                    />
                  )}
                </>
              ) : e.TYPE === "date" ? (
                <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={(() => {
                      if (typeof e.VALUE === "string") {
                        const dateString = e.VALUE;
                        let parts = dateString.split("-");
                        let day = parseInt(parts[0], 10);
                        let month = parseInt(parts[1], 10) - 1; // Subtract 1 from month because months are zero-indexed
                        let year = parseInt(parts[2], 10);
                        return new Date(year, month, day);
                      }
                      return e.VALUE
                    })()}
                    label={e.DISPLAY_NAME}
                    inputFormat="DD-MM-YYYY"
                    InputProps={{ name: e.FIELD }}
                    renderInput={(params) => (
                      <CssTextField
                        focused {...params}
                        required={e.REQUIRED}
                      />
                    )}
                    onChange={value => {
                      let updatedFieldArray = docFields?.map((a) => {
                        return { ...a };
                      });
                      updatedFieldArray.find((a) => a.FIELD == e.FIELD).VALUE = value;
                      setDocFields(updatedFieldArray);
                    }}
                  />
                </LocalizationProvider>
              ) : (
                <CssTextField
                  required={e.FILLED_BY === "sampler" ? e.REQUIRED : false}
                  multiline={
                    e.TYPE !== "date" &&
                    e.TYPE !== "time" &&
                    e.TYPE !== "month" &&
                    e.TYPE !== "number"
                  }
                  focused
                  type={e.TYPE}
                  id={`outlined-${e.DISPLAY_NAME}`}
                  label={e.DISPLAY_NAME}
                  name={e.DISPLAY_NAME}
                  value={e.VALUE}
                  inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                  onInput={(num) => {
                    // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
                    e.TYPE === "number" &&
                      (num.target.value = Math.floor(
                        Math.max(0, parseInt(num.target.value))
                          .toString()
                          .slice(0, e.MAX_CHAR || 12)
                      ));
                      e.TYPE ==="text_caps" &&
                      (num.target.value=num.target.value.toUpperCase());
                  }}
                  onChange={handleDocFieldChange}
                />
              );
            }
          })}
          <UpdateButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
              fontSize: "16px",
              letterSpacing: "4px",
              marginTop: "20px",
              minWidth: "200px",
            }}
            loading={loading}
            loadingPosition="start"
            type="submit"
            variant="contained"
            startIcon={<UpdateIcon />}
          >
            Update Document
          </UpdateButton>
        </Box>
      </DialogContent>
    </div>
  );
};

export default Edit;
