import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { selection } from "../../../../../features/selection";
import LoadingButton from "@mui/lab/LoadingButton";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { styled } from "@mui/material/styles";
import "../spdashboard.css";

//MUI
import Stack from "@mui/material/Stack";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import moment from "moment";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { token as setToken } from "../../../../../features/token";
import { login } from "../../../../../features/user";
import { setSelectedBankTab } from "../../../../../features/spselection";

//for customizing datepick text field
const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#000",
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "#000",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "#000",
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: "#000",
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: "#000",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
  {
    color: "#000",
  },
  [`& .${inputLabelClasses.outlined}`]: {
    color: "#000",
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: "#000",
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: "#000",
  },
});

const FileFilter = ({ fileCountResponse, startDate, endDate, setOpenBanksGrid }) => {
  const dispatch = useDispatch();
  const [bankListData, setBankListData] = useState([]); // bank list api response is stored in this state
  const [selectedBank, setSelectedBank] = useState(
    JSON.parse(localStorage.getItem("bankFilter"))
  ); // selected bank is stored in this state
  const [selectedfileStat, setSelectedfileStat] = useState(
    JSON.parse(localStorage.getItem("fileStatFilter"))
  ); // selected fileStat is stored in this state
  const [loading, setLoading] = useState(false);

  const startingDate = new Date();
  startingDate.setDate(startingDate.getDate() - 7);  // set 7 days before current date

  const [fromDate, setFromDate] = React.useState(startingDate);

  const [toDate, setToDate] = React.useState(new Date());

  const filterButton = useRef();

  const { token } = useSelector((state) => state.token.value);
  const { location } = useSelector((state) => state.user.value);
  const { value } = useSelector((state) => state.spselection.value);
  const selectionDetails = useSelector(state => state.selection.value);

  const [validFilterClick, setValidFilterClick] = useState(true);

  // custom mui button
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }));


  const fileCountApiCall = async (bankId) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/getFileCount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        bankRefIdlist: bankId,
      },
    })
      .then((response) => {
        fileCountResponse(response.data.sort((a, b) => a._id.bankname.localeCompare(b._id.bankname)));
        setLoading(false);
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 403') {
          swal({
            title: "Session expired!",
            text: "Please login again to continue",
            icon: "error",
          }).then(() => {
            dispatch(setToken({ token: "" })); // clear token value in redux
            dispatch(setSelectedBankTab(0));
            dispatch(login({ role: "null", location: "null", locationCode: "null" })); // clear role and location value in redux
            dispatch(
              selection({
                bank: [],
                bankFilter: [],
                doc: [],
                verifier: [],
                filestat: [],
              })
            );
            localStorage.clear();
          })
          return;
        }
        console.log(err.message);
        setLoading(false);
        // swal({
        //   title: "Failed to get file count",
        //   text: "Reselect the bank and try again",
        //   icon: "error",
        // });
      });
  };

  const handleFilterClick = async () => {
    if (selectionDetails?.bankFilter?.length > 0) {
      setValidFilterClick(true);
      localStorage.setItem("bankFilter", JSON.stringify(selectionDetails.bankFilter));
      localStorage.setItem("fileStatFilter", JSON.stringify(selectedfileStat));
      let returnId = [];
      selectionDetails.bankFilter.map((id) => {
        bankListData.map((e) => {
          if (id.value === e._id)
            returnId = [
              ...returnId,
              {
                bankname: e.bankName,
                bankRefId: e._id,
                logo: e.logo,
                bankCode: e.bankCode.toString(),
              },
            ];
        });
      });
      const returnFileStat = selectedfileStat.map((id) => id.value);
      localStorage.setItem("bank", JSON.stringify(returnId));
      localStorage.setItem("fileStat", JSON.stringify(returnFileStat));
      dispatch(
        selection({
          bank: returnId,
          bankFilter: selectionDetails.bankFilter,
          doc: [],
          verifier: [],
          filestat: returnFileStat,
        })
      );
      // to check whether the selected number of bank >= 1
      if (returnId.length <= 0) {
        // swal({
        //   title: "Please select atleast one bank",
        //   icon: "info",
        // });
        return;
      }
      if (value === "Report Sent" && returnFileStat.length <= 0) {
        swal({
          title: "Please select atleast one file status",
          icon: "info",
        });
        return;
      }
      // api call to get file count, if atleast one bank is selected
      setLoading(true);
      fileCountApiCall(returnId);
    }
  };

  const handleAllBanks = async () => {
    if (!validFilterClick) {
      await handleFilterClick();
    }

    setOpenBanksGrid(true);
  }

  //List of three status for completd files
  const fileStat = [
    { label: "Sent", value: "SENT" },
    { label: "Sent and Mail", value: "SENT AND MAIL" },
  ];

  const banklistDropdown = bankListData.map((data) => ({
    label: data.bankName,
    value: data._id,
  }));

  useEffect(() => {
    // api call to get bank list
    const params = new URLSearchParams();
    params.append("locationId", location);
    const bankList = async () => {
      try {
        await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUserBankListByLocation`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        }).then(async (response) => {
          // handle success
          if (response.data.status === 100)
            setBankListData(response.data.result.sort((a, b) => a.bankName.localeCompare(b.bankName)));
        });
      } catch (error) {
        swal({
          title: "Failed to get bank list",
          icon: "error",
        });
      }
    };
    bankList();
    let bankIds = JSON.parse(localStorage.getItem("bank"));
    fileCountApiCall(bankIds);
  }, []);
  useEffect(() => {
    const timer = setInterval(
      () => fileCountApiCall(JSON.parse(localStorage.getItem("bank"))),
      10000
    );
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    startDate && startDate(fromDate);
  }, []);

  // function to update from date
  const handleFromDateChange = (newValue) => {
    setFromDate(newValue.$d);
    startDate(newValue.$d);
  };
  // function to update to date
  const handleToDateChange = (newValue) => {
    setToDate(newValue.$d);
    endDate(newValue.$d);
  };

  function disableRandomDates() {
    return Math.random() > 0.7;
  }

  useEffect(() => {
    selectionDetails.bankFilter?.length === 0 &&
      dispatch(selection({
        ...selectionDetails,
        bankFilter: banklistDropdown
      }))
    setTimeout(() => {
      filterButton.current && value !== 'Report Sent' && filterButton.current.click();
    }, 500);
  }, [bankListData]);

  const setBankFilter = (list) => {
    setValidFilterClick(false);
    dispatch(selection({
      ...selectionDetails,
      bankFilter: list
    }))
  }

  return (
    <div className="filter">
      <div
        className="filter-row"
        style={{
          display: "flex",
          justifyContent: value === "Report Sent" ? "space-evenly" : "center",
          flexFlow: "row",
        }}
      >
        <div className="first-filter">
          <MultiSelect
            options={banklistDropdown}
            value={selectionDetails.bankFilter}
            onChange={setBankFilter}
            labelledBy="Bank"
          />
        </div>
        {value === "Report Sent" && (
          <>
            <div className="second-filter second-filter-fileStat">
              <MultiSelect
                options={fileStat}
                value={selectedfileStat}
                onChange={setSelectedfileStat}
                labelledBy="File Status"
              />
            </div>

            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack
                spacing={4}
                direction="row"
                justifyContent="center"
                margin="30px 10px 20px"
                width="30%"
              >
                <DesktopDatePicker
                  // shouldDisableDate={disableRandomDates}
                  label="From Date"
                  inputFormat="MM/dd/yyyy"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  renderInput={(params) => (
                    <StyledTextField
                      sx={{
                        width: "50%",
                      }}
                      {...params}
                    />
                  )}
                />
                <DesktopDatePicker
                  label="To Date"
                  inputFormat="MM/dd/yyyy"
                  value={toDate}
                  onChange={handleToDateChange}
                  renderInput={(params) => (
                    <StyledTextField
                      sx={{
                        width: "50%",
                      }}
                      {...params}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider> */}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack
                spacing={4}
                direction="row"
                justifyContent="center"
                margin="30px 10px 20px"
                width="30%"
              >
                <DatePicker
                  label="From Date"
                  inputFormat="DD/MM/YY"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  renderInput={(params) => (
                    <StyledTextField
                      sx={{
                        width: "50%",
                        '& label': {
                          zIndex: '-1'
                        }
                      }}
                      {...params}
                    />
                  )}
                />
                <DatePicker
                  label="To Date"
                  inputFormat="DD/MM/YY"
                  value={toDate}
                  onChange={handleToDateChange}
                  renderInput={(params) => (
                    <StyledTextField
                      sx={{
                        width: "50%",
                        '& label': {
                          zIndex: '-1'
                        }
                      }}
                      {...params}
                    />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </>
        )}

        <div className="filter-button" style={{ paddingLeft: "10px" }}>
          <ColorButton
            ref={filterButton}
            sx={{
              color: "#fff",
              borderRadius: "10px",
            }}
            onClick={handleFilterClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<FilterAltIcon />}
            variant="contained"
          >
            Filter
          </ColorButton>
        </div>

        {value === 'File' &&
          <div className="filter-button" style={{ paddingLeft: "10px" }}>
            <ColorButton
              sx={{
                color: "#fff",
                borderRadius: "10px",
              }}
              variant="contained"
              disabled={selectionDetails.bankFilter.length === 0}
              onClick={handleAllBanks}
              startIcon={<AccountBalanceIcon />}
            >
              All Banks
            </ColorButton>
          </div>}
      </div>
    </div>
  );
};
export default FileFilter;
