import React, { useRef } from 'react';
import { useState } from "react";
import axios from 'axios';
import swal from "sweetalert";

import { FaUserCog } from "react-icons/fa";
import { GoSignOut } from "react-icons/go";
import { HiSwitchHorizontal } from "react-icons/hi";
import { BsKeyFill } from "react-icons/bs";
// import logo from "../../utils/assets/images/logo.png";
import "./header.css";

// redux
import { token } from "../../features/token";
import { login } from "../../features/user";
import { selection } from "../../features/selection";
import { setSelectedBankTab } from "../../features/spselection";
import { useSelector, useDispatch } from "react-redux";
import { changeDrawerSelection } from '../../features/spselection';

// components
import Role from "../mainpages/role/Role";
import SPDashboard from "../mainpages/supervisor/dashboard/SPDashboard";
import AdminDashboard from "../mainpages/admin/dashboard/AdminDashboard";
import BMDashboard from "../mainpages/bank manager/dashboard/BMDashboard";
import PMDashboard from "../mainpages/prime manager/dashboard/PMDashboard";
import { Spdrawer } from "../mainpages/drawer/Spdrawer";
import { Admindrawer } from "../mainpages/drawer/Admindrawer";
import { BMdrawer } from "../mainpages/drawer/BMdrawer";
import { PMdrawer } from "../mainpages/drawer/PMdrawer";

// mui
import { styled, useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Stack } from "@mui/material";
import { Modal } from '@mui/material';
import { AddPhotoAlternate as AddPhotoAlternateIcon, Edit as EditIcon } from '@mui/icons-material';

import { rolesMap } from "../mainpages/role/Role";

//for custom MUI DialogTitle
const DialogTitleStyle = {
  color: "#fff",
  backgroundColor: "#1553b5 ",
  display: "flex",
  justifyContent: "space-between",
};

// mui customized button
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#fff"),
  backgroundColor: "#1553b5",
  "&:hover": {
    backgroundColor: "#1553b5",
  },
}));
// mui customized cancel button
const CancelButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#1553b5"),
  backgroundColor: "transparent",
  border: "1px solid #1553b5",
  "&:hover": {
    backgroundColor: "transparent",
    border: "1px solid #1553b5",
  },
}));

//custom MUI textField
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#171717",
  },
  "& label.MuiInputLabel-formControl": {
    color: "#171717",
  },
  "& svg.MuiSvgIcon-root": {
    color: "#171717",
  },
  "& .MuiSelect-select": {
    color: "#171717",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#171717",
  },
  "& .MuiOutlinedInput-input": {
    color: "#171717",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#171717",
    },
    "&:hover fieldset": {
      borderColor: "#171717",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#171717",
    },
  },
});

//for mui dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//////

const Header = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openPasswordChange, setOpenPasswordChange] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenChangePassword = () => {
    setOpenPasswordChange(true);
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordChange(false);
  };

  const CurrentUser = localStorage.getItem("userNameEmail");
  ////////
  const { role, locationCode, firstName, lastName } = useSelector((state) => state.user.value);

  const dispatch = useDispatch();

  const handleLogout = () => {
    axios({
      url: `${process.env.REACT_APP_ADMINNODE}/api/v2/admin/logOut`,
      headers: {
        Authorization: `Bearer ${authToken}`,
      }
    })
      .then(response => {
        if (response.data.status === 100) {
          dispatch(token({ token: "" })); // clear token value in redux
          dispatch(setSelectedBankTab(0));
          dispatch(login({ role: "null", location: "null", locationCode: "null" })); // clear role and location value in redux
          dispatch(
            selection({
              bank: [],
              bankFilter: [],
              doc: [],
              verifier: [],
              filestat: [],
            })
          );
          dispatch(changeDrawerSelection({ value: "Dashboard" }))
          localStorage.clear();
        }
      })
      .catch(err => {
        console.log(err)
      })
  };

  const handleRoleSwitch = () => {
    dispatch(login({ role: "null", location: "null", locationCode: "null" }));
    dispatch(setSelectedBankTab(0));
    localStorage.setItem("role", "null");
    localStorage.setItem("location", "null");
    localStorage.setItem("locationCode", "null");
    handleClose();
  };

  const logo = localStorage.getItem("agencyLogo")
  const agencyName = localStorage.getItem("agencyName")


  // profile update

  const [openProfileImageUpdation, setOpenProfileImage] = useState(false);
  //change password
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [changePasswordData, setChangePasswordDate] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [selectedProfileImage, setSelectedProfileImage] = useState(null);
  const [temporaryProfileImage, setTemporaryProfileImage] = useState(null);
  const [imageURLData, setImageURLData] = useState({});
  const [viewBool, setViewBool] = React.useState(false); // for re-rendering view file
  const { token: authToken } = useSelector((state) => state.token.value);
  const user_id = localStorage.getItem("_id")
  const user_profile = localStorage.getItem('userProfileImage')

  const fileInputRef = useRef(null);
  const handleProfileOpen = () => {
    setOpenProfileImage(true);
    setTemporaryProfileImage(selectedProfileImage);
  };
  //change password open poppup 
  const handleChangePasswordOpen = () => {
    setOpenChangePassword(true)
  }
  //change password close poppup
  const hanldeChangePasswordClose = () => {
    setOpenChangePassword(false)
  }
  const handleOnChangePassword = (e) => {

    setChangePasswordDate({
      ...changePasswordData,
      [e.target.name]: e.target.value
    })
    // if(changePasswordData.newpassword==(changePasswordData.confirmpassword))

  }
  const handleconfirmpasswordValidation = () => {
    if (changePasswordData.newpassword !== (changePasswordData.confirmpassword)) {
      setConfirmPasswordError("Confirm password is not matched");

    }
    else {
      setConfirmPasswordError("");
    }
  }

  const handleProfileClose = () => {
    setOpenProfileImage(false);
    setTemporaryProfileImage(null);
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageURLData(file)
      setTemporaryProfileImage(URL.createObjectURL(file));
    }
  };
  const handleProfileCancel = () => {
    setTemporaryProfileImage(selectedProfileImage);
    handleProfileClose();
  };
  // const handleProfileSave = () => {
  //   setSelectedProfileImage(temporaryProfileImage)


  //image upload
  const handleProfileSave = async () => {
    setSelectedProfileImage(temporaryProfileImage)
    var applicationForm = new FormData();
    applicationForm.append("s3path", "profile-img");
    applicationForm.append("file", imageURLData);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadToS3`,
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
        },
        data: applicationForm,
      });
      if (response.data.message === "Success") {
        let updatedSupervisorProfileImage = response.data.s3Url

        try {
          //api call for update file
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_ADMINNODE}/api/v3/admin/updateUserProfile`,
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
            data: {
              id: `${user_id}`,
              url: updatedSupervisorProfileImage,
            },
          });
          localStorage.setItem("userProfileImage", updatedSupervisorProfileImage);
          response.data.status === "1" && setViewBool((prev) => !prev);
          swal({
            title: `image Upload Success`,
            icon: "success",
          });
        } catch (error) {
          swal({
            title: `Failed on updating Supervisor profile image`,
            icon: "error",
          });
        }
      }
    } catch (error) {
      swal({
        title: `image Upload Failed`,
        icon: "error",
      });
    }
    handleProfileClose();
  };
  //change password api call

  const handlePasswordChangeSave = async () => {
    const changeData = {
      userName: CurrentUser,
      oldPassword: changePasswordData.currentpassword,
      newPassword: changePasswordData.confirmpassword
    }
    if (
      !changePasswordData.currentpassword ||
      !changePasswordData.newpassword ||
      !changePasswordData.confirmpassword
    ) {
      swal({
        title: `Please fill all fields`,
        icon: "error",
      });
      return
    }

    if (changePasswordData.newpassword !== changePasswordData.confirmpassword) {
      swal({
        title: `Passwords do not match`,
        icon: "error",
      });
      return
    }

    const passwordRegex = /^(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,12}$/
    if (!passwordRegex.test(changePasswordData.newpassword)) {
      swal({
        title: `Password must contain at least one special character and one digit, and between 8 to 12 characters`,
        icon: "error",
      });
      return
    }

    if (changePasswordData.newpassword == (changePasswordData.confirmpassword)) {
      try {
        //api call for update file
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v2/admin/changePassword`,
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: changeData,
        });

        if (response.data.status === 100) {
          swal({
            title: `Password Changed Successfully`,
            icon: "success",
          });
          hanldeChangePasswordClose();
        } else {
          throw new Error("Failed to change password")
        }
      } catch (error) {
        swal({
          title: `Password Changed Failed`,
          icon: "error",
        });
      }
    }
  }

  // };


  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ bgcolor: "#1553b5 !important " }}
      >
        <Toolbar>
          {(role === "supervisor" ||
            role === "admin" ||
            role === "bankmanager" ||
            role === "prime_manager" ||
            role === "client_manager") && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: "36px",
                  ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
            )}

          <Typography variant="h6" noWrap sx={{ flexGrow: 1, display: "flex" }} component="div">
            {/* <Avatar alt="Remy Sharp" className="logo" src={logo} /> */}
            {(logo && role !== "null") && (
              <Stack direction="row" alignItems="center">
                <img style={{ height: "50px" }} src={logo} />
                <Typography ml={1}>{agencyName}</Typography>
              </Stack>
            )}
          </Typography>

          <div>
            {locationCode !== 'null' && locationCode &&
              <Typography sx={{
                display: 'inline-block',
                fontSize: '12px',
                padding: '4px 6px',
                // border: '1px solid white',
                borderRadius: '5px',
                cursor: 'pointer',
                // backgroundColor: 'primary.main'
              }}
                onClick={handleMenu}
              >
                <span style={{ fontWeight: 'bold' }}>{`${firstName} ${lastName}`}</span>{` / ${locationCode} - ${rolesMap[role]}`}
              </Typography>}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >

              {user_profile ? (
                <Avatar
                  src={user_profile}
                  alt="Profile"
                  sx={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                  }}
                />
              ) :
                (
                  <AccountCircle />
                )
              }


            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose}>
                <FaUserCog size="20px" />
                &nbsp; Profile
              </MenuItem> */}

              {/* Profile Update*/}
              <MenuItem onClick={handleProfileOpen}>
                {user_profile ? (
                  <Avatar
                    src={user_profile}
                    alt="Profile"
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                    }}
                  />
                ) : (

                  < FaUserCog size="20px" />
                )}
                &nbsp; Profile

              </MenuItem>

              <MenuItem onClick={handleRoleSwitch}>
                <HiSwitchHorizontal size="20px" />
                &nbsp; Role switch
              </MenuItem>
              <MenuItem onClick={handleChangePasswordOpen}>
                <BsKeyFill size="20px" />
                &nbsp; Change password
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <GoSignOut size="20px" />
                &nbsp; Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {
        (role === "supervisor" ||
          role === "admin" ||
          role === "bankmanager" ||
          role === "prime_manager" ||
          role === "client_manager") && (
          <Drawer
            variant="permanent"
            open={open}
            Drawer
            PaperProps={{
              sx: {
                backgroundColor: "#1553b5 !important",
                color: "#fff",
              },
            }}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            {role === "bankmanager" ? (
              <BMdrawer />
            ) : role === "supervisor" ? (
              <Spdrawer />
            ) : role === "admin" ? (
              <Admindrawer />
            ) : (role === "prime_manager" || role === "client_manager") ? (
              <PMdrawer />
            ) : (
              <Role />
            )}
          </Drawer>
        )
      }
      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <DrawerHeader />
        {role === "bankmanager" ? (
          <BMDashboard />
        ) : role === "supervisor" ? (
          <SPDashboard />
        ) : role === "admin" ? (
          <AdminDashboard />
        ) : (role === "prime_manager" || role === "client_manager") ? (
          <PMDashboard />
        ) : (
          <Role />
        )}

        {/* profile Update */}
        <Modal open={openProfileImageUpdation} onClose={handleProfileClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'white',
              boxShadow: 24,
              p: 4,
              borderRadius: '8px',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Update Profile Picture</Typography>
            <input
              accept="image/*"
              id="image-upload"
              type="file"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            {temporaryProfileImage && !!user_profile ? (
              <div style={{ position: 'relative', marginBottom: '10px' }}>
                <Avatar
                  src={temporaryProfileImage}
                  alt="Selected"
                  sx={{
                    height: 300,
                    width: 300,
                    margin: '10px,0px'
                  }}
                  // style={{
                  //   maxWidth: '100%',
                  //   maxHeight: '300px',
                  //   borderRadius: '50%',
                  //   marginBottom: '20px',
                  // }}
                  onClick={() => fileInputRef.current.click()}
                />
                <div
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    background: 'white',
                    borderRadius: '50%',
                    padding: '4px',
                  }}
                  onClick={() => fileInputRef.current.click()}
                >
                  <EditIcon fontSize="medium" aria-label='edit' />
                </div>
              </div>
            ) : ''
            }
            {!temporaryProfileImage && !user_profile ? (
              <label htmlFor="image-upload">
                <Button variant="outlined" component="span" style={{ marginBottom: '10px' }}>
                  Upload Image
                </Button>
              </label>
            ) : ''
            }

            {!temporaryProfileImage && user_profile ?
              (
                <div style={{ position: 'relative', marginBottom: '10px' }}>
                  <Avatar
                    src={user_profile}
                    alt="Selected"
                    sx={{
                      height: 300,
                      width: 300,
                      margin: '10px,0px'
                    }}
                    // style={{
                    //   maxWidth: '100%',
                    //   maxHeight: '300px',
                    //   borderRadius: '50%',
                    //   marginBottom: '20px',
                    // }}
                    onClick={() => fileInputRef.current.click()}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      background: 'white',
                      borderRadius: '50%',
                      padding: '4px',
                    }}
                    onClick={() => fileInputRef.current.click()}
                  >
                    <EditIcon fontSize="medium" aria-label='edit' />
                  </div>
                </div>
              ) : ''
            }
            < Button variant="outlined" onClick={handleProfileCancel} style={{ marginLeft: '10px', marginRight: '10px' }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleProfileSave}
            >
              Save
            </Button>
          </Box>
        </Modal>
        {/* profile Update end */}


      </Box >

      <Dialog
        open={openChangePassword}
        onClose={handleClose}
        sx={{
          "& .css-twia2z-MuiPaper-root-MuiDialog-paper ": {
            borderRadius: "30px",
            backgroundColor: "#0E3F63",
          },
        }}
        TransitionComponent={Transition}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="xs" //You can change md to sm, xs, lg and xl as per your requirement.
      >
        <div style={{ backgroundColor: "#fff" }}>
          <DialogTitle sx={DialogTitleStyle}>Change Password</DialogTitle>
          <div style={{ margin: "10px auto" }}>
            <DialogContent>
              <CssTextField
                autoComplete='off'
                margin="dense"
                id="name"
                label="Current password"
                name="currentpassword"
                type="password"
                onChange={handleOnChangePassword}
                fullWidth
                required
              />
              <CssTextField
                autoComplete='off'
                margin="dense"
                id="name"
                label="New Password"
                name="newpassword"
                type="password"
                onChange={handleOnChangePassword}
                fullWidth
                required

              />
              <CssTextField
                autoComplete='off'
                margin="dense"
                id="name"
                label="Confirm Password"
                name="confirmpassword"
                type="password"
                onChange={handleOnChangePassword}
                onKeyUp={handleconfirmpasswordValidation}
                fullWidth
                required
              />
              <p className="text-danger">{confirmPasswordError}</p>
            </DialogContent>
          </div>
          <DialogActions sx={{ margin: "10px 20px" }}>
            <CancelButton
              sx={{
                color: "#0E3F63",
                borderRadius: "10px",
              }}
              onClick={hanldeChangePasswordClose}
              variant="contained"
            >
              Cancel
            </CancelButton>
            <ColorButton
              sx={{
                color: "#fff",
                borderRadius: "10px",
              }}
              onClick={handlePasswordChangeSave}
              variant="contained"
            >
              Submit
            </ColorButton>
          </DialogActions>
        </div>
      </Dialog>
    </Box >
  );
};

export default Header;
