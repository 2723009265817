import * as React from "react";
import FileTable from "./FileTable";
import { useDispatch, useSelector } from "react-redux";
import { changeDrawerSelection } from "../../../../../../features/spselection";
import swal from "sweetalert";
import axios from "axios";
import JsFileDownloader from "js-file-downloader";
import * as XLSX from "xlsx";

// mui
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";

//icons
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import ListItemIcon from "@mui/material/ListItemIcon";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

//for custom MUI DialogTitle
const DialogTitleStyle = {
  color: "#fff",
  backgroundColor: "#1553b5 ",
  display: "flex",
  justifyContent: "space-between",
};

function ConfirmationDialogRaw(props) {
  const { onClose, value: valueProp, open, bankId, setPositiveStatus, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [productList, setProductList] = React.useState([]); //getproductlist api reponse is stored in this state
  const radioGroupRef = React.useRef(null);
  const { token } = useSelector((state) => state.token.value);

  // mui customized button
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }));
  // mui customized cancel button
  const CancelButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#1553b5"),
    backgroundColor: "transparent",
    border: "1px solid #1553b5",
    "&:hover": {
      backgroundColor: "transparent",
      border: "1px solid #1553b5",
    },
  }));

  React.useEffect(() => {
    const params = new URLSearchParams();
    params.append("bankId", bankId);
    //api call to get product list
    const productList = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getProductListByBank`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });

        if (response.data.status !== 100)
          return

        const reportPositiveStatus = response.data.result[0].bankId.reportStatus.find(item => item.type?.toLowerCase() === 'positive');
        const reportPositiveStatusObj = { [reportPositiveStatus.status]: reportPositiveStatus.type }

        setProductList(response.data.result);
        setPositiveStatus(reportPositiveStatusObj);
      } catch (error) {
        console.log(error);
      }
    };
    productList();
  }, [bankId]);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleDownload = async () => {
    const params = new URLSearchParams();
    params.append("bankId", bankId);
    value !== "general" && params.append("productId", value);
    if (value.length > 0) {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/template/download`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });

        if (response.data.message === "File uploaded and Images Generated") {
          const fileUrl = response.data.url;
          new JsFileDownloader({
            url: fileUrl,
          })
            .then(function () {
              swal({
                title: "Download Complete",
                icon: "success",
              });
            })
            .catch(function (error) {
              swal({
                title: "Download Failed. Try Again",
                icon: "error",
              });
            });
        }
      } catch (error) {
        console.log(error);
      }
      onClose(value);
    } else {
      swal({
        title: "Please select one product",
        icon: "info",
      });
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "80%", maxHeight: 435 },
      }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <div>
        <DialogTitle sx={DialogTitleStyle}>Select Product</DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={value}
            onChange={handleChange}
          >
            <>
              <FormControlLabel
                value="general"
                key="general"
                control={
                  <Radio
                    sx={{
                      color: "#373737",
                      "&.Mui-checked": {
                        color: "#373737",
                      },
                    }}
                  />
                }
                label="General Product"
              />
              {productList.map((option) => {
                return (
                  option.type === "other" && (
                    <FormControlLabel
                      value={option._id}
                      key={option._id}
                      control={
                        <Radio
                          sx={{
                            color: "#373737",
                            "&.Mui-checked": {
                              color: "#373737",
                            },
                          }}
                        />
                      }
                      label={option.productName}
                    />
                  )
                );
              })}
            </>
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <CancelButton
            sx={{
              color: "#1553b5",
              borderRadius: "10px",
            }}
            onClick={handleCancel}
            variant="contained"
          >
            Cancel
          </CancelButton>
          <ColorButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
            }}
            onClick={handleDownload}
            variant="contained"
          >
            Download
          </ColorButton>
        </DialogActions>
      </div>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  product: PropTypes.string.isRequired,
  bankId: PropTypes.string.isRequired,
};

export default function TableHeader({
  selectedBankId,
  selectedBankCode,
  selectedBankName,
  selectedBankLogo,
  Count,
}) {
  var bankId = { selectedBankId, selectedBankName, selectedBankLogo };
  localStorage.setItem("currentBankId", selectedBankId);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.token.value);
  const { empId, firstName, lastName, role } = useSelector(
    (state) => state.user.value
  );

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("1");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [excelFile, setExcelFile] = React.useState({});
  const [product, setProduct] = React.useState("");
  const [positiveStatus, setPositiveStatus] = React.useState({});

  React.useEffect(() => {
    if (excelFile.size > 0) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const data = e.target.result;
        const workbook = XLSX.read(data);
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1});
        const validRows = jsonData.filter(row => Boolean(row?.length));
        const rowCount = validRows.length - 1;

        const content = document.createElement("div");
        content.innerHTML = `
          Are you sure that you want to upload ${excelFile.name}?
          <br/><br/>
          <strong>Total number of file(s): ${rowCount}</strong>
        `;
        
        swal({
          title: "Are you sure?",
          // text: `Are you sure that you want to upload ${excelFile.name}? \n\n File Count: ${rowCount}`,
          content: content,
          icon: "info",
          buttons: true,
          dangerMode: true,
        }).then(async (excelUpload) => {
          if (excelUpload) {          
            var bodyFormData = new FormData();
            bodyFormData.append("file", excelFile);
            bodyFormData.append("fileNames", "excelFile");
            bodyFormData.append("bankId", selectedBankCode);
            bodyFormData.append("bankRefId", selectedBankId);
            bodyFormData.append("samplerId", empId);
            bodyFormData.append(
              "samplerName",
              `${role}-${firstName} ${lastName}`
            );

            try {
              const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/excelUpload`,
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data",
                },
                data: bodyFormData,
              });
              if (response.data.status === "success") {
                swal({
                  title: `File(s) added successfully!`,
                  icon: "success",
                });
                setExcelFile({});
              } else {
                swal({
                  title: `${response.data.status}`,
                  icon: "error",
                });
                setExcelFile({});
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            setExcelFile({});
            // swal("Operation terminated");
          }
        });
      }
      reader.readAsArrayBuffer(excelFile);
    }
  }, [excelFile]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (value) => {
    dispatch(
      changeDrawerSelection({
        value: value,
        currentBankId: selectedBankId,
        bankName: selectedBankName,
        bankLogo: selectedBankLogo
      })
    );
    localStorage.setItem("currentBankId", selectedBankId);
  };

  const handleCloseDialog = (newValue) => {
    setOpen(false);

    if (newValue) {
      setProduct(newValue);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TabContext value={value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            bgcolor: "#1553b5",
            borderRadius: "20px",
            padding: "0 20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <TabList
            variant="fullWidth"
            onChange={handleChange}
            aria-label="lab API tabs example"
            textColor="inherit"
            TabIndicatorProps={{
              sx: {
                backgroundColor: "#032437",
                height: "100%",
                zIndex: 1,
                borderRadius: "10px",
              },
            }}
          >
            <Tab
              label={
                <div>
                  ALL &nbsp;
                  <span className="badge badge-tabpannel">{Count[0]}</span>
                </div>
              }
              value="1"
              style={{ fontSize: "17px", zIndex: 2 }}
            />
            <Tab
              label={
                <div>
                  ALLOCATION PENDING&nbsp;
                  <span className="badge badge-tabpannel">{Count[1]}</span>
                </div>
              }
              value="2"
              style={{ fontSize: "17px", zIndex: 2 }}
            />
            <Tab
              label={
                <div>
                  VERIFICATION PENDING&nbsp;
                  <span className="badge badge-tabpannel">{Count[2]}</span>
                </div>
              }
              value="3"
              style={{ fontSize: "17px", zIndex: 2 }}
            />
            <Tab
              label={
                <div>
                  VERIFIED&nbsp;
                  <span className="badge badge-tabpannel">{Count[3]}</span>
                </div>
              }
              value="4"
              style={{ fontSize: "17px", zIndex: 2 }}
            />
            <Tab
              label={
                <div>
                  READY FOR REPORT&nbsp;
                  <span className="badge badge-tabpannel">{Count[4]}</span>
                </div>
              }
              value="5"
              style={{ fontSize: "17px", zIndex: 2 }}
            />
          </TabList>
          <Toolbar>
            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-addFile"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AddBoxIcon />
              </IconButton>
              <Menu
                id="menu-addFile"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleClick("add file1")}>
                  <ListItemIcon>
                    <NoteAddIcon />
                  </ListItemIcon>
                  Add New File
                </MenuItem>
                <MenuItem>
                  <input
                    accept=".xlsx"
                    style={{ display: "none" }}
                    type="file"
                    id="excel-upload"
                    onChange={(e) => {
                      setExcelFile(e.target.files[0]);
                      e.target.value = "";
                    }}
                  />
                  <label htmlFor="excel-upload">
                    <ListItemIcon>
                      <UploadFileIcon />
                    </ListItemIcon>
                    Excel Upload
                  </label>
                </MenuItem>
                <MenuItem onClick={() => setOpen(true)}>
                  <ListItemIcon>
                    <FileDownloadIcon />
                  </ListItemIcon>
                  Download Template
                </MenuItem>
              </Menu>
            </div>
            <ConfirmationDialogRaw
              id="ringtone-menu"
              keepMounted
              open={open}
              onClose={handleCloseDialog}
              value={product}
              bankId={selectedBankId}
              setPositiveStatus={setPositiveStatus}
            />
          </Toolbar>
        </Box>

        <TabPanel value="1" style={{ padding: "20px 0" }}>
          <FileTable tabBankId={bankId} status={[]} positiveStatus={positiveStatus} />
        </TabPanel>
        <TabPanel value="2" style={{ padding: "20px 0" }}>
          <FileTable tabBankId={bankId} status={["NEW"]} positiveStatus={positiveStatus} />
        </TabPanel>
        <TabPanel value="3" style={{ padding: "20px 0" }}>
          <FileTable tabBankId={bankId} status={["INPROGRESS"]} positiveStatus={positiveStatus} />
        </TabPanel>
        <TabPanel value="4" style={{ padding: "20px 0" }}>
          <FileTable tabBankId={bankId} status={["VERIFIED"]} positiveStatus={positiveStatus} />
        </TabPanel>
        <TabPanel value="5" style={{ padding: "20px 0" }}>
          <FileTable tabBankId={bankId} status={["COMPLETED"]} positiveStatus={positiveStatus} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
