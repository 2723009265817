import React, { useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import swal from 'sweetalert';

//components
import FileFilter from "../../filter/FileFilter";
import TableHeader from "../../table/file/TableHeader";
import BanksGrid from "./components/BanksGrid";

// material ui
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

import { setSelectedBankTab } from "../../../../../../features/spselection";
import axios from "axios";

// for customizing count tooltip

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#032437",
    fontSize: 14,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const FileList = () => {
  const dispatch = useDispatch();
  // const [value, setValue] = React.useState(0);
  const [fileCountApiResponse, setFileCountApiResponse] = React.useState([]);
  const [openBanksGrid, setOpenBanksGrid] = React.useState(false)
  const value = useSelector(
    (state) => state.spselection.selectedBankTab
  );
  const { bank, bankFilter } = useSelector(state => state.selection.value);
  const { token } = useSelector((state) => state.token.value);

  const handleChange = (event, newValue) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(setSelectedBankTab(newValue));
    // setValue(newValue);
  };

  //to get count data
  var Count = [];
  fileCountApiResponse.map((result) => {
    var ALL = result.info.reduce(
      (prevValue, { Count }) => prevValue + Count,
      0
    );
    var ALLOCATION_PENDING = result.info
      .filter((q) => q.Status === "NEW")
      .map((s) => s.Count);
    var VERIFICATION_PENDING = result.info
      .filter((q) => q.Status === "INPROGRESS")
      .map((s) => s.Count);
    var VERIFIED = result.info
      .filter((q) => q.Status === "VERIFIED")
      .map((s) => s.Count);
    var READY_FOR_REPORT = result.info
      .filter((q) => q.Status === "COMPLETED")
      .map((s) => s.Count);
    var BANK_NAME = result._id.bankname;
    var BANK_ID = result._id.bankRefId;
    var BANK_CODE = result._id.bankCode;
    var BANK_LOGO = result._id.logo;

    Count.push({
      ALL: ALL,
      ALLOCATION_PENDING: ALLOCATION_PENDING[0] || 0,
      VERIFICATION_PENDING: VERIFICATION_PENDING[0] || 0,
      VERIFIED: VERIFIED[0] || 0,
      READY_FOR_REPORT: READY_FOR_REPORT[0] || 0,
      BANK_NAME: BANK_NAME,
      BANK_ID: BANK_ID,
      BANK_CODE: BANK_CODE,
      BANK_LOGO: BANK_LOGO,
    });
  });
  useLayoutEffect(() => {
    if (value > Count.length - 1 && Count.length !== 0)
      dispatch(setSelectedBankTab(0))
  }, [value, Count])

  const handleBanksGridClose = async ({ newBankFilter, newBank, dbClickedBankId }) => {
    if (bankFilter.length === 0 && !dbClickedBankId) {
      swal({
        title: "Please select atleast one bank",
        icon: "error",
      });
      return;
    }

    localStorage.setItem('bank', JSON.stringify(newBank ?? bank));
    localStorage.setItem('bankFilter', JSON.stringify(newBankFilter ?? bankFilter));

    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/getFileCount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        bankRefIdlist: newBank ?? bank,
      },
    }).then(response => {
      setFileCountApiResponse(response.data.sort((a, b) => a._id.bankname.localeCompare(b._id.bankname)));
    }).catch(err => {
      console.log(err);
    }).finally(() => {
      setOpenBanksGrid(false);

      const bankFilter = JSON.parse(localStorage.getItem('bankFilter'));
      const bank = JSON.parse(localStorage.getItem('bank'));
      const bankIndex = bankFilter.findIndex(bank => bank.value === dbClickedBankId);
      bankIndex !== -1 ? dispatch(setSelectedBankTab(bankIndex)) : (newBank && dispatch(setSelectedBankTab(0)))
    })
  }

  return (
    <div className="Supervisor-dashboard">
      <FileFilter
        // count api response from filter component is passed to setFileCountApiResponse
        fileCountResponse={(response) => setFileCountApiResponse(response)}
        setOpenBanksGrid={setOpenBanksGrid}
      />

      {Count.length > 0 &&
        <BanksGrid
          open={openBanksGrid}
          onClose={handleBanksGridClose}
        />}

      <Box
        sx={{
          flexGrow: 1,
          borderRadius: "20px",
          border: "2px solid #1553b5",
          display: "flex",
          color: "#fff",
          bgcolor: "inherit",
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          textColor="#fff"
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#1553b5",
              height: 3,
              width: "100%",
              borderTopLeftRadius: "17px",
              borderBottomLeftRadius: "17px",
              zIndex: -1,
              opacity: "50%",
            },
          }}
          sx={{
            borderRight: 1,
            borderColor: "#1553b5",
            borderBottom: "2px solid #1553b5",
            borderBottomRightRadius: "17px",
            borderBottomLeftRadius: "17px",
            maxWidth: 140,
            minWidth: 125,
            maxHeight: 800,
          }}
        >
          {/* Loop fileCountApiResponse to get bank name and file count  */}
          {Count.map((val) => (
            <Tab
              label={
                <div>
                  {/* to get total count */}
                  <LightTooltip
                    title="All"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <span className="badge badge-allCount">{val.ALL}</span>
                  </LightTooltip>
                  {/* to get Allocation Pending count */}
                  <LightTooltip
                    title="Allocation Pending"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <span className="badge badge-allocationPendingCount">
                      {val.ALLOCATION_PENDING}
                    </span>
                  </LightTooltip>
                  {/* to get Verification Pending count */}
                  <LightTooltip
                    title="Verification Pending"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <span className="badge badge-verificationPending">
                      {val.VERIFICATION_PENDING}
                    </span>
                  </LightTooltip>

                  {/* to get Verified count */}
                  <LightTooltip
                    title="Verified"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <span className="badge badge-verifiedFileCount">
                      {val.VERIFIED}
                    </span>
                  </LightTooltip>

                  {/* to get Ready For Report count */}
                  <LightTooltip
                    title="Ready For Report"
                    arrow
                    placement="top"
                    TransitionComponent={Zoom}
                  >
                    <span className="badge badge-completedFileCount">
                      {val.READY_FOR_REPORT}
                    </span>
                  </LightTooltip>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <img
                      alt=""
                      src={
                        val.BANK_LOGO
                          ? val.BANK_LOGO
                          : `https://uat.rijasprime.com/assets/images/bank-icon.png`
                      }
                      style={{
                        height: "50px",
                        width: "50px",
                        margin: "10px auto",
                      }}
                    />
                    <span style={{ color: "#000", paddingTop: "8px" }}>
                      {val.BANK_NAME}
                    </span>
                  </div>
                </div>
              }
              sx={{
                height: "auto",
                borderBottom: "2px solid #1553b5",
                borderBottomLeftRadius: "17px",
              }}
            />
          ))}
        </Tabs>
        {/* document proccesing table details */}
        {Count.map((result, index) => (
          <TabPanel value={value} index={index} style={{ width: "100%" }}>
            <TableHeader
              selectedBankId={result.BANK_ID}
              selectedBankCode={result.BANK_CODE}
              selectedBankName={result.BANK_NAME}
              selectedBankLogo={result.BANK_LOGO}
              Count={[
                result.ALL,
                result.ALLOCATION_PENDING,
                result.VERIFICATION_PENDING,
                result.VERIFIED,
                result.READY_FOR_REPORT,
              ]}
            />
          </TabPanel>
        ))}
      </Box>
    </div>
  );
};

export default FileList;
