import { createSlice } from "@reduxjs/toolkit";

export const tokenSlice = createSlice({
  name: "authtoken",
  initialState: {
    value: {
      token: localStorage.getItem("token"),
    },
  },
  reducers: {
    token: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { token } = tokenSlice.actions;

export default tokenSlice.reducer;
