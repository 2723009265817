import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import swal from "sweetalert";

import "./formUpload.css";

import { AttachFileImageConfig } from "../../config/AttachFileImageConfig.js";
import uploadImg from "../../utils/assets/attachFileImages/cloud_Upload.svg";
import DialogContent from "@mui/material/DialogContent";

const FormUpload = (props) => {
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFiles = e.target.files;
    // let blob = e.target.files[0];
    // if (
    //   // blob.type === "image/jpg" ||
    //   // blob.type === "image/jpeg" ||
    //   // blob.type === "image/png" ||
    //   // blob.type === "application/pdf"
    //   newFiles.some(file => !(["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(file.type)))
    // ) {
    //   if (newFiles) {
    //     const updatedList = newFiles; //const updatedList = [...fileList, newFile] (for multiple upload)
    //     setFileList(updatedList);
    //     props.onFileChange(updatedList);
    //   }
    // } else {
    //   swal({
    //     title: `Invalid application form`,
    //     icon: "info",
    //   });
    // }
    let invalidFileFound = false;
    for (let file of newFiles) {
      if (!(["image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(file.type))) {
        swal({
          title: `Invalid application form`,
          text: 'Only jpg, jpeg, png and pdf files are supported',
          icon: "info",
        });
        invalidFileFound = true;
        break;
      }
    }

    if (!invalidFileFound) {
      const fileList = []
      for (let file of newFiles) {
        fileList.push(file);
      }
      setFileList(fileList);
      props.onFileChange(newFiles);
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };

  return (
    <DialogContent dividers="paper" sx={{ backgroundColor: "#bdd6ff" }}>
      <div
        ref={wrapperRef}
        className="drop-file-input"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>Drag & Drop your files here</p>
        </div>
        <input
          accept="application/pdf, image/*"
          multiple
          type="file"
          value=""
          onChange={onFileDrop}
        />
      </div>
      {fileList.length > 0 ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">Ready to upload</p>
          {fileList.map((item, index) => (
            <div key={index} className="drop-file-preview__item">
              <img
                src={
                  item.type.split("/")[1] ===
                    "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ? AttachFileImageConfig["xlsx"]
                    : AttachFileImageConfig[item.type.split("/")[1]] ||
                    AttachFileImageConfig["default"]
                }
                alt=""
              />
              <div className="drop-file-preview__item__info">
                <p>{item.name}</p>
                <p>{item.size}B</p>
              </div>
              {/* <span
                className="drop-file-preview__item__del"
                onClick={() => fileRemove(item)}
              >
                x
              </span> */}
            </div>
          ))}
        </div>
      ) : null}
    </DialogContent>
  );
};

FormUpload.propTypes = {
  onFileChange: PropTypes.func,
};

export default FormUpload;
