import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: {
      role: localStorage.role,
      location: localStorage.location,
      locationCode: localStorage.locationCode,
      empId: localStorage.empId,
      name: localStorage.name,
      firstName: localStorage.firstName,
      lastName: localStorage.lastName,
    },
  },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { login } = userSlice.actions;

export default userSlice.reducer;
