import React from "react";

//components
import Filter from "../../filter/DocFilter";
import DocTable from "../../table/document/DocTable";

// material ui
import Box from "@mui/material/Box";

const DocumentList = () => {
  const [fileCountApiResponse, setFileCountApiResponse] = React.useState([]);

  return (
    <div className="Supervisor-dashboard">
      <Filter
        // count api response from filter component is passed to setFileCountApiResponse
        fileCountResponse={(response) => setFileCountApiResponse(response)}
      />

      <Box
        sx={{
          flexGrow: 1,
          borderRadius: "20px",
          border: "1px solid #1553b5",
          display: "flex",
          color: "#000",
          bgcolor: "inherit",
          height: "100%",
        }}
      >
        <DocTable />
      </Box>
    </div>
  );
};

export default DocumentList;
