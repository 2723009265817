import * as React from "react";
import axios from "axios";
import _ from "lodash";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import swal from "sweetalert";

import { ViewFile } from "../file/ViewFile";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";

//mui icons
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CloseIcon from "@mui/icons-material/Close";
import Link from '@mui/material/Link';
import { Autocomplete, Box } from "@mui/material";

//for custom MUI DialogTitle
const DialogTitleStyle = {
  color: "#fff",
  backgroundColor: "#1553b5 ",
};

//for custom MUI TableCell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1553b5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#CCCDCD",
    color: "#000",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
}));
//for custom MUI TableRow
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//for custom MUI Icon Button
const ColorIconButton = styled(IconButton)(() => ({
  backgroundColor: "#1553b5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#1553b5",
  },
}));

// custom mui button
const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#fff"),
  backgroundColor: "#1553b5",
  "&:hover": {
    backgroundColor: "#1553b5",
  },
}));

//custom MUI textField
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
  },
  "& label.MuiInputLabel-formControl": {
    color: "#000",
  },
  "& svg.MuiSvgIcon-root": {
    color: "#000",
  },
  "& .MuiSelect-select": {
    color: "#000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
  },
  "& .MuiOutlinedInput-input": {
    color: "#000",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
    },
  },
});

export const ViewDoc = ({ docId, openCloseBool }) => {
  // swal package doesn't support direct env call, hence stored in a variable;
  const transact_base_url = process.env.REACT_APP_NODESERVER;
  const { token } = useSelector((state) => state.token.value);
  const { empId, firstName, lastName } = useSelector(
    (state) => state.user.value
  );

  const [docDetail, setDocDetail] = React.useState({
    field: [],
    fileRefId: "",
    docId: "",
    minDetail: {},
    doumentType: "",
    verifierName: "",
    verifierId: "",
  });
  const [showMore, setShowMore] = React.useState(4);
  const [docArrLength, setDocArrLength] = React.useState(); // number of data to be displayed on  "Profile And Document Verification" table is set by "docArrLength"
  const [newArrayDoc, setNewArrayDoc] = React.useState([]); // the data to be displayed
  const [status, setStatus] = React.useState(""); // "status" state is update to ASSIGN,REASSIGN based on "viewFileDoc" api and to CLEAR,REVERIFY based on button click
  const [isDisplayed, setIsDisplayed] = React.useState(false);

  const [verifierList, setVerifierList] = React.useState([]); // verifier list api response is stored in this state
  const [input, setInput] = React.useState({
    verifierId: "",
    verifier: "",
    verifierCity: "",
    id: "",
    fileRefId: "",
  });
  const [openView, setOpenView] = React.useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = React.useState(false);


  React.useEffect(() => {
    const viewDoc = async () => {
      // api call to get file detail
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/viewDocument`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: docId,
        },
      })
        .then((response) => {
          if ((response.data.status = "success")) {
            response.data.docDetail?.map((data) => {
              var minData = {
                File: data.fileId,
                Bank: data.bankName,
                Branch: data.branchName,
                Status: data.verificationStatus,
              };
              setDocDetail((values) => ({
                ...values,
                field: data.fields,
                fileId: data.fileId,
                fileRefId: data.fileRefId,
                docId: data._id,
                minDetail: minData,
                documentType: data.docType,
                verifierName: data.verifier,
                verifierId: data.verifierId,
                bankRefId: data.bankRefId
              }));
              data.verifier ? setStatus("REASSIGN") : setStatus("ASSIGN");
            });
          } else {
            swal({
              title: `Error while Fetching Document Data`,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const verifierList = async () => {
      const params = new URLSearchParams();
      params.append("role", "verifier");
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });

        if (response.data.status !== 100)
          return

        setVerifierList(
          response.data.result.sort((a, b) =>
            a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
          ).map(verifer => ({
            id: verifer._id,
            empCode: verifer.empCode,
            firstName: verifer.firstName,
            lastName: verifer.lastName,
            city: verifer.district
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    verifierList();
    viewDoc();
  }, []);

  //for assign verifier and re-assign verifier
  const handleReassign = () => {
    if (input.verifierId.length > 0) {
      let stat = "";
      docDetail.verifierName ? (stat = "REASSIGN") : (stat = "ASSIGN");

      swal({
        title: "Are you sure?",
        text: `Are you sure that you want to ${stat} this document?`,
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((assignVerifier) => {
        if (assignVerifier) {
          axios({
            method: "post",
            url: `${transact_base_url}/api/v1/document/manage/assignVerifier`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: docDetail.docId,
              assignedBy: `${firstName}_${empId}`,
              verifier: input.verifier,
              verifierId: input.verifierId,
              verifierCity: input.verifierCity,
              fileRefId: docDetail.fileRefId,
              status: stat,
            },
          })
            .then(() => {
              swal({
                title: `${stat} Document Successfully`,
                icon: "success",
              });
            })
            .catch((err) => {
              console.log(err);
            });
          openCloseBool(false);
        } else {
          // swal("Operation terminated");
        }
      });
    }
  };

  const handleShowMore = () => {
    setDocArrLength(docDetail.field.length);
    showMore <= 4 ? setShowMore(docArrLength) : setShowMore(4);
  };

  React.useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
    }, 400);
  }, []);

  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      {/* close icon for doc view modal */}
      {isDisplayed && (
        <CloseIcon
          style={{
            color: "#171717",
            position: "fixed",
            top: 20,
            right: "50%",
            fontSize: "25px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "3px",
            zIndex: 1,
          }}
          onClick={() => {
            openCloseBool(false);
          }}
        />
      )}
      {/* Document Detail*/}
      <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
        Document Detail
      </DialogTitle>
      <DialogContent dividers="paper">
        <TableContainer component={Paper} sx={{ border: "1px solid #fff" }}>
          <Table aria-label="simple table">
            <TableBody>
              {_.map(docDetail.minDetail, (val, key) => (
                <StyledTableRow
                  key={key}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell component="th" scope="row">
                    {key}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {key === 'File' ?
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => { setOpenView(true) }}
                      >
                        {val}
                      </Link>
                      : val}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      {/* Property Verification */}
      <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
        {docDetail.documentType}
      </DialogTitle>
      <DialogContent dividers="paper">
        <TableContainer component={Paper} sx={{ border: "1px solid #fff" }}>
          <Table aria-label="simple table">
            <TableBody>
              {docDetail.field.slice(0, showMore).map((data) => (
                <StyledTableRow
                  key={data.DISPLAY_NAME}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {data.DISPLAY_NAME}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {data.TYPE === "select" && data.VALUE === "Other"
                      ? data.OTHER_VALUE
                      : data.VALUE}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ColorIconButton
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: " 8px auto auto",
            padding: "1px",
          }}
          onClick={() => handleShowMore()}
        >
          {showMore <= 4 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </ColorIconButton>
      </DialogContent>

      {/* Select Verifier */}
      <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
        Select Verifier
      </DialogTitle>
      <DialogContent dividers="paper">
        {/* <CssTextField
          select
          type="text"
          id="verifier-assign"
          label="select"
          value={input.verifierId ? input.verifierId : docDetail.verifierId}
          onChange={(e) => {
            verifierList.map(
              (verifiers) =>
                verifiers.id === e.target.value &&
                setInput((oldValues) => ({
                  ...oldValues,
                  id: docDetail.docId,
                  fileRefId: docDetail.fileRefId,
                  verifierId: e.target.value,
                  verifier: `${verifiers.firstName} ${verifiers.lastName}/${verifiers.city}`,
                  verifierCity: verifiers.city,
                }))
            );
          }}
          sx={{ margin: "auto" }}
          fullWidth
        >
          {verifierList.map((verifiers) => (
            <MenuItem value={verifiers.id} key={verifiers.id}>
              {verifiers.firstName} {verifiers.lastName}/{verifiers.city}
            </MenuItem>
          ))}
        </CssTextField> */}
        {verifierList.length > 0 &&
          docDetail.fileRefId &&
          <Autocomplete
            disableClearable
            open={autoCompleteOpen}
            onOpen={() => {
              setAutoCompleteOpen(true);
            }}
            onClose={(event) => {
              if (event.relatedTarget)
                if (event.relatedTarget.tagName !== 'DIV') {
                  setTimeout(() => {
                    event.target.focus();
                  }, 100);
                  setAutoCompleteOpen(true);
                }
                else
                  setAutoCompleteOpen(false);
              else if (event.type === 'click')
                setAutoCompleteOpen(false);
              else
                setAutoCompleteOpen(true);
            }}
            value={
              input.verifierId
                ? verifierList.find(verifier => verifier.id === input.verifierId)
                : verifierList.find(verifier => verifier.id === docDetail.verifierId)
            }
            options={verifierList}
            getOptionLabel={option => `${option.firstName} ${option.lastName}/${option.city}`}
            renderInput={(params) => <TextField {...params} label="Select verifier" />}
            renderOption={(props, option) =>
              <Box component="li" {...props} key={option.id}>
                {option.firstName} {option.lastName}/{option.city}
              </Box>
            }
            onChange={(e, newValue) => {
              verifierList.map(
                (verifiers) =>
                  verifiers.id === newValue.id &&
                  setInput((oldValues) => ({
                    ...oldValues,
                    id: docDetail.docId,
                    fileRefId: docDetail.fileRefId,
                    verifierId: newValue.id,
                    verifier: `${verifiers.firstName} ${verifiers.lastName}/${verifiers.city}`,
                    verifierCity: verifiers.city,
                  }))
              );
            }}
          />}
        <ColorButton
          sx={{
            color: "#fff",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: " 8px auto auto",
            padding: "6px 10px",
          }}
          onClick={handleReassign}
          variant="contained"
          startIcon={<AssignmentIndIcon />}
        >
          {status}
        </ColorButton>
      </DialogContent>

      <Dialog
        open={openView}
        onClose={() => { setOpenView(false); }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md" //You can change md to sm, xs, lg and xl as per your requirement.
      >
        <ViewFile
          bankId={docDetail.bankRefId}
          fileId={docDetail.fileId}
          fileRefId={docDetail.fileRefId}
          openCloseBool={setOpenView}
          tabelRender={() => { }}
        />
      </Dialog>
    </div>
  );
};
