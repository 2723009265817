import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import userReducer from "./features/user";
import Token from "./features/token";
import Selection from "./features/selection";
import Spselection from "./features/spselection";
import Pmselection from "./features/primeMangerselection";

const store = configureStore({
  reducer: {
    user: userReducer,
    token: Token,
    selection: Selection,
    spselection: Spselection,
    pmSelection: Pmselection,
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
