import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { MultiSelect } from "react-multi-select-component";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import FilterAltIcon from "@mui/icons-material/FilterAltOutlined";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { selection } from "../../../../../features/selection";
import { styled } from "@mui/material/styles";
import "../spdashboard.css";

import { token as setToken } from "../../../../../features/token";
import { login } from "../../../../../features/user";
import { setSelectedBankTab } from "../../../../../features/spselection";

const DocFilter = ({ fileCountResponse }) => {
  const dispatch = useDispatch();
  const [bankListData, setBankListData] = useState([]); // bank list api response is stored in this state
  const [documentListData, setDocumentListData] = useState([]); // document list api response is stored in this state
  const [verifierListData, setVerifierListData] = useState([]); // verifier list api response is stored in this state
  const [selectedBank, setSelectedBank] = useState(
    JSON.parse(localStorage.getItem("bankFilter"))
  ); // selected bank is stored in this state
  const [selectedDocument, setSelectedDocument] = useState(
    JSON.parse(localStorage.getItem("docFilter"))
  ); // selected document is stored in this state
  const [selectedVerifier, setSelectedVerifier] = useState(
    JSON.parse(localStorage.getItem("verifierFilter"))
  ); // selected verifier is stored in this state

  const [loading, setLoading] = useState(false);

  const [pendingDocuments, setPendingDocuments] = useState();
  const [fetchingDocuments, setFetchingDocuments] = useState(false);

  const { token } = useSelector((state) => state.token.value);
  const { location } = useSelector((state) => state.user.value);

  // custom mui button
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }));

  // api call to get bank list
  const params = new URLSearchParams();
  params.append("locationId", location);
  const bankList = async () => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUserBankListByLocation`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      }).then(async (response) => {
        if (response.data.status !== 100)
          throw "Failed"
        // handle success
        setBankListData(response.data.result.sort((a, b) => a.bankName.localeCompare(b.bankName)));
      });
    } catch (error) {
      swal({
        title: "Failed to get bank list",
        icon: "error",
      });
    }
  };

  // api call to get document list
  // const documentList = async () => {
  //   try {
  //     await axios({
  //       method: "get",
  //       url: `${process.env.REACT_APP_ADMINNODE}/api/v1/common/getDocumentTypeList`,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     }).then(async (response) => {
  //       console.log('DocFilter -> getDocumentTypeList', response.data);
  //       // handle success
  //       // setDocumentListData(response.data);
  //     });
  //   } catch (error) {
  //     swal({
  //       title: "Failed to get document list",
  //       icon: "error",
  //     });
  //   }
  // };

  // api call to get verifier list
  // const verifierList = async () => {
  //   const params = new URLSearchParams();
  //   params.append("role", "verifier");
  //   try {
  //     const response = await axios({
  //       method: "post",
  //       url: `${process.env.REACT_APP_ADMINNODE}/api/v1/common/getUsersByRole`,
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //       data: params,
  //     });
  //     // setVerifierListData(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fileCountApiCall = async (bankId) => {
    await axios({
      method: "post",
      url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/getFileCount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        bankRefIdlist: bankId,
      },
    })
      .then((response) => {
        fileCountResponse(response.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.message === 'Request failed with status code 403') {
          swal({
            title: "Session expired!",
            text: "Please login again to continue",
            icon: "error",
          }).then(() => {
            dispatch(setToken({ token: "" })); // clear token value in redux
            dispatch(setSelectedBankTab(0));
            dispatch(login({ role: "null", location: "null", locationCode: "null" })); // clear role and location value in redux
            dispatch(
              selection({
                bank: [],
                bankFilter: [],
                doc: [],
                verifier: [],
                filestat: [],
              })
            );
            localStorage.clear();
          })
          return;
        }
        setLoading(false);
        console.log(err.message);
        // swal({
        //   title: "Failed to get file count",
        //   text: "Reselect the bank and try again",
        //   icon: "error",
        // });
      });
  };

  const handleFilterClick = async () => {
    localStorage.setItem("bankFilter", JSON.stringify(selectedBank));
    localStorage.setItem("docFilter", JSON.stringify(selectedDocument));
    localStorage.setItem("verifierFilter", JSON.stringify(selectedVerifier));
    let returnId = [];
    selectedBank.map((id) => {
      bankListData.map((e) => {
        if (id.value === e._id)
          returnId = [
            ...returnId,
            {
              bankname: e.bankName,
              bankRefId: e._id,
              logo: e.logo,
              bankCode: e.bankCode.toString(),
            },
          ];
      });
    });
    const returnDoc = selectedDocument.map((id) => id.value);
    const returnVerifier = selectedVerifier.map((id) => id.value);
    localStorage.setItem("bank", JSON.stringify(returnId));
    localStorage.setItem("doc", JSON.stringify(returnDoc));
    localStorage.setItem("verifier", JSON.stringify(returnVerifier));
    dispatch(
      selection({
        bank: returnId,
        doc: returnDoc,
        bankFilter: selectedBank,
        verifier: returnVerifier,
        filestat: [],
      })
    );
    // to check whether the selected number of bank >= 1
    if (returnId.length <= 0) {
      swal({
        title: "Please select atleast one bank",
        icon: "info",
      });
      return;
    }
    // api call to get file count, if atleast one bank is selected
    setLoading(true);
    fileCountApiCall(returnId);
  };

  const handleResetClick = () => {
    setSelectedDocument([]);
    setSelectedVerifier([]);
    setSelectedBank([]);
  }

  const banklistDropdown = bankListData.map((data) => ({
    label: data.bankName,
    value: data._id,
  }));

  const documentListDropdown = documentListData.map((docType) => ({
    label: docType,
    value: docType,
  }));

  const verifierListDropdown = verifierListData.map((varName) => ({
    label: varName,
    value: varName,
  }));

  verifierListDropdown.push({
    label: 'Non Allocated',
    value: ''
  });

  useEffect(() => {
    const getDocumentList = async () => {
      setFetchingDocuments(true);
      const bankRefIds = selectedBank.map(bank => bank.value);
      try {
        const response = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/getPendingDocuments`,
          headers: {
            Authorization: `Bearer ${token}`
          },
          data: {
            bankRefId: bankRefIds
          }
        })

        setPendingDocuments(response.data.docDetail);

        const docListData = response.data.docDetail.map(doc => doc.docType);
        const uniqueDocListData = [...new Set(docListData)];
        setDocumentListData(uniqueDocListData.sort((a, b) => a.localeCompare(b)));
      } catch (error) {
        console.log(error);
      } finally {
        setFetchingDocuments(false);
      }
    }
    getDocumentList();
  }, [selectedBank])

  useEffect(() => {
    const selectedDocTypes = selectedDocument.map(doc => doc.label);
    if (pendingDocuments) {
      const verListData = pendingDocuments
        .filter(doc => selectedDocTypes.includes(doc.docType))
        .map(doc => doc.verifier)
        .filter(Boolean);
      const uniqueVerListData = [...new Set(verListData)];
      setVerifierListData(uniqueVerListData.sort((a, b) => a.localeCompare(b)));
    }
  }, [selectedDocument, pendingDocuments]);

  useEffect(() => {
    bankList();
    // documentList();
    // verifierList();
    let bankIds = JSON.parse(localStorage.getItem("bank"));
    fileCountApiCall(bankIds);
  }, []);
  useEffect(() => {
    const timer = setInterval(
      () => fileCountApiCall(JSON.parse(localStorage.getItem("bank"))),
      10000
    );
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="filter">
      <div className="filter-row">
        <div className="first-filter">
          <MultiSelect
            options={banklistDropdown}
            value={selectedBank}
            onChange={setSelectedBank}
            labelledBy="Bank"
          />
        </div>

        <div className="second-filter second-filter-document">
          <MultiSelect
            isLoading={fetchingDocuments}
            options={documentListDropdown}
            value={selectedDocument}
            onChange={setSelectedDocument}
            labelledBy="Document"
          />
        </div>
        <div className="third-filter third-filter-verifier">
          <MultiSelect
            options={verifierListDropdown}
            value={selectedVerifier}
            onChange={setSelectedVerifier}
            labelledBy="Verifier"
          />
        </div>
        <div className="filter-button" style={{ paddingLeft: "10px" }}>
          <ColorButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
            }}
            onClick={handleFilterClick}
            loading={loading}
            loadingPosition="start"
            startIcon={<FilterAltIcon />}
            variant="contained"
          >
            Filter
          </ColorButton>
        </div>
        <div className="filter-button" style={{ paddingLeft: "10px" }}>
          <ColorButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
            }}
            onClick={handleResetClick}
            loadingPosition="start"
            startIcon={<RestartAltIcon />}
            variant="contained"
          >
            Reset
          </ColorButton>
        </div>
      </div>
    </div>
  );
};
export default DocFilter;
