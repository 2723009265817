import React from "react";


import Piechartpage from "./components/Piechart";
import Barchartpage from "./components/Barchart";
import BarchartFileupdatesPage from "./components/BarchartFileupdates";


function Dashboard() {

    return (
        <>
            {/* Chart 1 PieChartWithPaddingAngle Start */}
            <Piechartpage />
            {/* Chart 1 PieChartWithPaddingAngle End */}


            {/* Current Pending Status BarChart  Section Start  */}
            <Barchartpage />
            {/* Current Pending Status BarChart  Section End  */}


            {/* File Updates Verified and Readyfor report BarChart  Section Start  */}
            <BarchartFileupdatesPage />
            {/* File Updates Verified and Readyfor report BarChart  Section End  */}

        </>
    )
}

export default Dashboard