import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import swal from "sweetalert";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import JsFileDownloader from "js-file-downloader";

// material ui
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import InputBase from "@mui/material/InputBase";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { Autocomplete } from "@mui/material";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import TextField from "@mui/material/TextField";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

//custom MUI select
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "15px",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 150,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#000",
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "#000",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
  {
    borderColor: "#000",
  },
  [`& .${outlinedInputClasses.input}`]: {
    color: "#000",
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: "#000",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
  {
    color: "#000",
  },
  [`& .${inputLabelClasses.outlined}`]: {
    color: "#000",
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: "#000",
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: "#000",
  },
});

const FileList = () => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.token.value);
  const { empId } = useSelector((state) => state.user.value);

  const [value, setValue] = useState(0);
  const [apiCallBool, setApiCallBool] = useState(false);
  const [loading, setLoading] = useState(false);
  const [samplerListData, setSamplerListData] = useState([]); // sampler list api response is stored in this state
  const [verifierListData, setVerifierListData] = useState([]); // verifier list api response is stored in this state
  const [supervisorListData, setSupervisorListData] = useState([]); // supervisor list api response is stored in this state
  const [bankListData, setBankListData] = useState([]); // bank list api response is stored in this state
  const [branchList, setBranchList] = useState([]); // branch list api response is stored in this state
  const [productList, setProductList] = useState([]); //product list api reponse is stored in this state
  const [selectedBankMulti, setSelectedBankMulti] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedProdMulti, setSelectedProdMulti] = useState([]);
  const [selectedVerifiers, setSelectedVerifiers] = useState([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState([]);
  const [selectedSamplers, setSelectedSamplers] = useState([]);
  const [selectProdSingle, setSelectProdSingle] = useState({
    productRefId: "",
    productName: "",
    fields: "",
    productType: "",
  });
  const [documentListData, setDocumentListData] = useState([]); // document list api response is stored in this state
  const [settingsListData, setSettingsListData] = useState([]); // settings list api response is stored in this state
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [input, setInput] = useState({
    bankId: "",
    bankName: "",
    bankRefId: "",
    reportStatus: [],
    branchName: [],
    productName: [],
    verifier: [],
    verifierDetails: [],
    supervisorDetails: [],
    sampler: [],
    supervisor: [],
    fromdate: "",
    todate: "",
    startDate: "",
    docId: "",
    documentType: "",
    docFields: [],
    sortby: "",
    user: "",
    userRole: "",
  });
  const [performanceReportData, setPerformanceReportData] = useState({});
  const [stateListData, setStateListData] = useState([]);
  const [selectedState, setSelectedState] = useState([])
  const [fetchingBranchList, setFetchingBranchList] = useState(false);
  const [vendorMISType, setVendorMISType] = useState('');
  const [samplerAttendanceType, setSamplerAttendanceType] = useState("")

  //api call for generate report . Here useLayoutEffect and useRef is used to prevent api call on initial render
  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    switch (value) {
      case 0: //MIS-1
        const mis1 = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getMIS1Report`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankId,
                bankName: input.bankName,
                branchName: input.branchName,
                productName: input.productName,
                from: input.fromdate,
                to: input.todate,
                reportStatus: input.reportStatus,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        mis1();
        break;
      case 1: //MIS-2
        if (selectProdSingle.productType === 'general') {
          var productNames = [];

          productList.forEach(product => {
            if (product.type === 'general') {
              productNames.push({
                product_name: product.productName,
                productRefId: product.id
              })
            }
          })
        }

        const mis2 = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getMIS2Report`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankId,
                bankName: input.bankName,
                branchName: input.branchName,
                // productRefId: selectProdSingle.productType === 'general' ? null : selectProdSingle.productObjId,
                productName: selectProdSingle.productType === 'general' ? productNames : selectProdSingle.productName,
                ...(selectProdSingle.productType !== 'general' && { productRefId: selectProdSingle.productObjId }),
                fields: selectProdSingle.fields,
                Type: selectProdSingle.productType,
                from: input.fromdate,
                to: input.todate,
                reportStatus: input.reportStatus,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        mis2();
        break;
      case 2: //Other
        const other = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getMIS1Report`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankId,
                bankName: input.bankName,
                productRefId: selectProdSingle.productRefId,
                productName: selectProdSingle.productName,
                fields: selectProdSingle.fields,
                from: input.fromdate,
                to: input.todate,
                reportStatus: input.reportStatus,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        other();
        break;
      case 3: //Pending
        const pending = async () => {
          setLoading(true);
          try {
            let bankId = [];
            selectedBankMulti.map((id) => {
              let result = [...bankId, id.value];
              bankId = result.map(String);
            });
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getVerifierPendingReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: bankId,
                verifier: input.verifier,
                sortBy: input.sortby,
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        pending();
        break;
      case 4: //Login
        const login_details = async () => {
          setLoading(true);
          try {
            let bankId = [];
            selectedBankMulti.map((id) => {
              bankId = [...bankId, id.value];
            });
            let reqBranchNames = [];
            let reqProductNames = [];
            input.branchName.map((data) => {
              reqBranchNames = [...reqBranchNames, data.branch_name];
            });
            input.productName.map((data) => {
              reqProductNames = [...reqProductNames, data.product_name];
            });
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getLoginMIS`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankRefId,
                bankName: input.bankName,
                branch: reqBranchNames,
                product: reqProductNames,
                sampler: input.sampler,
                from: input.fromdate,
                to: input.todate,
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        login_details();
        break;
      case 5: //Travelling claim
        const travelling_claim = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/TravelClaimReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                from: input.fromdate,
                to: input.todate,
                verifier_name: input.verifier,
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        travelling_claim();
        break;
      case 6: //Verifier Report
        const verifier_report = async () => {
          setLoading(true);
          try {
            let tavelValue = "";
            let pointValue = "";
            settingsListData.map((data) => {
              if (data.key === "point_value") pointValue = data.value;
              if (data.key === "travel_value") tavelValue = data.value;
            });
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getVerifierPerfomance`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                from: input.fromdate,
                to: input.todate,
                verifier_name: input.verifierDetails,
                Emp_id: empId,
                travel_value: tavelValue,
                point_value: pointValue,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        verifier_report();
        break;
      case 7: //Supervisor Report
        const supervisor_report = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/getSupervisorReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                from: input.fromdate,
                to: input.todate,
                supervisor: input.supervisorDetails,
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        supervisor_report();
        break;
      case 8: //Sampler Report
        const sampler_report = async () => {
          setLoading(true);
          // api call to get branch data
          const params = new URLSearchParams();
          params.append("bankId", input.bankRefId);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBranchListByBankMinimum`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: params,
            });

            if (response.data.status !== 100 && response.data.status !== 702)
              throw "Could not get bank list"

            let branchListArray = [];
            response.data.result.map((branch) => {
              branchListArray = [...branchListArray, branch.branchName];
            });
            try {
              const response = await axios({
                method: "post",
                url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/samplerReport`,
                headers: {
                  Authorization: `Bearer ${token}`,
                },
                data: {
                  bankId: input.bankId.toString(),
                  bankObjId: input.bankRefId,
                  bankName: input.bankName,
                  branch: branchListArray,
                  from: input.fromdate,
                  to: input.todate,
                  sampler: input.sampler,
                  Emp_id: empId,
                },
              });
              if (response.data.status === 1) {
                new JsFileDownloader({
                  url: response.data.data,
                })
                  .then(function () {
                    swal({
                      title: "Download Complete",
                      icon: "success",
                    });
                  })
                  .catch(function (error) {
                    swal({
                      title: "Report Can Be Download From Reportdownload page",
                      icon: "info",
                    });
                    console.log(error);
                  });
              } else if (response.data.status !== 702) {
                swal({
                  title: `${response.data.data}`,
                  icon: "info",
                });
              }
            } catch (err) {
              console.log(err);
              swal({
                title: `Server Error`,
                icon: "error",
              });
            }
          } catch (error) {
            swal({
              title: "Failed to get branch list",
              icon: "error",
            });
          }
          setLoading(false);
        };
        sampler_report();
        break;
      case 9: //Fraud MIS
        const fraud_mis = async () => {
          setLoading(true);
          let verifierData = [];
          input.verifierDetails.map((data) => {
            console.log(data);
            verifierData = [
              ...verifierData,
              {
                verifier_Id: data.emp_id,
                verifier_name: data.verifier_name,
                id: data.id,
              },
            ];
          });
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/FraudMIS`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                from: input.fromdate,
                to: input.todate,
                verifier: verifierData,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        fraud_mis();
        break;
      case 10: //Document MIS
        const doc_mis = async () => {
          setLoading(true);
          try {
            let bankData = [];
            selectedBankMulti.map((data) => {
              bankListData.map((id) => {
                if (id.bankCode === data.value) {
                  bankData = [
                    ...bankData,
                    { bankId: id.bankCode, bankName: id.bankName },
                  ];
                }
              });
            });
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/DocMisReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                documentType: input.documentType,
                fields: input.docFields,
                banks: bankData,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        doc_mis();
        break;
      case 11: //Vendor MIS
        const vendor_mis = async () => {
          setLoading(true);
          try {
            let bankData = [];
            selectedBankMulti.map((data) => {
              bankListData.map((id) => {
                if (id.bankCode === data.value) {
                  bankData = [
                    ...bankData,
                    { bankId: id.bankCode, bankName: id.bankName },
                  ];
                }
              });
            });

            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/VendorMIS`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                Emp_id: empId,
                Type: vendorMISType,
                banks: bankData,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        vendor_mis();
        break;
      case 12: //User MIS
        const user_mis = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/UserMIS`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                userType: input.user,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        user_mis();
        break;
      case 13: //Attendance Report
        const attendance_report = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/AttendanceReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                EmpId: empId,
                from: input.fromdate,
                to: input.todate,
                sampler: selectedSamplers.map(item => item.value),
                supervisor: selectedSupervisor.map(item => item.value),
                verifier: selectedVerifiers.map(item => item.value)
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        attendance_report();
        break;
      case 14: //Performance Report
        const performance_mis = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/PerfomanceMIS`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                userType: input.userRole,
                Emp_id: empId,
                userDetailObject: performanceReportData,
                from: input.fromdate,
                to: input.todate,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        performance_mis();
        break;
      case 15: //Billing MIS
        const billingMIS = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/BillingReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                bankId: input.bankId,
                productName: selectProdSingle.productName,
                productType: selectProdSingle.productType,
                from: input.fromdate,
                to: input.todate,
                emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        };
        billingMIS();
        break;
      case 16:
        const employee = input.sampler.map(item => ({ name: item.sampler_name, id: item.id }))
        const samplerAttendance = async () => {
          setLoading(true)
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/EmployeeAttendanceDetails`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                from: input.fromdate,
                to: input.todate,
                employee,
                userType: "sampler",
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        }

        const samplerAttendance2 = async () => {
          setLoading(true)
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_REPORTSERVER}/api/v1/report/view/SamplerAttendanceDetails`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                from: input.fromdate,
                to: input.todate,
                employee,
                userType: "sampler",
                Emp_id: empId,
              },
            });
            if (response.data.status === 1) {
              new JsFileDownloader({
                url: response.data.data,
              })
                .then(function () {
                  swal({
                    title: "Download Complete",
                    icon: "success",
                  });
                })
                .catch(function (error) {
                  swal({
                    title: "Report Can Be Download From Reportdownload page",
                    icon: "info",
                  });
                  console.log(error);
                });
            } else if (response.data.status !== 702) {
              swal({
                title: `${response.data.data}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        }
        if (samplerAttendanceType === 1)
          samplerAttendance()
        else if (samplerAttendanceType === 2)
          samplerAttendance2()
        else {
          swal({
            title: "Please select a type!",
            icon: "info",
          })
        }
        break
      case 17:
        const verifierIDs = input.verifierDetails.map(item => item.id);
        const samplerIDs = input.sampler.map(item => item.id);
        const autokmMIS = async () => {
          setLoading(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/autoKmMIS`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                // veriiers: verifierIDs,
                // samplers: samplerIDs,
                empId,
                userId: [...samplerIDs, ...verifierIDs],
                from: input.fromdate,
                to: input.todate
              }
            })
            if (response.data.status === "File uploaded and Images Generated") {
              new JsFileDownloader({
                url: response.data.result
              })
              .then(function () {
                swal({
                  title: "Download Complete",
                  icon: "success",
                });
              })
              .catch(function (error) {
                swal({
                  title: "Report Can Be Download From Reportdownload page",
                  icon: "info",
                });
                console.log(error);
              });
            } else {
              swal({
                title: `${response.data.status}`,
                icon: "info",
              });
            }
          } catch (err) {
            console.log(err);
            swal({
              title: `Server Error`,
              icon: "error",
            });
          }
          setLoading(false);
        }
        autokmMIS();
      default:
        break;
    }
  }, [apiCallBool]);

  // mui customized button
  const ColorButton = styled(LoadingButton)(({ theme }) => ({
    border: "1px solid #1553b5",
    backgroundColor: "#1553b5",
    color: "#fff !important",
    padding: "4px 8px",
    margin: "auto 2px",
    "&:hover": {
      border: "1px solid #f4f4f4",
      backgroundColor: "#1553b5",
      color: "#fff !important",
    },
  }));

  // fucntion to get VerifierList SupervisorList SamplerList settingsList documentList
  // api call to get verifier list
  const getVerifierList = async () => {
    const params = new URLSearchParams();
    params.append("role", "verifier");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      });
      if (response.data.status !== 100)
        return

      setVerifierListData(
        response.data.result
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map(user => ({ ...user, id: user._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  // api call to get supervisor list
  const getSupervisorList = async () => {
    const params = new URLSearchParams();
    params.append("role", "supervisor");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      });
      if (response.data.status !== 100)
        return

      setSupervisorListData(
        response.data.result
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map(user => ({ ...user, id: user._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  // api call to get sampler list
  const getSamplerList = async () => {
    const params = new URLSearchParams();
    params.append("role", "sampler");
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      });
      if (response.data.status !== 100)
        return

      setSamplerListData(
        response.data.result
          .sort((a, b) => a.firstName.localeCompare(b.firstName))
          .map(user => ({ ...user, id: user._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  // api call to get settings list
  const settingsList = async () => {
    try {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getSettingsList`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (response) => {
        if (response.data.status !== 100  && response.data.status !== 702)
          throw "Error"
        // handle success
        setSettingsListData(response.data.result);
      });
    } catch (error) {
      swal({
        title: "Failed to get document list",
        icon: "error",
      });
    }
  };
  // api call to get document list
  const documentList = async () => {
    try {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getDocumentTypeList`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (response) => {
        if (response.data.status !== 100  && response.data.status !== 702)
          throw "Error"
        // handle success
        setDocumentListData(
          response.data.result[0].documentTypeId.map(document => ({ ...document, id: document._id }))
        );
      });
    } catch (error) {
      swal({
        title: "Failed to get document list",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    // api call to get bank list
    const bankList = async () => {
      try {
        const response = await axios({
          // method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBankListById`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.status !== 100  && response.data.status !== 702)
          throw "Error"

        setBankListData(
          response.data.bankDetails
            .sort((a, b) => a.bankName.localeCompare(b.bankName))
            .map(bank => ({ ...bank, id: bank._id }))
            .filter(item => item.enabled)
        );
        setStateListData([
          ...new Set(response.data.bankDetails
            .map(item => {
              if (item.locationId)
                return item.locationId.locationName
              return 'Other'
            }))
        ]);
      } catch (error) {
        swal({
          title: "Failed to get bank list",
          icon: "error",
        });
      }
    };
    bankList();
  }, []);

  const [filteredBankList, setFilteredBankList] = useState([]);

  useEffect(() => {
    const states = selectedState.map(item => item.value);
    setFilteredBankList(bankListData.filter(item => {
      if (!item.locationId)
        return states.includes('Other');
      return states.includes(item.locationId.locationName);
    }
    ));
  }, [selectedState]);

  useEffect(() => {
    if (value === 3 || value === 5 || value === 6 || value === 9) {
      if (value === 6) {
        settingsList();
      }
      getVerifierList();
    } else if (value === 4 || value === 8) {
      getSamplerList();
    } else if (value === 7) {
      getSupervisorList();
    } else if (value === 10) {
      documentList();
    } else if (value === 14 || value === 13 || value === 16 || value === 17) {
      getVerifierList();
      getSupervisorList();
      getSamplerList();
    }
  }, [value]);

  const stateListDropdown = stateListData.map((locationName) => ({
    label: locationName,
    value: locationName
  }));

  const banklistDropdown = filteredBankList.map((data) => ({
    label: data.bankName,
    value: data.bankCode,
  }));

  const branchlistDropdown = branchList.map((data) => ({
    label: data.branchName,
    value: data.branchCode,
  }));

  const productListDropdown = productList.map((data) => ({
    label: data.productName,
    value: data.prodRefId,
  }));

  const generalProductList = productList.map(
    (data) =>
      data.type === "general" && {
        label: data.productName,
        value: data.prodRefId,
      }
  );

  const verifierListDropdown = verifierListData.map((data) => ({
    label: `${data.firstName} ${data.lastName}/${data.district}`,
    value: data.empCode,
  }));

  const samplerListDropdown = samplerListData.map((data) => ({
    label: `${data.firstName} ${data.lastName}/${data.district}`,
    value: data.empCode,
  }));

  const supervisorListDropdown = supervisorListData.map((data) => ({
    label: `${data.firstName} ${data.lastName}/${data.district}`,
    value: data.empCode,
  }));
  let productListDropdownGeneral = [];
  useEffect(() => {
    generalProductList.length > 0 &&
      generalProductList.forEach(function (el) {
        typeof el === "object" && productListDropdownGeneral.push(el);
      });
  }, [generalProductList]);

  //for switching report tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setBranchList([]);
    setSelectedBranch([]);
    setSelectProdSingle({
      productRefId: "",
      productName: "",
      fields: "",
      productType: "",
    });
    setSelectedProdMulti([]);
    setInput({
      bankId: "",
      bankName: "",
      reportStatus: [],
      branchName: [],
      productName: [],
      verifier: [],
      sampler: [],
      supervisor: [],
      fromdate: "",
      todate: "",
      sortby: "",
    });
    setLoading(false);
  };

  const handleChangeBank = (event) => {
    bankListData.map((bank) => {
      if (bank.bankCode === event.target.value) {
        let bankId = bank.id;
        setInput((values) => ({
          ...values,
          bankRefId: bank.id,
          bankId: bank.bankCode,
          bankName: bank.bankName,
          reportStatus: bank.reportStatus,
        }));
        const params = new URLSearchParams();
        params.append("bankId", bankId);
        //api call to get branch list

        const getBranchList = async () => {
          setFetchingBranchList(true);
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBranchListByBank`,
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: params,
            });
            if (response.data.status !== 100)
              throw "Error"
            setBranchList(
              response.data.result.map(branch => ({ ...branch, id: branch._id }))
            );
          } catch (err) {
            setBranchList([]);
            console.log(err);
          }
          setFetchingBranchList(false);
        };

        const getProductList = async () => {
          try {
            const response = await axios({
              method: "post",
              url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getProductListByBank`,
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
              data: params,
            });
            if (response.data.status !== 100)
              return
            setProductList(
              response.data.result.map(product => ({ ...product, id: product._id }))
            );
          } catch (error) {
            console.log(error);
          }
        };
        getBranchList();
        getProductList();
      }
    });
  };

  const handleChangeSingleProd = (event) => {
    productList.map((prod) => {
      if (prod.prodRefId === event.target.value) {
        setSelectProdSingle((values) => ({
          ...values,
          productRefId: prod.prodRefId,
          productObjId: prod.id,
          productName: prod.productName,
          fields: prod.fields,
          productType: prod.type === "other" ? "special" : "general",
        }));
      }
    });
  };

  const handleChangeDoc = (event) => {
    documentListData.map((doc) => {
      if (doc.documentTypeCode === event.target.value) {
        setInput((values) => ({
          ...values,
          docId: doc.documentTypeCode,
          documentType: doc.documentTypeName,
          docFields: doc.fields,
        }));
      }
    });
  };

  const handleChangeSortBy = (event) => {
    setInput((values) => ({
      ...values,
      sortby: event.target.value,
    }));
  };

  const handleChangeUserType = (event) => {
    setInput((values) => ({
      ...values,
      userRole: event.target.value,
    }));
  };

  const handleChangeUser = (event) => {
    setInput((values) => ({
      ...values,
      user: event.target.value,
    }));
  };

  //Reusable dropdowns
  const SelectBank = () => {
    return (
      <FormControl sx={{ m: 1 }} variant="standard">
        <FormHelperText
          sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
        >
          Bank
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label-bank"
          value={input.bankId}
          id="demo-simple-select-bank"
          MenuProps={MenuProps}
          onChange={handleChangeBank}
          input={<BootstrapInput />}
        >
          {filteredBankList?.map((bank) => (
            <MenuItem key={bank.id} value={bank.bankCode}>
              {bank.bankName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const SelectProduct = () => {
    return (
      <FormControl sx={{ m: 1 }} variant="standard">
        <FormHelperText
          sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
        >
          Product
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label-prod"
          value={selectProdSingle.productRefId}
          id="demo-simple-select-prod"
          MenuProps={MenuProps}
          onChange={handleChangeSingleProd}
          input={<BootstrapInput />}
          disabled={input.bankId === ""}
        >
          {value === 2 ? productList?.map(
            (prod) =>
              prod.type === "other" && (
                <MenuItem key={prod.id} value={prod.prodRefId}>
                  {prod.productName}
                </MenuItem>
              )
          ) : value === 15 || value === 1 ? productList.length && productList
            .filter((value, index, self) => {  // filtering out repeating general products
              if (value.type === 'other')
                return true
              return index === self.findIndex((t) => t.type === value.type)
            })
            .map((prod) => {
              if (prod.type === 'other') {
                return (
                  <MenuItem key={prod.id} value={prod.prodRefId}>
                    {prod.productName}
                  </MenuItem>
                )
              }
              else if (prod.type === 'general') {
                return (
                  <MenuItem key={prod.id} value={prod.prodRefId}>
                    General
                  </MenuItem>
                )
              }
            }) : productList?.map((prod) => (
              <MenuItem key={prod.id} value={prod.prodRefId}>
                {prod.productName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const SelectSortBy = () => {
    return (
      <FormControl sx={{ m: 1, width: 200 }}>
        <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
          Sort By
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={input.sortby}
          MenuProps={MenuProps}
          onChange={handleChangeSortBy}
          input={<BootstrapInput />}
        >
          <MenuItem value="Verifier">Verifier</MenuItem>
          <MenuItem value="Date">Date</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const SelectByRole = () => {
    return (
      <FormControl sx={{ m: 1, width: 200 }}>
        <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
          User Type
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={input.userRole}
          MenuProps={MenuProps}
          onChange={handleChangeUserType}
          input={<BootstrapInput />}
        >
          <MenuItem value="verifier">Verifier</MenuItem>
          <MenuItem value="sampler">Sampler</MenuItem>
          <MenuItem value="supervisor">Supervisor</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const maxDate = new Date(fromDate)
  maxDate.setDate(maxDate.getDate() + 31)

  const DateSelect = () => {
    return (
      <FormControl sx={{ m: 1, width: 300 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            margin="15px 10px 20px"
          >
            <DatePicker
              label="From Date"
              inputFormat="DD/MM/YY"
              value={fromDate}
              onChange={handleFromDateChange}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                    '& label': {
                      zIndex: '-1'
                    }
                  }}
                  {...params}
                />
              )}
            />
            <DatePicker
              maxDate={maxDate}
              label="To Date"
              inputFormat="DD/MM/YY"
              value={toDate}
              onChange={handleToDateChange}
              renderInput={(params) => (
                <StyledTextField
                  sx={{
                    width: "50%",
                    '& label': {
                      zIndex: '-1'
                    }
                  }}
                  {...params}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
        <FormHelperText sx={{ my: -1.5 }}>Report available for maximum 31 days</FormHelperText>
      </FormControl>
    )
  }

  // const DateSelect = () => {
  //   return (
  //     <FormControl sx={{ m: 1, width: 300 }}>
  //       <LocalizationProvider dateAdapter={AdapterDateFns}>
  //         <Stack
  //           spacing={2}
  //           direction="row"
  //           justifyContent="center"
  //           margin="15px 10px 20px"
  //         >
  //           <DesktopDatePicker
  //             // shouldDisableDate={disableRandomDates}
  //             label="From Date"
  //             inputFormat="dd/MM/yy"
  //             value={fromDate}
  //             onChange={handleFromDateChange}
  //             renderInput={(params) => (
  //               <StyledTextField
  //                 sx={{
  //                   width: "50%",
  //                   '& label': {
  //                     zIndex: '-1'
  //                   }
  //                 }}
  //                 {...params}
  //               />
  //             )}
  //           />
  //           <DesktopDatePicker
  //             label="To Date"
  //             inputFormat="dd/MM/yy"
  //             value={toDate}
  //             onChange={handleToDateChange}
  //             renderInput={(params) => (
  //               <StyledTextField
  //                 sx={{
  //                   width: "50%",
  //                   '& label': {
  //                     zIndex: '-1'
  //                   }
  //                 }}
  //                 {...params}
  //               />
  //             )}
  //           />
  //         </Stack>
  //       </LocalizationProvider>
  //     </FormControl>
  //   );
  // };

  // single select varifier
  const [selectedValue, setSelectedValue] = useState(null);

  const filterOptions = (options, state) => {
    return options.filter((option) =>
      option.label.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  };

  const SelectSampler = () => {
    return (
      <div style={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}>
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            setSelectedValue(newValue);
          }}
          options={samplerListDropdown}
          getOptionLabel={(option) => option.label}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField {...params} label="Select a Sampler" size="small" variant="outlined"
              sx={{
                width: '250px',
                borderRadius: '10px',
                backgroundColor: 'white',
                border: 'none',
                outline: 'none',
              }}
            />
          )}
        />
      </div>
    );
  };

  const SelectDocument = () => {
    return (
      <FormControl sx={{ m: 1 }} variant="standard">
        <FormHelperText
          sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
        >
          Document
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label-bank"
          value={input.docId}
          id="demo-simple-select-bank"
          MenuProps={MenuProps}
          onChange={handleChangeDoc}
          input={<BootstrapInput />}
        >
          {documentListData?.map((doc) => (
            <MenuItem key={doc.id} value={doc.documentTypeCode}>
              {doc.documentTypeName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const UserList = () => {
    return (
      <FormControl sx={{ m: 1 }} variant="standard">
        <FormHelperText
          sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
        >
          User Type
        </FormHelperText>
        <Select
          labelId="demo-simple-select-label-user"
          id="demo-simple-select-user"
          value={input.user}
          MenuProps={MenuProps}
          onChange={handleChangeUser}
          input={<BootstrapInput />}
        >
          <MenuItem value={"admin"}>Admin</MenuItem>
          <MenuItem value={"supervisor"}>Supervisor</MenuItem>
          <MenuItem value={"sampler"}>Sampler</MenuItem>
          <MenuItem value={"verifier"}>Verifier</MenuItem>
        </Select>
      </FormControl>
    );
  };

  // const MonthSelect = () => {
  //   return (
  //     <FormControl sx={{ m: 1, width: 300 }}>
  //       <LocalizationProvider dateAdapter={AdapterDateFns}>
  //         <DesktopDatePicker
  //           label="From Date"
  //           views={["month", "year"]}
  //           inputFormat="MM/yy"
  //           value={startDate}
  //           onChange={handleStartDateChange}
  //           renderInput={(params) => (
  //             <StyledTextField
  //               sx={{
  //                 width: "50%",
  //               }}
  //               {...params}
  //             />
  //           )}
  //         />
  //       </LocalizationProvider>
  //     </FormControl>
  //   );
  // };

  //generate report button
  const GenerateReportButton = () => {
    return (
      <div style={{ paddingLeft: "10px", paddingTop: "15px" }}>
        <ColorButton
          sx={{
            color: "#fff",
            borderRadius: "10px",
          }}
          onClick={handleGenerateReport}
          loading={loading}
          loadingPosition="start"
          variant="contained"
        >
          Generate Report
        </ColorButton>
      </div>
    );
  };

  // function to update from date
  const handleFromDateChange = (newValue) => {
    setFromDate(newValue.$d);
  };
  // function to update to date
  const handleToDateChange = (newValue) => {
    setToDate(newValue.$d);
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue);
  };

  //for report generation api call
  const handleGenerateReport = async () => {
    //setting branch input
    let inputBranch = [];
    selectedBranch.map((e) => {
      let branchData = branchList.find((list) => {
        return list.branchCode === e.value;
      });
      inputBranch = [
        ...inputBranch,
        {
          branch_name: branchData.branchName,
          branchId: branchData.branchCode,
          branchObjId: branchData.id
        },
      ];
    });

    //setting product input
    let inputProd = [];
    (value === 0 || value === 4) && //for MIS-1 and login details only
      selectedProdMulti.map((e) => {
        let prodData = productList.find((list) => {
          return list.prodRefId === e.value;
        });
        inputProd = [
          ...inputProd,
          {
            prodRefId: prodData.prodRefId,
            product_name: prodData.productName,
            fields: prodData.fields,
          },
        ];
      });

    //setting verifier input
    let inputVerifier = [];
    let inputVerifierDetails = [];
    selectedVerifiers.map((e) => {
      let verifierData = verifierListData.find((list) => {
        return list.empCode === e.value;
      });
      inputVerifier = [
        ...inputVerifier,
        `${verifierData.firstName} ${verifierData.lastName}/${verifierData.district}`,
      ];
      inputVerifierDetails = [
        ...inputVerifierDetails,
        {
          emp_id: verifierData.empCode,
          verifier_name: `${verifierData.firstName} ${verifierData.lastName}/${verifierData.district}`,
          salary_per_day: verifierData.salaryPerDay,
          city: verifierData.district,
          id: verifierData.id,
        },
      ];
    });

    //setting sampler input
    let inputSampler = [];
    selectedSamplers.map((e) => {
      let samplerData = samplerListData.find((list) => {
        return list.empCode === e.value;
      });
      inputSampler = [
        ...inputSampler,
        {
          id: samplerData.id,
          emp_id: samplerData.empCode,
          sampler_name: `${samplerData.firstName} ${samplerData.lastName}/${samplerData.district}`,
        },
      ];
    });

    //setting supervisor input
    let inputSupervisorDetails = [];
    selectedSupervisor.map((e) => {
      let supervisorData = supervisorListData.find((list) => {
        return list.empCode === e.value;
      });
      inputSupervisorDetails = [
        ...inputSupervisorDetails,
        {
          empCode: supervisorData.empCode,
          supervisorName: `${supervisorData.firstName} ${supervisorData.lastName}/${supervisorData.district}`,
          firstName: `${supervisorData.firstName}`,
          lastName: `${supervisorData.lastName}`,
          city: supervisorData.district,
        },
      ];
    });

    if (value === 14) {
      if (input.userRole === 'supervisor') {
        const supervisorsPerformanceList = selectedSupervisor.map(supervisor => {
          const foundData = supervisorListData.find(user => supervisor.value === user.empCode);
          return {
            firstName: foundData.firstName,
            lastName: foundData.lastName,
            city: foundData.district,
            _id: foundData.id,
            empCode: foundData.empCode
          }
        });
        setPerformanceReportData(supervisorsPerformanceList)
      } else if (input.userRole === 'sampler') {
        const samplerPerformanceList = selectedSamplers.map(sampler => {
          const foundData = samplerListData.find(user => sampler.value === user.empCode);
          return {
            firstName: foundData.firstName,
            lastName: foundData.lastName,
            city: foundData.district,
            _id: foundData.id,
            empCode: foundData.empCode
          }
        })
        setPerformanceReportData(samplerPerformanceList)
      } else if (input.userRole === 'verifier') {
        const verifierPerformanceList = selectedVerifiers.map(verifier => {
          const foundData = verifierListData.find(user => verifier.value === user.empCode);
          return {
            firstName: foundData.firstName,
            lastName: foundData.lastName,
            city: foundData.district,
            _id: foundData.id,
            empCode: foundData.empCode
          }
        })
        setPerformanceReportData(verifierPerformanceList)
      }
    }

    setInput((values) => ({
      ...values,
      branchName: inputBranch,
      productName: inputProd,
      fromdate: moment(fromDate).format("YYYY-MM-DD"),
      todate: moment(toDate).format("YYYY-MM-DD"),
      startDate: moment(startDate).format("YYYY-MM"),
      verifier: inputVerifier,
      verifierDetails: inputVerifierDetails,
      supervisorDetails: inputSupervisorDetails,
      sampler: inputSampler,
    }));

    setApiCallBool((prev) => !prev);
  };

  const Labels = [
    "MIS-1 Report",
    "MIS-2 Report",
    "Other Report",
    "Pending Report",
    "Login Details",
    "Travelling Claim",
    "Verifier Report",
    "Supervisor Report",
    "Sampler Report",
    "Fraud MIS",
    "Document MIS",
    "Vendor MIS",
    "User MIS",
    "Attendance Report",
    "Performance MIS",
    "Billing MIS",
    "Sampler Attendance",
    "AutoKM MIS",
  ];

  return (
    <div className="Supervisor-dashboard">
      <Box
        sx={{
          flexGrow: 1,
          borderRadius: "20px",
          border: "2px solid #1553b5",
          display: "flex",
          color: "#000",
          bgcolor: "inherit",
          maxHeight: 550,
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          textColor="#000"
          TabIndicatorProps={{
            sx: {
              backgroundColor: "#1553b5",
              height: 3,
              width: "100%",
              borderTopLeftRadius: "17px",
              borderBottomLeftRadius: "17px",
              zIndex: -1,
              opacity: "50%",
            },
          }}
          sx={{
            borderRight: 1,
            borderColor: "#1553b5",
          }}
        >
          {Labels.map((label) => (
            <Tab
              label={label}
              sx={{
                height: "90px",
                borderBottom: "2px solid #1553b5",
                borderBottomLeftRadius: "17px",
              }}
            />
          ))}
        </Tabs>

        {/* MIS-1 */}
        <TabPanel value={value} index={0} style={{ width: "100%" }}>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <SelectBank />
          {/* branch */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Branch
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                isLoading={fetchingBranchList}
                disabled={branchList.length <= 0}
                options={branchlistDropdown}
                value={selectedBranch}
                onChange={setSelectedBranch}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* product */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Product
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={productListDropdownGeneral}
                value={selectedProdMulti}
                onChange={setSelectedProdMulti}
                labelledBy="product"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* MIS-2 */}
        <TabPanel value={value} index={1} style={{ width: "100%" }}>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <SelectBank />
          {/* branch */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Branch
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                isLoading={fetchingBranchList}
                disabled={branchList.length <= 0}
                options={branchlistDropdown}
                value={selectedBranch}
                onChange={setSelectedBranch}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* product */}
          <SelectProduct />
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Other  */}
        <TabPanel value={value} index={2} style={{ width: "100%" }}>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <SelectBank />
          {/* product */}
          <SelectProduct />
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Pending */}
        <TabPanel value={value} index={3} style={{ width: "100%" }}>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Bank
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={banklistDropdown}
                value={selectedBankMulti}
                onChange={setSelectedBankMulti}
                labelledBy="Bank"
              />
            </div>
          </FormControl>
          {/* verifier */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Verifier
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={selectedBankMulti.length <= 0}
                options={verifierListDropdown}
                value={selectedVerifiers}
                onChange={setSelectedVerifiers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* Sort by */}
          <SelectSortBy />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Login details */}
        <TabPanel value={value} index={4} style={{ width: "100%" }}>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <SelectBank />
          {/* branch */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Branch
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                isLoading={fetchingBranchList}
                disabled={branchList.length <= 0}
                options={branchlistDropdown}
                value={selectedBranch}
                onChange={setSelectedBranch}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* product */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Product
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={productListDropdown}
                value={selectedProdMulti}
                onChange={setSelectedProdMulti}
                labelledBy="product"
              />
            </div>
          </FormControl>
          {/* sampler */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Sampler
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                disabled={branchList.length <= 0}
                options={samplerListDropdown}
                value={selectedSamplers}
                onChange={setSelectedSamplers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Travelling Claim */}
        <TabPanel value={value} index={5} style={{ width: "100%" }}>
          {/* verifier */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Verifier
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={verifierListDropdown}
                value={selectedVerifiers}
                onChange={setSelectedVerifiers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Verifier Report */}
        <TabPanel value={value} index={6} style={{ width: "100%" }}>
          {/* verifier */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Verifier
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={verifierListDropdown}
                value={selectedVerifiers}
                onChange={setSelectedVerifiers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Supervisor Report */}
        <TabPanel value={value} index={7} style={{ width: "100%" }}>
          {/* supervisor */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Supervisor
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={supervisorListDropdown}
                value={selectedSupervisor}
                onChange={setSelectedSupervisor}
                labelledBy="supervisor"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Sampler Report */}
        <TabPanel value={value} index={8} style={{ width: "100%" }}>
          {/* sampler */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Sampler
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={samplerListDropdown}
                value={selectedSamplers}
                onChange={setSelectedSamplers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <SelectBank />
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/*  Fraud MIS */}
        <TabPanel value={value} index={9} style={{ width: "100%" }}>
          {/* verifier */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Verifier
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={verifierListDropdown}
                value={selectedVerifiers}
                onChange={setSelectedVerifiers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/*  Document MIS */}
        <TabPanel value={value} index={10} style={{ width: "100%" }}>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Bank
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={banklistDropdown}
                value={selectedBankMulti}
                onChange={setSelectedBankMulti}
                labelledBy="Bank"
              />
            </div>
          </FormControl>
          {/* SelectDocument */}
          <SelectDocument />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Vendor MIS */}
        <TabPanel value={value} index={11} style={{ width: "100%" }}>
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Bank
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={banklistDropdown}
                value={selectedBankMulti}
                onChange={setSelectedBankMulti}
                labelledBy="Bank"
              />
            </div>
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="standard">
            <FormHelperText
              sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
            >
              Type
            </FormHelperText>
            <Select
              labelId="demo-simple-select-label-bank"
              value={vendorMISType || ''}
              id="demo-simple-select-bank"
              MenuProps={MenuProps}
              onChange={(event) => setVendorMISType(event.target.value)}
              input={<BootstrapInput />}
            >
              <MenuItem key={1} value='IT MIS'>
                IT MIS
              </MenuItem>
              <MenuItem key={2} value='BS MIS'>
                BS MIS
              </MenuItem>
            </Select>
          </FormControl>
          <GenerateReportButton />
        </TabPanel>

        {/* User MIS */}
        <TabPanel value={value} index={12} style={{ width: "100%" }}>
          {/* user type */}
          <UserList />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Attendance Report  */}
        <TabPanel value={value} index={13} style={{ width: "100%" }}>
          {/* supervisor multi-select */}
          < FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Supervisor
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={supervisorListDropdown}
                value={selectedSupervisor}
                onChange={setSelectedSupervisor}
                labelledBy="supervisor"
              />
            </div>
          </FormControl>
          {/* sampler multi-select */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Sampler
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={samplerListDropdown}
                value={selectedSamplers}
                onChange={setSelectedSamplers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          {/* verifier multi-select */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Verifier
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={verifierListDropdown}
                value={selectedVerifiers}
                onChange={setSelectedVerifiers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          <br />
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Performance MIS */}
        <TabPanel value={value} index={14} style={{ width: "100%" }}>
          <SelectByRole />

          {input.userRole === 'verifier' ? (
            <FormControl sx={{ m: 1, width: 200, height: 100 }}>
              <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
                Verifier
              </FormHelperText>
              <div style={{ height: "200px", color: "#000" }}>
                <MultiSelect
                  options={verifierListDropdown}
                  value={selectedVerifiers}
                  onChange={setSelectedVerifiers}
                  labelledBy="Branch"
                />
              </div>
            </FormControl>
          ) : input.userRole === 'supervisor' ? (
            < FormControl sx={{ m: 1, width: 200, height: 100 }}>
              <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
                Supervisor
              </FormHelperText>
              <div style={{ height: "200px", color: "#000" }}>
                <MultiSelect
                  options={supervisorListDropdown}
                  value={selectedSupervisor}
                  onChange={setSelectedSupervisor}
                  labelledBy="supervisor"
                />
              </div>
            </FormControl>
          ) : input.userRole === 'sampler' ? (
            <FormControl sx={{ m: 1, width: 200, height: 100 }}>
              <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
                Sampler
              </FormHelperText>
              <div style={{ height: "200px", color: "#000" }}>
                <MultiSelect
                  options={samplerListDropdown}
                  value={selectedSamplers}
                  onChange={setSelectedSamplers}
                  labelledBy="Branch"
                />
              </div>
            </FormControl>
          ) : null}
          <br />
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Billing MIS */}
        <TabPanel value={value} index={15} style={{ width: "100%" }}>
          {/* state */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              State
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={stateListDropdown}
                value={selectedState}
                onChange={setSelectedState}
                labelledBy="State"
              />
            </div>
          </FormControl>
          {/* bank */}
          <SelectBank />
          {/* product */}
          <SelectProduct />
          {/* from date & to date */}
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Sampler Attendance */}
        <TabPanel value={value} index={16} style={{ width: "100%" }}>
          {/* user type */}
          {/* <SelectSampler /> */}
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Sampler
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={samplerListDropdown}
                value={selectedSamplers}
                onChange={setSelectedSamplers}
              />
            </div>
          </FormControl>
          <DateSelect />
          <FormControl sx={{ m: 1 }} variant="standard">
            <FormHelperText
              sx={{ fontSize: "1em", color: "#000", paddingLeft: "10px" }}
            >
              Type
            </FormHelperText>
            <Select
              labelId="demo-simple-select-label-bank"
              value={samplerAttendanceType || ''}
              id="demo-simple-select-bank"
              MenuProps={MenuProps}
              onChange={(event) => setSamplerAttendanceType(event.target.value)}
              input={<BootstrapInput />}
            >
              <MenuItem key={1} value={1}>
                Type 1
              </MenuItem>
              <MenuItem key={2} value={2}>
                Type 2
              </MenuItem>
            </Select>
          </FormControl>
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>

        {/* Auto KM MIS */}
        <TabPanel value={value} index={17} style={{ width: "100%" }}>
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Verifier
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={verifierListDropdown}
                value={selectedVerifiers}
                onChange={setSelectedVerifiers}
                labelledBy="Branch"
              />
            </div>
          </FormControl>
          <FormControl sx={{ m: 1, width: 200, height: 100 }}>
            <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
              Sampler
            </FormHelperText>
            <div style={{ height: "200px", color: "#000" }}>
              <MultiSelect
                options={samplerListDropdown}
                value={selectedSamplers}
                onChange={setSelectedSamplers}
              />
            </div>
          </FormControl>
          <DateSelect />
          {/* generate report button */}
          <GenerateReportButton />
        </TabPanel>
      </Box>
    </div >
  );
};

export default FileList;
