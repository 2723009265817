import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { changeDrawerSelection } from "../../../../../../features/spselection";
import { AttachFileImageConfig } from "../../../../../../config/AttachFileImageConfig";
import swal from "sweetalert";
import "./AddNewFile.css"
// mui
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import FormUpload from "../../../../../formUpload/FormUpload";
import VoiceRecord from "../../../../../voice_record/VoiceRecord";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/material";
import { LoadingButton } from '@mui/lab'
import { InputAdornment } from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//MUI icon
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChildModal } from "../../../../../modalViewer.js/ChildModal";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import moment from "moment";

var _ = require("lodash");

//Doc type select component
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// for third page document type upload
const Input = styled("input")({
  display: "none",
});

//custom MUI textField
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#171717",
  },
  "& label.MuiInputLabel-formControl": {
    color: "#171717",
  },
  "& svg.MuiSvgIcon-root": {
    color: "#171717",
  },
  "& .MuiSelect-select": {
    color: "#171717",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#171717",
  },
  "& .MuiOutlinedInput-input": {
    color: "#171717",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#171717",
    },
    "&:hover fieldset": {
      borderColor: "#171717",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#171717",
    },
  },
  '& input:focus': {
    boxShadow: 'none'
  }
});

const AttachButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const Attach = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const AttachBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  // backgroundImage:linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0)),
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const AttachMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 30,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 15px)",
  transition: theme.transitions.create("opacity"),
}));

//for mui dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddNewFile = ({ fileDetails, toAddNewDocument }) => {
  const { verificationType, fileViewDetail: fileDetail, fileDocDetail } = useSelector((state) => state.spselection.value);
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.token.value);
  const { value, currentBankId, bankName, bankLogo } = useSelector(
    (state) => state.spselection.value
  );
  const { empId, firstName, lastName, role } = useSelector(
    (state) => state.user.value
  );

  const [selectedFileDetail, setSelectedFileDetail] = useState(
    fileDetails ? fileDetails : ""
  );
  const [fileViewDetail, setFileViewDetail] = React.useState([]);
  const [fileList, setFileList] = useState([]); //for displaying uploaded application form
  const [selectedDocumentDocType, setSelectedDocumentDocType] = useState([]);
  const [selectedProfileDocType, setSelectedProfileDocType] = useState([]);

  const [selectedDocName, setSelectedDocName] = React.useState(""); //profile dropdown selection (on page 3 )
  const [selectedDocCode, setSelectedDocCode] = React.useState("");
  const [selectedProfileName, setSelectedProfileName] = React.useState(""); //document dropdown selection (on page 3 )
  const [selectedProfileCode, setSelectedProfileCode] = React.useState("");

  const [openFormUploadView, setOpenFormUploadView] = React.useState(false); //application form upload (on page 3)
  const [openRecordVoiceView, setOpenRecordVoiceView] = React.useState(false); //voice upload(on page 3 )

  const [formSubmitLoading, setFormSubmitLoading] = useState(false)

  const [documentType, setDocumentType] = useState([]);
  const [inputs, setInputs] = useState({
    id: "",
    fileNo: "", //File No
    CustomerName: "", //Customer Name
    Location: "", //Location
    branchRefId: "", //CPA Branch
    branchName: "",
    productName: "", //Product
    VerificationType: "", //Verification Type
    productRefId: "", //update on selecting product
    productFields: [], //addfile second page input
    productType: "",
    docType: [...selectedDocumentDocType, ...selectedProfileDocType],
    applicationImg: [], //form upload
    bankId: currentBankId,
    bankRefId: currentBankId,
    bankName: "",
    samplerName: `${role}-${firstName} ${lastName}`,
    createdby: empId,
    supervisorVoice: [],
  }); // user entered input data is stored in "inputs" state
  const [state, setState] = useState("");
  const [productList, setProductList] = useState([]); //getproductlist api reponse is stored in this state
  const [branchList, setBranchList] = useState([]); //getbranchlist api reponse is stored in this state
  const [inputVoice, setInputVoice] = useState("");
  //for playing audio
  const audioRef = React.useRef([]);
  const playerButton = React.useRef([]);
  const timeline = React.useRef([]);
  const soundButton = React.useRef([]);
  //svg icons for audio
  const playIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
  </svg>
  `;
  const pauseIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
  </svg>
  `;
  const soundIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
  </svg>`;
  const muteIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
  </svg>`;

  useEffect(() => {
    if (toAddNewDocument) {
      setState("add file3");
      setInputs((values) => ({
        ...values,
        VerificationType: verificationType
      }))
    }
    else if (selectedFileDetail.length < 1) {
      setState("add file1");
    } else {
      setState("add file2");
      const viewFileDocument = async () => {
        try {
          // api call to get file doc detail
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/viewFileDocument`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              fileRefId: selectedFileDetail._id,
            },
          });
          setFileViewDetail(response.data.docDetail);
        } catch (error) {
          console.log(error);
        }
      };
      viewFileDocument();
      setInputs({
        id: selectedFileDetail._id,
        fileNo: selectedFileDetail.fileId,
        CustomerName: selectedFileDetail.userName,
        Location: selectedFileDetail.location,
        branchRefId: selectedFileDetail.branchRefId,
        branchName: selectedFileDetail.branchName,
        productName: selectedFileDetail.productName,
        VerificationType: selectedFileDetail.verificationType,
        productRefId: selectedFileDetail.productRefId,
        productFields: selectedFileDetail.productFields,
        productType: "",
        docType: [...selectedDocumentDocType, ...selectedProfileDocType],
        applicationImg: selectedFileDetail.applicationImage,
        bankId: selectedFileDetail.bankId,
        bankRefId: selectedFileDetail.bankRefId,
        bankName: selectedFileDetail.bankName,
        samplerName: selectedFileDetail.samplerName,
        createdby: selectedFileDetail.createdBy,
        supervisorVoice: selectedFileDetail.supervisorRecord,
      });
      let draftImgArr = [];
      selectedFileDetail.applicationImage.map((e) => {
        draftImgArr = [
          ...draftImgArr,
          [{ name: e.url.split("/")[4].replace(/[0-9]/g, "") }],
        ];
      });
      setFileList(draftImgArr);
    }
  }, [selectedFileDetail]);

  useEffect(() => {
    if (state === "add file1") {
      const params = new URLSearchParams();
      params.append("bankId", currentBankId);
      //api call to get product list
      const productList = async () => {
        try {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getProductListByBank`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: params,
          });
          if (response.data.status === 100) {
            setProductList(response.data.result);

            setInputs(values => ({
              ...values,
              reportStatus: response.data.result[0]?.bankId.reportStatus
            }))
          }
        } catch (error) {
          console.log(error);
        }
      };
      //api call to get branch list
      const branchList = async () => {
        try {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBranchListByBank`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: params,
          });
          if (response.data.status === 100)
            setBranchList(response.data.result.sort((a, b) => a.branchName.localeCompare(b.branchName)))
        } catch (err) {
          console.log(err);
        }
      };
      productList();
      branchList();
    } else if (state === "add file3") {
      const docTypeParams = new URLSearchParams();
      docTypeParams.append("category", "");

      const docTypeList = async () => {
        try {
          const response = await axios({
            url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getDocumentTypeListMinimum`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: docTypeParams,
          });
          setDocumentType(
            response.data[0]?.documentTypeId
              .sort((a, b) => a.documentTypeName.localeCompare(b.documentTypeName))
              .filter(document => document.documentTypeCode !== undefined)
          );
        } catch (error) {
          console.log(error);
        }
      };
      docTypeList();
    }
  }, [state]);

  useEffect(() => {
    if (fileViewDetail.length > 0) {
      fileViewDetail.map((e) => {
        documentType.map((doc) => {
          let updatedObj = {};
          if (e.docRefId === doc._id) {
            let clonedDocumentType = _.cloneDeep(doc);
            let clonedDocField = _.cloneDeep(e.fields);
            let clonedDocImg = _.cloneDeep(e.docImage);
            updatedObj = {
              ...clonedDocumentType,
              id: e._id,
              fields: clonedDocField,
              docImg: clonedDocImg,
            };
            doc.category === "document"
              ? setSelectedDocumentDocType((prev) => [...prev, updatedObj])
              : setSelectedProfileDocType((prev) => [...prev, updatedObj]);
          }
        });
      });
    }
  }, [documentType]);

  useEffect(() => {
    branchList?.map((branch) => {
      branch._id === inputs.branchRefId &&
        setInputs((values) => ({
          ...values,
          bankId: branch.bankId.bankCode,
          bankName: branch.bankId.bankName,
          branchName: branch.branchName,
        }));
    });
  }, [inputs.branchRefId]);

  useEffect(() => {
    if (inputs.productRefId.length > 0 && _.size(selectedFileDetail) <= 0) {
      const params = new URLSearchParams();
      params.append("bankId", currentBankId);
      params.append("productId", inputs.productRefId);
      //api call to get product list
      const productFields = async () => {
        try {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getProductFields`,
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            data: params,
          });
          if (response.data.status === 100) {
            setInputs((values) => ({
              ...values,
              productName: response.data.result.productName,
              productFields: response.data.result.fields,
              productType: response.data.result.type,
            }));
          }
        } catch (error) {
          console.log(error);
          swal({
            text: "Failed to get product fields",
            icon: "error",
          });
        }
      };
      productFields();
    }
  }, [inputs.productRefId]);

  //inputs.doctype is updated upon updating selectedDocumentDocType and selectedProfileDocType state
  useEffect(() => {
    setInputs((values) => ({
      ...values,
      docType: [...selectedDocumentDocType, ...selectedProfileDocType],
    }));
  }, [selectedDocumentDocType, selectedProfileDocType]);

  // mui customized button
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }));

  const LoadingColorButton = styled(LoadingButton)(({ theme }) => ({
    color: theme.palette.getContrastText("#fff"),
    backgroundColor: "#1553b5",
    "&:hover": {
      backgroundColor: "#1553b5",
    },
  }))

  //called while user enter input (on page 1)
  const handleChange = (event) => {
    const name = event.target.name;
    const value =
      name === "CustomerName"
        ? event.target.value.toUpperCase().replace(/\s+/g, ' ')
        : event.target.value; //customer name must be in uppercase
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleProdFieldChange = (event) => {
    const name = event.target.name;
    const value =
      name === "_pan_number"
        ? event.target.value.toUpperCase()
        :  name === "TEXTCAPS" ? event.target.value.toUpperCase():event.target.value //pancard number must be in uppercase


    inputs.productFields.map((e) => {
      let updatedProdArray = inputs.productFields.map((a) => {
        return { ...a };
      });
      updatedProdArray.find((a) => a.FIELD == name).VALUE = value;
      setInputs((values) => ({ ...values, productFields: updatedProdArray }));
    });
  };

  //api call for add file (second page)
  const updateProductFieldApiCall = async () => {
    setFormSubmitLoading(true)
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/updateNewFile`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          inputs: { ...inputs, agencyName: localStorage.getItem("agencyName") },
          currentStatus: "DRAFT"
        },
      });
      if (response.data.status === "success") {
        setState("add file3");
      } else {
        swal({
          title: `${response.data.status}`,
          icon: "error",
        });
      }
    } catch (error) {
      swal({
        title: `Failed to update file`,
        icon: "error",
      });
    } finally {
      setFormSubmitLoading(false)
    }
  };

  const handleFormSubmitFirstPage = async (e) => {
    e.preventDefault();

    // to trip whitespaces from the input values
    const trimmedInput = _.cloneDeep(inputs);
    Object.keys(trimmedInput)
      .forEach(key => {
        if (typeof trimmedInput[key] === 'string')
          trimmedInput[key] = trimmedInput[key].trim()
      });
    setInputs(trimmedInput);

    try {
      setFormSubmitLoading(true)
      //api call for add file
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/addFile`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: trimmedInput,
      });
      if (response.data.status === "success") {
        setInputs((values) => ({ ...values, id: response.data.id }));
        setState("add file2");
      } else {
        swal({
          title: `${response.data.status}`,
          icon: "info",
        });
      }
    } catch (error) {
      swal({
        title: `Failed on adding new file`,
        icon: "error",
      });
    } finally {
      setFormSubmitLoading(false)
    }
  };

  const handleFormSubmitSecondPage = (e) => {
    e.preventDefault();
    const panField = inputs.productFields.find(field => field.FIELD === "_pan_number")
    const phoneNumField = inputs.productFields.find(
      field => field.TYPE === "phone_number" && field.FILLED_BY === "sampler"
    )

    let isPanValid = true
    let isPhoneNumValid = true

    if (panField) {
      let panRegex = /^[A-Z]{3}([ABCFGHLJPT])[A-Z][0-9]{4}[A-Z]$/
      if (!(panRegex.test(panField.VALUE) || panField.VALUE === "NA")) {
        isPanValid = false
      }
    }

    if (phoneNumField) {
      let phoneNumRegex = /^(?!.*;;)(?!.*;$)[\d;]+$/
      if (!(phoneNumRegex.test(phoneNumField.VALUE))) {
        isPhoneNumValid = false
      }
    }

    if (!isPanValid || !isPhoneNumValid) {
      !isPanValid && swal({
        title: `Invalid Pancard Number`,
        icon: "info",
      })
      !isPhoneNumValid && swal({
        title: 'Invalid Phone Number Format',
        icon: 'info'
      })
      return
    }

    let invalidDateFound = false;
    const formattedProdFields = inputs.productFields.map(item => {
      if (item.FILLED_BY === "sampler" && item.TYPE === "date" && item.VALUE) {
        const date = moment(item.VALUE.$d).format("DD-MM-YYYY");
        if (date === "Invalid date") {
          invalidDateFound = true;
        }
        return {
          ...item,
          VALUE: date
        }
      }
      return item;
    })

    inputs.productFields = formattedProdFields;

    if (invalidDateFound) {
      swal({
        title: 'Invalid Date Format',
        icon: 'info'
      })
      return;
    }

    try {
      updateProductFieldApiCall()
    } catch (error) {
      swal({
        title: `Failed on adding product fields`,
        icon: "error",
      })
    }
  }

  const handleFormSubmitThirdPage = async (e) => {
    e.preventDefault();
    if (inputs.docType.length < 1) {
      return inputs.VerificationType === "profile"
        ? swal({
          title: `Select atleast one Profile type`,
          icon: "info",
        })
        : inputs.VerificationType === "document"
          ? swal({
            title: `Select atleast one Document type`,
            icon: "info",
          })
          : swal({
            title: `Select atleast one Profile and Document type`,
            icon: "info",
          });
    } else {
      if (inputs.VerificationType === "document") {
        //document attachment check
        let docTypeLength = inputs.docType.length;
        let checkLength = 0;

        inputs.docType.map((e) => {
          if (e.docImg && e.docImg?.length > 0) {
            checkLength++;
          }
        });
        if (docTypeLength === checkLength) {
          handleThirdPageUpdate();
        } else {
          swal({
            title: `Please attach document for the selected types`,
            icon: "info",
          });
        }
      } else if (inputs.VerificationType === "profile and document") {
        let categoryArr = [];
        const values = ["document", "profile"];
        inputs.docType.map((e) => {
          categoryArr.push(e.category);
        });
        const multipleExist = values.every((value) => {
          return categoryArr.includes(value);
        });
        if (!multipleExist) {
          swal({
            title: `Select atleast one Profile and Document type`,
            icon: "info",
          });
        } else {
          let docTypeLength = 0;
          let checkLength = 0;

          inputs.docType.map((e) => {
            if (e.category === "document") {
              docTypeLength++;
              if (e.docImg && e.docImg?.length > 0) {
                checkLength++;
              }
            }
          });
          if (docTypeLength === checkLength) {
            handleThirdPageUpdate();
          } else {
            swal({
              title: `Please attach document for the selected document types`,
              icon: "info",
            });
          }
        }
      } else if (inputs.VerificationType === "profile") {
        handleThirdPageUpdate();
      }
    }
  };

  const handleThirdPageUpdate = async () => {
    let newValue;
    let offset = 0;
    if (toAddNewDocument && fileDocDetail?.length) {
      offset = fileDocDetail.length
    }

    let invalidDateFound = false;
    setInputs(prevValue => {
      newValue = _.cloneDeep(inputs);
      const newDoctype = inputs.docType.map((document, index) => {
        const letter = String.fromCharCode(97 + index + offset);  // 97 is the ASCII code for the letter 'a'

        const formattedFields = document.fields.map(field => {
          if (field.TYPE === "date" && field.FILLED_BY === "sampler" && field.VALUE) {
            const date = moment(field.VALUE.$d).format("DD-MM-YYYY");
            if (date === "Invalid date") {
              invalidDateFound = true;
            }
            return {
              ...field,
              VALUE: date
            }
          }
          return field;
        })

        return {
          ...document,
          docTag: `${letter})${document.documentTypeName}`,
          fields: formattedFields
        }
      });
      newValue.docType = newDoctype;

      return newValue;
    });

    if (invalidDateFound) {
      swal({
        title: 'Invalid Date Format',
        icon: 'info'
      })
      return;
    }

    try {      
      setFormSubmitLoading(true)
      //api call for add file
      if (toAddNewDocument) {
        const getVerificationType = () => {
        const documentCategories = [];
        fileDocDetail.forEach(doc => {
          documentCategories.push(doc.category);
        });
        newValue.docType.forEach(doc => {
          documentCategories.push(doc.category);
        });
  
        const documentCategoriesSet = [...new Set(documentCategories)];
        if (documentCategoriesSet.every(value => value === "profile"))
          return "profile";
        else if (documentCategoriesSet.every(value => value === "document"))
          return "document";
        else
          return "profile and document";
        }
  
        const verType = getVerificationType();
        
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/addDocument`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            _id: fileDetail[0]._id,
            verificationType: verType,
            docType: newValue.docType
          },
        });
        if (response.data.status === 100) {
          swal({
            title: `Document added successfully!`,
            icon: "success",
          });
          dispatch(
            changeDrawerSelection({
              value: "File",
              currentBankId: localStorage.getItem("currentBankId"),
            })
          );
        } else {
          swal({
            title: `${response.data.status}`,
            icon: "error",
          });
        }
      } else {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/updateNewFile`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            inputs: { ...newValue, agencyName: localStorage.getItem("agencyName") },
            currentStatus: "NEW"
          },
        });
        if (response.data.status === "success") {
          swal({
            title: `File added successfully!`,
            icon: "success",
          });
          dispatch(
            changeDrawerSelection({
              value: "File",
              currentBankId: localStorage.getItem("currentBankId"),
            })
          );
        } else {
          swal({
            title: `${response.data.status}`,
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.log(error);
      swal({
        title: `Failed on adding product fields`,
        icon: "error",
      });
    } finally {
      setFormSubmitLoading(false)
    }
  };

  const handleClick = (value) => {
    dispatch(
      changeDrawerSelection({
        value: value,
        currentBankId: currentBankId,
      })
    );
  };

  //aplication form upload
  const onFileChange = async (files) => {
    var applicationForm = new FormData();
    applicationForm.append("s3path", "application-form");
    // applicationForm.append("file", files[0]);

    if (files.length > 20) {
      swal({
        title: `Cannot upload more than 20 files at a time`,
        icon: "error",
      })

      return;
    }

    for (let i = 0; i < files.length; i++) {
      applicationForm.append('file', files[i]);
    }

    const newFiles = [];
    for (let file of files) {
      newFiles.push(file);
    }

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: applicationForm,
      });
      if (response.data.s3Url) {
        let updatedApplicationImg = [
          ...inputs.applicationImg,
          // { url: response.data.url },
          ...response.data.s3Url.map(url => ({ url }))
        ];
        setInputs((values) => ({
          ...values,
          applicationImg: updatedApplicationImg,
        }));
        // const newFile = files[0];

        if (newFiles.length) {
          // const updatedList = [newFile]; //const updatedList = [...fileList, newFile] (for multiple upload)
          // var updatedListArray = [...fileList, updatedList];
          var updatedListArray = [...fileList, ...newFiles]
          setFileList(updatedListArray);
        }
        swal({
          title: `Application Form Upload Success`,
          icon: "success",
        });
      }
    } catch (error) {
      swal({
        title: `Application Form Upload Failed`,
        icon: "error",
      });
    }
    handleClose();
  };

  //application form remove
  const applicationFormRemove = async (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);

    const appImageList = inputs.applicationImg;
    appImageList.splice(fileList.indexOf(file), 1);
    setInputs(prevValue => ({
      ...prevValue, applicationImg: appImageList
    }))
  };

  //voice upload
  const onUpload = async (bool) => {
    var applicationForm = new FormData();
    applicationForm.append("s3path", "voice-upload");
    applicationForm.append("file", inputVoice);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadToS3`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: applicationForm,
      });
      if ("s3Url" in response.data) {
        let updatedSupervisorVoice = [
          ...inputs.supervisorVoice,
          { url: response.data.s3Url },
        ];
        setInputs((values) => ({
          ...values,
          supervisorVoice: updatedSupervisorVoice,
        }));
        swal({
          title: `Voice Upload Success`,
          icon: "success",
        });
      }
    } catch (error) {
      swal({
        title: `Voice Upload Failed`,
        icon: "error",
      });
    }
    handleClose();
  };

  //change audio timeline
  const changeTimelinePosition = (index) => {
    let state = audioRef.current[index];
    const percentagePosition = (100 * state.currentTime) / state.duration;
    timeline.current[
      index
    ].style.backgroundSize = `${percentagePosition}% 100%`;
    timeline.current[index].value = percentagePosition;
  };
  //function called after audio ended
  const audioEnded = (index) => {
    playerButton.current[index].innerHTML = playIcon;
  };
  //play/pause audio
  const toggleAudio = (index) => {
    if (audioRef.current[index].paused) {
      audioRef.current[index].play();
      playerButton.current[index].innerHTML = pauseIcon;
    } else {
      audioRef.current[index].pause();
      playerButton.current[index].innerHTML = playIcon;
    }
  };
  const changeSeek = (index) => {
    const time =
      (timeline.current[index].value * audioRef.current[index].duration) / 100;
    audioRef.current[index].currentTime = time;
  };
  //mute/unmute sound
  const toggleSound = (index) => {
    audioRef.current[index].muted = !audioRef.current[index].muted;
    soundButton.current[index].innerHTML = audioRef.current[index].muted
      ? muteIcon
      : soundIcon;
  };

  // on form upload button click
  const handleFormClick = () => {
    setOpenFormUploadView(true);
  };

  const handleRecordVoiceClick = () => {
    setOpenRecordVoiceView(true);
  };
  const handleClose = () => {
    setOpenFormUploadView(false);
    setOpenRecordVoiceView(false);
  };

  ///////PROFILE selection on page 3
  //doctype select and list profile (add file 3rd page)
  const handleSelectProfileChange = (event) => {
    const {
      target: { value },
    } = event;
    documentType.map((e) => {
      if (value === e.documentTypeName) {
        let clonedDocumentTypeName = _.cloneDeep(e.documentTypeName);
        let clonedDocumentTypeCode = _.cloneDeep(e.documentTypeCode);
        setSelectedProfileName(clonedDocumentTypeName);
        setSelectedProfileCode(clonedDocumentTypeCode);
      }
    });
  };

  //for adding profile from dropdown
  const handleProfileDocAdd = () => {
    const params = new URLSearchParams();
    params.append("bankId", toAddNewDocument ? fileDetail[0].bankRefId : currentBankId);
    params.append("documentTypeCode", selectedProfileCode);
    //api call to get product list
    const documentTypeFields = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getDocumentTypeFields`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });
        setSelectedProfileDocType([...selectedProfileDocType, { ...response.data, id: response.data._id }]);
      } catch (error) {
        console.log(error);
        swal({
          text: "Failed to get Document Data",
          icon: "error",
        });
      }
    };
    selectedProfileCode.length > 0 && documentTypeFields();
  };

  //for deleting selected profile doctype
  const handleSelectedProfileDocDelete = (index) => {
    var array = [...selectedProfileDocType]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedProfileDocType(array);
    }
  };

  ///////DOCUMENT selection on page 3
  //doctype select and list document (add file 3rd page)
  const handleSelectDocChange = (event) => {
    const {
      target: { value },
    } = event;

    documentType.map((e) => {
      if (value === e.documentTypeName) {
        let clonedDocumentTypeName = _.cloneDeep(e.documentTypeName);
        let clonedDocumentTypeCode = _.cloneDeep(e.documentTypeCode);
        setSelectedDocName(clonedDocumentTypeName);
        setSelectedDocCode(clonedDocumentTypeCode);
      }
    });
  };

  //for adding document from dropdown
  const handleDocumentDocAdd = () => {
    const params = new URLSearchParams();
    params.append("bankId", toAddNewDocument ? fileDetail[0].bankRefId : currentBankId);
    params.append("documentTypeCode", selectedDocCode);
    //api call to get product list
    const documentTypeFields = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getDocumentTypeFields`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });
        setSelectedDocumentDocType([...selectedDocumentDocType, { ...response.data, id: response.data._id }]);
      } catch (error) {
        console.log(error);
        swal({
          text: "Failed to get Document Data",
          icon: "error",
        });
      }
    };
    selectedDocCode.length > 0 && documentTypeFields();
  };
  //for deleting selected document doctype
  const handleSelectedDocumentDocDelete = (index) => {
    var array = [...selectedDocumentDocType]; // make a separate copy of the array
    if (index !== -1) {
      array.splice(index, 1);
      setSelectedDocumentDocType(array);
    }
  };

  const handlePANChange = (event, field, docIndex) => {
    const panNumber = event.target.value.toUpperCase()
    field.VALUE = panNumber;

    const foundDoc = selectedDocumentDocType[docIndex]
    let regexPan = /^[A-Z]{3}([ABCFGHLJPT])[A-Z][0-9]{4}[A-Z]$/
    if (regexPan.test(panNumber)) {
      foundDoc._temp = { isPanValid: true }
    } else {
      foundDoc._temp = { isPanValid: false }
    }

    setSelectedDocumentDocType([
      ...selectedDocumentDocType
    ])
  }

  const [panVerLoading, setPanVerLoading] = useState(false)

  const handleVerifyPAN = async (docIndex) => {
    const foundDoc = selectedDocumentDocType[docIndex]
    const panNumber = foundDoc.fields.find(field => field.FIELD === "number").VALUE
    const firstName = localStorage.getItem("firstName")
    const lastName = localStorage.getItem("lastName")
    const employeeName = `${firstName} ${lastName}`

    if (foundDoc?._temp?.isPanAuthentic !== undefined)
      return

    setPanVerLoading(true)
    axios.post(
      process.env.REACT_APP_ADMINNODE + '/api/validate/PancardVerification',
      { panNumber, employeeName, requestThrough: "Supervisor Web" },
      { headers: { Authorization: "Bearer " + token } }
    )
      .then(res => {
        if (res.data.status === 'VALID') {
          foundDoc._temp.isPanAuthentic = true
          const panDetails = res.data.data
          const docFields = foundDoc.fields

          const nameField = docFields.find(field => field.FIELD === 'Name')
          const nameArray = [panDetails['FIRST_NAME'], panDetails['MIDDLE_NAME'], panDetails['LAST_NAME']]
          const name = nameArray.filter(ele => ele !== "").join(' ').trim()
          nameField.VALUE = name === "" ? 'NA' : name

          const dobField = docFields.find(field => field.FIELD === 'DOB')
          dobField.VALUE = panDetails['DOB'] === "" ? 'NA' : panDetails['DOB']

          const genderField = docFields.find(field => field.FIELD === 'Gender')
          genderField.VALUE = panDetails['GENDER'] === "" ? 'NA' : panDetails['GENDER']

          const typeField = docFields.find(field => field.FIELD === 'Type')
          typeField.VALUE = panDetails['IDENTITY_TYPE'] === "" ? 'NA' : panDetails['IDENTITY_TYPE']

          const addressField = docFields.find(field => field.FIELD === 'Address')
          const addressArray = [
            panDetails['ADDRESS_1'],
            panDetails['ADDRESS_2'],
            panDetails['ADDRESS_3'],
            panDetails['PINCODE'],
            panDetails['CITY'],
            panDetails['STATE']
          ]
          const address = addressArray.filter(ele => ele !== "").join(', ')
          addressField.VALUE = address === "" ? 'NA' : address

          const contactField = docFields.find(field => field.FIELD === 'Contact')
          contactField.VALUE = panDetails['MOBILE_NO'] === "" ? 'NA' : panDetails['MOBILE_NO']

          const aadhaarField = docFields.find(field => field.FIELD === 'Aadhaar')
          aadhaarField.VALUE = panDetails['AADHAR_NUM'] === "" ? 'NA' : panDetails['AADHAR_NUM']

          const emailField = docFields.find(field => field.FIELD === 'Mail')
          emailField.VALUE = panDetails['EMAIL'] === "" ? 'NA' : panDetails['EMAIL']
        }

        else if (res.data.status === 'INVALID') {
          foundDoc._temp.isPanAuthentic = false
          const docFields = foundDoc.fields

          const nameField = docFields.find(field => field.FIELD === 'Name')
          nameField.VALUE = 'NA'

          const dobField = docFields.find(field => field.FIELD === 'DOB')
          dobField.VALUE = 'NA'

          const genderField = docFields.find(field => field.FIELD === 'Gender')
          genderField.VALUE = 'NA'

          const typeField = docFields.find(field => field.FIELD === 'Type')
          typeField.VALUE = 'NA'

          const addressField = docFields.find(field => field.FIELD === 'Address')
          addressField.VALUE = 'NA'

          const contactField = docFields.find(field => field.FIELD === 'Contact')
          contactField.VALUE = 'NA'

          const aadhaarField = docFields.find(field => field.FIELD === 'Aadhaar')
          aadhaarField.VALUE = 'NA'

          const emailField = docFields.find(field => field.FIELD === 'Mail')
          emailField.VALUE = 'NA'
        }

        else {
          swal({
            text: 'Failed to verify PAN',
            icon: "error"
          })
        }

        setSelectedDocumentDocType([
          ...selectedDocumentDocType
        ])
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setPanVerLoading(false)
      })
  }

  const handleSubmitButtonClick = () => {
    // to change border color of input to red for validation error 
    const form = document.querySelector("form")
    form.classList.add("submitted")  // style defined in AddNewFile.css
  }

  return (
    <div style={{ marginTop: '0px' }} className="addfile-container">
      <div className="addfile-container-column">
        <div style={{ width: '100%' }} className="addfile addfile-first">
          <ColorButton
            sx={{
              color: "#fff",
              borderRadius: "10px",
            }}
            onClick={() => handleClick("File")}
            variant="contained"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </ColorButton>

          {bankLogo &&
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              marginTop='-15px'
              marginBottom='10px'
            >
              <img src={bankLogo} width='55px' height='55px' style={{ borderRadius: '5px' }} />
              <Typography
                sx={{
                  display: 'inline-block',
                  fontSize: '22px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  paddingLeft: '10px',
                  paddingRight: '10px'
                }}
              >
                {bankName}
              </Typography>
            </Box>}
        </div>
        <div style={{ marginTop: '0' }} className="addfile addfile-second">
          <p style={{ color: "#1553b5" }}>{toAddNewDocument ? "Add Document" : "Add File"}</p>
        </div>
        {state === "add file2" ? (
          //add file second page
          <div
            className="addfile addfile-third"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto",
              width: "80%",
            }}
          >
            <Box
              sx={{
                borderRadius: "20px",
                border: "2px solid #1553b5",
                height: "100%",
                width: "100%",
                display: "flex",
                flexFlow: "column",
                justifyContent: "space-between",
                "& > :not(style)": { m: 4 },
              }}
              component="form"
              autoComplete="off"
              onSubmit={handleFormSubmitSecondPage}
            >
              {inputs.productFields.map((e) => {
                if (e.FILLED_BY.toLowerCase() === "sampler") {
                  return e.TYPE === "select" ? (
                    <>
                      <CssTextField
                        required={e.REQUIRED}
                        select
                        type={e.TYPE}
                        id={`outlined-${e.DISPLAY_NAME}`}
                        label={e.DISPLAY_NAME}
                        name={e.FIELD}
                        value={e.VALUE}
                        onChange={handleProdFieldChange}
                        sx={{ textAlign: "left" }}
                        key={e.DISPLAY_NAME}
                      >
                        {e.VALUES_LIST.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </CssTextField>
                      {e.VALUE.toLowerCase() === "other" && (
                        <CssTextField
                          focused
                          multiline
                          required
                          id="outlined-fileNo"
                          label={`Other Value (${e.DISPLAY_NAME})`}
                          name="otherValue"
                          value={e.OTHER_VALUE || ""}
                          onChange={(s) => {
                            e.OTHER_VALUE = s.target.value;
                            setInputs({ ...inputs });
                          }}
                          sx={{ marginLeft: "30px" }}
                        />
                      )}
                    </>
                  ) : e.TYPE === "date" ? (
                    <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={e.VALUE}
                        label={e.DISPLAY_NAME}
                        inputFormat="DD-MM-YYYY"
                        InputProps={{ name: e.FIELD }}
                        renderInput={(params) => (
                          <CssTextField
                            focused {...params}
                            required={e.REQUIRED}
                          />
                        )}
                        onChange={value => {
                          const updatedProdArray = inputs.productFields.map(a => {
                            return { ...a }
                          });
                          updatedProdArray.find(a => a.FIELD === e.FIELD).VALUE = value;
                          setInputs(values => ({ ...values, productFields: updatedProdArray }));
                        }}
                      />
                    </LocalizationProvider>
                  ) : (
                    <CssTextField
                      key={e.DISPLAY_NAME}
                      required={e.REQUIRED}
                      multiline={
                        e.TYPE !== "date" &&
                        e.TYPE !== "time" &&
                        e.TYPE !== "month" &&
                        e.TYPE !== "number" &&
                        e.TYPE !== "text"
                      }
                      focused
                      type={e.TYPE}
                      id={`outlined-${e.DISPLAY_NAME}`}
                      label={e.DISPLAY_NAME}
                      name={e.FIELD}
                      value={e.VALUE}
                      inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                      onInput={(num) => {
                        // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
                        e.TYPE === "number" &&
                          (num.target.value = Math.floor(
                            Math.max(0, parseInt(num.target.value))
                              .toString()
                              .slice(0, e.MAX_CHAR || 12)
                          ));
                          e.TYPE ==="text_caps"&&
                          (num.target.value=num.target.value.toLocaleUpperCase())
                      }}
                      onChange={handleProdFieldChange}
                    />
                  );
                }
              })}
              <LoadingColorButton
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  fontSize: "16px",
                  letterSpacing: "4px",
                  marginTop: "20px",
                  minWidth: "200px",
                }}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
                loadingPosition="start"
                loading={formSubmitLoading}
                onClick={handleSubmitButtonClick}
              >
                Save
              </LoadingColorButton>
            </Box>
          </div>
        ) : state === "add file3" ? (
          //add file third page
          <div
            className="addfile addfile-third"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                borderRadius: "20px",
                border: "2px solid #1553b5",
                height: "100%",
                width: "100%",
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "space-between",
                "& > :not(style)": { m: 4, width: "100%" },
              }}
              component="form"
              autoComplete="off"
              onSubmit={handleFormSubmitThirdPage}
            >
              {/* application image and supervisor voice upload section */}
              {!toAddNewDocument && <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  minWidth: 500,
                  width: "100%",
                }}
              >
                <AttachButton
                  focusRipple
                  style={{
                    width: "50%",
                    border: "1px solid #fff",
                  }}
                  onClick={handleFormClick}
                >
                  <AttachBackdrop className="MuiImageBackdrop-root" />
                  <Attach>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      sx={{
                        position: "relative",
                        p: 4,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      }}
                    >
                      Application Form Upload
                      <AttachMarked className="MuiImageMarked-root" />
                    </Typography>
                  </Attach>
                </AttachButton>

                <AttachButton
                  focusRipple
                  style={{
                    width: "50%",
                    border: "1px solid #fff",
                  }}
                  onClick={handleRecordVoiceClick}
                >
                  <AttachBackdrop className="MuiImageBackdrop-root" />
                  <Attach>
                    <Typography
                      component="span"
                      variant="subtitle1"
                      color="inherit"
                      sx={{
                        position: "relative",
                        p: 4,
                        pt: 2,
                        pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                      }}
                    >
                      Attach Supervisor Voice
                      <AttachMarked className="MuiImageMarked-root" />
                    </Typography>
                  </Attach>
                </AttachButton>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    width: "100%",
                  }}
                >
                  <div>
                    {fileList.length > 0 ? (
                      <div
                        className="drop-file-preview"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "50%",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          {fileList.map((item, index) => (
                            <div
                              key={index}
                              className="drop-file-preview__item"
                            >
                              <img
                                src={
                                  inputs.applicationImg[index]?.url.split(
                                    "."
                                  )[5] ===
                                    "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    ? AttachFileImageConfig["xlsx"]
                                    : AttachFileImageConfig[
                                    inputs.applicationImg[index]?.url.split(
                                      "."
                                    )[5]
                                    ] || AttachFileImageConfig["pdf"]
                                }
                                alt=""
                              />
                              <div
                                onClick={() => {
                                  window.open(
                                    `${inputs.applicationImg[index]?.url}`,
                                    "_blank"
                                  );
                                }}
                                style={{
                                  cursor: "pointer",
                                }}
                                className="drop-file-preview__item__info"
                              >
                                <p>{inputs.applicationImg[index]?.url.split("/").at(-1).replace(/[0-9]/g, "")}</p>
                                {/* <p>{item[0].size}B</p> */}
                              </div>

                              <div id="delete-appImg">
                                <IconButton
                                  sx={{
                                    color: "#C9302C",
                                    position: "absolute",

                                    top: "-35%",
                                    left: "85%",
                                  }}
                                  size="large"
                                  aria-label="delete-appImg"
                                  onClick={() => applicationFormRemove(item)}
                                >
                                  <CancelIcon sx={{ fontSize: "30px" }} />
                                </IconButton>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className="voice_record"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {inputs.supervisorVoice.length > 0 &&
                      inputs.supervisorVoice.map((voice, index) => (
                        <div>
                          <audio
                            controls
                            ref={(el) => {
                              audioRef.current[index] = el;
                            }}
                            onTimeUpdate={() => changeTimelinePosition(index)}
                            onEnded={() => audioEnded(index)}
                            src={voice.url}
                            hidden
                          ></audio>

                          <div className="controls">
                            <button
                              type="button"
                              className="player-button"
                              ref={(el) => {
                                playerButton.current[index] = el;
                              }}
                              onClick={() => toggleAudio(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="#1553b5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <input
                              type="range"
                              ref={(el) => {
                                timeline.current[index] = el;
                              }}
                              className="timeline"
                              max="100"
                              value="0"
                              onChange={() => changeSeek(index)}
                            />
                            <button
                              type="button"
                              className="sound-button"
                              ref={(el) => {
                                soundButton.current[index] = el;
                              }}
                              onClick={() => toggleSound(index)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="#1553b5"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </Box>

                <Dialog
                  sx={{
                    "& .css-twia2z-MuiPaper-root-MuiDialog-paper ": {
                      borderRadius: "30px",
                      backgroundColor: "#0E3F63",
                    },
                  }}
                  open={openFormUploadView}
                  TransitionComponent={Transition}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  fullWidth
                  maxWidth="xs" //You can change md to sm, xs, lg and xl as per your requirement.
                >
                  <FormUpload onFileChange={(files) => onFileChange(files)} />
                </Dialog>
                <Dialog
                  sx={{
                    "& .css-twia2z-MuiPaper-root-MuiDialog-paper ": {
                      borderRadius: "30px",
                      backgroundColor: "#0E3F63",
                    },
                  }}
                  open={openRecordVoiceView}
                  TransitionComponent={Transition}
                  onClose={handleClose}
                  scroll="paper"
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  fullWidth
                  maxWidth="xs" //You can change md to sm, xs, lg and xl as per your requirement.
                >
                  <VoiceRecord
                    onVoiceUpload={(voice) => setInputVoice(voice)}
                    onUpload={(bool) => onUpload(bool)}
                  />
                </Dialog>
              </Box>}

              {/* Document type selection section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  minWidth: toAddNewDocument ? 700 : 400,
                  width: "100%",
                }}
              >
                {inputs.VerificationType === "profile and document" ? (
                  <>
                    {/* profile */}
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FormControl sx={{ m: 1, width: "90%" }}>
                        <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
                          Profile
                        </FormHelperText>
                        <div style={{ display: "flex" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="profile-select"
                            value={selectedProfileName}
                            onChange={handleSelectProfileChange}
                            input={<OutlinedInput label="Tag" />}
                            MenuProps={MenuProps}
                            sx={{
                              "& .MuiInputBase-input": {
                                color: "#000",
                                border: "1px solid #000",
                              },
                              border: "1px solid #000",
                              width: "100%",
                            }}
                          >
                            {documentType.map((name) => {
                              if (name.category === "profile")
                                return (
                                  <MenuItem
                                    key={name.documentTypeCode}
                                    value={name.documentTypeName}
                                  >
                                    {name.documentTypeName}
                                  </MenuItem>
                                );
                            })}
                          </Select>
                          <ColorButton
                            sx={{
                              color: "#fff",
                              borderRadius: "10px",
                              fontSize: "16px",
                              width: "20%",
                              marginLeft: "5px",
                              marginRight: "0px",
                            }}
                            onClick={handleProfileDocAdd}
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                          >
                            Add
                          </ColorButton>
                        </div>
                      </FormControl>

                      {typeof selectedProfileDocType !== "undefined" &&
                        selectedProfileDocType.length > 0 && (
                          <List
                            sx={{
                              width: "90%",
                              bgcolor: "#c9c7c7",
                              borderRadius: "10px",
                              padding: "0px",
                            }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                          >
                            {selectedProfileDocType.map((each, index) => (
                              <div
                                key={index}
                                style={{
                                  borderBottom: "5px solid #f4f4f4",
                                  paddingBottom: "40px",
                                  paddingTop: "10px",
                                  borderBottomLeftRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              >
                                <ListItemButton
                                  sx={{
                                    margin: "15px auto",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  <ListItemText
                                    primary={each.documentTypeName}
                                  />

                                  <label
                                    htmlFor={
                                      "contained-button-profile-" + index
                                    }
                                  >
                                    <Input
                                      accept="application/pdf, image/*"
                                      id={"contained-button-profile-" + index}
                                      type="file"
                                      multiple
                                      onChange={async (s) => {
                                        var applicationForm = new FormData();
                                        applicationForm.append("s3path", "document_type");
                                        const files = s.target.files;

                                        if (files.length > 20) {
                                          swal({
                                            title: `Cannot upload more than 20 files at a time`,
                                            icon: "error",
                                          })

                                          return;
                                        }

                                        for (let i = 0; i < files.length; i++) {
                                          applicationForm.append('file', files[i]);
                                        }
                                        try {
                                          const response = await axios({
                                            method: "post",
                                            url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                                            headers: {
                                              Authorization: `Bearer ${token}`,
                                              "Content-Type": "multipart/form-data",
                                            },
                                            data: applicationForm,
                                          });
                                          if (response.data.s3Url) {
                                            let updatedProfileDocImg =
                                              each.docImg
                                                ? [
                                                  ...each.docImg,
                                                  ...response.data.s3Url.map(url => ({ url })),
                                                ]
                                                : [...response.data.s3Url.map(url => ({ url }))];
                                            each.docImg = updatedProfileDocImg;
                                            setSelectedProfileDocType([
                                              ...selectedProfileDocType,
                                            ]);
                                            swal({
                                              title: `File Upload Complete`,
                                              icon: "success",
                                            });
                                          }
                                        } catch (error) {
                                          swal({
                                            title: `File Upload Failed`,
                                            icon: "error",
                                          });
                                        }
                                      }}
                                      onClick={(event) => {
                                        event.target.value = "";
                                      }}
                                    />
                                    <ColorButton
                                      sx={{
                                        color: "#fff",
                                        fontSize: "13px",
                                        backgroundColor: "#0C2752",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        padding: "6px 10px",
                                        marginRight: "20px",
                                        "&:hover": {
                                          backgroundColor: "#0C2752",
                                        },
                                      }}
                                      variant="contained"
                                      component="span"
                                      startIcon={<AttachFileIcon />}
                                    >
                                      Attach
                                    </ColorButton>
                                  </label>
                                  <IconButton
                                    sx={{
                                      color: "#C9302C",
                                      position: "absolute",

                                      top: "-75%",
                                      left: "93%",
                                    }}
                                    size="large"
                                    aria-label="delete"
                                    onClick={() =>
                                      handleSelectedProfileDocDelete(index)
                                    }
                                  >
                                    <CancelIcon sx={{ fontSize: "30px" }} />
                                  </IconButton>
                                </ListItemButton>
                                {each.docImg?.length > 0 &&
                                  each.docImg.map((img, index) => (
                                    <div
                                      id="addDoc"
                                      style={{
                                        dislay: "flex ",
                                        justifyContent: "center",
                                        position: "relative",
                                        paddingRight: "50px",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div style={{ cursor: "pointer" }}>
                                        <ChildModal url={img.url} />
                                      </div>
                                      <div>
                                        <span
                                          onClick={() => {
                                            each.docImg.splice(index, 1);
                                            setSelectedProfileDocType([
                                              ...selectedProfileDocType,
                                            ]);
                                          }}
                                          style={{
                                            color: "#F44336",
                                            width: "14px",
                                            height: "26px",
                                            fontWeight: "900",
                                            borderRadius: "50%",
                                            paddingLeft: "10px",
                                            paddingBottom: "0",
                                            transform: "translateY(-50%)",
                                            boxShadow:
                                              "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                            cursor: "pointer",
                                            opacity: "1",
                                            transition: "opacity 0.3s ease",
                                          }}
                                        >
                                          x
                                        </span>
                                      </div>
                                    </div>
                                  ))}

                                <List
                                  component="div"
                                  disablePadding
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    gap: "15px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {each.fields?.map((e) => {
                                    if (e.FILLED_BY === "sampler") {
                                      return e.TYPE === "select" ? (
                                        <>
                                          <CssTextField
                                            required={e.REQUIRED}
                                            fullWidth
                                            select
                                            type={e.TYPE}
                                            id={`outlined-${each.id}-${index}`}
                                            label={e.DISPLAY_NAME}
                                            name={e.FIELD}
                                            value={e.VALUE}
                                            onChange={(s) => {
                                              e.VALUE = s.target.value;
                                              setSelectedProfileDocType([
                                                ...selectedProfileDocType,
                                              ]);
                                            }}
                                            sx={{ textAlign: "left" }}
                                            key={e.DISPLAY_NAME}
                                          >
                                            {e.VALUES_LIST.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.value}
                                              </MenuItem>
                                            ))}
                                          </CssTextField>
                                          {e.VALUE.toLowerCase() === "other" && (
                                            <CssTextField
                                              focused
                                              multiline
                                              required
                                              id="outlined-fileNo"
                                              label={`Other Value (${e.DISPLAY_NAME})`}
                                              name="otherValue"
                                              value={e.OTHER_VALUE || ""}
                                              onChange={(s) => {
                                                e.OTHER_VALUE = s.target.value;
                                                setSelectedProfileDocType([
                                                  ...selectedProfileDocType,
                                                ]);
                                              }}
                                              sx={{
                                                marginLeft: "30px",
                                                width: "85%",
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : e.TYPE === "date" ? (
                                        <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                                          <DatePicker
                                            value={e.VALUE}
                                            label={e.DISPLAY_NAME}
                                            inputFormat="DD-MM-YYYY"
                                            InputProps={{ name: e.FIELD }}
                                            renderInput={(params) => (
                                              <CssTextField
                                                focused 
                                                fullWidth
                                                {...params}
                                                required={e.REQUIRED}
                                              />
                                            )}
                                            onChange={(s) => {
                                              e.VALUE = s;
                                              setSelectedProfileDocType([
                                                ...selectedProfileDocType,
                                              ]);
                                            }}
                                          />
                                        </LocalizationProvider>
                                      ) : (
                                        <CssTextField
                                          required={e.REQUIRED}
                                          multiline={
                                            e.TYPE !== "date" &&
                                            e.TYPE !== "time" &&
                                            e.TYPE !== "month" &&
                                            e.TYPE !== "number" &&
                                            e.TYPE !== "text"
                                          }
                                          focused
                                          fullWidth
                                          type={e.TYPE}
                                          id={`outlined-${each.id}-${index}`}
                                          inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                                          label={e.DISPLAY_NAME}
                                          name={e.FIELD}
                                          value={e.VALUE}
                                          onChange={(s) => {
                                            e.VALUE = s.target.value;
                                            setSelectedProfileDocType([
                                              ...selectedProfileDocType,
                                            ]);
                                          }}
                                          onInput={(num) => {
                                            // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
                                            e.TYPE === "number" &&
                                              (num.target.value = Math.floor(
                                                Math.max(
                                                  0,
                                                  parseInt(num.target.value)
                                                )
                                                  .toString()
                                                  .slice(0, e.MAX_CHAR)
                                              ));
                                              e.TYPE ==="text_caps" &&
                                              (num.target.value=num.target.value.toLocaleUpperCase())
                                          }}
                                        />
                                      );
                                    }
                                  })}
                                </List>
                              </div>
                            ))}
                          </List>
                        )}
                    </div>
                    {/* Document */}
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <FormControl sx={{ m: 1, width: "90%" }}>
                        <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
                          Document
                        </FormHelperText>
                        <div style={{ display: "flex" }}>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="document-select"
                            value={selectedDocName}
                            onChange={handleSelectDocChange}
                            input={<OutlinedInput label="Tag" />}
                            MenuProps={MenuProps}
                            sx={{
                              "& .MuiInputBase-input": {
                                color: "#000",
                                border: "1px solid #000",
                              },
                              border: "1px solid #000",
                              width: "100%",
                            }}
                          >
                            {documentType.map((name) => {
                              if (name.category === "document")
                                return (
                                  <MenuItem
                                    key={name.documentTypeCode}
                                    value={name.documentTypeName}
                                  >
                                    {name.documentTypeName}
                                  </MenuItem>
                                );
                            })}
                          </Select>

                          <ColorButton
                            sx={{
                              color: "#fff",
                              borderRadius: "10px",
                              fontSize: "16px",
                              width: "20%",
                              marginLeft: "5px",
                              marginRight: "0px",
                            }}
                            onClick={handleDocumentDocAdd}
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                          >
                            Add
                          </ColorButton>
                        </div>
                      </FormControl>
                      {typeof selectedDocumentDocType !== "undefined" &&
                        selectedDocumentDocType.length > 0 && (
                          <List
                            sx={{
                              width: "90%",
                              bgcolor: "#c9c7c7",
                              borderRadius: "10px",
                              padding: "0px",
                            }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                          >
                            {selectedDocumentDocType.map((each, index) => (
                              <div
                                key={index}
                                style={{
                                  borderBottom: "5px solid #f4f4f4",
                                  paddingBottom: "40px",
                                  paddingTop: "10px",
                                  borderBottomLeftRadius: "10px",
                                  borderBottomRightRadius: "10px",
                                }}
                              >
                                <ListItemButton
                                  sx={{
                                    margin: "15px auto",
                                    textTransform: "uppercase",
                                  }}
                                >
                                  <ListItemText
                                    primary={each.documentTypeName}
                                  />
                                  <label
                                    htmlFor={"contained-button-doc-" + index}
                                  >
                                    <Input
                                      accept="application/pdf, image/*"
                                      id={"contained-button-doc-" + index}
                                      type="file"
                                      multiple
                                      onChange={async (s) => {
                                        var applicationForm = new FormData();
                                        applicationForm.append("s3path", "document_type");
                                        const files = s.target.files;

                                        if (files.length > 20) {
                                          swal({
                                            title: `Cannot upload more than 20 files at a time`,
                                            icon: "error",
                                          })

                                          return;
                                        }

                                        for (let i = 0; i < files.length; i++) {
                                          applicationForm.append('file', files[i]);
                                        }
                                        try {
                                          const response = await axios({
                                            method: "post",
                                            url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                                            headers: {
                                              Authorization: `Bearer ${token}`,
                                              "Content-Type": "multipart/form-data",
                                            },
                                            data: applicationForm,
                                          });
                                          if (response.data.s3Url) {
                                            let updatedDocImg = each.docImg
                                              ? [
                                                ...each.docImg,
                                                ...response.data.s3Url.map(url => ({ url })),
                                              ]
                                              : [...response.data.s3Url.map(url => ({ url }))];
                                            each.docImg = updatedDocImg;
                                            setSelectedDocumentDocType([
                                              ...selectedDocumentDocType,
                                            ]);
                                            swal({
                                              title: `File Upload Complete`,
                                              icon: "success",
                                            });
                                          }
                                        } catch (error) {
                                          swal({
                                            title: `File Upload Failed`,
                                            icon: "error",
                                          });
                                        }
                                      }}
                                      onClick={(event) => {
                                        event.target.value = "";
                                      }}
                                    />
                                    <ColorButton
                                      sx={{
                                        color: "#fff",
                                        fontSize: "13px",
                                        backgroundColor: "#0C2752",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        padding: "6px 10px",
                                        "&:hover": {
                                          backgroundColor: "#0C2752",
                                        },
                                      }}
                                      variant="contained"
                                      component="span"
                                      startIcon={<AttachFileIcon />}
                                    >
                                      Attach
                                    </ColorButton>
                                  </label>
                                  <IconButton
                                    sx={{
                                      color: "#C9302C",
                                      position: "absolute",

                                      top: "-75%",
                                      left: "93%",
                                    }}
                                    size="large"
                                    aria-label="delete"
                                    onClick={() =>
                                      handleSelectedDocumentDocDelete(index)
                                    }
                                  >
                                    <CancelIcon sx={{ fontSize: "30px" }} />
                                  </IconButton>
                                </ListItemButton>
                                {each.docImg?.length > 0 &&
                                  each.docImg.map((img, index) => (
                                    <div
                                      id="addDoc"
                                      style={{
                                        dislay: "flex ",
                                        justifyContent: "center",
                                        position: "relative",
                                        paddingRight: "50px",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div style={{ cursor: "pointer" }}>
                                        <ChildModal url={img.url} />
                                      </div>
                                      <div>
                                        <span
                                          onClick={() => {
                                            each.docImg.splice(index, 1);
                                            setSelectedProfileDocType([
                                              ...selectedProfileDocType,
                                            ]);
                                          }}
                                          style={{
                                            color: "#F44336",
                                            width: "14px",
                                            height: "26px",
                                            fontWeight: "900",
                                            borderRadius: "50%",
                                            paddingLeft: "10px",
                                            transform: "translateY(-50%)",
                                            boxShadow:
                                              "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                            cursor: "pointer",
                                            opacity: "1",
                                            transition: "opacity 0.3s ease",
                                          }}
                                        >
                                          x
                                        </span>
                                      </div>
                                    </div>
                                  ))}

                                <List
                                  component="div"
                                  disablePadding
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    gap: "15px",
                                    paddingLeft: "20px",
                                    paddingRight: "20px",
                                  }}
                                >
                                  {each.fields?.map((e) => {
                                    if (e.FILLED_BY === "sampler") {
                                      return e.TYPE === "select" ? (
                                        <>
                                          <CssTextField
                                            required={e.REQUIRED}
                                            fullWidth
                                            select
                                            type={e.TYPE}
                                            id={`outlined-${each.id}-${index}`}
                                            label={e.DISPLAY_NAME}
                                            name={e.FIELD}
                                            value={e.VALUE}
                                            onChange={(s) => {
                                              e.VALUE = s.target.value;
                                              setSelectedDocumentDocType([
                                                ...selectedDocumentDocType,
                                              ]);
                                            }}
                                            sx={{ textAlign: "left" }}
                                            key={e.DISPLAY_NAME}
                                          >
                                            {e.VALUES_LIST.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.value}
                                              </MenuItem>
                                            ))}
                                          </CssTextField>
                                          {e.VALUE.toLowerCase() === "other" && (
                                            <CssTextField
                                              focused
                                              multiline
                                              required
                                              id="outlined-fileNo"
                                              label={`Other Value (${e.DISPLAY_NAME})`}
                                              name="otherValue"
                                              value={e.OTHER_VALUE || ""}
                                              onChange={(s) => {
                                                e.OTHER_VALUE = s.target.value;
                                                setSelectedDocumentDocType([
                                                  ...selectedDocumentDocType,
                                                ]);
                                              }}
                                              sx={{
                                                marginLeft: "30px",
                                                width: "85%",
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : (e.FIELD === 'number' && each?.panVerificationRequired) ? (
                                        <CssTextField
                                          fullWidth
                                          focused
                                          sx={{
                                            flexGrow: 1
                                          }}
                                          type={e.TYPE}
                                          id={`outlined-${each.id}-${index}`}
                                          inputProps={{ maxLength: e.MAX_CHAR }}
                                          label={e.DISPLAY_NAME}
                                          name={e.FIELD}
                                          value={e.VALUE}
                                          onChange={(event) => handlePANChange(event, e, index)}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                {each?._temp?.isPanValid ? (
                                                  <LoadingColorButton
                                                    loading={panVerLoading}
                                                    size="large"
                                                    startIcon={
                                                      each?._temp?.isPanAuthentic === undefined ?
                                                        <CloudSyncIcon sx={{ color: 'white !important' }} /> :
                                                        (each?._temp?.isPanAuthentic ?
                                                          <CheckCircleOutlinedIcon sx={{ color: 'white !important' }} /> : <CancelOutlinedIcon sx={{ color: 'white !important' }} />)
                                                    }
                                                    loadingPosition="start"
                                                    onClick={() => handleVerifyPAN(index)}
                                                    sx={{
                                                      width: 150,
                                                      margin: '5px 0',
                                                      color: "#fff",
                                                      fontSize: "13px",
                                                      padding: "6px 10px",
                                                      backgroundColor: each?._temp?.isPanAuthentic === undefined ? 'primary.main' :
                                                        (each?._temp?.isPanAuthentic ? 'success.light' : 'error.light'),
                                                      '&:hover': {
                                                        backgroundColor: each?._temp?.isPanAuthentic === undefined ? 'primary.main' :
                                                          (each?._temp?.isPanAuthentic ? 'success.light' : 'error.light'),
                                                      }
                                                    }}
                                                    variant="contained"
                                                  >
                                                    {each?._temp?.isPanAuthentic === undefined ?
                                                      panVerLoading ? 'Verifying Pan' : 'Verify Pan' :
                                                      each?._temp?.isPanAuthentic ? 'Pan Verified' : 'Invalid Pan'}
                                                  </LoadingColorButton>
                                                ) : (
                                                  <LoadingButton
                                                    disableRipple
                                                    variant="outlined"
                                                    color="error"
                                                    startIcon={
                                                      <CancelOutlinedIcon
                                                        sx={{
                                                          color: '#d32f2f !important'
                                                        }}
                                                      />
                                                    }
                                                    sx={{
                                                      width: 150,
                                                      visibility: '_temp' in each ? "visible" : "hidden",
                                                      margin: '5px 0',
                                                      fontSize: "13px",
                                                      padding: "6px 10px",
                                                      borderWidth: 2,
                                                      "&:hover": {
                                                        borderWidth: 2
                                                      },
                                                    }}
                                                  >
                                                    Invalid PAN
                                                  </LoadingButton>
                                                )}
                                              </InputAdornment>
                                            )
                                          }}
                                        />
                                      ) : e.TYPE === "date" ? (
                                        <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                                          <DatePicker
                                            value={e.VALUE}
                                            label={e.DISPLAY_NAME}
                                            inputFormat="DD-MM-YYYY"
                                            InputProps={{ name: e.FIELD }}
                                            renderInput={(params) => (
                                              <CssTextField
                                                focused 
                                                fullWidth
                                                {...params}
                                                required={e.REQUIRED}
                                              />
                                            )}
                                            onChange={(s) => {
                                              e.VALUE = s;
                                              setSelectedDocumentDocType([
                                                ...selectedDocumentDocType,
                                              ]);
                                            }}
                                          />
                                        </LocalizationProvider>
                                      ) : (
                                        <CssTextField
                                          required={e.REQUIRED}
                                          multiline={
                                            e.TYPE !== "date" &&
                                            e.TYPE !== "time" &&
                                            e.TYPE !== "month" &&
                                            e.TYPE !== "number" &&
                                            e.TYPE !== "text"
                                          }
                                          focused
                                          fullWidth
                                          type={e.TYPE}
                                          id={`outlined-${each.id}-${index}`}
                                          inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                                          onInput={(num) => {
                                            // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit

                                            e.TYPE === "number" &&
                                              (num.target.value = Math.floor(
                                                Math.max(
                                                  0,
                                                  parseInt(num.target.value)
                                                )
                                                  .toString()
                                                  .slice(0, e.MAX_CHAR || 12)
                                              ));
                                              e.TYPE ==="text_caps" &&
                                              (num.target.value=num.target.value.toLocaleUpperCase())
                                          }}
                                          label={e.DISPLAY_NAME}
                                          name={e.FIELD}
                                          value={e.VALUE}
                                          onChange={(s) => {
                                            e.VALUE = s.target.value;
                                            setSelectedDocumentDocType([
                                              ...selectedDocumentDocType,
                                            ]);
                                          }}
                                        />
                                      );
                                    }
                                  })}

                                  {each?.panVerificationRequired &&
                                    <>
                                      <Typography fontSize={14} fontWeight='bold'>Filled by Verifier</Typography>
                                      {each.fields?.map(e => {
                                        if (e.FILLED_BY === "verifier") {
                                          if (e.FIELD === "status")
                                            return

                                          return e.TYPE === "select" ? (
                                            <>
                                              <CssTextField
                                                required={e.REQUIRED}
                                                fullWidth
                                                select
                                                type={e.TYPE}
                                                id={`outlined-${each.id}-${index}`}
                                                label={e.DISPLAY_NAME}
                                                name={e.FIELD}
                                                value={e.VALUE}
                                                onInput={(num) => {
                                                  // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
  
                                                  e.TYPE ==="text_caps" &&
                                                  (num.target.value=num.target.value.toLocaleUpperCase()) 
                                                }}
                                                onChange={(s) => {
                                                  e.VALUE = s.target.value;
                                                  setSelectedDocumentDocType([
                                                    ...selectedDocumentDocType,
                                                  ]);
                                                }}
                                                sx={{ textAlign: "left" }}
                                                key={e.DISPLAY_NAME}
                                              >
                                                {e.VALUES_LIST.map((option) => (
                                                  <MenuItem
                                                    key={option.value}
                                                    value={option.value}
                                                  >
                                                    {option.value}
                                                  </MenuItem>
                                                ))}
                                              </CssTextField>
                                              {e.VALUE.toLowerCase() === "other" && (
                                                <CssTextField
                                                  focused
                                                  multiline
                                                  required
                                                  id="outlined-fileNo"
                                                  label={`Other Value (${e.DISPLAY_NAME})`}
                                                  name="otherValue"
                                                  value={e.OTHER_VALUE || ""}
                                                  onChange={(s) => {
                                                    e.OTHER_VALUE = s.target.value;
                                                    setSelectedDocumentDocType([
                                                      ...selectedDocumentDocType,
                                                    ]);
                                                  }}
                                                  sx={{
                                                    marginLeft: "30px",
                                                    width: "85%",
                                                  }}
                                                />
                                              )}
                                            </>
                                          ) : (
                                            <CssTextField
                                              required={e.REQUIRED}
                                              multiline={
                                                e.TYPE !== "date" &&
                                                e.TYPE !== "time" &&
                                                e.TYPE !== "month" &&
                                                e.TYPE !== "number" &&
                                                e.TYPE !== "text"
                                              }
                                              focused
                                              fullWidth
                                              type={e.TYPE}
                                              id={`outlined-${each.id}-${index}`}
                                              inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                                             
                                              onInput={(num) => {
                                                // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit

                                                e.TYPE === "number" &&
                                                  (num.target.value = Math.floor(
                                                    Math.max(
                                                      0,
                                                      parseInt(num.target.value)
                                                    )
                                                      .toString()
                                                      .slice(0, e.MAX_CHAR || 12)
                                                  ));
                                                  e.TYPE ==="text_caps" &&
                                                  (num.target.value=num.target.value.toLocaleUpperCase()) 
                                              }}
                                              label={e.DISPLAY_NAME}
                                              name={e.FIELD}
                                              value={e.VALUE}
                                              onChange={(s) => {
                                                e.VALUE = s.target.value;
                                                setSelectedDocumentDocType([
                                                  ...selectedDocumentDocType,
                                                ]);
                                              }}
                                            />
                                          );
                                        }
                                      })}
                                    </>}
                                </List>
                              </div>
                            ))}
                          </List>
                        )}
                    </div>
                  </>
                ) : inputs.VerificationType === "profile" ? (
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <FormControl sx={{ m: 1, width: "90%" }}>
                      <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
                        Profile
                      </FormHelperText>
                      <div style={{ display: "flex" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="profile-select"
                          value={selectedProfileName}
                          onChange={handleSelectProfileChange}
                          input={<OutlinedInput label="Tag" />}
                          MenuProps={MenuProps}
                          sx={{
                            "& .MuiInputBase-input": {
                              color: "#000",
                              border: "1px solid #000",
                            },
                            border: "1px solid #000",
                            width: "100%",
                          }}
                        >
                          {documentType.map((name) => {
                            if (name.category === "profile")
                              return (
                                <MenuItem
                                  key={name.documentTypeCode}
                                  value={name.documentTypeName}
                                >
                                  {name.documentTypeName}
                                </MenuItem>
                              );
                          })}
                        </Select>
                        <ColorButton
                          sx={{
                            color: "#fff",
                            borderRadius: "10px",
                            fontSize: "16px",
                            width: "20%",
                            marginLeft: "5px",
                            marginRight: "0px",
                          }}
                          onClick={handleProfileDocAdd}
                          variant="contained"
                          startIcon={<AddCircleIcon />}
                        >
                          Add
                        </ColorButton>
                      </div>
                    </FormControl>

                    {typeof selectedProfileDocType !== "undefined" &&
                      selectedProfileDocType.length > 0 && (
                        <List
                          sx={{
                            width: "90%",
                            bgcolor: "#c9c7c7",
                            borderRadius: "10px",
                            padding: "0px",
                          }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                        >
                          {selectedProfileDocType.map((each, index) => (
                            <div
                              key={index}
                              style={{
                                borderBottom: "5px solid #f4f4f4",
                                paddingBottom: "40px",
                                paddingTop: "10px",
                                borderBottomLeftRadius: "10px",
                                borderBottomRightRadius: "10px",
                              }}
                            >
                              <ListItemButton
                                sx={{
                                  margin: "15px auto",
                                  textTransform: "uppercase",
                                }}
                              >
                                <ListItemText primary={each.documentTypeName} />

                                <label
                                  htmlFor={"contained-button-profile-" + index}
                                >
                                  <Input
                                    accept="application/pdf, image/*"
                                    id={"contained-button-profile-" + index}
                                    type="file"
                                    multiple
                                    onChange={async (s) => {
                                      var applicationForm = new FormData();
                                      applicationForm.append("s3path", "document_type");
                                      const files = s.target.files;

                                      if (files.length > 20) {
                                        swal({
                                          title: `Cannot upload more than 20 files at a time`,
                                          icon: "error",
                                        })

                                        return;
                                      }

                                      for (let i = 0; i < files.length; i++) {
                                        applicationForm.append('file', files[i]);
                                      }
                                      try {
                                        const response = await axios({
                                          method: "post",
                                          url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                                          headers: {
                                            Authorization: `Bearer ${token}`,
                                            "Content-Type": "multipart/form-data",
                                          },
                                          data: applicationForm,
                                        });
                                        if (response.data.s3Url) {
                                          let updatedProfileDocImg = each.docImg
                                            ? [
                                              ...each.docImg,
                                              ...response.data.s3Url.map(url => ({ url })),
                                            ]
                                            : [...response.data.s3Url.map(url => ({ url }))];
                                          each.docImg = updatedProfileDocImg;
                                          setSelectedProfileDocType([
                                            ...selectedProfileDocType,
                                          ]);
                                          swal({
                                            title: `File Upload Complete`,
                                            icon: "success",
                                          });
                                        }
                                      } catch (error) {
                                        swal({
                                          title: `File Upload Failed`,
                                          icon: "error",
                                        });
                                      }
                                    }}
                                    onClick={(event) => {
                                      event.target.value = "";
                                    }}
                                  />
                                  <ColorButton
                                    sx={{
                                      color: "#fff",
                                      fontSize: "13px",
                                      backgroundColor: "#0C2752",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "6px 10px",
                                      marginRight: "20px",
                                      "&:hover": {
                                        backgroundColor: "#0C2752",
                                      },
                                    }}
                                    variant="contained"
                                    component="span"
                                    startIcon={<AttachFileIcon />}
                                  >
                                    Attach
                                  </ColorButton>
                                </label>
                                <IconButton
                                  sx={{
                                    color: "#C9302C",
                                    position: "absolute",

                                    top: "-75%",
                                    left: "93%",
                                  }}
                                  size="large"
                                  aria-label="delete"
                                  onClick={() =>
                                    handleSelectedProfileDocDelete(index)
                                  }
                                >
                                  <CancelIcon sx={{ fontSize: "30px" }} />
                                </IconButton>
                              </ListItemButton>
                              {each.docImg?.length > 0 &&
                                each.docImg.map((img, index) => (
                                  <div
                                    id="addDoc"
                                    style={{
                                      dislay: "flex ",
                                      justifyContent: "center",
                                      position: "relative",
                                      paddingRight: "50px",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div style={{ cursor: "pointer" }}>
                                      <ChildModal url={img.url} />
                                    </div>
                                    <div>
                                      <span
                                        onClick={() => {
                                          each.docImg.splice(index, 1);
                                          setSelectedProfileDocType([
                                            ...selectedProfileDocType,
                                          ]);
                                        }}
                                        style={{
                                          color: "#F44336",
                                          width: "14px",
                                          height: "26px",
                                          fontWeight: "900",
                                          borderRadius: "50%",
                                          paddingLeft: "10px",
                                          paddingBottom: "0",
                                          transform: "translateY(-50%)",
                                          boxShadow:
                                            "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                          cursor: "pointer",
                                          opacity: "1",
                                          transition: "opacity 0.3s ease",
                                        }}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                ))}

                              <List
                                component="div"
                                disablePadding
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                  gap: "15px",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                {each.fields?.map((e) => {
                                  if (e.FILLED_BY === "sampler") {
                                    return e.TYPE === "select" ? (
                                      <>
                                        <CssTextField
                                          required={e.REQUIRED}
                                          fullWidth
                                          select
                                          type={e.TYPE}
                                          id={`outlined-${each.id}-${index}`}
                                          label={e.DISPLAY_NAME}
                                          name={e.FIELD}
                                          value={e.VALUE}
                                          onChange={(s) => {
                                            e.VALUE = s.target.value;
                                            setSelectedProfileDocType([
                                              ...selectedProfileDocType,
                                            ]);
                                          }}
                                          sx={{ textAlign: "left" }}
                                          key={e.DISPLAY_NAME}
                                        >
                                          {e.VALUES_LIST.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </CssTextField>
                                        {e.VALUE.toLowerCase() === "other" && (
                                          <CssTextField
                                            focused
                                            multiline
                                            required
                                            id="outlined-fileNo"
                                            label={`Other Value (${e.DISPLAY_NAME})`}
                                            name="otherValue"
                                            value={e.OTHER_VALUE || ""}
                                            onChange={(s) => {
                                              e.OTHER_VALUE = s.target.value;
                                              setSelectedProfileDocType([
                                                ...selectedProfileDocType,
                                              ]);
                                            }}
                                            sx={{
                                              marginLeft: "30px",
                                              width: "85%",
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : e.TYPE === "date" ? (
                                      <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          value={e.VALUE}
                                          label={e.DISPLAY_NAME}
                                          inputFormat="DD-MM-YYYY"
                                          InputProps={{ name: e.FIELD }}
                                          renderInput={(params) => (
                                            <CssTextField
                                              focused 
                                              fullWidth
                                              {...params}
                                              required={e.REQUIRED}
                                            />
                                          )}
                                          onChange={(s) => {
                                            e.VALUE = s;
                                            setSelectedProfileDocType([
                                              ...selectedProfileDocType,
                                            ]);
                                          }}
                                        />
                                      </LocalizationProvider>
                                    ) : (
                                      <CssTextField
                                        required={e.REQUIRED}
                                        multiline={
                                          e.TYPE !== "date" &&
                                          e.TYPE !== "time" &&
                                          e.TYPE !== "month" &&
                                          e.TYPE !== "number" &&
                                          e.TYPE !== "text"
                                        }//
                                        focused
                                        fullWidth
                                        type={e.TYPE}
                                  
                                        id={`outlined-${each.id}-${index}`}
                                        inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                                        label={e.DISPLAY_NAME}
                                        name={e.TYPE}
                                        value={e.VALUE}
                                      // {...e.TYPE=="text_caps"?(""):("")}
                                        onChange={(s) => {
                                         e.VALUE = s.target.value
                                         if(e.TYPE==="text_caps"){
                                          e.VALUE = s.target.value.toLocaleUpperCase();
                                         }
                                        setSelectedProfileDocType([
                                           ...selectedProfileDocType,
                                         ])
                                          
                                         
                                        }}
                                      
                                      
                                        onInput={(num) => {
                                          // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
                                          e.TYPE === "number" &&
                                            (num.target.value = Math.floor(
                                              Math.max(
                                                0,
                                                parseInt(num.target.value)
                                              )
                                                .toString()
                                                .slice(0, e.MAX_CHAR)
                                            ));
                                        }}
                                      />
                                    );
                                  }
                                })}
                              </List>
                            </div>
                          ))}
                        </List>
                      )}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <FormControl sx={{ m: 1, width: "90%" }}>
                      <FormHelperText sx={{ fontSize: "1em", color: "#000" }}>
                        Document
                      </FormHelperText>
                      <div style={{ display: "flex" }}>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="document-select"
                          value={selectedDocName}
                          onChange={handleSelectDocChange}
                          input={<OutlinedInput label="Tag" />}
                          MenuProps={MenuProps}
                          sx={{
                            "& .MuiInputBase-input": {
                              color: "#000",
                              border: "1px solid #000",
                            },
                            border: "1px solid #000",
                            width: "100%",
                          }}
                        >
                          {documentType.map((name) => {
                            if (name.category === "document")
                              return (
                                <MenuItem
                                  key={name.documentTypeCode}
                                  value={name.documentTypeName}
                                >
                                  {name.documentTypeName}
                                </MenuItem>
                              );
                          })}
                        </Select>

                        <ColorButton
                          sx={{
                            color: "#fff",
                            borderRadius: "10px",
                            fontSize: "16px",
                            width: "20%",
                            marginLeft: "5px",
                            marginRight: "0px",
                          }}
                          onClick={handleDocumentDocAdd}
                          variant="contained"
                          startIcon={<AddCircleIcon />}
                        >
                          Add
                        </ColorButton>
                      </div>
                    </FormControl>
                    {typeof selectedDocumentDocType !== "undefined" &&
                      selectedDocumentDocType.length > 0 && (
                        <List
                          sx={{
                            width: "90%",
                            bgcolor: "#c9c7c7",
                            borderRadius: "10px",
                            padding: "0px",
                          }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                        >
                          {selectedDocumentDocType.map((each, index) => (
                            <div
                              key={index}
                              style={{
                                borderBottom: "5px solid #f4f4f4",
                                paddingBottom: "40px",
                                paddingTop: "10px",
                                borderBottomLeftRadius: "10px",
                                borderBottomRightRadius: "10px",
                              }}
                            >
                              <ListItemButton
                                sx={{
                                  margin: "15px auto",
                                  textTransform: "uppercase",
                                }}
                              >
                                <ListItemText primary={each.documentTypeName} />
                                <label
                                  htmlFor={"contained-button-doc-" + index}
                                >
                                  <Input
                                    accept="application/pdf, image/*"
                                    id={"contained-button-doc-" + index}
                                    type="file"
                                    multiple
                                    onChange={async (s) => {
                                      var applicationForm = new FormData();
                                      applicationForm.append("s3path", "document_type");
                                      const files = s.target.files;

                                      if (files.length > 20) {
                                        swal({
                                          title: `Cannot upload more than 20 files at a time`,
                                          icon: "error",
                                        })

                                        return;
                                      }

                                      for (let i = 0; i < files.length; i++) {
                                        applicationForm.append('file', files[i]);
                                      }
                                      try {
                                        const response = await axios({
                                          method: "post",
                                          url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                                          headers: {
                                            Authorization: `Bearer ${token}`,
                                            "Content-Type": "multipart/form-data",
                                          },
                                          data: applicationForm,
                                        });
                                        if (response.data.s3Url) {
                                          let updatedDocImg = each.docImg
                                            ? [
                                              ...each.docImg,
                                              ...response.data.s3Url.map(url => ({ url })),
                                            ]
                                            : [...response.data.s3Url.map(url => ({ url }))];
                                          each.docImg = updatedDocImg;
                                          setSelectedDocumentDocType([
                                            ...selectedDocumentDocType,
                                          ]);
                                          swal({
                                            title: `File Upload Complete`,
                                            icon: "success",
                                          });
                                        }
                                      } catch (error) {
                                        swal({
                                          title: `File Upload Failed`,
                                          icon: "error",
                                        });
                                      }
                                    }}
                                    onClick={(event) => {
                                      event.target.value = "";
                                    }}
                                  />
                                  <ColorButton
                                    sx={{
                                      color: "#fff",
                                      fontSize: "13px",
                                      backgroundColor: "#0C2752",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      padding: "6px 10px",
                                      "&:hover": {
                                        backgroundColor: "#0C2752",
                                      },
                                    }}
                                    variant="contained"
                                    component="span"
                                    startIcon={<AttachFileIcon />}
                                  >
                                    Attach
                                  </ColorButton>
                                </label>
                                <IconButton
                                  sx={{
                                    color: "#C9302C",
                                    position: "absolute",

                                    top: "-75%",
                                    left: "93%",
                                  }}
                                  size="large"
                                  aria-label="delete"
                                  onClick={() =>
                                    handleSelectedDocumentDocDelete(index)
                                  }
                                >
                                  <CancelIcon sx={{ fontSize: "30px" }} />
                                </IconButton>
                              </ListItemButton>
                              {each.docImg?.length > 0 &&
                                each.docImg.map((img, index) => (
                                  <div
                                    id="addDoc"
                                    style={{
                                      dislay: "flex ",
                                      justifyContent: "center",
                                      position: "relative",
                                      paddingRight: "50px",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div style={{ cursor: "pointer" }}>
                                      <ChildModal url={img.url} />
                                    </div>
                                    <div>
                                      <span
                                        onClick={() => {
                                          each.docImg.splice(index, 1);
                                          setSelectedProfileDocType([
                                            ...selectedProfileDocType,
                                          ]);
                                        }}
                                        style={{
                                          color: "#F44336",
                                          width: "14px",
                                          height: "26px",
                                          fontWeight: "900",
                                          borderRadius: "50%",
                                          paddingLeft: "10px",
                                          transform: "translateY(-50%)",
                                          boxShadow:
                                            "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                          cursor: "pointer",
                                          opacity: "1",
                                          transition: "opacity 0.3s ease",
                                        }}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                ))}

                              <List
                                component="div"
                                disablePadding
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "flex-start",
                                  flexDirection: "column",
                                  gap: "15px",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              >
                                {each.fields?.map((e) => {
                                  if (e.FILLED_BY === "sampler") {
                                    return e.TYPE === "select" ? (
                                      <>
                                        <CssTextField
                                          required={e.REQUIRED}
                                          fullWidth
                                          select
                                          type={e.TYPE}
                                          id={`outlined-${each.id}-${index}`}
                                          label={e.DISPLAY_NAME}
                                          name={e.FIELD}
                                          value={e.VALUE}
                                          onChange={(s) => {
                                            e.VALUE = s.target.value;
                                            setSelectedDocumentDocType([
                                              ...selectedDocumentDocType,
                                            ]);
                                          }}
                                          sx={{ textAlign: "left" }}
                                          key={e.DISPLAY_NAME}
                                        >
                                          {e.VALUES_LIST.map((option) => (
                                            <MenuItem
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.value}
                                            </MenuItem>
                                          ))}
                                        </CssTextField>
                                        {e.VALUE.toLowerCase() === "other" && (
                                          <CssTextField
                                            focused
                                            multiline
                                            required
                                            id="outlined-fileNo"
                                            label={`Other Value (${e.DISPLAY_NAME})`}
                                            name="otherValue"
                                            value={e.OTHER_VALUE || ""}
                                            onChange={(s) => {
                                              e.OTHER_VALUE = s.target.value;
                                              setSelectedDocumentDocType([
                                                ...selectedDocumentDocType,
                                              ]);
                                            }}
                                            sx={{
                                              marginLeft: "30px",
                                              width: "85%",
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : (e.FIELD === 'number' && each?.panVerificationRequired) ? (
                                      <CssTextField
                                        fullWidth
                                        focused
                                        sx={{
                                          flexGrow: 1
                                        }}
                                        type={e.TYPE}
                                        id={`outlined-${each.id}-${index}`}
                                        inputProps={{ maxLength: e.MAX_CHAR }}
                                        label={e.DISPLAY_NAME}
                                        name={e.FIELD}
                                        value={e.VALUE}
                                        onChange={(event) => handlePANChange(event, e, index)}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {each?._temp?.isPanValid ? (
                                                <LoadingColorButton
                                                  loading={panVerLoading}
                                                  size="large"
                                                  startIcon={
                                                    each?._temp?.isPanAuthentic === undefined ?
                                                      <CloudSyncIcon sx={{ color: 'white !important' }} /> :
                                                      (each?._temp?.isPanAuthentic ?
                                                        <CheckCircleOutlinedIcon sx={{ color: 'white !important' }} /> : <CancelOutlinedIcon sx={{ color: 'white !important' }} />)
                                                  }
                                                  loadingPosition="start"
                                                  onClick={() => handleVerifyPAN(index)}
                                                  sx={{
                                                    width: 150,
                                                    margin: '5px 0',
                                                    color: "#fff",
                                                    fontSize: "13px",
                                                    padding: "6px 10px",
                                                    backgroundColor: each?._temp?.isPanAuthentic === undefined ? 'primary.main' :
                                                      (each?._temp?.isPanAuthentic ? 'success.light' : 'error.light'),
                                                    '&:hover': {
                                                      backgroundColor: each?._temp?.isPanAuthentic === undefined ? 'primary.main' :
                                                        (each?._temp?.isPanAuthentic ? 'success.light' : 'error.light'),
                                                    }
                                                  }}
                                                  variant="contained"
                                                >
                                                  {each?._temp?.isPanAuthentic === undefined ?
                                                    panVerLoading ? 'Verifying Pan' : 'Verify Pan' :
                                                    each?._temp?.isPanAuthentic ? 'Pan Verified' : 'Invalid Pan'}
                                                </LoadingColorButton>
                                              ) : (
                                                <LoadingButton
                                                  disableRipple
                                                  variant="outlined"
                                                  color="error"
                                                  startIcon={
                                                    <CancelOutlinedIcon
                                                      sx={{
                                                        color: '#d32f2f !important'
                                                      }}
                                                    />
                                                  }
                                                  sx={{
                                                    width: 150,
                                                    visibility: '_temp' in each ? "visible" : "hidden",
                                                    margin: '5px 0',
                                                    fontSize: "13px",
                                                    padding: "6px 10px",
                                                    borderWidth: 2,
                                                    "&:hover": {
                                                      borderWidth: 2
                                                    },
                                                  }}
                                                >
                                                  Invalid PAN
                                                </LoadingButton>
                                              )}
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                    ) : e.TYPE === "date" ? (
                                      <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          value={e.VALUE}
                                          label={e.DISPLAY_NAME}
                                          inputFormat="DD-MM-YYYY"
                                          InputProps={{ name: e.FIELD }}
                                          renderInput={(params) => (
                                            <CssTextField
                                              focused 
                                              fullWidth
                                              {...params}
                                              required={e.REQUIRED}
                                            />
                                          )}
                                          onChange={(s) => {
                                            e.VALUE = s;
                                            setSelectedDocumentDocType([
                                              ...selectedDocumentDocType,
                                            ]);
                                          }}
                                        />
                                      </LocalizationProvider>
                                    ) : (
                                      <CssTextField
                                        required={e.REQUIRED}
                                        multiline={
                                          e.TYPE !== "date" &&
                                          e.TYPE !== "time" &&
                                          e.TYPE !== "month" &&
                                          e.TYPE !== "number" &&
                                          e.TYPE !== "text"
                                        }
                                        focused
                                        fullWidth
                                        type={e.TYPE}
                                        id={`outlined-${each.id}-${index}`}
                                        inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                                        onInput={(num) => {
                                          // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit

                                          e.TYPE === "number" &&
                                            (num.target.value = Math.floor(
                                              Math.max(
                                                0,
                                                parseInt(num.target.value)
                                              )
                                                .toString()
                                                .slice(0, e.MAX_CHAR || 12)
                                            ));
                                            e.TYPE ==="text_caps" &&
                                            (num.target.value=num.target.value.toLocaleUpperCase())
                                        }}
                                        label={e.DISPLAY_NAME}
                                        name={e.FIELD}
                                        value={e.VALUE}
                                        onChange={(s) => {
                                          e.VALUE = s.target.value;
                                          setSelectedDocumentDocType([
                                            ...selectedDocumentDocType,
                                          ]);
                                        }}
                                      />
                                    );
                                  }
                                })}

                                {each?.panVerificationRequired &&
                                  <>
                                    <Typography fontSize={14} fontWeight='bold'>Filled by Verifier</Typography>
                                    {each.fields?.map(e => {
                                      if (e.FILLED_BY === "verifier") {
                                        if (e.FIELD === "status")
                                          return

                                        return e.TYPE === "select" ? (
                                          <>
                                            <CssTextField
                                              required={e.REQUIRED}
                                              fullWidth
                                              select
                                              type={e.TYPE}
                                              id={`outlined-${each.id}-${index}`}
                                              label={e.DISPLAY_NAME}
                                              name={e.FIELD}
                                              value={e.VALUE}
                                              onInput={(num) => {
                                                // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit
      
                                               
                                                  e.TYPE ==="text_caps" &&
                                                  (num.target.value=num.target.value.toLocaleUpperCase())
                                              }}
                                              onChange={(s) => {
                                                e.VALUE = s.target.value;
                                                setSelectedDocumentDocType([
                                                  ...selectedDocumentDocType,
                                                ]);
                                              }}
                                              sx={{ textAlign: "left" }}
                                              key={e.DISPLAY_NAME}
                                            >
                                              {e.VALUES_LIST.map((option) => (
                                                <MenuItem
                                                  key={option.value}
                                                  value={option.value}
                                                >
                                                  {option.value}
                                                </MenuItem>
                                              ))}
                                            </CssTextField>
                                            {e.VALUE.toLowerCase() === "other" && (
                                              <CssTextField
                                                focused
                                                multiline
                                                required
                                                id="outlined-fileNo"
                                                label={`Other Value (${e.DISPLAY_NAME})`}
                                                name="otherValue"
                                                value={e.OTHER_VALUE || ""}
                                                onChange={(s) => {
                                                  e.OTHER_VALUE = s.target.value;
                                                  setSelectedDocumentDocType([
                                                    ...selectedDocumentDocType,
                                                  ]);
                                                }}
                                                sx={{
                                                  marginLeft: "30px",
                                                  width: "85%",
                                                }}
                                              />
                                            )}
                                          </>
                                        ) : (
                                          <CssTextField
                                            required={e.REQUIRED}
                                            multiline={
                                              e.TYPE !== "date" &&
                                              e.TYPE !== "time" &&
                                              e.TYPE !== "month" &&
                                              e.TYPE !== "number" &&
                                              e.TYPE !== "text"
                                            }
                                            focused
                                            fullWidth
                                            type={e.TYPE}
                                            id={`outlined-${each.id}-${index}`}
                                            inputProps={{ maxLength: e.MAX_CHAR }} // for setting max char limit
                                            onInput={(num) => {
                                              // inputprops maxlength will not work for type="number",so onInput is used for setting max num limit

                                              e.TYPE === "number" &&
                                                (num.target.value = Math.floor(
                                                  Math.max(
                                                    0,
                                                    parseInt(num.target.value)
                                                  )
                                                    .toString()
                                                    .slice(0, e.MAX_CHAR || 12)
                                                ));
                                                e.TYPE ==="text_caps" &&
                                                (num.target.value=num.target.value.toLocaleUpperCase())
                                                
                                            }}
                                            label={e.DISPLAY_NAME}
                                            name={e.FIELD}
                                            value={e.VALUE}
                                            onChange={(s) => {
                                              e.VALUE = s.target.value;
                                              setSelectedDocumentDocType([
                                                ...selectedDocumentDocType,
                                              ]);
                                            }}
                                          />
                                        );
                                      }
                                    })}
                                  </>}
                              </List>
                            </div>
                          ))}
                        </List>
                      )}
                  </div>
                )}
              </Box>
              <LoadingColorButton
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  fontSize: "16px",
                  letterSpacing: "4px",
                  maxWidth: "45ch",
                  margin: "50px auto !important",
                }}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
                loadingPosition="start"
                loading={formSubmitLoading}
                onClick={handleSubmitButtonClick}
              >
                Submit File
              </LoadingColorButton>
            </Box>
          </div>
        ) : (
          //add file first page
          <div
            className="addfile addfile-third"
            style={{ display: "flex", flexDirection: "column", margin: "auto" }}
          >
           
            <Box
              sx={{
                borderRadius: "20px",
                border: "2px solid #1553B5",
                height: "100%",
                width: "90%",
                display: "flex",
                flexFlow: "row-reverse wrap",
                justifyContent: "space-between",
                "& > :not(style)": { margin: '24px 40px', width: "50ch" },
              }}
              component="form"
              autoComplete="off"
              onSubmit={handleFormSubmitFirstPage}
            >
              {/* CPA Branch */}
              <CssTextField
                focused
                required
                id="outlined-select-currency"
                select
                label="CPA Branch"
                name="branchRefId"
                value={inputs.branchRefId || ""}
                onChange={handleChange}
              // InputProps={{
              //   style: { width: "300px" },
              // }}
              >
                {branchList.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.branchName}
                  </MenuItem>
                ))}
              </CssTextField>

              {/* File No */}
              <CssTextField
                focused
                multiline
                required
                id="outlined-fileNo"
                label="File No"
                name="fileNo"
                value={inputs.fileNo || ""}
                onChange={handleChange}
              // InputProps={{
              //   style: { width: "300px" },
              // }}
              />

              {/* Product */}
              <CssTextField
                focused
                required
                id="outlined-select-currency"
                select
                label="Product"
                name="productRefId"
                value={inputs.productRefId || ""}
                onChange={handleChange}
              // InputProps={{
              //   style: { width: "300px" },
              // }}
              >
                {productList.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.productName}
                  </MenuItem>
                ))}
              </CssTextField>

              {/* Customer Name */}
              <CssTextField
                focused
                multiline
                required
                id="outlined-CustomerName"
                label="Customer Name"
                name="CustomerName"
                value={inputs.CustomerName || ""}
                onChange={handleChange}
              // InputProps={{
              //   style: { width: "300px" },
              // }}
              />

              {/* Verification Type */}
              <CssTextField
                focused
                required
                id="outlined-select-currency"
                select
                label="Verification Type"
                name="VerificationType"
                value={inputs.VerificationType || ""}
                onChange={handleChange}
              // InputProps={{
              //   style: { width: "300px" },
              // }}
              >
                <MenuItem value="profile">Profile</MenuItem>
                <MenuItem value="document">Document</MenuItem>
                <MenuItem value="profile and document">
                  Profile and Document
                </MenuItem>
              </CssTextField>

              {/* Location */}
              {/* <CssTextField
                focused
                multiline
                required
                id="outlined-Location"
                label="Location"
                name="Location"
                value={inputs.Location || ""}
                onChange={handleChange}
              // InputProps={{
              //   style: { width: "300px" },
              // }}
              /> */}

              <CssTextField
                focused
                required
                id="outlined-select-currency"
                select
                label="Location"
                name="Location"
                value={inputs.Location || ""}
                onChange={handleChange}
              // InputProps={{
              //   style: { width: "300px" },
              // }}
              >
                {branchList.map((option) => (
                  <MenuItem key={option._id} value={option.branchName}>
                    {option.branchName}
                  </MenuItem>
                ))}
              </CssTextField>

              <LoadingColorButton
                sx={{
                  color: "#fff",
                  borderRadius: "10px",
                  fontSize: "16px",
                  letterSpacing: "4px",
                }}
                type="submit"
                variant="contained"
                startIcon={<ArrowCircleRightIcon />}
                loadingPosition="start"
                loading={formSubmitLoading}
                onClick={handleSubmitButtonClick}
              >
                Next
              </LoadingColorButton>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewFile;
