import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminLogin from "./admin/login/Login";
import MangerLogin from "./bank manager/login/Login";
import PrimeManagerLogin from "./prime manager/login/Login";
import SupervisorLogin from "./supervisor/login/Login";
import NotFound from "../../utils/notfound/notFound";
import BMDashboard from "./bank manager/dashboard/BMDashboard";
import Header from "../header/Header";

const Pages = () => {
  !localStorage.getItem("token") && localStorage.setItem("token", "");
  !localStorage.getItem("role") && localStorage.setItem("role", "null");
  !localStorage.getItem("userName") && localStorage.setItem("userName", "null");
  !localStorage.getItem("location") && localStorage.setItem("location", "null");
  !localStorage.getItem("locationCode") && localStorage.setItem("locationCode", "null");
  !localStorage.getItem("bank") && localStorage.setItem("bank", "[]");
  !localStorage.getItem("doc") && localStorage.setItem("doc", "[]");
  !localStorage.getItem("verifier") && localStorage.setItem("verifier", "[]");
  !localStorage.getItem("currentBankId") &&
    localStorage.setItem("currentBankId", null);
  !localStorage.getItem("empId") && localStorage.setItem("empId", null);
  !localStorage.getItem("firstName") && localStorage.setItem("firstName", null);
  !localStorage.getItem("lastName") && localStorage.setItem("lastName", null);
  !localStorage.getItem("bankFilter") &&
    localStorage.setItem("bankFilter", "[]");
  !localStorage.getItem("docFilter") && localStorage.setItem("docFilter", "[]");
  !localStorage.getItem("verifierFilter") &&
    localStorage.setItem("verifierFilter", "[]");
  !localStorage.getItem("fileStat") && localStorage.setItem("fileStat", "[]");
  !localStorage.getItem("fileStatFilter") &&
    localStorage.setItem("fileStatFilter", "[]");

  const { token } = useSelector((state) => state.token.value);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/admindashboard"
          component={!token ? AdminLogin : Header}
        />
        <Route
          exact
          path="/managerdashboard"
          component={!token ? MangerLogin : BMDashboard}
        />
        <Route
          exact
          path="/primemanager"
          component={!token ? PrimeManagerLogin : Header}
        />
        <Route exact path="/" component={!token ? SupervisorLogin : Header} />
        <Route path="*" exact component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Pages;
