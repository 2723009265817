import { createSlice } from "@reduxjs/toolkit";

export const spSlice = createSlice({
  name: "drawer",
  initialState: {
    value: {
      value: "Dashboard",
      currentBankId: localStorage.currentBankId,
    },
    selectedBankTab: 0,
    openedFileDetails: null
  },
  reducers: {
    changeDrawerSelection: (state, action) => {
      state.value = action.payload;
    },
    setSelectedBankTab: (state, action) => {
      state.selectedBankTab = action.payload;
    },
    setOpenedFileDetails: (state, action) => {
      state.openedFileDetails = action.payload;
    }
  },
});

export const { changeDrawerSelection, setSelectedBankTab, setOpenedFileDetails } = spSlice.actions;

export default spSlice.reducer;
