import * as React from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import swal from "sweetalert";
import JsFileDownloader from "js-file-downloader";
import fileDownload from "js-file-download";
import { ChildModal } from "../../../../../modalViewer.js/ChildModal";
import ExpandableItem from "./ExpandableItem";
import { changeDrawerSelection, setOpenedFileDetails } from "../../../../../../features/spselection";

//MUI
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Select from "@mui/material/Select";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Dialog from "@mui/material/Dialog";
import VoiceRecord from "../../../../../voice_record/VoiceRecord";
import LinearProgress from "@mui/material/LinearProgress";
import { InputAdornment } from "@mui/material";

//mui icons
import UpdateIcon from "@mui/icons-material/Update";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import Slide from "@mui/material/Slide";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { AttachFileImageConfig } from "../../../../../../config/AttachFileImageConfig";
import { Autocomplete, Popper } from "@mui/material";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import './ViewFile.css';
import moment from "moment";

//for custom MUI DialogTitle
const DialogTitleStyle = {
  color: "#fff",
  backgroundColor: "#1553b5 ",
  display: "flex",
  justifyContent: "space-between",
};

// for external document  upload
const Input = styled("input")({
  display: "none",
});

//custom MUI textField
const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#000",
  },
  "& label.MuiInputLabel-formControl": {
    color: "#000",
  },
  "& svg.MuiSvgIcon-root": {
    color: "#000",
  },
  "& .MuiSelect-select": {
    color: "#000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
  },
  "& .MuiOutlinedInput-input": {
    color: "#000",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#000",
    },
    "&:hover fieldset": {
      borderColor: "#000",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#000",
    },
  },
});

// custom mui button
const ColorButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: "#f4f4f4",
  border: "1px solid #1553b5",
  color: "#1553b5",
  padding: "4px 8px",
  margin: "auto 2px",
  "&:hover": {
    border: "1px solid #f4f4f4",
    backgroundColor: "#1553b5",
    color: "#fff !important",
  },
}));

const LoadingColorButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.getContrastText("#fff"),
  backgroundColor: "#1553b5",
  "&:hover": {
    backgroundColor: "#1553b5",
  },
}))

// custom mui button (enable/disable)
const ColorEnableDisableButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.getContrastText("#fff"),
  backgroundColor: "#1553b5",
  "&:hover": {
    backgroundColor: "#1553b5",
  },
}));

//for custom MUI TableCell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1553b5",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "#CCCDCD",
    color: "#000",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
}));

//for custom MUI TableRow
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

//for custom MUI Icon Button
const ColorIconButton = styled(IconButton)(() => ({
  backgroundColor: "#1553b5",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#1553b5",
  },
}));

//for customizing action tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));

//for mui dialog transition
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ViewFile = ({
  bankId,
  fileId,
  fileRefId,
  openCloseBool,
  tabelRender,
  fromDedupeView
}) => {
  // swal package doesn't support direct env call, hence stored in a variable;
  const transact_base_url = process.env.REACT_APP_NODESERVER;
  const master_base_url = process.env.REACT_APP_ADMINNODE;

  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.token.value);
  const { role, empId, firstName, lastName } = useSelector(
    (state) => state.user.value
  );

  const [fileViewDetail, setFileViewDetail] = React.useState([]);
  const [fileDocDetail, setFileDocDetail] = React.useState([]);
  const [viewChecked, setViewChecked] = React.useState(false);
  const [editChecked, setEditChecked] = React.useState(false);
  const [showMore, setShowMore] = React.useState(4);
  const [status, setStatus] = React.useState(""); // "status" state is update to ASSIGN,REASSIGN based on "viewFileDoc" api and to CLEAR,REVERIFY based on button click
  const [fileStatus, setFileStatus] = React.useState("");
  const [fileIsActive, setFileIsActive] = React.useState("");
  const [docArrLength, setDocArrLength] = React.useState(); // number of data to be displayed on  "Profile And Document Verification" table is set by "docArrLength"
  const [newArrayFile, setNewArrayFile] = React.useState({}); // the data to be displayed
  const [verifierList, setVerifierList] = React.useState([]); // verifier list api response is stored in this state
  const [assign, setAssign] = React.useState({
    id: "",
    assignedBy: `${role}-${firstName} ${lastName}`,
    verifier: "",
    verifierId: "",
    verifierCity: "",
    fileRefId: "",
    status: status,
    createdBy: "",
    productName: "",
    bankName: "",
    branchName: "",
  }); // data to be send on assign,reassign,clear assign and reverify
  const [selectedDocId, setSelectedDocId] = React.useState("");
  const [selectedDocRefId, setSelectedDocRefId] = React.useState();
  const [anchorElFile, setAnchorElFile] = React.useState(null);
  const [anchorElGenerate, setAnchorElGenerate] = React.useState(null);
  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const [anchorElAddDoc, setAnchorElAddDoc] = React.useState(null);
  const [inputs, setInputs] = React.useState({
    id: "",
    docImage: [],
    reportStatus: [],
    fields: [],
  });
  const openFileButton = Boolean(anchorElFile);
  const openGenerateButton = Boolean(anchorElGenerate);
  const openReportButton = Boolean(anchorElReport);
  const openAddDocButton = Boolean(anchorElAddDoc);
  const [docFields, setDocFields] = React.useState([]);
  const [boolean, setBoolean] = React.useState(false); //for document update
  const [viewBool, setViewBool] = React.useState(false); // for re-rendering view file
  const [loading, setLoading] = React.useState(false);
  const [isDisplayed, setIsDisplayed] = React.useState(false);
  const [fileDocExtra, setFileDocExtra] = React.useState({
    verificationType: "",
    samplerLocation: "",
  });
  const [openRecordVoiceView, setOpenRecordVoiceView] = React.useState(false); //supervisor voice upload
  const [inputVoice, setInputVoice] = React.useState("");

  const [isPincodeChanged, setIsPincodeChanged] = React.useState(false);
  const [autoCompleteOpen, setAutoCompleteOpen] = React.useState([]);

  const closeThisFile = () => {
    openCloseBool(false);
    dispatch(setOpenedFileDetails(null));
  }

  // // useRef and useEffect is used to set the status of "status" state to  "REVERIFY" or "CLEAR". By the use of useRef the function inside useEffect will not called on initial render. Also a rendering condition is mentioned
  const isInitialMount = React.useRef(true);
  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      updateDocCall();
    }
  }, [boolean]);
  // ///////////////////////////////////////////

  //for playing audio
  const audioRef = React.useRef([]);
  const playerButton = React.useRef([]);
  const timeline = React.useRef([]);
  const soundButton = React.useRef([]);
  //svg icons for audio
  const playIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
  </svg>
  `;
  const pauseIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
  </svg>
  `;
  const soundIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clip-rule="evenodd" />
  </svg>`;
  const muteIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"  fill="#1553b5">
  <path fill-rule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM12.293 7.293a1 1 0 011.414 0L15 8.586l1.293-1.293a1 1 0 111.414 1.414L16.414 10l1.293 1.293a1 1 0 01-1.414 1.414L15 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L13.586 10l-1.293-1.293a1 1 0 010-1.414z" clip-rule="evenodd" />
  </svg>`;
  const downloadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24"  height="24" viewBox="0 0 24 24" fill="none" stroke="#1553b5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"/></svg>`;

  //change audio timeline
  const changeTimelinePosition = (index) => {
    let state = audioRef.current[index];
    const percentagePosition = (100 * state.currentTime) / state.duration;
    timeline.current[
      index
    ].style.backgroundSize = `${percentagePosition}% 100%`;
    timeline.current[index].value = percentagePosition;
  };
  //function called after audio ended
  const audioEnded = (index) => {
    playerButton.current[index].innerHTML = playIcon;
  };
  //play/pause audio
  const toggleAudio = (index) => {
    if (audioRef.current[index].paused) {
      audioRef.current[index].play();
      playerButton.current[index].innerHTML = pauseIcon;
    } else {
      audioRef.current[index].pause();
      playerButton.current[index].innerHTML = playIcon;
    }
  };
  const changeSeek = (index) => {
    const time =
      (timeline.current[index].value * audioRef.current[index].duration) / 100;
    audioRef.current[index].currentTime = time;
  };
  //mute/unmute sound
  const toggleSound = (index) => {
    audioRef.current[index].muted = !audioRef.current[index].muted;
    soundButton.current[index].innerHTML = audioRef.current[index].muted
      ? muteIcon
      : soundIcon;
  };

  //voice upload
  const onUpload = async (bool) => {
    var applicationForm = new FormData();
    applicationForm.append("s3path", "voice-upload");
    applicationForm.append("file", inputVoice);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadToS3`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: applicationForm,
      });
      if ("s3Url" in response.data) {
        let updatedSupervisorVoice = [
          ...fileViewDetail[0].supervisorRecord,
          { url: response.data.s3Url },
        ];
        try {
          //api call for update file
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/updateFile`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: fileViewDetail[0]._id,
              supervisorVoice: updatedSupervisorVoice,
            },
          });
          response.data.status === "success" && setViewBool((prev) => !prev);
          swal({
            title: `Voice Upload Success`,
            icon: "success",
          });
        } catch (error) {
          swal({
            title: `Failed on updating Supervisor voice`,
            icon: "error",
          });
        }
      }
    } catch (error) {
      swal({
        title: `Voice Upload Failed`,
        icon: "error",
      });
    }
    handleClose();
  };

  const downloadSound = (url) => {
    let fileName = url.split("/")[4].replace(/[0-9]/g, "");

    try {
      fileDownload(url, `${fileName}.mp3`);
      swal({
        title: "Download Complete",
        icon: "success",
      });
    } catch (error) {
      swal({
        title: "Download Failed. Try Again",
        icon: "error",
      });
    }
  };

  React.useEffect(() => {
    const viewFile = async () => {
      try {
        // api call to get file detail
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/file/manage/viewFile`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: fileRefId,
          },
        });
        setFileViewDetail(response.data.fileDetail);
        let capitalizeVerificationType =
          response.data.fileDetail[0].verificationType.replace(
            /(^\w{1})|(\s+\w{1})/g,
            (letter) => letter.toUpperCase()
          );
        setFileDocExtra((values) => ({
          ...values,
          verificationType: capitalizeVerificationType,
        }));
        setFileDocExtra((values) => ({
          ...values,
          samplerLocation: response.data.fileDetail[0].location,
        }));
        setAssign((oldValues) => ({
          ...oldValues,
          createdBy: response.data.fileDetail[0]?.createdBy,
          productName: response.data.fileDetail[0]?.productName,
          bankName: response.data.fileDetail[0]?.bankName,
          branchName: response.data.fileDetail[0]?.branchName,
          fileRefId: response.data.fileDetail[0]?._id,
        }));
        response.data.fileDetail.map((stat) => {
          // setting the "fileStatus" based on selected file to display on
          switch (stat.status) {
            case "NEW":
              setFileStatus("NEW");
              break;
            case "INPROGRESS":
              setFileStatus("INPROGRESS");
              break;
            case "VERIFIED":
              setFileStatus("VERIFIED");
              break;
            case "COMPLETED":
              setFileStatus("COMPLETED");
              break;
            case "SENT":
              setFileStatus("SENT");
              break;
            case "SENT AND MAIL":
              setFileStatus("SENT AND MAIL");
              break;
            default:
              setFileStatus("");
          }
          switch (stat.isActive) {
            case "active":
              setFileIsActive("active");
              break;
            case "inactive":
              setFileIsActive("inactive");
              break;
            default:
              setFileIsActive("");
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    const viewFileDocument = async () => {
      try {
        // api call to get file doc detail
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/viewFileDocument`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            fileRefId: fileRefId,
          },
        });
        setFileDocDetail(response.data.docDetail);
        setAutoCompleteOpen(response.data.docDetail.map(doc => false));
      } catch (error) {
        console.log(error);
      }
    };
    const verifierList = async () => {
      const params = new URLSearchParams();
      params.append("role", "verifier");
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getUsersByRole`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });
        // setting ascending order for verifier list
        if (response.data.status === 100) {
          setVerifierList(
            response.data.result.sort((a, b) =>
              a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
            ).map(verifer => ({
              id: verifer._id,
              empCode: verifer.empCode,
              firstName: verifer.firstName,
              lastName: verifer.lastName,
              city: verifer.district
            }))
          );
        }
      } catch (error) {
        console.log(error);
      }
    };
    verifierList();
    viewFile();
    viewFileDocument();
  }, [viewBool]);

  const [branchList, setBranchList] = React.useState();
  const [selectedSamplerLocation, setSelectedSamplerLocation] = React.useState([]);
  const [isLoadingBranchList, setIsLoadingBranchList] = React.useState(false);
  const [triggerOGLReload, setTriggerOGLReload] = React.useState(false);
  React.useEffect(() => {
    const params = new URLSearchParams();
    if (!fileViewDetail.length > 0) {
      return;
    }
    params.append("bankId", fileViewDetail[0]?.bankRefId);
    const getBranchList = async () => {
      try {
        setIsLoadingBranchList(true);
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBranchListByBankMinimum`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: params,
        });

        if (response.data.status !== 100)
          return

        const branchLocations = response.data.result.map(item => ({
          id: item._id,
          branchName: item.branchName,
          locationConfig: item.LocationConfig
        }));

        const defaultSamplerLocation = branchLocations.find(branch => fileViewDetail[0]?.branchName === branch.branchName);

        const docsWithSamplerLocation = fileDocDetail.map(document => ({
          documentId: document._id,
          samplerLocation: defaultSamplerLocation,
          distance: document.distance,
          distanceSaved: Boolean(document.distance),
          isActive: document.isActive,
          kmRequired: 'kmRequired' in document ? document.kmRequired : 'notfound'
        }));
        setBranchList(branchLocations);
        setSelectedSamplerLocation(docsWithSamplerLocation);
        setIsLoadingBranchList(false);
      } catch (err) {
        console.log(err);
      }
    };
    getBranchList();
  }, [fileViewDetail, fileDocDetail, triggerOGLReload]);

  React.useEffect(() => {
    if (fileViewDetail.length > 0) {
      let updatedData = {
        "Reference No": fileViewDetail[0].internalRefId,
        "File No ": fileViewDetail[0].fileId,
        "Bank ": fromDedupeView ? "(other bank)" : fileViewDetail[0].bankName,
        "Branch ": fromDedupeView ? "(other bank)" : fileViewDetail[0].branchName,
        "Customer Name": fileViewDetail[0].userName,
        "Product ": fileViewDetail[0].productName,
        Status: fileViewDetail[0].status,
        "Application Image": fileViewDetail[0].applicationImage
          ? fileViewDetail[0].applicationImage
          : null,
        "Supervisor Voice": fileViewDetail[0].supervisorRecord
          ? fileViewDetail[0].supervisorRecord
          : null,
        "Sampler Voice": fileViewDetail[0].samplerRecord
          ? fileViewDetail[0].samplerRecord
          : null,
        "Bank Manager Voice": fileViewDetail[0].managerVoice
          ? fileViewDetail[0].managerVoice
          : null,
      };

      setNewArrayFile(updatedData);
    }
  }, [fileViewDetail]);

  // to get report status
  React.useEffect(() => {
    const bankDetails = async () => {
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getBankList`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status !== 100)
          return

        response.data.bankDetails.map((details) => {
          details._id === fileViewDetail[0].bankRefId &&
            setInputs((values) => ({
              ...values,
              reportStatus: details.reportStatus,
            }));
        });
      } catch (error) {
        console.log(error);
      }
    };
    bankDetails();
  }, [fileViewDetail]);

  const [panVerifyStatus, setPanVerifyStatus] = React.useState()

  const handlePANChange = (event, field, docIndex) => {
    const panNumber = event.target.value.toUpperCase()
    field.VALUE = panNumber;

    let regexPan = /^[A-Z]{3}([ABCFGHLJPT])[A-Z][0-9]{4}[A-Z]$/
    if (regexPan.test(panNumber)) {
      setPanVerifyStatus({ isPanValid: true })
    } else {
      setPanVerifyStatus({ isPanValid: false })
    }

    setDocFields([
      ...docFields
    ])
  }

  const [panVerLoading, setPanVerLoading] = React.useState(false)

  const handleVerifyPAN = async (docIndex) => {
    const panNumber = docFields.find(field => field.FIELD === "number").VALUE
    const firstName = localStorage.getItem("firstName")
    const lastName = localStorage.getItem("lastName")
    const employeeName = `${firstName} ${lastName}`

    if (panVerifyStatus?.isPanAuthentic !== undefined)
      return

    setPanVerLoading(true)
    axios.post(
      process.env.REACT_APP_ADMINNODE + '/api/validate/PancardVerification',
      { panNumber, employeeName, requestThrough: "Supervisor Web" },
      { headers: { Authorization: "Bearer " + token } }
    )
      .then(res => {
        if (res.data.status === 'VALID') {
          panVerifyStatus.isPanAuthentic = true
          const panDetails = res.data.data

          const nameField = docFields.find(field => field.FIELD === 'Name')
          const nameArray = [panDetails['FIRST_NAME'], panDetails['MIDDLE_NAME'], panDetails['LAST_NAME']]
          const name = nameArray.filter(ele => ele !== "").join(' ').trim()
          nameField.VALUE = name === "" ? 'NA' : name

          const dobField = docFields.find(field => field.FIELD === 'DOB')
          dobField.VALUE = panDetails['DOB'] === "" ? 'NA' : panDetails['DOB']

          const genderField = docFields.find(field => field.FIELD === 'Gender')
          genderField.VALUE = panDetails['GENDER'] === "" ? 'NA' : panDetails['GENDER']

          const typeField = docFields.find(field => field.FIELD === 'Type')
          typeField.VALUE = panDetails['IDENTITY_TYPE'] === "" ? 'NA' : panDetails['IDENTITY_TYPE']

          const addressField = docFields.find(field => field.FIELD === 'Address')
          const addressArray = [
            panDetails['ADDRESS_1'],
            panDetails['ADDRESS_2'],
            panDetails['ADDRESS_3'],
            panDetails['PINCODE'],
            panDetails['CITY'],
            panDetails['STATE']
          ]
          const address = addressArray.filter(ele => ele !== "").join(', ')
          addressField.VALUE = address === "" ? 'NA' : address

          const contactField = docFields.find(field => field.FIELD === 'Contact')
          contactField.VALUE = panDetails['MOBILE_NO'] === "" ? 'NA' : panDetails['MOBILE_NO']

          const aadhaarField = docFields.find(field => field.FIELD === 'Aadhaar')
          aadhaarField.VALUE = panDetails['AADHAR_NUM'] === "" ? 'NA' : panDetails['AADHAR_NUM']

          const emailField = docFields.find(field => field.FIELD === 'Mail')
          emailField.VALUE = panDetails['EMAIL'] === "" ? 'NA' : panDetails['EMAIL']
        }

        else if (res.data.status === 'INVALID') {
          panVerifyStatus.isPanAuthentic = false

          const nameField = docFields.find(field => field.FIELD === 'Name')
          nameField.VALUE = 'NA'

          const dobField = docFields.find(field => field.FIELD === 'DOB')
          dobField.VALUE = 'NA'

          const genderField = docFields.find(field => field.FIELD === 'Gender')
          genderField.VALUE = 'NA'

          const typeField = docFields.find(field => field.FIELD === 'Type')
          typeField.VALUE = 'NA'

          const addressField = docFields.find(field => field.FIELD === 'Address')
          addressField.VALUE = 'NA'

          const contactField = docFields.find(field => field.FIELD === 'Contact')
          contactField.VALUE = 'NA'

          const aadhaarField = docFields.find(field => field.FIELD === 'Aadhaar')
          aadhaarField.VALUE = 'NA'

          const emailField = docFields.find(field => field.FIELD === 'Mail')
          emailField.VALUE = 'NA'
        }

        else {
          swal({
            text: 'Failed to verify PAN',
            icon: "error"
          })
        }

        setDocFields([
          ...docFields
        ])
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setPanVerLoading(false)
      })
  }

  React.useEffect(() => {
    fileDocDetail.map((data) => {
      if (data._id === selectedDocId) {
        setDocFields(data.fields);
        setInputs((values) => ({
          ...values,
          id: data._id,
          docImage: data.docImage,
        }));

        const panNumber = data.fields.find(field => field.FIELD === 'number')?.VALUE

        let regexPan = /^[A-Z]{3}([ABCFGHLJPT])[A-Z][0-9]{4}[A-Z]$/
        if (regexPan.test(panNumber)) {
          setPanVerifyStatus({ isPanValid: true })
        } else {
          setPanVerifyStatus({ isPanValid: false })
        }
      }
    });
  }, [selectedDocId, viewChecked]);

  const button = document.getElementsByClassName('swal-button--confirm');
  React.useEffect(() => {
    const handler = (event) => {
      if (event.key === 'Enter')
        button[0].click();
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    }
  }, []);

  //for assign verifier and re-assign verifier
  React.useEffect(() => {
    if (assign.verifierId.length > 0 && assign.id.length > 0) {
      let stat = "";
      fileDocDetail.map((doc) => {
        if (doc._id === assign.id) {
          doc.verifier ? (stat = "REASSIGN") : (stat = "ASSIGN");
        }
      });

      swal({
        title: "Are you sure?",
        text: `Are you sure that you want to ${stat} this document?`,
        icon: "info",
        buttons: true,
        dangerMode: true,
      }).then((assignVerifier) => {
        if (assignVerifier) {
          axios({
            method: "post",
            url: `${transact_base_url}/api/v1/document/manage/assignVerifier`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              id: assign.id,
              assignedBy: assign.assignedBy,
              verifier: assign.verifier,
              verifierId: assign.verifierId,
              verifierCity: assign.verifierCity,
              fileRefId: assign.fileRefId,
              status: stat,
            },
          })
            .then(() => {
              swal({
                title: `${stat} Document Successfully`,
                icon: "success"
              });
            })
            .catch((err) => {
              console.log(err);
            });
          setAssign((oldValues) => ({
            ...oldValues,
            id: "",
          }));
          tabelRender((prevTabelRender) => !prevTabelRender);
        } else {
          setAssign((oldValues) => ({
            ...oldValues,
            id: "",
            verifierId: "",
            verifier: "",
            verifierCity: "",
          }));
        }

        setViewBool((prev) => !prev);
      });
    }
  }, [assign.id, assign.verifier.length > 0]);

  React.useEffect(() => {
    setInterval(() => {
      setIsDisplayed(true);
    }, 500);
  }, []);

  const updateDocCall = async () => {
    setLoading(true);
    let pincodeUpdate = { pincodeChange: false };
    try {
      if (isPincodeChanged) {
        const resp = await axios({
          url: `${process.env.REACT_APP_ADMINNODE}/api/v1/admin/getDocumentTypeList`,
          headers: { Authorization: `Bearer ${token}` }
        });
        if (resp.data.status !== 100)
          throw "Error"

        const document = resp.data?.result[0]?.documentTypeId.find((doc) => {
          const selectedDocument = fileDocDetail.find(doc => doc._id === selectedDocId)
          return doc._id === selectedDocument.docRefId;
        });

        if (document) {
          pincodeUpdate = {
            pincodeBasedAssignment: document.pincodeBasedAssignment,
            pincodeChange: true
          }
          setIsPincodeChanged(false);
        } else {
          setIsPincodeChanged(false);
          setLoading(false);
          return swal({
            title: 'Pincode cannot be updated',
            icon: 'error'
          })
        }
      }

      if (inputs.reportStatus?.length === 0) {
        return swal({
          title: "Could not get report status. Document Updation Failed",
          icon: "error"
        })
      }

      // api call to get file detail
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/updateDocument`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          ...inputs,
          ...pincodeUpdate
        }
      });
      response.data.status === "success"
        ? swal({
          title: `Document Updated Successfully`,
          icon: "success",
        })
        : swal({
          title: `Document Updation Failed`,
          icon: "error",
        });
      setLoading(false);
    } catch (error) {
      console.log(error);
      swal({
        title: `Document Updation Failed`,
        icon: "error",
      });
      setLoading(false);
    }
    setViewBool((prev) => !prev);
  };

  const docRefs = React.useRef([]);

  const handleEdit = (id, index, docRefId) => {
    setSelectedDocId(prevValue => {
      if (prevValue === id) {
        setEditChecked(false);
        setTimeout(() => docRefs.current[index].scrollIntoView({ behavior: 'smooth' }), 100);
        return null;
      }
      else {
        setEditChecked(true)
        setTimeout(() => docRefs.current[index].scrollIntoView({ behavior: 'smooth' }), 100);
        return id;
      }
    })

    setSelectedDocRefId(prevValue => {
      if (prevValue === id) {
        return null;
      }
      else {
        return docRefId;
      }
    })
    setViewChecked(false);
  };

  // single document reverify
  const handleReverify = (data) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to Reverify this document?`,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((assignVerifier) => {
      if (assignVerifier) {
        axios({
          method: "post",
          url: `${transact_base_url}/api/v1/document/manage/assignVerifier`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: data._id,
            assignedBy: assign.assignedBy,
            fileRefId: data.fileRefId,
            status: "REVERIFY",
          },
        })
          .then(() => {
            swal({
              title: `Document Reverify Successfully`,
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // swal("Operation terminated");
      }

      setViewBool((prev) => !prev);
    });
  };

  const handleDisable = (id) => {
    fileDocDetail.map((doc) => {
      if (doc._id === id) {
        swal({
          title: "Are you sure?",
          text:
            "Are you sure that you want to " +
            (doc.isActive === "inactive" ? "Enable" : "Disable") +
            " this document?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            const enableDisableApi = async () => {
              try {
                const response = await axios({
                  method: "post",
                  url: `${transact_base_url}/api/v1/file/manage/enableDisable`,
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                  data: {
                    _id: "",
                    docId: id,
                    isActive: doc.isActive === "active" ? "inactive" : "active",
                  },
                });
                if (response.data.status === "success") {
                  swal({
                    title:
                      "Document " +
                      (doc.isActive === "inactive" ? "Enabled" : "Disabled") +
                      " Successfully",
                    icon: "success",
                  });
                  setViewBool((prev) => !prev);
                } else {
                  swal({
                    title: `${response.data.status}`,
                    icon: "success",
                  });
                }
              } catch (error) {
                console.log(error);
              }
            };
            enableDisableApi();
          } else {
            swal(
              "Selected Document is not " +
              (doc.isActive === "inactive" ? "Enabled" : "Disabled")
            );
          }
        });
      }
    });
  };

  const handleViewMore = (id) => {
    setSelectedDocId(id);
    setViewChecked((prev) => !prev);
    setEditChecked(false);
  };

  const editRemark = async (id, verificationRemarks, otherRemarks) => {
    try {
      // api call to get file detail
      const response = await axios({
        method: "patch",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/editRemarks`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: id,
          remarks: verificationRemarks,
          otherRemarks: otherRemarks,
        },
      });
      response.data.status === "success"
        ? swal({
          title: `Remark Updated Successfully`,
          icon: "success",
        })
        : swal({
          title: `Remark Updation Failed`,
          icon: "error",
        });
    } catch (error) {
      console.log(error);
      swal({
        title: `Remark Updation Failed`,
        icon: "error",
      });
    }
  };

  const saveDistance = async (index) => {
    try {
      // api call to update distance
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/saveDistance`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // data: {
        //   docid: selectedSamplerLocation[index].documentId,
        //   distance: selectedSamplerLocation[index].distance,
        // },
        data: {
          fileid: fileDocDetail[index].fileRefId,
          docid: selectedSamplerLocation[index].documentId,
          verifierLocation: fileDocDetail[index].verifierLocation,
          samplerLocation: fileViewDetail[0].location,
          distance: selectedSamplerLocation[index].distance
        }
      });
      response.data.status === "success" &&
        swal({
          title: `Distance updated successfully`,
          icon: "success",
        });
      // selectedSamplerLocation[index].distanceSaved = true;
      selectedSamplerLocation.length > 0 &&
        setSelectedSamplerLocation((currentValue) => {
          const newValue = currentValue.slice();  // create a new reference
          newValue[index].distanceSaved = true;
          return newValue;
        });
    } catch (error) {
      console.log(error);
      swal({
        title: `Error in updating distance`,
        icon: "error",
      });
    }
  };

  const [calculatingDistance, setCalculatingDistance] = React.useState(false);
  const calculateDistance = async (index) => {
    let originLat;
    let originLong;
    try {
      originLat = selectedSamplerLocation[index].samplerLocation.locationConfig.Latitude;
      originLong = selectedSamplerLocation[index].samplerLocation.locationConfig.Longitude;
      if (!originLat || !originLong) {
        throw 'Sampler location not found!'
      }
    } catch (error) {
      console.log(error);
      selectedSamplerLocation.length > 0 && setSelectedSamplerLocation((currentValue) => {
        const newValue = currentValue.slice();
        newValue[index].distance = '';
        return newValue;
      })
      swal({
        title: `Sampler location not found!`,
        icon: "error",
      });
      return;
    }
    const destLat = fileDocDetail[index].location.latitude;
    const destLong = fileDocDetail[index].location.longitude;
    setCalculatingDistance(true);
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/calculateDistance`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          "origin": [`${originLat}, ${originLong}`],
          "destination": [`${destLat}, ${destLong}`]
        },
      });
      if (response.data.status === 'success') {
        setSelectedSamplerLocation((currentValue) => {
          const newValue = currentValue.slice();
          newValue[index].distance = parseFloat(response.data.distance).toString();
          return newValue;
        })
      } else {
        swal({
          title: response.data.status,
          icon: 'error'
        })
      }
    } catch (error) {
      console.log(error);
    }
    setCalculatingDistance(false);
  };

  const addSupervisorVoice = () => {
    setOpenRecordVoiceView(true);
  };

  const handleClose = () => {
    setOpenRecordVoiceView(false);
  };

  //called while user enter location input
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFileDocExtra((values) => ({ ...values, [name]: value }));
  };

  // functions to update document
  const handleDocUpdate = async (e) => {
    e.preventDefault();
    let updatedDisplayName = [];
    let updatedValue = [];
    docFields?.map((data) => {
      updatedDisplayName = updatedDisplayName.concat(data.DISPLAY_NAME);
      updatedValue = updatedValue.concat(data.VALUE);
    });

    let invalidDateFound = false;
    const formattedDocFields = docFields.map(field => {
      if (field.TYPE === "date" && (field.FILLED_BY === "sampler" || field.FILLED_BY === "verifier") && field.VALUE) {
        let date;
        if (typeof field.VALUE === "string") {
          date = field.VALUE;
        } else {
          date = moment(field.VALUE.$d).format("DD-MM-YYYY");
        }
        if (date === "Invalid date") {
          invalidDateFound = true;
        }
        return {
          ...field,
          VALUE: date
        }
      }
      return field;
    })

    setInputs((values) => ({
      ...values,
      fields: formattedDocFields,
    }));

    if (invalidDateFound) {
      swal({
        title: 'Invalid Date Format',
        icon: 'info'
      })
      return;
    }

    setBoolean(!boolean);
  };
  const handleDocFieldChange = (event) => {
    if (event.target.name === 'Pincode') {
      setIsPincodeChanged(true);
    }

    const name = event.target.name;
    const value = event.target.value;
    docFields?.map((e) => {
      let updatedFieldArray = docFields?.map((a) => {
        return { ...a };
      });
      updatedFieldArray.find((a) => a.DISPLAY_NAME == name).VALUE = value;
      setDocFields(updatedFieldArray);
    });
  };

  const handleShowMore = () => {
    fileViewDetail.map((list) => setDocArrLength(list.productFields.length));
    showMore <= 4 ? setShowMore(docArrLength) : setShowMore(4);
  };

  // function is called on clear assign  button click
  const handleClearAssign = (stat) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to ${stat} this file?`,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((click) => {
      if (click) {
        axios({
          method: "post",
          url: `${transact_base_url}/api/v1/document/manage/assignVerifier`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            fileRefId: assign.fileRefId,
            assignedBy: assign.assignedBy,
          },
        })
          .then(() => {
            swal({
              title: `${status} Document Successfully`,
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
        closeThisFile();
      }
    });
  };

  // function is called on file re-verify button click
  const handleFileReverify = (stat) => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to ${stat} this file?`,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((click) => {
      if (click) {
        axios({
          method: "post",
          url: `${transact_base_url}/api/v1/document/manage/reverify`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            fileRefId: assign.fileRefId,
            assignedBy: assign.assignedBy,
            samplerName: `${role}-${firstName} ${lastName}`,
            status: stat,
          },
        })
          .then(() => {
            swal({
              title: `${stat} Document Successfully`,
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
            swal({
              title: `${status} Document Failed`,
              icon: "error",
            });
          });
        closeThisFile();
      }
    });
  };

  // function to Reassign file (changing file status from NEW to DRAFT)
  const handleFileReassign = () => {
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to Sent Back this file?`,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((process) => {
      if (process) {
        axios({
          method: "post",
          url: `${transact_base_url}/api/v1/file/manage/sendBack`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: fileRefId,
            createdBy: assign.createdBy,
          },
        })
          .then(() => {
            swal({
              title: `File Sent Back Successfully`,
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
        closeThisFile();
      }
    });
  };

  // function for complete file, process file and re-process file
  const handleProcess_ReProcess = (stat) => {
    if (selectedSamplerLocation.some(item => {
      return !item.distanceSaved && item.isActive === 'active' && item.kmRequired;
    }) && fileStatus === 'VERIFIED') {
      swal({
        icon: 'error',
        title: 'Please save distance before processing'
      })
      return;
    }
    handleCloseFileButton(); // to close the menu item
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to ${stat === "VERIFIED"
        ? "Process"
        : stat === "COMPLETED"
          ? "Re-Process"
          : "Complete"
        } this file?`,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((process) => {
      if (process) {
        axios({
          method: "post",
          url: `${transact_base_url}/api/v1/file/manage/processFile`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            id: fileRefId,
            status: stat,
            processedBy: `${firstName}_${empId}`,
          },
        })
          .then(() => {
            swal({
              title: `${stat === "VERIFIED"
                ? "Process"
                : stat === "COMPLETED"
                  ? "Re-Process"
                  : "Complete"
                } File Successfully`,
              icon: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
        closeThisFile();
      }
    });
  };

  // function for generate and send both pdf and word report
  const handleGenerate_SendReport = (format, action) => {
    handleCloseGenerateClick(); // to close the menu item
    handleCloseReportClick(); // to close the menu item
    let flag = false;
    for (const document of fileDocDetail) {
      for (const extDoc of document.externalDoc) {
        if (extDoc.checked && extDoc.url.endsWith('.pdf')) {
          flag = true;
          break;
        }
      }
      if (flag)
        break;
    }
    swal({
      title: "Are you sure?",
      text: `Are you sure that you want to ${action?.toLowerCase()} the report of this  file?`,
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then((process) => {
      if (process) {
        const params = new URLSearchParams();
      
        params.append("bankId", bankId);
        axios({
          method: "post",
          url: `${master_base_url}/api/v1/admin/getProductListByBank`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: params,
        })
          .then((response) => {
            if (response.data.status !== 100)
              return


            let logo, sign, pdfFormat = "", agencyName = '';
            let email = [];
            response.data.result.map((prod) => {
              if (prod.productName === assign.productName) {
                let pdfFormatCheck =
                  prod.type === "other"
                    ? prod.pdfFormat.length
                    : prod.bankId.pdfFormat.length;
                if (pdfFormatCheck <= 0) return true;
                logo = prod.bankId.logo;
                sign = prod.bankId.sign;
                agencyName = localStorage.getItem("agencyName");
                pdfFormat =
                  prod.type === "other" ? prod.pdfFormat : prod.bankId.pdfFormat;
                email =
                  prod.type === "other"
                    ? prod.sendPdfEmails
                    : prod.bankId.sendPdfEmails;
              }
            });
            axios({
              method: "post",
              url: `${transact_base_url}/api/v1/file/manage/generateReport`,
              headers: {
                Authorization: `Bearer ${token}`,
              },
              data: {
                fileRefId: assign.fileRefId,
                fileId: fileId,
                productName: assign.productName,
                logo,
                sign,
                pdfFormat,
                bankName: assign.bankName,
                branchName: assign.branchName,
                status: format,
                action: action,
                email,
                sendBy: `${firstName}_${empId}`,
                firstName,
                lastName,
                agencyName,
                flag
              },
            })
              .then((response) => {
                action === "GENERATE"
                  ? swal({
                    title: `${action.charAt(0).toUpperCase() + action.slice(1)
                      } Report Successfully`,
                    // text: "Once deleted, you will not be able to recover this imaginary file!",
                    icon: "success",
                    buttons: {
                      download: {
                        text: "Download",
                      },
                      view: {
                        text: "View",
                      },
                    },
                  }).then((text) => {
                    switch (text) {
                      case "view":
                        window.open(`${response.data}`);
                        break;

                      case "download":
                        const fileUrl = response.data;
                        new JsFileDownloader({
                          url: fileUrl,
                        })
                          .then(function () {
                            swal({
                              title: "Download Complete",
                              icon: "success",
                            });
                          })
                          .catch(function (error) {
                            swal({
                              title: "Download Failed. Try Again",
                              icon: "error",
                            });
                          });
                        break;

                      default:
                    }
                  })
                  : swal({
                    title: `${action.charAt(0).toUpperCase() + action.slice(1)
                      } Report Successfully`,
                    icon: "success",
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
            swal("Failed to get Master Data");
          });
      }
    });
  };

  // verifier image add to report button function
  const handleAddToReport = async (id, verifierImage) => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/updateVerifierImage`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: id,
          verifierImage: verifierImage,
        },
      });
      if (response.data.status === "success") {
        swal({
          title: `Successfully update selected verifier image(s) for`,
          icon: "success",
        });
      } else {
        swal({
          title: `${response.data.status}`,
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // verifier image add to report button function
  const handleAddExternalDoc = async (id, externalDoc) => {
    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/addExternalDoc`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          id: id,
          externalDoc: externalDoc,
        },
      });
      if (response.data.status === "success") {
        swal({
          title: `Successfully update selected Document image(s)`,
          icon: "success",
        });
      } else {
        swal({
          title: `${response.data.status}`,
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };


  // Add document from file view page
  const handleAddDocument = (verificationType) => {
    dispatch(changeDrawerSelection({
      value: "add file3",
      verificationType,
      fileViewDetail,
      fileDocDetail
    }));
    dispatch(setOpenedFileDetails({ bankId, fileId, fileRefId }));
  }

  //////////////////////////////////////////////////////////////////////
  // used for menu item display for add doc button
  const handleAddDocClick = (event) => {
    setAnchorElAddDoc(event.currentTarget);
  };
  const handleCloseAddDoc = (event) => {
    setAnchorElAddDoc(null);
  }

  // used for menu item display for file button
  const handleFileButtonClick = (event) => {
    setAnchorElFile(event.currentTarget);
  };
  const handleCloseFileButton = () => {
    setAnchorElFile(null);
  };

  // used for menu item display for Generate Report button
  const handleGenerateClick = (event) => {
    setAnchorElGenerate(event.currentTarget);
  };
  const handleCloseGenerateClick = () => {
    setAnchorElGenerate(null);
  };

  // used for menu item display for Report send button
  const handleReportClick = (event) => {
    setAnchorElReport(event.currentTarget);
  };
  const handleCloseReportClick = () => {
    setAnchorElReport(null);
  };
  /////////////////////////////////////////////////////////////////////////
  // for rendering buttons on fileview based on the file status and file active status
  const renderSwitch = (filestat, isActive) => {
    if (isActive === "active") {
      switch (filestat) {
        case "NEW":
          return (
            <div style={{ display: "flex" }}>
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openAddDocButton ? "file-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAddDocButton ? "true" : undefined}
                onClick={handleAddDocClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                Add Document
              </ColorButton>
              <Menu
                id="file-menu"
                anchorEl={anchorElAddDoc}
                open={openAddDocButton}
                onClose={handleCloseAddDoc}
                MenuListProps={{
                  "aria-labelledby": "file-button",
                }}
              >
                <MenuItem
                  onClick={() => handleAddDocument("profile")}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("document")}>
                  Document
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("profile and document")}>
                  Profile & Document
                </MenuItem>
              </Menu>
              {/* Re-Verify */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                onClick={() => handleFileReverify("REVERIFY")}
                variant="contained"
              >
                Re-Verify
              </ColorButton>
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                onClick={handleFileReassign}
                variant="contained"
              >
                Reassign
              </ColorButton>
            </div>
          );
        case "INPROGRESS":
          return (
            <div style={{ display: "flex" }}>
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openAddDocButton ? "file-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAddDocButton ? "true" : undefined}
                onClick={handleAddDocClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                Add Document
              </ColorButton>
              <Menu
                id="file-menu"
                anchorEl={anchorElAddDoc}
                open={openAddDocButton}
                onClose={handleCloseAddDoc}
                MenuListProps={{
                  "aria-labelledby": "file-button",
                }}
              >
                <MenuItem
                  onClick={() => handleAddDocument("profile")}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("document")}>
                  Document
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("profile and document")}>
                  Profile & Document
                </MenuItem>
              </Menu>
              {/* Re-Verify */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                onClick={() => handleFileReverify("REVERIFY")}
                variant="contained"
              >
                Re-Verify
              </ColorButton>
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                onClick={() => handleClearAssign("CLEAR")}
                variant="contained"
              >
                Clear Assign
              </ColorButton>
            </div>
          );
        case "VERIFIED":
          return (
            <div style={{ display: "flex" }}>
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openAddDocButton ? "file-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAddDocButton ? "true" : undefined}
                onClick={handleAddDocClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                Add Document
              </ColorButton>
              <Menu
                id="file-menu"
                anchorEl={anchorElAddDoc}
                open={openAddDocButton}
                onClose={handleCloseAddDoc}
                MenuListProps={{
                  "aria-labelledby": "file-button",
                }}
              >
                <MenuItem
                  onClick={() => handleAddDocument("profile")}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("document")}>
                  Document
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("profile and document")}>
                  Profile & Document
                </MenuItem>
              </Menu>
              {/* Re-Verify */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                onClick={() => handleFileReverify("REVERIFY")}
                variant="contained"
              >
                Re-Verify
              </ColorButton>
              <ColorButton
                loading={isLoadingBranchList}
                sx={{
                  borderRadius: "10px",
                }}
                onClick={() => handleProcess_ReProcess(fileStatus)}
                variant="contained"
              >
                Process File
              </ColorButton>
            </div>
          );
        case "COMPLETED":
          return (
            <div style={{ display: "flex" }}>
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openAddDocButton ? "file-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAddDocButton ? "true" : undefined}
                onClick={handleAddDocClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                Add Document
              </ColorButton>
              <Menu
                id="file-menu"
                anchorEl={anchorElAddDoc}
                open={openAddDocButton}
                onClose={handleCloseAddDoc}
                MenuListProps={{
                  "aria-labelledby": "file-button",
                }}
              >
                <MenuItem
                  onClick={() => handleAddDocument("profile")}
                >
                  Profile
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("document")}>
                  Document
                </MenuItem>
                <MenuItem onClick={() => handleAddDocument("profile and document")}>
                  Profile & Document
                </MenuItem>
              </Menu>
              {/* Re-Verify */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                onClick={() => handleFileReverify("REVERIFY")}
                variant="contained"
              >
                Re-Verify
              </ColorButton>
              {/* File */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openFileButton ? "file-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openFileButton ? "true" : undefined}
                onClick={handleFileButtonClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                File
              </ColorButton>
              <Menu
                id="file-menu"
                anchorEl={anchorElFile}
                open={openFileButton}
                onClose={handleCloseFileButton}
                MenuListProps={{
                  "aria-labelledby": "file-button",
                }}
              >
                <MenuItem
                  onClick={() => handleProcess_ReProcess("completeFile")}
                >
                  Complete File
                </MenuItem>
                <MenuItem onClick={() => handleProcess_ReProcess(fileStatus)}>
                  Re-Process File
                </MenuItem>
              </Menu>
              {/*  Generate Report */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openGenerateButton ? "generate-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openGenerateButton ? "true" : undefined}
                onClick={handleGenerateClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                Generate Report
              </ColorButton>
              <Menu
                id="generate-menu"
                anchorEl={anchorElGenerate}
                open={openGenerateButton}
                onClose={handleCloseGenerateClick}
                MenuListProps={{
                  "aria-labelledby": "generate-button",
                }}
              >
                <MenuItem
                  onClick={() => handleGenerate_SendReport("PDF", "GENERATE")}
                >
                  PDF Report
                </MenuItem>
                <MenuItem
                  onClick={() => handleGenerate_SendReport("DOC", "GENERATE")}
                >
                  WORD Report
                </MenuItem>
              </Menu>
              {/*  Send Report */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openReportButton ? "report-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openReportButton ? "true" : undefined}
                onClick={handleReportClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                Send Report
              </ColorButton>
              <Menu
                id="report-menu"
                anchorEl={anchorElReport}
                open={openReportButton}
                onClose={handleCloseReportClick}
                MenuListProps={{
                  "aria-labelledby": "report-button",
                }}
              >
                <MenuItem
                  onClick={() => handleGenerate_SendReport("PDF", "SEND")}
                >
                  PDF Report
                </MenuItem>
                <MenuItem
                  onClick={() => handleGenerate_SendReport("DOC", "SEND")}
                >
                  DOC Report
                </MenuItem>
              </Menu>
            </div>
          );
        case "SENT":
        case "SENT AND MAIL":
          return (
            <div style={{ display: "flex" }}>
              {/*  Re-Process */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                onClick={() => handleProcess_ReProcess("COMPLETED")}
                variant="contained"
              >
                Re-Process File
              </ColorButton>

              {/*  Generate Report */}
              <ColorButton
                sx={{
                  borderRadius: "10px",
                }}
                aria-controls={openGenerateButton ? "generate-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openGenerateButton ? "true" : undefined}
                onClick={handleGenerateClick}
                variant="contained"
                startIcon={<KeyboardArrowDownIcon />}
              >
                Generate Report
              </ColorButton>
              <Menu
                id="generate-menu"
                anchorEl={anchorElGenerate}
                open={openGenerateButton}
                onClose={handleCloseGenerateClick}
                MenuListProps={{
                  "aria-labelledby": "generate-button",
                }}
              >
                <MenuItem
                  onClick={() => handleGenerate_SendReport("PDF", "GENERATE")}
                >
                  PDF Report
                </MenuItem>
                <MenuItem
                  onClick={() => handleGenerate_SendReport("DOC", "GENERATE")}
                >
                  WORD Report
                </MenuItem>
              </Menu>
            </div>
          );

        default:
          return null;
      }
    }
  };
  return (
    <div style={{ backgroundColor: "#f4f4f4" }}>
      {/* close icon for file view modal */}
      {isDisplayed && (
        <CloseIcon
          style={{
            color: "#171717",
            position: "fixed",
            top: 20,
            right: "50%",
            fontSize: "25px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            padding: "3px",
            zIndex: 1,
          }}
          onClick={() => {
            closeThisFile();
          }}
        />
      )}

      {/*Application Form */}
      <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
        Application Form
        {fromDedupeView ? null : renderSwitch(fileStatus, fileIsActive)}
      </DialogTitle>
      <DialogContent dividers="paper">
        <TableContainer
          component={Paper}
          sx={{
            border: "1px solid #fff",
          }}
        >
          <Table aria-label="simple table">
            <TableBody>
              {Object.keys(newArrayFile).map((key, Index) => (
                <StyledTableRow
                  key={key}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ width: "300px" }}
                  >
                    {key}
                  </StyledTableCell>
                  {key === "Application Image" ? (
                    <StyledTableCell align="left">
                      {newArrayFile[key].length > 0
                        ? newArrayFile[key].map((data) => (
                          <div
                            style={{
                              dislay: "flex",
                              justifyContent: "center",
                              position: "relative",
                              paddingRight: "50px",
                              cursor: "pointer",
                            }}
                          >
                            <ChildModal url={data.url} />
                          </div>
                        ))
                        : "No attachments found"}
                    </StyledTableCell>
                  ) : key === "Supervisor Voice" ? (
                    <StyledTableCell align="left">
                      {(fileStatus === "NEW" ||
                        fileStatus === "INPROGRESS") &&
                        !fromDedupeView && (
                          <CustomTooltip
                            title="Add Supervisor Voice"
                            arrow
                            placement="top"
                            TransitionComponent={Zoom}
                          >
                            <ColorEnableDisableButton
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                color: "#fff",
                              }}
                              aria-label="disable"
                              onClick={addSupervisorVoice}
                            >
                              <KeyboardVoiceIcon />
                              Upload Voice
                            </ColorEnableDisableButton>
                          </CustomTooltip>
                        )}
                      {newArrayFile[key].length > 0
                        ? newArrayFile[key].map((data, index) => (
                          <audio
                            controls
                            src={data.url}
                            style={{
                              width: '400px',
                              height: '30px',
                              display: 'block',
                              margin: '5px',
                              borderRadius: '10px'
                            }}
                          >
                          </audio>
                        ))
                        : "No voice found"}
                    </StyledTableCell>
                  ) : key === "Sampler Voice" ? (
                    <StyledTableCell align="left">
                      {newArrayFile[key].length > 0
                        ? newArrayFile[key].map((data, index) => (
                          <audio
                            controls
                            src={data.url}
                            style={{
                              width: '200px',
                              height: '30px',
                              display: 'block',
                              margin: '5px',
                              borderRadius: '10px'
                            }}
                          >
                          </audio>
                        ))
                        : "No voice found"}
                    </StyledTableCell>
                  ) : key === "Bank Manager Voice" ? (
                    <StyledTableCell align="left">
                      {newArrayFile[key].length > 0
                        ? newArrayFile[key].map((data, index) => (
                          <audio
                            controls
                            src={data.url}
                            style={{
                              width: '200px',
                              height: '30px',
                              display: 'block',
                              margin: '5px',
                              borderRadius: '10px'
                            }}
                          >
                          </audio>
                        ))
                        : "No voice found"}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="left" sx={{ maxWidth: "300px" }}>
                      {newArrayFile[key]}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      {/* VerificationType */}
      {!fromDedupeView &&  // Hide the below details if the file is from a different agency
        <>
          <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
            {fileDocExtra.verificationType} Verification
          </DialogTitle>
          <DialogContent dividers="paper">
            <TableContainer component={Paper} sx={{ border: "1px solid #fff" }}>
              <Table aria-label="simple table">
                <TableBody>
                  {fileViewDetail.map((list) =>
                    list.productFields.slice(0, showMore).map((prod) => (
                      <StyledTableRow
                        key={prod.DISPLAY_NAME}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <StyledTableCell
                          component="th"
                          scope="row"
                          sx={{ width: "300px" }}
                        >
                          {prod.DISPLAY_NAME}
                        </StyledTableCell>
                        <StyledTableCell align="left" sx={{ maxWidth: "200px" }}>
                          {prod.POST_DEFAULT ? (
                            prod.POST_DEFAULT === "AGENCY_REMARKS" ? (
                              <div
                                dangerouslySetInnerHTML={{ __html: prod.VALUE }} //for converting string to html
                              />
                            ) : (
                              prod.VALUE
                            )
                          ) : prod.TYPE === "select" && prod.VALUE?.toLowerCase() === "other" ? (
                            prod.OTHER_VALUE
                          ) : (
                            prod.VALUE
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <ColorIconButton
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: " 8px auto auto",
                padding: "1px",
              }}
              onClick={() => handleShowMore()}
            >
              {showMore <= 4 ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </ColorIconButton>
          </DialogContent>

          {/* Sampled Documents */}
          <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
            Sampled Documents
          </DialogTitle>
          <DialogContent dividers="paper">
            {fileDocDetail.map((list, index) => (
              <TableContainer
                ref={element => {
                  const found = docRefs.current?.find(ele => ele?.isEqualNode(element))
                  if (!found)
                    docRefs.current.push(element)
                  docRefs.current = docRefs.current.filter(Boolean);
                }}
                key={list._id}
                component={Paper}
                sx={{ border: "1px solid #fff" }}
              >
                <ExpandableItem
                  render={(xprops) => (
                    <>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Document Type
                            </StyledTableCell>
                            <StyledTableCell align="left">Status</StyledTableCell>
                            <StyledTableCell align="center">
                              Verifier
                            </StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <StyledTableRow
                            key={list._id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <StyledTableCell
                              align="left"
                              sx={{ maxWidth: "120px", minWidth: "120px" }}
                            >
                              {list.docType}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              sx={{ maxWidth: "100px", minWidth: "100px" }}
                            >
                              {list.verificationStatus}
                            </StyledTableCell>
                            <StyledTableCell
                              align="center"
                              sx={{ maxWidth: "230px", minWidth: "230px" }}
                            >
                              {/* verifier can't be reassigned for verification completed document  */}
                              {list.verificationStatus !== "COMPLETED" ? verifierList.length > 0 && (
                                // <FormControl>
                                //   <Select
                                //     labelId="demo-simple-select-label"
                                //     id="demo-simple-select"
                                //     value={list.verifierId} //here Select value and MenuItem value must be same to display the corresponding data
                                //     onChange={(e) => {
                                //       verifierList.map(
                                //         (verifiers) =>
                                //           verifiers.id === e.target.value &&
                                //           setAssign((oldValues) => ({
                                //             ...oldValues,
                                //             id: list._id,
                                //             fileRefId: list.fileRefId,
                                //             verifierId: e.target.value,
                                //             verifier: `${verifiers.firstName} ${verifiers.lastName}/${verifiers.city}`,
                                //             verifierCity: verifiers.city,
                                //           }))
                                //       );
                                //     }}
                                //     displayEmpty
                                //     sx={{
                                //       width: "250px",
                                //       "& .MuiInputBase-input": {
                                //         color: "#000",
                                //         border: "1px solid #000",
                                //       },
                                //       "&:hover": {
                                //         "&& fieldset": {
                                //           border: "1px solid #000",
                                //         },
                                //       },
                                //       "& .MuiSvgIcon-root": {
                                //         color: "#000",
                                //       },
                                //     }}
                                //   >
                                //     {verifierList.map((verifiers) => (
                                //       <MenuItem
                                //         value={verifiers.id}
                                //         key={verifiers.id}
                                //       >
                                //         {verifiers.firstName} {verifiers.lastName}/
                                //         {verifiers.city}
                                //       </MenuItem>
                                //     ))}
                                //   </Select>
                                // </FormControl>
                                <Autocomplete
                                  disableClearable
                                  open={autoCompleteOpen.length ? autoCompleteOpen[index] : false}
                                  onOpen={() => {
                                    setAutoCompleteOpen(prevValue => {
                                      const newValue = prevValue.slice();
                                      newValue[index] = true
                                      return newValue;
                                    });
                                  }}
                                  onClose={(event) => {
                                    if (event.relatedTarget)
                                      if (event.relatedTarget.tagName !== 'DIV') {
                                        setTimeout(() => {
                                          event.target.focus();
                                        }, 100);
                                        setAutoCompleteOpen(prevValue => {
                                          const newValue = prevValue.slice();
                                          newValue[index] = true
                                          return newValue;
                                        });
                                      }
                                      else
                                        setAutoCompleteOpen(prevValue => {
                                          const newValue = prevValue.slice();
                                          newValue[index] = false
                                          return newValue;
                                        });
                                    else if (event.type === 'click')
                                      setAutoCompleteOpen(prevValue => {
                                        const newValue = prevValue.slice();
                                        newValue[index] = false
                                        return newValue;
                                      });
                                    else
                                      setAutoCompleteOpen(prevValue => {
                                        const newValue = prevValue.slice();
                                        newValue[index] = true
                                        return newValue;
                                      });
                                  }}
                                  value={verifierList.find(verifier => verifier.id === list.verifierId)}
                                  options={verifierList}
                                  getOptionLabel={(option) => `${option.firstName} ${option.lastName}/${option.city}`}
                                  renderInput={(params) => <TextField {...params} label="Select verifier" />}
                                  renderOption={(props, option) =>
                                    <Box component="li" {...props} key={option.id}>
                                      {option.firstName} {option.lastName}/{option.city}
                                    </Box>
                                  }
                                  onChange={(e, newValue) => {
                                    verifierList.map(
                                      (verifiers) =>
                                        verifiers.id === newValue.id &&
                                        setAssign((oldValues) => ({
                                          ...oldValues,
                                          id: list._id,
                                          fileRefId: list.fileRefId,
                                          verifierId: newValue.id,
                                          verifier: `${verifiers.firstName} ${verifiers.lastName}/${verifiers.city}`,
                                          verifierCity: verifiers.city,
                                        }))
                                    );
                                  }}
                                />
                              ) : (
                                list.verifier
                              )}
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              sx={{ maxWidth: "160px", minWidth: "160px" }}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                                alignItems="center"
                              >
                                {/* view more/less */}
                                <CustomTooltip
                                  title="View"
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                >
                                  <ColorIconButton
                                    aria-label="view"
                                    // onClick={() => handleViewMore(list._id)}
                                    onClick={() =>
                                      xprops.setOpenView(!xprops.openView)
                                    }
                                    //adding style to change background color on button click
                                    sx={{
                                      backgroundColor: xprops.openView && "#fff",
                                      color: xprops.openView && "#1553b5",
                                      border:
                                        xprops.openView && "2px solid #1553b5",
                                      "&:hover": {
                                        backgroundColor: xprops.openView && "#fff",
                                        color: xprops.openView && "#1553b5",
                                        border:
                                          xprops.openView && "2px solid #1553b5",
                                      },
                                    }}
                                  >
                                    <RemoveRedEyeIcon />
                                  </ColorIconButton>
                                </CustomTooltip>
                                {/* edit */}
                                {fileStatus !== "COMPLETED" && (
                                  <CustomTooltip
                                    title="Edit"
                                    arrow
                                    placement="top"
                                    TransitionComponent={Zoom}
                                  >
                                    <ColorIconButton
                                      color="secondary"
                                      aria-label="edit"
                                      onClick={() => handleEdit(list._id, index, list.docRefId)}
                                      sx={{
                                        backgroundColor: list._id === selectedDocId && "#fff",
                                        color: list._id === selectedDocId && "#1553b5",
                                        border:
                                          list._id === selectedDocId && "2px solid #1553b5",
                                        "&:hover": {
                                          backgroundColor: list._id === selectedDocId && "#fff",
                                          color: list._id === selectedDocId && "#1553b5",
                                          border:
                                            list._id === selectedDocId && "2px solid #1553b5",
                                        },
                                      }}
                                    >
                                      <EditIcon />
                                    </ColorIconButton>
                                  </CustomTooltip>
                                )}
                                {/* re-verify */}
                                {fileStatus === "VERIFIED" && (
                                  <CustomTooltip
                                    title="Re-verify"
                                    arrow
                                    placement="top"
                                    TransitionComponent={Zoom}
                                  >
                                    <ColorIconButton
                                      color="secondary"
                                      aria-label="edit"
                                      onClick={() => handleReverify(list)}
                                    >
                                      <AssignmentIndIcon />
                                    </ColorIconButton>
                                  </CustomTooltip>
                                )}
                                {/* disable */}
                                <CustomTooltip
                                  title={
                                    list.isActive === "inactive"
                                      ? "Enable Document"
                                      : "Disable Document"
                                  }
                                  arrow
                                  placement="top"
                                  TransitionComponent={Zoom}
                                >
                                  <ColorEnableDisableButton
                                    // sx={{
                                    //   display: "flex",
                                    //   flexDirection: "row",
                                    //   color: "#fff",
                                    // }}
                                    sx={{
                                      backgroundColor: list.isActive === "inactive" && "#fff",
                                      color: list.isActive === "inactive" ? "#1553b5" : "#fff",
                                      border:
                                        list.isActive === "inactive" && "2px solid #1553b5",
                                      "&:hover": {
                                        backgroundColor: list.isActive === "inactive" && "#fff",
                                        color: list.isActive === "inactive" && "#1553b5",
                                        border:
                                          list.isActive === "inactive" && "2px solid #1553b5",
                                      },
                                    }}
                                    aria-label="disable"
                                    onClick={() => handleDisable(list._id)}
                                  >
                                    {/* <DoDisturbAltIcon /> */}
                                    {list.isActive === "inactive"
                                      ? "Enable"
                                      : "Disable"}
                                  </ColorEnableDisableButton>
                                </CustomTooltip>
                              </Stack>
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableBody>
                      </Table>
                      {/* {list._id === selectedDocId && ( */}
                      <Collapse in={xprops.openView}>
                        <TableContainer
                          component={Paper}
                          sx={{ border: "1px solid #fff" }}
                        >
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center">
                                  Doc Details
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Sampler Attachments
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Verifier Attachments
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <StyledTableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell align="center">
                                  <TableContainer
                                    component={Paper}
                                    sx={{
                                      border: "1px solid #fff",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <Table aria-label="simple table">
                                      <TableBody>
                                        {list.fields.map((result) => (
                                          <StyledTableRow
                                            key={result.DISPLAY_NAME}
                                            sx={{
                                              "&:last-child td, &:last-child th": {
                                                border: 0,
                                              },
                                            }}
                                          >
                                            <StyledTableCell
                                              component="th"
                                              scope="row"
                                              sx={{ width: "200px" }}
                                            >
                                              {result.DISPLAY_NAME}
                                            </StyledTableCell>
                                            <StyledTableCell
                                              align="left"
                                              sx={{ maxWidth: "200px" }}
                                            >
                                              {result.TYPE === "select" &&
                                                result.VALUE?.toLowerCase() === "other"
                                                ? result.OTHER_VALUE
                                                : result.VALUE}
                                            </StyledTableCell>
                                          </StyledTableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  sx={{ minWidth: "200px" }}
                                >
                                  {list.docImage
                                    ? list.docImage.map((data) => (
                                      <div
                                        style={{
                                          dislay: "flex",
                                          justifyContent: "center",
                                          position: "relative",
                                          paddingRight: "50px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        {data.url.length > 0 && (
                                          <ChildModal url={data.url} />
                                        )}
                                      </div>
                                    ))
                                    : "No Attachments Found"}
                                </StyledTableCell>
                                <StyledTableCell
                                  align="center"
                                  sx={{ maxWidth: "230px" }}
                                >
                                  <div>
                                    {list.verifierRecord.length > 0 &&
                                      list.verifierRecord.map((data, index) => (
                                        <audio
                                          controls
                                          src={data.url}
                                          style={{
                                            width: '100%',
                                            height: '30px',
                                            display: 'block',
                                            marginBottom: '5px',
                                            borderRadius: '10px'
                                          }}
                                        >
                                        </audio>
                                        // <div
                                        //   className="voice_record"
                                        //   style={{
                                        //     display: "flex",
                                        //     alignItems: "center",
                                        //     justifyContent: "center",
                                        //   }}
                                        // >
                                        //   <audio
                                        //     controls
                                        //     ref={(el) => {
                                        //       audioRef.current[index] = el;
                                        //     }}
                                        //     onTimeUpdate={() =>
                                        //       changeTimelinePosition(index)
                                        //     }
                                        //     onEnded={() => audioEnded(index)}
                                        //     src={data.url}
                                        //     hidden
                                        //   ></audio>
                                        //   <div className="controls">
                                        //     <button
                                        //       type="button"
                                        //       className="player-button"
                                        //       ref={(el) => {
                                        //         playerButton.current[index] = el;
                                        //       }}
                                        //       onClick={() => toggleAudio(index)}
                                        //     >
                                        //       <svg
                                        //         xmlns="http://www.w3.org/2000/svg"
                                        //         viewBox="0 0 20 20"
                                        //         fill="#1553b5"
                                        //       >
                                        //         <path
                                        //           fillRule="evenodd"
                                        //           d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                        //           clipRule="evenodd"
                                        //         />
                                        //       </svg>
                                        //     </button>
                                        //     <input
                                        //       type="range"
                                        //       ref={(el) => {
                                        //         timeline.current[index] = el;
                                        //       }}
                                        //       className="timeline"
                                        //       max="100"
                                        //       value="0"
                                        //       onChange={() => changeSeek(index)}
                                        //     />
                                        //     <button
                                        //       type="button"
                                        //       className="sound-button"
                                        //       ref={(el) => {
                                        //         soundButton.current[index] = el;
                                        //       }}
                                        //       onClick={() => toggleSound(index)}
                                        //     >
                                        //       <svg
                                        //         xmlns="http://www.w3.org/2000/svg"
                                        //         viewBox="0 0 20 20"
                                        //         fill="#1553b5"
                                        //       >
                                        //         <path
                                        //           fillRule="evenodd"
                                        //           d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z"
                                        //           clipRule="evenodd"
                                        //         />
                                        //       </svg>
                                        //     </button>
                                        //     <button
                                        //       type="button"
                                        //       className="download-button"
                                        //       onClick={() =>
                                        //         downloadSound(data.url)
                                        //       }
                                        //     >
                                        //       <svg
                                        //         xmlns="http://www.w3.org/2000/svg"
                                        //         width="24"
                                        //         height="24"
                                        //         viewBox="0 0 24 24"
                                        //         fill="none"
                                        //         stroke="#1553b5"
                                        //         stroke-width="2"
                                        //         stroke-linecap="round"
                                        //         stroke-linejoin="round"
                                        //       >
                                        //         <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5" />
                                        //       </svg>
                                        //       <downloadIcon />
                                        //     </button>
                                        //   </div>
                                        // </div>
                                      ))}
                                  </div>
                                  {list.verifierImage.length > 0 && (
                                    <div
                                      style={{
                                        border: "1px solid #fff",
                                        borderRadius: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <FormControl
                                        sx={{ m: 3 }}
                                        component="fieldset"
                                        variant="standard"
                                      >
                                        <FormGroup>
                                          {list.verifierImage.map((img, index) => (
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: "pointer",
                                              }}
                                            >
                                              {fileStatus !== "INPROGRESS" &&
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={img.checked}
                                                      onChange={(e) => {
                                                        img.checked = !img.checked;
                                                        console.log(fileDocDetail);
                                                        setFileDocDetail([
                                                          ...fileDocDetail,
                                                        ]);
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />}

                                              <ChildModal url={img.url} imageList={list.verifierImage} imageIndex={index} />
                                            </div>
                                          ))}

                                          {fileStatus !== "INPROGRESS" &&
                                            <ColorEnableDisableButton
                                              sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                color: "#fff",
                                              }}
                                              aria-label="disable"
                                              onClick={() =>
                                                handleAddToReport(
                                                  list._id,
                                                  list.verifierImage
                                                )
                                              }
                                            >
                                              Add to report
                                            </ColorEnableDisableButton>}
                                        </FormGroup>
                                      </FormControl>
                                    </div>
                                  )}
                                  {["VERIFIED", "INPROGRESS", "COMPLETED", "SENT", "SENT AND MAIL"].includes(fileStatus) && (
                                    <div
                                      style={{
                                        border: "1px solid #fff",
                                        borderRadius: "10px",
                                        width: '190px'
                                      }}
                                    >
                                      <label
                                        htmlFor={"contained-button-doc-" + list._id}
                                      >
                                        <Input
                                          accept="application/pdf, image/*"
                                          id={"contained-button-doc-" + list._id}
                                          type="file"
                                          multiple
                                          onChange={async (s) => {
                                            var applicationForm = new FormData();
                                            applicationForm.append(
                                              "s3path",
                                              "document_type"
                                            );
                                            const files = s.target.files;

                                            if (files.length > 20) {
                                              swal({
                                                title: `Cannot upload more than 20 files at a time`,
                                                icon: "error",
                                              })

                                              return;
                                            }

                                            for (let i = 0; i < files.length; i++) {
                                              applicationForm.append('file', files[i]);
                                            }
                                            try {
                                              const response = await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                                                headers: {
                                                  Authorization: `Bearer ${token}`,
                                                  "Content-Type":
                                                    "multipart/form-data",
                                                },
                                                data: applicationForm,
                                              });
                                              if (response.data.s3Url) {
                                                let updatedExternalDocImg =
                                                  list.externalDoc
                                                    ? [
                                                      ...list.externalDoc,
                                                      ...response.data.s3Url.map(url => ({ url, checked: false }))
                                                    ]
                                                    : [
                                                      ...response.data.s3Url.map(url => ({ url, checked: false }))
                                                    ];
                                                try {
                                                  const response = await axios({
                                                    method: "post",
                                                    url: `${process.env.REACT_APP_NODESERVER}/api/v1/document/manage/updateDocument`,
                                                    headers: {
                                                      Authorization: `Bearer ${token}`,
                                                    },
                                                    data: {
                                                      id: list._id,
                                                      externalDoc:
                                                        updatedExternalDocImg,
                                                    },
                                                  });
                                                  if (
                                                    response.data.status ===
                                                    "success"
                                                  ) {
                                                    list.externalDoc =
                                                      updatedExternalDocImg;
                                                    setFileDocDetail([
                                                      ...fileDocDetail,
                                                    ]);
                                                  }
                                                  swal({
                                                    title: `External Document Upload Complete`,
                                                    icon: "success",
                                                  });
                                                } catch (error) {
                                                  swal({
                                                    title: `File Upload Failed`,
                                                    icon: "error",
                                                  });
                                                }
                                              }
                                            } catch (error) {
                                              swal({
                                                title: `Document Upload Failed`,
                                                icon: "error",
                                              });
                                            }
                                          }}
                                          onClick={(event) => {
                                            event.target.value = "";
                                          }}
                                        />
                                        <ColorEnableDisableButton
                                          sx={{
                                            color: "#fff",
                                            margin: "10px 0",
                                          }}
                                          variant="contained"
                                          component="span"
                                        >
                                          Upload Document
                                        </ColorEnableDisableButton>
                                      </label>

                                      {list.externalDoc.length > 0 && (
                                        <FormControl
                                          sx={{ m: 3 }}
                                          component="fieldset"
                                          variant="standard"
                                        >
                                          <FormGroup>
                                            {list.externalDoc.map((img) => (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <FormControlLabel
                                                  control={
                                                    <Checkbox
                                                      checked={img.checked}
                                                      onChange={(e) => {
                                                        img.checked = !img.checked;
                                                        setFileDocDetail([
                                                          ...fileDocDetail,
                                                        ]);
                                                      }}
                                                    />
                                                  }
                                                  label=""
                                                />

                                                <ChildModal url={img.url} />
                                              </div>
                                            ))}

                                            <ColorEnableDisableButton
                                              sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                color: "#fff",
                                              }}
                                              aria-label="disable"
                                              onClick={() =>
                                                handleAddExternalDoc(
                                                  list._id,
                                                  list.externalDoc
                                                )
                                              }
                                            >
                                              Add Document
                                            </ColorEnableDisableButton>
                                          </FormGroup>
                                        </FormControl>
                                      )}
                                    </div>
                                  )}
                                </StyledTableCell>
                              </StyledTableRow>
                            </TableBody>
                          </Table>

                          {list.verificationStatus === "COMPLETED" && (
                            <>
                              <div
                                style={{
                                  backgroundColor: "#cccdcd",
                                  padding: "20px",
                                }}
                              >
                                <p style={{ color: "#373737" }}>Remarks</p>
                                <TextareaAutosize
                                  minRows={3}
                                  maxRows={6}
                                  aria-label="empty textarea"
                                  placeholder="Empty"
                                  name="verifierRemark"
                                  value={list.verificationRemarks}
                                  readOnly={fileStatus === "COMPLETED"} //editable text area only on verified tab
                                  onChange={(e) => {
                                    list.verificationRemarks = e.target.value;
                                    setFileDocDetail([...fileDocDetail]);
                                  }}
                                  style={{
                                    width: "98%",
                                    margin: "5px",
                                    backgroundColor: "#cccdcd",
                                    color: "#373737",
                                    border: "1px solid #373737",
                                    fontSize: "16px",
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#cccdcd",
                                  padding: "20px",
                                }}
                              >
                                <p style={{ color: "#373737" }}>Other Remarks</p>
                                <TextareaAutosize
                                  minRows={3}
                                  maxRows={6}
                                  aria-label="empty textarea"
                                  placeholder="Empty"
                                  name="otherRemark"
                                  value={list.otherRemarks}
                                  readOnly={fileStatus === "COMPLETED"} //editable text area only on verified tab
                                  onChange={(e) => {
                                    list.otherRemarks = e.target.value;
                                    setFileDocDetail([...fileDocDetail]);
                                  }}
                                  style={{
                                    width: "98%",
                                    margin: "5px",
                                    backgroundColor: "#cccdcd",
                                    color: "#373737",
                                    border: "1px solid #373737",

                                    fontSize: "16px",
                                  }}
                                />
                              </div>
                              {/* edit remark button */}
                              {fileStatus !== "COMPLETED" && (
                                <div
                                  style={{
                                    backgroundColor: "#cccdcd",
                                    padding: "0 20px 10px",
                                  }}
                                >
                                  <ColorEnableDisableButton
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      color: "#fff",
                                    }}
                                    aria-label="disable"
                                    onClick={() =>
                                      editRemark(
                                        list._id,
                                        list.verificationRemarks,
                                        list.otherRemarks
                                      )
                                    }
                                  >
                                    Update Remark
                                  </ColorEnableDisableButton>
                                </div>
                              )}
                              <div
                                style={{
                                  backgroundColor: "#cccdcd",
                                  padding: "20px",
                                  color: "#373737",
                                }}
                              >
                                <h2>OGL Details</h2>
                              </div>
                              {isLoadingBranchList ?
                                <LinearProgress color="inherit" /> :
                                <>
                                  <div
                                    style={{
                                      backgroundColor: "#cccdcd",
                                      padding: "20px",
                                    }}
                                  >
                                    {/* <CssTextField
                              inputProps={
                                fileStatus === "COMPLETED" && {
                                  readOnly: true,
                                } //editable input only on verified tab
                              }
                              id="outlined-samplerLoc"
                              label="Sampler Location"
                              name="samplerLocation"
                              value={fileDocExtra.samplerLocation || ""}
                              sx={{ width: "100%" }}
                              onChange={handleChange}
                            /> */}
                                    <CssTextField
                                      select
                                      focused
                                      sx={{ width: "100%" }}
                                      label="Sampler Location"
                                      name="samplerLocation"
                                      value={selectedSamplerLocation && selectedSamplerLocation[index]?.samplerLocation || ''}
                                      onChange={(event) => {
                                        setSelectedSamplerLocation.length > 0 &&
                                          setSelectedSamplerLocation((currentValue) => {
                                            try {
                                              const newValue = currentValue.slice();  // create a new reference
                                              newValue[index].samplerLocation = event.target.value
                                              return newValue;
                                            } catch (error) {
                                              console.log(error);
                                              setTriggerOGLReload(prevValue => !prevValue)
                                            }
                                          });
                                      }}
                                      // InputProps={{
                                      //   style: { width: "300px" },
                                      // }}
                                      inputProps={
                                        fileStatus === "COMPLETED" && {
                                          readOnly: true,
                                        } //editable input only on verified tab
                                      }
                                    >
                                      {branchList && branchList.map((option) => (
                                        <MenuItem key={option.id} value={option}>
                                          {option.branchName}
                                        </MenuItem>
                                      ))}
                                    </CssTextField>
                                  </div>
                                  <div
                                    style={{
                                      backgroundColor: "#cccdcd",
                                      padding: "20px",
                                    }}
                                  >
                                    <CssTextField
                                      inputProps={{
                                        readOnly: true,
                                        //editable input only on verified tab
                                      }}
                                      id="outlined-verifierLoc"
                                      label="Verifier Location"
                                      name="verifierLocation"
                                      value={list.verifierLocation || "Not found"}
                                      sx={{ width: "100%" }}
                                      onChange={(e) => {
                                        list.verifierLocation = e.target.value;
                                        setFileDocDetail([...fileDocDetail]);
                                      }}
                                    />
                                  </div>
                                  {fileStatus !== "COMPLETED" && (
                                    <div
                                      style={{
                                        backgroundColor: "#cccdcd",
                                        padding: "0 20px 10px",
                                      }}
                                    >
                                      <ColorEnableDisableButton
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          color: "#fff",
                                        }}
                                        aria-label="disable"
                                        onClick={() => calculateDistance(index)}
                                        loading={calculatingDistance}
                                      >
                                        Calculate Distance
                                      </ColorEnableDisableButton>
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      backgroundColor: "#cccdcd",
                                      padding: "20px",
                                    }}
                                  >
                                    <CssTextField
                                      inputProps={
                                        fileStatus === "COMPLETED" && {
                                          readOnly: true,
                                        } //editable input only on verified tab
                                      }
                                      focused
                                      id="outlined-distance"
                                      label="Distance"
                                      name="distance"
                                      // type="number"
                                      value={selectedSamplerLocation && selectedSamplerLocation[index]?.distance || ""}
                                      sx={{ width: "100%" }}
                                      onChange={(event) => {
                                        selectedSamplerLocation.length > 0 &&
                                          setSelectedSamplerLocation((currentValue) => {
                                            const newValue = currentValue.slice();  // create a new reference
                                            newValue[index].distance = event.target.value
                                            return newValue;
                                          });
                                      }}
                                    />
                                  </div>
                                  {/* save distance button */}
                                  {fileStatus !== "COMPLETED" && (
                                    <div
                                      style={{
                                        backgroundColor: "#cccdcd",
                                        padding: "0 20px 10px",
                                      }}
                                    >
                                      <ColorEnableDisableButton
                                        disabled={
                                          !(selectedSamplerLocation &&
                                            parseFloat(selectedSamplerLocation[index]?.distance))
                                        }
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          color: "#fff",
                                          '&:disabled': {
                                            backgroundColor: 'gray'
                                          }
                                        }}
                                        aria-label="disable"
                                        onClick={() =>
                                          saveDistance(index)
                                        }
                                      >
                                        Save Distance
                                      </ColorEnableDisableButton>
                                    </div>
                                  )}
                                </>}
                            </>
                          )}
                        </TableContainer>
                      </Collapse>
                      {/* //edit doc )} */}
                      {list._id === selectedDocId && (
                        <div style={{ backgroundColor: "#cccdcd" }}>
                          <Collapse in={editChecked}>
                            <DialogContent dividers="paper">
                              <Box
                                sx={{
                                  borderRadius: "20px",
                                  border: "2px solid #1553b5",
                                  height: "100%",
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  "& > :not(style)": { m: 4 },
                                }}
                                component="form"
                                autoComplete="off"
                                onSubmit={handleDocUpdate}
                              >
                                <label htmlFor="contained-button-formUpload">
                                  <Input
                                    accept="application/pdf, image/*"
                                    id="contained-button-formUpload"
                                    type="file"
                                    multiple
                                    onChange={async (s) => {
                                      var applicationForm = new FormData();
                                      applicationForm.append(
                                        "s3path",
                                        "application-form"
                                      );
                                      const files = s.target.files;

                                      if (files.length > 20) {
                                        swal({
                                          title: `Cannot upload more than 20 files at a time`,
                                          icon: "error",
                                        })

                                        return;
                                      }

                                      for (let i = 0; i < files.length; i++) {
                                        applicationForm.append('file', files[i]);
                                      }
                                      try {
                                        const response = await axios({
                                          method: "post",
                                          url: `${process.env.REACT_APP_AWSUPLOAD}/api/upload/superadmin/uploadMultipleFilesToS3`,
                                          headers: {
                                            Authorization: `Bearer ${token}`,
                                            "Content-Type": "multipart/form-data",
                                          },
                                          data: applicationForm,
                                        });
                                        if (response.data.s3Url) {
                                          let updatedDocImage = [
                                            ...inputs.docImage,
                                            ...response.data.s3Url.map(url => ({ url })),
                                          ];
                                          setInputs((values) => ({
                                            ...values,
                                            docImage: updatedDocImage,
                                          }));
                                          swal({
                                            title: `File Upload Complete`,
                                            icon: "success",
                                          });
                                        }
                                      } catch (error) {
                                        swal({
                                          title: `File Upload Failed`,
                                          icon: "error",
                                        });
                                      }
                                    }}
                                  />
                                  <ColorButton
                                    sx={{
                                      color: "#fff",
                                      backgroundColor: "#1553b5",
                                      borderRadius: "10px",
                                      fontSize: "16px",
                                    }}
                                    variant="contained"
                                    component="span"
                                    startIcon={<AttachFileIcon />}
                                  >
                                    Attach Documents
                                  </ColorButton>
                                </label>
                                <div style={{ margin: "0" }}>
                                  {inputs.docImage?.length > 0 ? (
                                    <div
                                      className="drop-file-preview"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "50%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {inputs.docImage.map((item, index) => (
                                          <div
                                            key={index}
                                            // className="drop-file-preview__item"
                                            style={{ display: "flex" }}
                                          >
                                            {inputs.docImage[index]?.url.length >
                                              0 && (
                                                <>
                                                  <div style={{ cursor: "pointer" }}>
                                                    <ChildModal
                                                      url={
                                                        inputs.docImage[index]?.url
                                                      }
                                                    />
                                                  </div>
                                                  <div>
                                                    <span
                                                      onClick={() => {
                                                        inputs.docImage.splice(
                                                          index,
                                                          1
                                                        );
                                                        setFileDocDetail([
                                                          ...fileDocDetail,
                                                        ]);
                                                      }}
                                                      style={{
                                                        color: "#F44336",
                                                        width: "14px",
                                                        height: "26px",
                                                        fontWeight: "900",
                                                        borderRadius: "50%",
                                                        paddingLeft: "10px",
                                                        transform: "translateY(-50%)",
                                                        boxShadow:
                                                          "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                                                        cursor: "pointer",
                                                        opacity: "1",
                                                        transition:
                                                          "opacity 0.3s ease",
                                                      }}
                                                    >
                                                      x
                                                    </span>
                                                  </div>
                                                </>
                                              )}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {docFields.map((e) => {
                                  if (
                                    e.FILLED_BY?.toLowerCase() === "verifier" ||
                                    e.FILLED_BY?.toLowerCase() === "sampler"
                                  ) {
                                    return e.TYPE === "select" ? (
                                      <>
                                        <CssTextField
                                          select
                                          focused
                                          type={e.TYPE}
                                          id={`outlined-${e.DISPLAY_NAME}`}
                                          label={e.DISPLAY_NAME}
                                          name={e.DISPLAY_NAME}
                                          value={e.VALUE}
                                          onChange={handleDocFieldChange}
                                          sx={{ margin: "auto" }}
                                          key={e.DISPLAY_NAME}
                                        >
                                          {e.FIELD === "status"
                                            ? e.VALUES_LIST[0].value.map(
                                              (option) => (
                                                <MenuItem
                                                  key={option.type}
                                                  value={option.type}
                                                >
                                                  {option.status}
                                                </MenuItem>
                                              )
                                            )
                                            : e.VALUES_LIST.map((option) => (
                                              <MenuItem
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.value}
                                              </MenuItem>
                                            ))}
                                        </CssTextField>
                                        {e.VALUE?.toLowerCase() === "other" && (
                                          <CssTextField
                                            focused
                                            multiline
                                            required
                                            id="outlined-fileNo"
                                            label={`Other Value (${e.DISPLAY_NAME})`}
                                            name="otherValue"
                                            value={e.OTHER_VALUE || ""}
                                            onChange={(s) => {
                                              e.OTHER_VALUE = s.target.value;
                                              setDocFields([...docFields]);
                                            }}
                                            sx={{
                                              marginLeft: "30px",
                                            }}
                                          />
                                        )}
                                      </>
                                    ) : e.FIELD === 'number' &&
                                      ['6461afffd910459d01ed6b70', '6461b08ad910459d01ed6cb0'].includes(selectedDocRefId) ? (
                                      <CssTextField
                                        focused
                                        sx={{
                                          flexGrow: 1
                                        }}
                                        type={e.TYPE}
                                        id={`outlined-${e.DISPLAY_NAME}`}
                                        inputProps={{ maxLength: e.MAX_CHAR }}
                                        label={e.DISPLAY_NAME}
                                        name={e.FIELD}
                                        value={e.VALUE}
                                        onChange={(event) => handlePANChange(event, e, index)}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              {panVerifyStatus?.isPanValid ? (
                                                <LoadingColorButton
                                                  loading={panVerLoading}
                                                  size="large"
                                                  startIcon={
                                                    panVerifyStatus?.isPanAuthentic === undefined ?
                                                      <CloudSyncIcon sx={{ color: 'white !important' }} /> :
                                                      (panVerifyStatus?.isPanAuthentic ?
                                                        <CheckCircleOutlinedIcon sx={{ color: 'white !important' }} /> : <CancelOutlinedIcon sx={{ color: 'white !important' }} />)
                                                  }
                                                  loadingPosition="start"
                                                  onClick={() => handleVerifyPAN(index)}
                                                  sx={{
                                                    width: 150,
                                                    margin: '5px 0',
                                                    color: "#fff",
                                                    fontSize: "13px",
                                                    padding: "6px 10px",
                                                    backgroundColor: panVerifyStatus?.isPanAuthentic === undefined ? 'primary.main' :
                                                      (panVerifyStatus?.isPanAuthentic ? 'success.light' : 'error.light'),
                                                    '&:hover': {
                                                      backgroundColor: panVerifyStatus?.isPanAuthentic === undefined ? 'primary.main' :
                                                        (panVerifyStatus?.isPanAuthentic ? 'success.light' : 'error.light'),
                                                    }
                                                  }}
                                                  variant="contained"
                                                >
                                                  {panVerifyStatus?.isPanAuthentic === undefined ?
                                                    panVerLoading ? 'Verifying Pan' : 'Verify Pan' :
                                                    panVerifyStatus?.isPanAuthentic ? 'Pan Verified' : 'Invalid Pan'}
                                                </LoadingColorButton>
                                              ) : (
                                                <LoadingButton
                                                  disableRipple
                                                  variant="outlined"
                                                  color="error"
                                                  startIcon={
                                                    <CancelOutlinedIcon
                                                      sx={{
                                                        color: '#d32f2f !important'
                                                      }}
                                                    />
                                                  }
                                                  sx={{
                                                    width: 150,
                                                    visibility: panVerifyStatus !== null ? "visible" : "hidden",
                                                    margin: '5px 0',
                                                    fontSize: "13px",
                                                    padding: "6px 10px",
                                                    borderWidth: 2,
                                                    "&:hover": {
                                                      borderWidth: 2
                                                    },
                                                  }}
                                                >
                                                  Invalid PAN
                                                </LoadingButton>
                                              )}
                                            </InputAdornment>
                                          )
                                        }}
                                      />
                                    ) : e.TYPE === "date" ? (
                                      <LocalizationProvider key={e.DISPLAY_NAME} dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          value={(() => {
                                            if (typeof e.VALUE === "string") {
                                              const dateString = e.VALUE;
                                              let parts = dateString.split("-");
                                              let day = parseInt(parts[0], 10);
                                              let month = parseInt(parts[1], 10) - 1; // Subtract 1 from month because months are zero-indexed
                                              let year = parseInt(parts[2], 10);
                                              return new Date(year, month, day);
                                            }
                                            return e.VALUE
                                          })()}
                                          label={e.DISPLAY_NAME}
                                          inputFormat="DD-MM-YYYY"
                                          InputProps={{ name: e.FIELD }}
                                          renderInput={(params) => (
                                            <CssTextField
                                              focused {...params}
                                            />
                                          )}
                                          onChange={value => {
                                            let updatedFieldArray = docFields?.map((a) => {
                                              return { ...a };
                                            });
                                            updatedFieldArray.find((a) => a.FIELD == e.FIELD).VALUE = value;
                                            setDocFields(updatedFieldArray);
                                          }}
                                        />
                                      </LocalizationProvider>
                                    ) : (
                                      <CssTextField
                                        focused
                                        type={e.TYPE}
                                        id={`outlined-${e.DISPLAY_NAME}`}
                                        label={e.DISPLAY_NAME}
                                        name={e.DISPLAY_NAME}
                                        value={e.VALUE}
                                        onInput={(num) => {
                          
                                          e.TYPE ==="text_caps" &&
                                          (num.target.value=num.target.value.toUpperCase());
                                      }}
                                        onChange={handleDocFieldChange}
                                      />
                                    );
                                  }
                                })}
                                <ColorButton
                                  sx={{
                                    color: "#fff",
                                    backgroundColor: "#1553b5",
                                    borderRadius: "10px",
                                    fontSize: "16px",
                                    letterSpacing: "4px",
                                    marginTop: "20px",
                                    minWidth: "200px",
                                  }}
                                  loading={loading}
                                  loadingPosition="start"
                                  type="submit"
                                  variant="contained"
                                  startIcon={<UpdateIcon />}
                                >
                                  Update Document
                                </ColorButton>
                              </Box>
                            </DialogContent>
                          </Collapse>
                        </div>
                      )}
                    </>
                  )}
                />
              </TableContainer>
            ))}
          </DialogContent>

          {/* Remarks */}
          {(fileStatus === "COMPLETED" ||
            fileStatus === "SENT" ||
            fileStatus === "SENT AND MAIL") && (
              <>
                <DialogTitle id="scroll-dialog-title" sx={DialogTitleStyle}>
                  Remarks
                </DialogTitle>
                <DialogContent dividers="paper">
                  {fileViewDetail.map((list) => (
                    <TableContainer
                      key={list._id}
                      component={Paper}
                      sx={{ border: "1px solid #fff" }}
                    >
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">Document</StyledTableCell>
                            <StyledTableCell align="left">Remarks</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {list.fileVerificationRemarks.map((remarks, index) => (
                            <StyledTableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}
                            >
                              <StyledTableCell align="left" sx={{ width: "200px" }}>
                                {remarks.docType}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                {remarks.remark}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))}
                </DialogContent>
              </>
            )}
        </>}

      <Dialog
        sx={{
          "& .css-twia2z-MuiPaper-root-MuiDialog-paper ": {
            borderRadius: "30px",
            backgroundColor: "#0E3F63",
          },
        }}
        open={openRecordVoiceView}
        TransitionComponent={Transition}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="xs" //You can change md to sm, xs, lg and xl as per your requirement.
      >
        <VoiceRecord
          onVoiceUpload={(voice) => setInputVoice(voice)}
          onUpload={(bool) => onUpload(bool)}
        />
      </Dialog>
      <Dialog>
        
      </Dialog>
    </div>
  );
};
