import React from "react";
import "./login.css";
import swal from "sweetalert";

import { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux"; // useSelect for accessing state and useDispatch for updating state
import { token } from "../../../../features/token";

const AdminLogin = ({}) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      const params = new URLSearchParams();
      params.append("grant_type", "password");
      params.append("client_id", process.env.REACT_APP_CLIENT_ID);
      params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET);
      params.append("username", user.username);
      params.append("password", user.password);
      await axios({
        method: "post",
        url: process.env.REACT_APP_AUTHSERVER,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: params,
      }).then(async (response) => {
        // handle success
        localStorage.setItem("token", response.data.access_token);
        dispatch(token({ token: response.data.access_token }));
      });
    } catch (err) {
      swal({
        title: "Invalid user credentials",
        text: "Check your username/password and try again.",
        icon: "error",
      });
    }
  };

  return (
    <div className="container">
      <form action="" onSubmit={loginSubmit} className="adminLoginForm">
        <p className="login-title">Welcome</p>
        <input
          className="login-input"
          type="text"
          required
          value={user.username}
          placeholder="Username"
          onChange={(e) => {
            setUser({ ...user, username: e.target.value });
          }}
        />
        <br />
        <input
          className="login-input"
          type="password"
          required
          value={user.password}
          placeholder="Password"
          onChange={(e) => {
            setUser({ ...user, password: e.target.value });
          }}
        />
        <br />
        <input className="login-input" type="submit" value="Sign in" />
        <br />
      </form>

      <div className="drops">
        <div className="drop drop-1"></div>
        <div className="drop drop-2"></div>
        <div className="drop drop-3"></div>
        <div className="drop drop-4"></div>
        <div className="drop drop-5"></div>
        <div className="drop drop-6"></div>
        <div className="drop drop-7"></div>
        <div className="drop drop-8"></div>
      </div>
    </div>
  );
};

export default AdminLogin;
