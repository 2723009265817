import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeDrawerSelection } from "../../../features/spselection";

//mui
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// mui icons
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import ArticleIcon from "@mui/icons-material/Article";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DownloadIcon from "@mui/icons-material/Download";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import InsightsIcon from "@mui/icons-material/Insights";

export const Spdrawer = () => {
  const dispatch = useDispatch();
  const { value } = useSelector((state) => state.spselection.value);
  const [selectedText, setSelectedText] = useState("File");

  const handleSelection = (value, text) => {
    setSelectedText(text);
    dispatch(
      changeDrawerSelection({
        value: value,
        currentBankId: localStorage.getItem("currentBankId"),
      })
    );
  };

  const itemsList = [
    {
      text: "Dashboard",
      icon: <InsightsIcon />,
      onClick: (text) => handleSelection("Dashboard", text)
    },
    {
      text: "File",
      icon: <ArticleIcon />,
      onClick: (text) => handleSelection("File", text),
    },
    {
      text: "Document",
      icon: <InsertDriveFileIcon />,
      onClick: (text) => handleSelection("Document", text),
    },
    {
      text: "Report Sent",
      icon: <MarkEmailReadIcon />,
      onClick: (text) => handleSelection("Report Sent", text),
    },
    {
      text: "Disabled Files",
      icon: <NotInterestedIcon />,
      onClick: (text) => handleSelection("Disabled Files", text),
    },
    {
      text: "Report",
      icon: <AssessmentIcon />,
      onClick: (text) => handleSelection("Report", text),
    },
    {
      text: "Report Download",
      icon: <DownloadIcon />,
      onClick: (text) => handleSelection("Report Download", text),
    },
    {
      text: "Draft",
      icon: <CopyAllIcon />,
      onClick: (text) => handleSelection("Draft", text),
    }
  ];
  return (
    <div>
      <List>
        {itemsList.map((item, index) => {
          const { text, icon, onClick } = item;
          return (
            <ListItem
              selected={text === value}
              button
              key={text}
              onClick={() => onClick(text)}
              style={value === text ? { backgroundColor: "#032437" } : {}}
            >
              {icon && (
                <ListItemIcon style={value === text ? { color: "white" } : {}}>
                  {icon}
                </ListItemIcon>
              )}
              <ListItemText
                style={value === text ? { color: "white" } : { color: "#000" }}
                primary={text}
              />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};
