import { createSlice } from "@reduxjs/toolkit";

export const selectionSlice = createSlice({
  name: "selection",
  initialState: {
    value: {
      bank: JSON.parse(localStorage.getItem('bank')),
      bankFilter: JSON.parse(localStorage.getItem('bankFilter')),
      doc: localStorage.doc,
      verifier: localStorage.verifier,
      filestat: localStorage.filestat,
    },
  },
  reducers: {
    selection: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { selection } = selectionSlice.actions;

export default selectionSlice.reducer;
